import { RolesBrowseTypes, RolesTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  EntitiesBrowserProps,
} from '../../../../states'
import {
  adaptSDKBrowseRequest,
  cacheKeys,
  isValidUuid,
} from '../../../../utils-hooks'
import {
  CARDS_DATA_PER_FETCH,
  EntitiesCardsBrowser,
} from '../../EntitiesCardsBrowser'
import { RoleCard } from './RoleCard'

export const RolesBrowser = (props: EntitiesBrowserProps) => (
  <BrowseEntitiesContextProvider
    dataPerFetch={CARDS_DATA_PER_FETCH}
    browse={browse}
    dataKey="role_id"
    searchable
    entityType="role"
    {...props}
  >
    <EntitiesCardsBrowser render={render} />
  </BrowseEntitiesContextProvider>
)

const browse: BrowseEntitiesConfig<RolesTypes> = {
  request: adaptSDKBrowseRequest({
    request: swiftClient.role.browse,
    select: [
      'created_at',
      'entity_description',
      'entity_name',
      'granted_on',
      'overseer_id',
      'overseer_name',
      'profile_count',
      'role_id',
      'updated_at',
    ],
    getFilters: (search: string) => {
      if (!search) {
        return undefined
      }

      const q = getQueryHandler<RolesBrowseTypes>()

      if (isValidUuid(search)) {
        return [
          q('WHERE', 'role_id', 'EQ', search),
          q('OR', 'overseer_id', 'EQ', search),
        ]
      }

      return [
        q('WHERE', 'entity_name', 'EQ_IC', search),
        q('OR', 'entity_description', 'EQ_IC', search),
      ]
    },
    sort: 'entity_name:asc',
  }),
  cacheKey: cacheKeys.roles,
}

const render = (role: RolesTypes) => <RoleCard role={role} />
