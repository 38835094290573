import { Button, Form, Swift } from '@swiftctrl/swift-component-library'

type FormAddButtonProps = {
  enabled: boolean
  loading: boolean
}

export const FormAddButton: Swift.FC<FormAddButtonProps> = ({
  enabled,
  loading,
}) => (
  <Form.Item>
    <Button
      type="primary"
      htmlType="submit"
      disabled={!enabled}
      loading={loading}
    >
      Add
    </Button>
  </Form.Item>
)
