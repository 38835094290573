import { Button } from '@swiftctrl/swift-component-library'
import { useNavigate } from 'react-router-dom'

export interface RedirectButtonProps {
  label: string
  redirectPath: string
}

export const RedirectButton = ({
  redirectPath,
  label,
}: RedirectButtonProps) => {
  const navigate = useNavigate()

  const handleButtonClick = () => navigate(redirectPath)
  return (
    <Button type="primary" onClick={handleButtonClick}>
      {label}
    </Button>
  )
}
