import { EntityBrowseTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { EntityType } from '../data/models'

export const buildEntityTypeFilter = (...entityTypes: EntityType[]) => {
  const q = getQueryHandler<EntityBrowseTypes>()

  if (entityTypes.length === 1) {
    return q('WHERE', 'entity_type_id', 'EQ', entityTypes[0])
  }

  return q('WHERE', 'entity_type_id', 'ANY', entityTypes)
}
