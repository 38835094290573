import {
  EntityBrowseTypes,
  EntityTypes,
  EntityTypesIds,
} from '@swiftctrl/api-client'
import { FilterParams, getQueryHandler } from '@swiftctrl/api-helpers'
import { useInfiniteQuery } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { buildEntityBrowserNextPageParam, cacheKeys } from '../../utils-hooks'

const LIMIT = 50

export const useBrowseChildren = (
  overseerId: string,
  entityType: EntityTypesIds | undefined,
) =>
  useInfiniteQuery({
    queryKey: [cacheKeys.EntityAncestryView_children, overseerId, entityType],
    queryFn: ({ pageParam, signal }) => {
      const q = getQueryHandler<EntityBrowseTypes>()

      const filters = [
        q('WHERE', 'overseer_id', 'EQ', overseerId),
        q('AND', 'entity_type_id', 'EQ', entityType),
      ]

      return browseChildren(overseerId, filters, pageParam, signal)
    },
    initialPageParam: 0,
    getNextPageParam: buildEntityBrowserNextPageParam,
    enabled: Boolean(entityType),
  })

export const browseChildren = (
  entityId: string,
  filters: FilterParams<keyof EntityTypes>[],
  pageParam: any,
  signal: AbortSignal,
) => {
  return swiftClientWithoutErrorHandling.entity.browse(
    {
      baseId: entityId,
      select: ['entity_id', 'entity_name', 'entity_type_id'],
      filters,
      offset: pageParam,
      limit: LIMIT,
    },
    {
      signal,
    },
  )
}
