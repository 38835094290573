import { PersonRoleArcheTypes, PersonTypes } from '@swiftctrl/api-client'
import { ColumnsType } from '@swiftctrl/swift-component-library'
import { PropsWithChildren } from 'react'
import { EntitiesTableBrowser } from '../../../components'
import { swiftClientWithoutErrorHandling } from '../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  FETCH_ALL_DATA,
} from '../../../states'
import { adaptSDKBrowseRequest, cacheKeys } from '../../../utils-hooks'
import { ArchetypeActionsDropdown } from './ArchetypeActionsDropdown'

type ArchetypesBrowserProps = PropsWithChildren<{
  person: PersonTypes
}>

export const ArchetypesBrowser = ({
  person,
  children,
}: ArchetypesBrowserProps) => {
  const browse = buildBrowse(person)

  const columns = buildColumns(person)

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={FETCH_ALL_DATA}
      browse={browse}
      dataKey="role_archetype_id"
      entityType="archetype"
    >
      <EntitiesTableBrowser columns={columns} />
      {children}
    </BrowseEntitiesContextProvider>
  )
}

const buildBrowse = (
  person: PersonTypes,
): BrowseEntitiesConfig<PersonRoleArcheTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClientWithoutErrorHandling.person.at(person.person_id)
      .roleArcheTypes.browse,
    select: [
      'role_archetype_id',
      'role_archetype',
      'building_selection',
      'buildings',
    ],
  }),
  cacheKey: [cacheKeys.person_role_archetypes, person.person_id],
})

const buildColumns = (
  person: PersonTypes,
): ColumnsType<Partial<PersonRoleArcheTypes>> => [
  {
    title: 'Archetype',
    render: (_, archetype) => <>{archetype.role_archetype}</>,
    width: '50%',
  },
  {
    title: 'Buildings',
    render: (_, archetype) => {
      if (archetype.building_selection === 'all') {
        return 'All Buildings'
      }

      const buildingNames = archetype.buildings?.map(
        (building) => building.building_name,
      )

      return buildingNames?.join(', ')
    },
    width: '50%',
  },
  {
    key: 'action',
    render: (_, archetype) => (
      <ArchetypeActionsDropdown archetype={archetype} person={person} />
    ),
  },
]
