import { swiftDefaultTheme } from '@swiftctrl/swift-component-library'
import { colors } from './Colors'

// Extends the default theme and overrides the button styles
const appTheme = swiftDefaultTheme

appTheme.designTokens.colors.accent.primary.accent =
  colors['primary-color-darker-3']

export { appTheme }
