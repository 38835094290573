import { useEventEmitter } from 'ahooks'
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

type EventAction =
  | 'BrowseEntitiesContext.isFetching'
  | 'credentialTemplate.valid'
  | 'CredentialTemplateScreenHeader.DuplicateButton.click'
  | 'CredentialTemplateScreenHeader.PreviewButton.click'
  | 'hasUnsavedChanges'
  | 'JsonEditor.TextEditor.change'
  | 'JsonEditor.TextEditor.valid'
  | 'JsonEditor.UIEditor.change'
  | 'PersonHasNoEmailPasswordProviderProfile.CreateNewButton.click'
  | 'ProfileSelect.change'
  | 'role.permission.added'

type Event = {
  action: EventAction
  payload: any
}

type ContextType = {
  /**
   * Events should be emitted _outside_ of the component rendering.
   * For example, in click event handlers, or in `useEffect` bodies.
   *
   * This is because received events are typically used to update state.
   * If the event is emitted while the origin component is rendering,
   * then React will log this warning to the console:
   * `Cannot update a component while rendering a different component`.
   */
  emitEvent: (action: EventAction, payload?: any) => void
  useEvent: (action: EventAction, callback: (payload: any) => void) => void
}

const EventContext = createContext<ContextType | undefined>(undefined)

export const EventContextProvider = ({ children }: PropsWithChildren) => {
  const { emit, useSubscription } = useEventEmitter<Event>()

  const emitEvent = (action: EventAction, payload: any) => {
    emit({ action, payload })
  }

  const useEvent = (action: EventAction, callback: (payload: any) => void) => {
    useSubscription((event) => {
      if (event.action === action) {
        callback(event.payload)
      }
    })
  }

  return (
    <EventContext.Provider value={{ emitEvent, useEvent }}>
      {children}
    </EventContext.Provider>
  )
}

export const useEventContext = () => {
  const context = useContext(EventContext)

  if (!context) {
    throw new Error('useEventContext must be used within EventContextProvider')
  }

  return context
}

/**
 * Utility hook for persisting an action payload in state
 */
const useEventState = <T,>(action: EventAction, initialState: T) => {
  const [state, setState] = useState<T>(initialState)

  const { useEvent } = useEventContext()

  useEvent(action, setState)

  return state
}

/**
 * Utility hook for persisting a boolean action payload in state
 */
export const useBooleanEventState = (
  action: EventAction,
  initialState = false,
) => useEventState<boolean>(action, initialState)

/**
 * Utility hook for emitting an action payload using an effect
 */
export const useEventEmitterEffect = (action: EventAction, payload: any) => {
  const { emitEvent } = useEventContext()

  useEffect(() => {
    emitEvent(action, payload)
  }, [emitEvent, action, payload])
}
