import { AccessLevelFilterTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { useInfiniteQuery } from '@tanstack/react-query'
import { TABLE_DATA_PER_FETCH } from '../../../../components'
import { swiftClient } from '../../../../data/swiftClient'
import {
  buildEntityBrowserNextPageParam,
  cacheKeys,
  flattenInfiniteQueryData,
} from '../../../../utils-hooks'

export const useInfiniteBrowseAccessLevels = ({
  landlordId,
  tenantId,
  buildingId,
  enabled,
  assigned,
  search,
}: {
  landlordId: string
  tenantId: string
  buildingId: string
  enabled: boolean
  assigned: boolean
  search?: string
}) => {
  const { data, fetchNextPage, isFetching } = useInfiniteQuery({
    queryKey: [
      cacheKeys.landlord_tenant_building_access_levels,
      landlordId,
      tenantId,
      buildingId,
      assigned,
      search,
    ],
    queryFn: ({ pageParam, signal }) => {
      const q = getQueryHandler<AccessLevelFilterTypes>()

      const filters = [q('WHERE', 'is_allocated', 'EQ', assigned)]

      return swiftClient.organization
        .at(landlordId)
        .tenant.at(tenantId)
        .buildings.at(buildingId)
        .accessLevel.browse(
          {
            filters,
            sort: 'entity_name:asc',
            limit: TABLE_DATA_PER_FETCH,
            offset: pageParam,
            search,
          },
          {
            signal,
          },
        )
    },
    enabled,
    initialPageParam: 0,
    getNextPageParam: buildEntityBrowserNextPageParam,
  })

  const flattenedData = flattenInfiniteQueryData(data) || []

  return {
    data: flattenedData,
    fetchNextPage,
    isFetching,
    isFetchingFirstTime: !data,
  }
}
