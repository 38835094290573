import { ProfilesTypes } from '@swiftctrl/api-client'
import { FilterOutlined, Result } from '@swiftctrl/swift-component-library'
import { CARDS_DATA_PER_FETCH, EntitiesCardsBrowser } from '../../components'
import { SWIFT_ROOT_ENTITY_ID } from '../../data/models'
import { swiftClient } from '../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
} from '../../states'
import { adaptSDKBrowseRequest, cacheKeys, useBoolean } from '../../utils-hooks'
import { AddProfileView } from './AddProfileView'
import { ProfileCard } from './ProfileCard'

export const ProfilesBrowser = () => {
  const [isAddViewOpen, openAddView, closeAddView] = useBoolean()

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={CARDS_DATA_PER_FETCH}
      browse={browse}
      dataKey="profile_id"
      searchable
      entityType="profile"
      filters={{
        baseIdType: 'organization',
      }}
      enableQuery={{
        condition: (baseId: string) => baseId !== SWIFT_ROOT_ENTITY_ID,
        disabledView: (
          <Result icon={<FilterOutlined />} title="Select an organization" />
        ),
      }}
      displayAddButton
      onAddButtonClick={openAddView}
    >
      <EntitiesCardsBrowser
        render={(profile: ProfilesTypes) => <ProfileCard profile={profile} />}
      />
      <AddProfileView open={isAddViewOpen} onClose={closeAddView} />
    </BrowseEntitiesContextProvider>
  )
}

const browse: BrowseEntitiesConfig<ProfilesTypes> = {
  request: adaptSDKBrowseRequest({
    request: swiftClient.profile.browse,
    select: [
      'profile_id',
      'display_name',
      'first_name',
      'last_name',
      'email',
      'phone_primary',
      'overseer_id',
      'overseer_name',
      'created_at',
      'updated_at',
      'person_id',
    ],
    getSearch: (search: string) => search,
  }),
  cacheKey: cacheKeys.profiles,
}
