import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons'
import { Button, Tooltip } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'

type CredentialTemplateFieldIntegerExpandButtonProps = {
  fieldId: string
  expandedFieldIds: string[]
  formatHasValidValues: boolean
  expandFieldId: (id: string) => void
  collapseFieldId: (id: string) => void
}

const ICON_OUTLINE_COLOR = '#f0f0f0'

const ICON_SYMBOL_COLOR = '#262626'

export const CredentialTemplateFieldIntegerExpandButton = ({
  fieldId,
  expandedFieldIds,
  formatHasValidValues,
  expandFieldId,
  collapseFieldId,
}: CredentialTemplateFieldIntegerExpandButtonProps) => {
  const isExpanded = expandedFieldIds.includes(fieldId)

  const icon = isExpanded ? <StyledCollapseIcon /> : <StyledExpandIcon />

  const onClick = () => {
    const callback = isExpanded ? collapseFieldId : expandFieldId

    callback(fieldId)
  }

  const tooltip = formatHasValidValues
    ? "Due to the format field constraints, bitfield value can't be added"
    : undefined

  return (
    <Tooltip title={tooltip}>
      <Button
        type="link"
        size="small"
        icon={icon}
        onClick={onClick}
        disabled={formatHasValidValues}
      />
    </Tooltip>
  )
}

const StyledCollapseIcon = styled(MinusSquareOutlined)`
  path:nth-of-type(1) {
    color: ${ICON_SYMBOL_COLOR};
  }

  path:nth-of-type(2) {
    color: ${ICON_OUTLINE_COLOR};
  }
`

const StyledExpandIcon = styled(PlusSquareOutlined)`
  path:nth-of-type(1) {
    color: ${ICON_SYMBOL_COLOR};
  }

  path:nth-of-type(2) {
    color: ${ICON_OUTLINE_COLOR};
  }
`
