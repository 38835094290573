import { OrganizationTypes } from '@swiftctrl/api-client'
import { styled } from 'styled-components'
import { TagTabs, TagTabsProps } from '../../../components'
import { useGetValidParam, useHandleUrlParams } from '../../../utils-hooks'
import { AssignedBuildingsBrowser } from './AssignedBuildingsBrowser'
import { DescendantsBuildingsBrowser } from './DescendantsBuildingsBrowser'
import { ManagedBuildingsBrowser } from './ManagedBuildingsBrowser'

type BuildingsTabViewProps = {
  organization: OrganizationTypes
}

enum BuildingsTabViewKey {
  Descendants = 'descendants',
  Managed = 'managed',
  Assigned = 'assigned',
}

export const BuildingsTabView = ({ organization }: BuildingsTabViewProps) => {
  const { organization_id } = organization

  const { updateParams } = useHandleUrlParams()

  const viewParam = useGetValidParam({
    paramKey: 'view',
    validator: validateViewParam,
  })

  const updateUrl = (view: string) => {
    updateParams({ view })
  }

  const activeView = viewParam || BuildingsTabViewKey.Descendants

  const items: TagTabsProps['items'] = [
    {
      key: BuildingsTabViewKey.Descendants,
      label: 'Descendants',
      children: (
        <DescendantsBuildingsBrowser organizationId={organization_id} />
      ),
    },
    {
      key: BuildingsTabViewKey.Managed,
      label: 'Managed',
      children: <ManagedBuildingsBrowser organizationId={organization_id} />,
    },
    {
      key: BuildingsTabViewKey.Assigned,
      label: 'Assigned',
      children: <AssignedBuildingsBrowser tenant={organization} />,
    },
  ]

  return (
    <StyledTagTabs
      items={items}
      defaultActiveKey={activeView}
      onChange={updateUrl}
    />
  )
}

const validateViewParam = (viewParam: string | undefined) => {
  switch (viewParam) {
    case BuildingsTabViewKey.Descendants:
    case BuildingsTabViewKey.Managed:
    case BuildingsTabViewKey.Assigned:
      return viewParam
  }

  return ''
}

const StyledTagTabs = styled(TagTabs)`
  padding-top: 1em;
`
