import { CredentialFormatsTypes } from '@swiftctrl/api-client'
import { Drawer, Form, Input, Select } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CREDENTIAL_TEMPLATES_SCREEN_PATH } from '../../../../config/paths'
import { SWIFT_ROOT_ENTITY_ID } from '../../../../data/models'
import { useBrowseEntitiesContext } from '../../../../states'
import {
  buildCommonDrawerProps,
  buildCommonFormProps,
  getErrorMessage,
  hasSomeValue,
  showSuccessNotification,
  useCheckBeforeClose,
  useReadCredentialFormat,
} from '../../../../utils-hooks'
import { EntitySelect } from '../../../add-entities'
import { AddFormButtonGroup } from '../../../AddFormButtonGroup'
import { ErrorAlert } from '../../../ErrorAlert'
import { useAddCredentialTemplate } from './useAddCredentialTemplate'
import { AddCredentialTemplateFormData } from './utils'

type AddCredentialTemplateViewProps = {
  open: boolean
  onClose: () => void
}

export const AddCredentialTemplateView = ({
  open,
  onClose,
}: AddCredentialTemplateViewProps) => {
  const [values, setValues] = useState<AddCredentialTemplateFormData>({})

  const [error, setError] = useState<Error | undefined>()

  const reset = () => {
    setValues({})

    setError(undefined)
  }

  const isDirty = hasSomeValue(values)

  const { invalidateData } = useBrowseEntitiesContext()

  const navigate = useNavigate()

  const { mutate, isPending } = useAddCredentialTemplate({
    onSuccess: (credentialTemplateId) => {
      showSuccessNotification('Credential template added')

      reset()

      invalidateData()

      navigate(`/${CREDENTIAL_TEMPLATES_SCREEN_PATH}/${credentialTemplateId}`)
    },
    onError: setError,
  })

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending,
    isDirty,
    reset,
    close: onClose,
  })

  const credentialFormatId = values.credentialFormat?.entity_id

  const {
    data: fullCredentialFormat,
    isFetching: readingFullCredentialFormat,
    error: readFullCredentialFormatError,
  } = useReadCredentialFormat(credentialFormatId!, Boolean(credentialFormatId))

  const isValid = validate(values, fullCredentialFormat)

  const handleFinish = () => {
    setError(undefined)

    mutate({ values, fullCredentialFormat })
  }

  return (
    <Drawer
      {...buildCommonDrawerProps({
        open,
        title: 'Add Credential Template',
        onClose: checkBeforeClose,
      })}
    >
      <Form
        {...buildCommonFormProps({
          values,
          setValues,
          isPending,
          onFinish: handleFinish,
        })}
      >
        <Form.Item
          name="credentialFormat"
          label="Core Credential Format"
          rules={[
            {
              required: true,
              message: 'Core Credential Format is required',
            },
          ]}
        >
          <EntitySelect
            baseId={SWIFT_ROOT_ENTITY_ID}
            entityType="credential_format"
            size="large"
          />
        </Form.Item>
        <Form.Item
          label="Overseer"
          required
          validateStatus={readFullCredentialFormatError ? 'error' : undefined}
          help={
            readFullCredentialFormatError
              ? getErrorMessage(readFullCredentialFormatError)
              : undefined
          }
        >
          <Select
            showLabel={false}
            disabled
            loading={readingFullCredentialFormat}
            value={fullCredentialFormat?.overseer_id}
            options={
              fullCredentialFormat
                ? [
                    {
                      value: fullCredentialFormat.overseer_id,
                      label:
                        fullCredentialFormat.overseer_name ||
                        fullCredentialFormat.overseer_id,
                    },
                  ]
                : []
            }
          />
        </Form.Item>
        <Form.Item
          name="templateName"
          label="Name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Name is required',
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="templateDescription"
          label="Description"
          required={false}
        >
          <Input.TextArea size="large" rows={4} />
        </Form.Item>
        {error && (
          <Form.Item>
            <ErrorAlert error={error} />
          </Form.Item>
        )}
        <AddFormButtonGroup
          onClose={checkBeforeClose}
          isValid={isValid}
          isPending={isPending}
        />
      </Form>
    </Drawer>
  )
}

const validate = (
  values: AddCredentialTemplateFormData,
  fullCredentialFormat: CredentialFormatsTypes | undefined,
) => {
  const { credentialFormat, templateName } = values

  if (!credentialFormat || !templateName || !fullCredentialFormat) {
    return false
  }

  return true
}
