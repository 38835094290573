import { SinkBrowseTypes, SinkTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import {
  ColumnsType,
  Layout,
  Typography,
} from '@swiftctrl/swift-component-library'
import {
  ORGANIZATIONS_SCREEN_PATH,
  ORGANIZATION_SCREEN_SINKS_PATH,
} from '../../../../config/paths'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  EntitiesBrowserProps,
  FETCH_ALL_DATA,
} from '../../../../states'
import {
  adaptSDKBrowseRequest,
  buildEntityTypeTagsList,
  cacheKeys,
  useBoolean,
} from '../../../../utils-hooks'
import { CopyableDisplay, CopyableLink } from '../../../data-display'
import { EntitiesBrowserStyles } from '../../EntitiesBrowser'
import { EntitiesTableBrowser } from '../../EntitiesTableBrowser'
import { AddSinkView } from './add-sink'
import { DeleteSinkButton } from './DeleteSinkButton'

type SinksBrowserProps = Omit<EntitiesBrowserProps, 'baseId'> &
  Pick<EntitiesBrowserStyles, 'nested'> & {
    sourceId: string
    organizationId: string
  }

export const SinksBrowser = ({
  sourceId,

  nested,
  organizationId,
  displayAddButton,
  ...props
}: SinksBrowserProps) => {
  const [addSinkViewOpen, openAddSinkView, closeAddSinkView] = useBoolean()

  const columns = getColumns(organizationId)

  return (
    <>
      <BrowseEntitiesContextProvider
        dataPerFetch={FETCH_ALL_DATA}
        browse={buildBrowse(sourceId)}
        dataKey="sink_id"
        entityType="sink"
        onAddButtonClick={openAddSinkView}
        displayAddButton={displayAddButton}
        {...props}
      >
        <Layout grow paddingBottom="xxLarge">
          <EntitiesTableBrowser
            nested={nested}
            columns={columns}
            background={false}
          />
        </Layout>
      </BrowseEntitiesContextProvider>
      <AddSinkView
        open={addSinkViewOpen}
        sourceId={sourceId}
        onClose={closeAddSinkView}
      />
    </>
  )
}

const buildBrowse = (sourceId: string) => {
  const browse: BrowseEntitiesConfig<SinkTypes> = {
    request: adaptSDKBrowseRequest({
      request: swiftClient.sink.browse,
      // The `source_id` is not displayed, but it is still needed here.
      // It's used in the navigation to the sink screen.
      // On sink deletion, it's used to invalidate the cache in order to refetch the sinks
      select: [
        'sink_id',
        'entity_name',
        'sink_target_id',
        'sink_target_entity',
        'sink_owner_id',
        'sink_owner',
        'types_using_target_id',
        'source_id',
      ],
      sort: 'entity_name:asc',

      getFilters: () => {
        const q = getQueryHandler<SinkBrowseTypes>()

        return [q('WHERE', 'source_id', 'EQ', sourceId)]
      },
    }),
    cacheKey: [cacheKeys.sinks, sourceId],
  }

  return browse
}

const getColumns = (organizationId: string) => {
  const columns: ColumnsType<SinkTypes> = [
    {
      title: 'Sink Name',
      render: (_, sink) => {
        const { sink_id, entity_name } = sink

        const path = `/${ORGANIZATIONS_SCREEN_PATH}/${organizationId}/${ORGANIZATION_SCREEN_SINKS_PATH}/${sink_id}`

        return (
          <CopyableLink
            to={path}
            label={entity_name || sink_id}
            copyableText={sink_id}
          />
        )
      },
      width: '36%',
    },
    {
      title: 'Sink Target Entity',
      render: (_, sink) => (
        <CopyableDisplay
          label={sink.sink_target_entity.entity_name}
          copyableText={sink.sink_target_id}
        />
      ),
      width: '15%',
    },
    {
      title: 'Owner',
      render: (_, sink) => (
        <CopyableDisplay
          label={sink.sink_owner.entity_name}
          copyableText={sink.sink_owner_id}
        />
      ),
      width: '15%',
    },
    {
      title: 'Type Names',
      render: (_, sink) => (
        <Typography.Text>
          {buildEntityTypeTagsList(sink.types_using_target_id)}
        </Typography.Text>
      ),
      width: '34%',
    },
    {
      render: (_, sink) => <DeleteSinkButton sink={sink} />,
      width: '20px',
    },
  ]

  return columns
}
