import { useLocation } from 'react-router-dom'
import {
  SINK_SCREEN_PLACEHOLDERS_TAB,
  SINK_SCREEN_RULES_TAB,
} from '../../../config/paths'

export const usePathToRule = () => {
  const { pathname } = useLocation()

  const path = pathname.replace(
    SINK_SCREEN_PLACEHOLDERS_TAB,
    SINK_SCREEN_RULES_TAB,
  )

  return path
}
