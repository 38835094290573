import { AxiosResponse } from '@swiftctrl/api-client'
import { Modal } from '@swiftctrl/swift-component-library'
import { useMutation } from '@tanstack/react-query'
import { getEntityLabel } from '../config/labels'
import { EntityType } from '../data/models'
import { queryClient } from '../data/queryClient'
import { getEntityName } from './getEntityName'
import { showErrorNotification, showSuccessNotification } from './notifications'

type Action = 'delete' | 'remove'

type Params = {
  entity: object
  entityType: EntityType
  action?: Action
  mutationFn: () => Promise<AxiosResponse<any, any>>
  invalidateQueryKeys: string[][]
}

type ActionLabels = {
  present: string
  presentCapitalized: string
  past: string
  continuous: string
}

const actionToActionLabels: Record<Action, ActionLabels> = {
  delete: {
    present: 'delete',
    presentCapitalized: 'Delete',
    past: 'deleted',
    continuous: 'deleting',
  },
  remove: {
    present: 'remove',
    presentCapitalized: 'Remove',
    past: 'removed',
    continuous: 'removing',
  },
}

export const useHandleDeleteClick = ({
  entity,
  entityType,
  action = 'delete',
  mutationFn,
  invalidateQueryKeys,
}: Params) => {
  const entityLabel = getEntityLabel({ entityType, capitalize: false })

  const actionLabels = actionToActionLabels[action]

  const handleDeleteClick = () => {
    Modal.confirm({
      centered: true,
      title: `${actionLabels.presentCapitalized} ${entityLabel}`,
      content: (
        <>
          Are you sure you want to {actionLabels.present}{' '}
          <b>{getEntityName(entity, entityType)}</b>?
        </>
      ),
      okText: actionLabels.presentCapitalized,
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
      onOk: () => mutate(),
    })
  }

  const { mutate } = useMutation({
    mutationFn,
    onSuccess: () => {
      invalidateQueryKeys.forEach((queryKey) => {
        queryClient.invalidateQueries({
          queryKey,
        })
      })

      const message = `${getEntityLabel({ entityType })} ${actionLabels.past}`

      showSuccessNotification(message)
    },
    onError: (error) => {
      const message = `Error while ${actionLabels.continuous} ${entityLabel}`

      showErrorNotification(message, error)
    },
  })

  return { handleDeleteClick }
}
