import { EntityTypes } from '@swiftctrl/api-client'

export const isTargetInArray = (
  target: Partial<EntityTypes>,
  targets: Partial<EntityTypes>[],
) => {
  const targetId = target.entity_id

  for (let i = 0; i < targets.length; i++) {
    const { entity_id } = targets[i]

    if (entity_id === targetId) {
      return true
    }
  }

  return false
}
