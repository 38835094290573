import { CredentialFieldConstraint } from '@swiftctrl/api-client'
import { RemoveConstraintButton } from '../RemoveConstraintButton'
import { TooltipIcon } from '../TooltipIcon'
import { BitfieldValue, Constraint } from '../utils'
import { IntegerConstraintTypeView } from './IntegerConstraintTypeView'
import { IntegerRangeInput } from './IntegerRangeInput'
import {
  getBitfieldValueMax,
  getBitfieldValueMin,
  IntegerConstraintContainer,
} from './utils'

type TemplateBitfieldValueWithTemplateIntegerRangeViewProps = {
  templateConstraint: Constraint
  templateBitfieldValue: BitfieldValue
  formatConstraint: CredentialFieldConstraint | undefined
  onChange: (constraint: Constraint | null) => void
}

export const TemplateBitfieldValueWithTemplateIntegerRangeView = ({
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: TemplateBitfieldValueWithTemplateIntegerRangeViewProps) => {
  const min = getBitfieldValueMin(templateBitfieldValue)

  const max = getBitfieldValueMax(templateBitfieldValue)

  const templateIntegerRange = templateConstraint.integer_range!

  const startDefaultValue =
    templateIntegerRange.start !== undefined ? templateIntegerRange.start : min

  const endDefaultValue =
    templateIntegerRange.end !== undefined ? templateIntegerRange.end : max

  const tooltip = buildTooltip(min, max)

  return (
    <IntegerConstraintContainer>
      <IntegerConstraintTypeView
        value="integer_range"
        templateConstraint={templateConstraint}
        templateBitfieldValue={templateBitfieldValue}
        formatConstraint={formatConstraint}
        onChange={onChange}
      />
      <TooltipIcon message={tooltip} />
      <IntegerRangeInput
        startDefaultValue={startDefaultValue}
        endDefaultValue={endDefaultValue}
        min={min}
        max={max}
        onChange={(start, end) => {
          onChange({
            integer_range: { start, end },
            date_range: null,
            valid_values: null,
          })
        }}
      />
      <RemoveConstraintButton onClick={() => onChange(null)} />
    </IntegerConstraintContainer>
  )
}

const buildTooltip = (min: number | undefined, max: bigint | undefined) => {
  if (min === undefined || max === undefined) {
    return undefined
  }

  const minLabel = min.toLocaleString()

  const maxLabel = max.toLocaleString()

  const tooltip = `Due to the template field constraints, the integer range min and max are ${minLabel} and ${maxLabel}`

  return tooltip
}
