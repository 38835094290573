import { SinkTypes } from '@swiftctrl/api-client'
import { Button } from '@swiftctrl/swift-component-library'
import { Link, useParams } from 'react-router-dom'
import {
  ORGANIZATIONS_SCREEN_PATH,
  ORGANIZATION_SCREEN_SINKS_PATH,
} from '../../../config/paths'
import { buildEntityTypeTagsList } from '../../../utils-hooks'
import { DetailItem } from './DetailItem'

type SinkDetailsProps = {
  sink: SinkTypes
}

export const SinkDetails = ({ sink }: SinkDetailsProps) => {
  const params = useParams<{ organizationId: string }>()

  const { types_using_target_id, sink_target_entity, sink_id } = sink

  return (
    <>
      <DetailItem
        label="Types using target ID"
        value={buildEntityTypeTagsList(types_using_target_id)}
      />
      <DetailItem label="Target" value={sink_target_entity.entity_name} />
      <Link
        to={`/${ORGANIZATIONS_SCREEN_PATH}/${params.organizationId}/${ORGANIZATION_SCREEN_SINKS_PATH}/${sink_id}`}
      >
        <Button size="middle">View details</Button>
      </Link>
    </>
  )
}
