import { EntityTypes } from '@swiftctrl/api-client'
import { useEffect, useState } from 'react'
import { SWIFT_ROOT_ENTITY_ID } from '../data/models'
import { useReadEntity } from './web'

type Params = {
  entity: EntityTypes
  enabled?: boolean
}

export const useGetAncestry = ({ entity }: Params) => {
  const [ancestry, setAncestry] = useState<EntityTypes[]>([])

  let nextOverseerId = entity.overseer_id

  let hasReachedRoot = entity.entity_id === SWIFT_ROOT_ENTITY_ID

  if (ancestry.length > 0) {
    const [topOverseer] = ancestry

    nextOverseerId = topOverseer.overseer_id

    hasReachedRoot = topOverseer.entity_id === SWIFT_ROOT_ENTITY_ID
  }

  const { data } = useReadEntity(nextOverseerId, !hasReachedRoot)

  useEffect(() => {
    setAncestry((previous) => {
      if (!data) {
        return previous
      }

      const included = previous.some(
        (overseer) => overseer.entity_id === data.entity_id,
      )

      if (included) {
        return previous
      }

      if (data.entity_id === entity.entity_id) {
        return previous
      }

      return [data, ...previous]
    })
  }, [data, entity.entity_id])

  return {
    ancestry,
    isPending: !hasReachedRoot,
  }
}
