import { useEffect, useRef } from 'react'
import { RestApiParamsKeys } from '../types'
import { showInvalidFilterEntryErrorNotification } from './notifications'
import { useHandleUrlParams } from './useHandleParams'
import { isValidUuid } from './uuidUtils'

type Params = { [key in RestApiParamsKeys]?: string | undefined }

/**
 * Ensures that the URL params are valid UUIDs.
 *
 * If a param isn't a valid UUID, it will be removed from the URL.
 *
 * An "Invalid filter" notification will also be displayed.
 */
export const useGetValidUuidParams = (paramKeys: RestApiParamsKeys[]) => {
  const { currentParams, cleanParams } = useHandleUrlParams()

  const paramsToCleanRef = useRef(new Array<RestApiParamsKeys>())

  const params: Params = {}

  paramKeys.forEach((paramKey) => {
    const param = currentParams[paramKey]

    switch (typeof param) {
      case 'number':
        paramsToCleanRef.current.push(paramKey)

        return

      case 'string':
        if (!isValidUuid(param)) {
          paramsToCleanRef.current.push(paramKey)

          return
        }

        params[paramKey] = param
    }
  })

  useEffect(() => {
    if (paramsToCleanRef.current.length === 0) {
      return
    }

    paramsToCleanRef.current.forEach((paramKey) => {
      showInvalidFilterEntryErrorNotification(paramKey)
    })

    cleanParams(paramsToCleanRef.current)

    paramsToCleanRef.current.length = 0
  }, [cleanParams])

  return params
}
