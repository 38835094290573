import { Button, CheckOutlined, Icon } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'

interface AddButtonOrCheckmarkDisplayProps {
  showAddButton: boolean
  addButtonDisabled: boolean
  onAdd: () => void
}

const WIDTH = '45px'

export const AddButtonOrCheckmarkDisplay = ({
  showAddButton,
  addButtonDisabled,
  onAdd,
}: AddButtonOrCheckmarkDisplayProps) => {
  if (showAddButton) {
    return (
      <StyledButton
        size="small"
        onClick={() => onAdd()}
        disabled={addButtonDisabled}
      >
        Add
      </StyledButton>
    )
  }

  return (
    <StyledDiv>
      <Icon color="success" component={() => <CheckOutlined />} />
    </StyledDiv>
  )
}

const StyledButton = styled(Button)`
  border-color: #531dab;
  color: #531dab;
  width: ${WIDTH};
`

const StyledDiv = styled.div`
  text-align: center;
  height: 24px;
  width: ${WIDTH};
`
