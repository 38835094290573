import {
  ContactFieldsSetupValues,
  OrganizationTypes,
  ProfileAddTypes,
} from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { VALID_EMAIL_FORMAT } from '../../../../data/models'
import { queryClient } from '../../../../data/queryClient'
import {
  BackendError,
  swiftClientWithoutErrorHandling,
} from '../../../../data/swiftClient'
import {
  cacheKeys,
  showSuccessNotification,
  useCheckBeforeClose,
} from '../../../../utils-hooks'
import { AddProfileFormData, ContactOptions } from './utils'

const CONTACT_EMAIL_OPTIONS: ContactFieldsSetupValues[] = ['email', 'both']

const CONTACT_PHONE_OPTIONS: ContactFieldsSetupValues[] = ['phone', 'both']

export const useAddProfileLogic = ({
  organization: organizationProp,
  onClose,
}: {
  organization: OrganizationTypes | undefined
  onClose: () => void
}) => {
  const initialValues = { organization: organizationProp }

  const [values, setValues] = useState<AddProfileFormData>(initialValues)

  const [error, setError] = useState<BackendError | undefined>()

  const organization = getOrganization(values, organizationProp)

  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      const { firstName, lastName, email, phone } = values

      const { organization_id } = organization!

      const payload: ProfileAddTypes = {
        first_name: firstName!,
        last_name: lastName!,
        overseer_id: organization_id,
        email,
        phone_primary: phone,
        organization_id,
      }

      return swiftClientWithoutErrorHandling.profile.add(payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [cacheKeys.profiles],
      })

      queryClient.invalidateQueries({
        queryKey: [cacheKeys.organization_profiles],
      })

      queryClient.invalidateQueries({
        queryKey: [cacheKeys.person_profiles],
      })

      reset()

      showSuccessNotification('Profile added')

      onClose()
    },
    onError: setError,
  })

  const reset = () => {
    setValues({ organization: organizationProp })

    setError(undefined)
  }

  const isDirty = !isEqual(values, initialValues)

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending,
    isDirty,
    close: onClose,
    reset,
  })

  const handleFinish = () => {
    setError(undefined)

    mutate()
  }

  const contactOptions = getContactOptions(organization)

  const isValid = validate(values, contactOptions)

  const {
    email: emailOptions,
    phone: phoneOptions,
    requireEmailOrPhone,
  } = contactOptions

  return {
    values,
    setValues,
    error,
    isValid,
    checkBeforeClose,
    handleFinish,
    isPending,
    emailOptions,
    phoneOptions,
    requireEmailOrPhone,
  }
}

/**
 * Slightly unusual way to get the organization, but it's based on the usages.
 *
 * If the organization is not initially passed in, then the form that consumes
 * this hook requires the organization to be entered via a select. In which case,
 * the `values.organization` will have a value.
 *
 * Otherwise, the `organizationProp` has a value, but the `values.organization`
 * does not because the other form that consumes this hook has a dummy select,
 * which doesn't work well with passing the organization in the initial values.
 */
const getOrganization = (
  values: AddProfileFormData,
  organizationProp: OrganizationTypes | undefined,
) => {
  const { organization } = values

  if (organization) {
    return organization
  }

  return organizationProp
}

const getContactOptions = (
  organization: OrganizationTypes | undefined,
): ContactOptions => {
  const profileSettings = organization?.extra_json?.entity_settings?.profile

  if (!profileSettings) {
    return {
      email: {
        required: true,
      },
      phone: {
        visible: true,
        required: true,
      },
      requireEmailOrPhone: true,
    }
  }

  const { hidden_fields, mandatory_contact_fields } = profileSettings

  return {
    email: {
      required: CONTACT_EMAIL_OPTIONS.includes(mandatory_contact_fields),
    },
    phone: {
      visible: hidden_fields ? !hidden_fields.includes('phone') : true,
      required: CONTACT_PHONE_OPTIONS.includes(mandatory_contact_fields),
    },
    requireEmailOrPhone: mandatory_contact_fields === 'any',
  }
}

const validate = (
  values: AddProfileFormData,
  contactOptions: ContactOptions,
) => {
  const { firstName, lastName, email, phone } = values

  if (!firstName || !lastName) {
    return false
  }

  if (contactOptions.email.required && !email) {
    return false
  }

  if (contactOptions.phone.required && !phone) {
    return false
  }

  if (contactOptions.requireEmailOrPhone && !email && !phone) {
    return false
  }

  if (email && !VALID_EMAIL_FORMAT.test(email)) {
    return false
  }

  if (phone && !isPossiblePhoneNumber(phone)) {
    return false
  }

  return true
}
