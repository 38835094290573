import { MultiTargetPredicate } from '@swiftctrl/api-client'
import { Empty } from '@swiftctrl/swift-component-library'
import { Collapse } from 'antd'
import styled from 'styled-components'
import { CollapseContainer, CopyableDisplay } from '../../components'
import { normalizeFilterConfiguration } from '../../utils-hooks'
import { FilterGroupsView } from './FilterGroupsView'

type MultiTargetsViewProps = {
  multiTargets: MultiTargetPredicate[] | null
}

export const MultiTargetsView = ({ multiTargets }: MultiTargetsViewProps) => {
  if (!multiTargets || multiTargets.length === 0) {
    return <Empty description="No multi targets" />
  }

  return (
    <CollapseContainer>
      <Collapse accordion bordered={false}>
        {multiTargets.map((predicate, index) => {
          const { target_id, filter_configuration } = predicate

          const filterConfiguration =
            normalizeFilterConfiguration(filter_configuration)

          return (
            <Collapse.Panel
              key={index}
              header={
                <StyledCopyableDisplay
                  label="Target ID"
                  copyableText={target_id}
                />
              }
            >
              <FilterGroupsView filterConfiguration={filterConfiguration} />
            </Collapse.Panel>
          )
        })}
      </Collapse>
    </CollapseContainer>
  )
}

const StyledCopyableDisplay = styled(CopyableDisplay)`
  font-weight: 600;
`
