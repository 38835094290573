import { CommonEntityTypes, EntityTypes } from '@swiftctrl/api-client'
import { useQuery } from '@tanstack/react-query'
import { swiftClient } from '../data/swiftClient'
import { cacheKeys, longCacheOptions } from './cacheKeysUtils'
import { getEntityId } from './getEntityId'

type Params = {
  entity: CommonEntityTypes
}

export const useGetOverseerOrganization = ({ entity }: Params) =>
  useQuery({
    queryKey: [
      cacheKeys.OverseerOrganization,
      getEntityId(entity, entity.entity_type_id),
    ],
    queryFn: ({ signal }) =>
      getOverseerOrganization(entity.overseer_id, signal),
    ...longCacheOptions,
  })

const getOverseerOrganization = async (
  overseerId: string,
  signal: AbortSignal,
): Promise<EntityTypes> => {
  let overseer = { overseer_id: overseerId } as EntityTypes

  do {
    let response = await swiftClient.entity
      .at(overseer.overseer_id)
      .read({ signal })

    overseer = response.data
  } while (overseer?.entity_type_id !== 'organization')

  return overseer
}
