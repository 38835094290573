import { styled } from 'styled-components'
import {
  ENTITIES_BROWSER_TOP_BAR_HEIGHT,
  HTMLTitle,
  StyledEntitiesBrowserScreen,
} from '../components'
import { ProfilesBrowser } from '../screen-components/profiles-screen'
import { APP_HEADER_HEIGHT } from '../utils-hooks'

export const ProfilesScreen = () => (
  <StyledScreen>
    <HTMLTitle value="Profiles" />
    <ProfilesBrowser />
  </StyledScreen>
)

const StyledScreen = styled(StyledEntitiesBrowserScreen)`
  .ant-result {
    height: calc(
      100vh - ${APP_HEADER_HEIGHT} - ${ENTITIES_BROWSER_TOP_BAR_HEIGHT} - 2em
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
