import { FieldInput } from './FieldInput'
import { SWIFT_ROOT_ENTITY_ID } from '../../data/models'
import { useAddEntityFormContext } from '../../states'
import { FieldConfig } from '../../utils-hooks'

type AddEntityFieldInputProps<T> = {
  field: FieldConfig<T>
}

export const AddEntityFieldInput = <T,>({
  field,
}: AddEntityFieldInputProps<T>) => {
  const key = String(field.key)

  const entitySelectBaseIdKey = String(field.entitySelectBaseIdKey)

  const { values, updateValue, addingEntity } = useAddEntityFormContext()

  const handleUpdateValue = (value: any) => updateValue(key, value)

  const selectBaseId = getSelectBaseId(key, entitySelectBaseIdKey, values)

  return (
    <FieldInput
      field={field}
      selectBaseId={selectBaseId}
      disabled={addingEntity}
      onChange={handleUpdateValue}
      size="large"
      operation="add"
    />
  )
}

const getSelectBaseId = (
  key: string,
  baseIdKey: string | undefined,
  values: any,
) => {
  if (key === 'baseId') {
    return SWIFT_ROOT_ENTITY_ID
  }

  if (baseIdKey) {
    return values?.[baseIdKey] || SWIFT_ROOT_ENTITY_ID
  }

  return values?.baseId || SWIFT_ROOT_ENTITY_ID
}
