import { queryClient } from '../../../data/queryClient'
import { cacheKeys } from '../../../utils-hooks'

export const personRoleQueryKeys = [
  [cacheKeys.person_role_archetypes],
  [cacheKeys.person_roles],
  [cacheKeys.role_profiles],
  [cacheKeys.organization_roles],
]

export const invalidatePersonRoleQueries = () => {
  personRoleQueryKeys.forEach((key) => {
    queryClient.invalidateQueries({
      queryKey: key,
    })
  })
}
