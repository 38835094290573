import { RoleArchetype } from '@swiftctrl/api-client'
import {
  AddTableAction,
  RoleSubdataTable,
  RoleSubDataTableItem,
} from '../../manage-subdata'
import { checkExistingArchetype } from './checkExistingArchetype'

type CandidateArchetypesTableProps = {
  data: RoleArchetype[]
  selectedArchetypes: RoleArchetype[]
  onAddArchetype: (archetype: RoleArchetype) => void
}

export const CandidateArchetypesTable = ({
  data,
  selectedArchetypes,
  onAddArchetype,
}: CandidateArchetypesTableProps) => {
  return (
    <RoleSubdataTable
      header="Archetype List"
      dataSource={data}
      renderItem={(archetype) => (
        <RoleSubDataTableItem>
          {archetype}
          <AddTableAction
            showAddButton={
              !checkExistingArchetype(selectedArchetypes, archetype)
            }
            onAdd={() => onAddArchetype(archetype)}
          />
        </RoleSubDataTableItem>
      )}
    />
  )
}
