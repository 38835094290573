import 'antd/dist/antd.variable.min.css'
import { ConfigProvider } from 'antd'
import { PropsWithChildren } from 'react'
import { ThemeProvider } from 'styled-components'
import { ColorThemeProvider } from './ColorThemeProvider'
import { appTheme } from './theme'
import { colors } from './Colors'

export const AppThemeProvider = ({ children }: PropsWithChildren) => {
  ConfigProvider.config({
    theme: {
      primaryColor: colors['primary-color'],
    },
  })

  return (
    <ConfigProvider>
      <ColorThemeProvider>
        <ThemeProvider theme={appTheme}>{children}</ThemeProvider>
      </ColorThemeProvider>
    </ConfigProvider>
  )
}
