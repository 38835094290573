import { Entry, ValidationRule } from './types'
import { validate } from './validate'

export const handleConfirm = <T>(
  entries: Entry<T>[],
  dataIndexToValidationRules: Map<string, ValidationRule<T>[]>,
  setErrors: (errors: Map<string, string[]>) => void,
  onConfirm: (data: T[]) => void,
) => {
  const errors = new Map<string, string[]>()

  entries.forEach((entry) => {
    Object.entries(entry).forEach(([dataIndex, value]) => {
      if (dataIndex === 'key') {
        return
      }

      const rules = dataIndexToValidationRules.get(dataIndex)

      if (!rules) {
        return
      }

      validate(value, rules, errors, entry.key, dataIndex, entries)
    })
  })

  setErrors(errors)

  if (errors.size > 0) {
    return
  }

  const data = entries.map((item) => {
    const { key: _, ...output } = item

    return output as unknown as T
  })

  onConfirm(data)
}
