import {
  CredentialFieldConstraint,
  CredentialFieldIntegerRange,
} from '@swiftctrl/api-client'
import { TooltipIcon } from '../TooltipIcon'
import { BitfieldValue, Constraint } from '../utils'
import { IntegerConstraintTypeView } from './IntegerConstraintTypeView'
import { IntegerRangeInput } from './IntegerRangeInput'
import {
  getMinMaxFromFormatIntegerRangeWithTemplateBitfieldValue,
  IntegerConstraintContainer,
} from './utils'

type FormatIntegerRangeWithTemplateBitfieldValueViewProps = {
  templateConstraint: Constraint
  templateBitfieldValue: BitfieldValue
  formatConstraint: CredentialFieldConstraint
  onChange: (constraint: Constraint | null) => void
}

export const FormatIntegerRangeWithTemplateBitfieldValueView = ({
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: FormatIntegerRangeWithTemplateBitfieldValueViewProps) => {
  const templateIntegerRange = templateConstraint.integer_range!

  const { min, max, origin } = getDerivedData(
    formatConstraint.value as CredentialFieldIntegerRange,
    templateBitfieldValue,
  )

  const minLabel = min.toLocaleString()

  const maxLabel = max.toLocaleString()

  const tooltip = `Due to the ${origin} field constraints, the integer range min and max are ${minLabel} and ${maxLabel}`

  return (
    <IntegerConstraintContainer>
      <IntegerConstraintTypeView
        templateConstraint={templateConstraint}
        templateBitfieldValue={templateBitfieldValue}
        formatConstraint={formatConstraint}
        value="integer_range"
        onChange={onChange}
      />
      <TooltipIcon message={tooltip} />
      <IntegerRangeInput
        startDefaultValue={templateIntegerRange.start}
        endDefaultValue={templateIntegerRange.end}
        min={min}
        max={max}
        onChange={(start, end) => {
          onChange({
            integer_range: { start, end },
            date_range: null,
            valid_values: null,
          })
        }}
      />
    </IntegerConstraintContainer>
  )
}

const getDerivedData = (
  formatIntegerRange: CredentialFieldIntegerRange,
  templateBitfieldValue: BitfieldValue,
) => {
  const { min, max, constraintFromFormat, constraintFromTemplate } =
    getMinMaxFromFormatIntegerRangeWithTemplateBitfieldValue(
      formatIntegerRange,
      templateBitfieldValue,
    )

  const originTokens = []

  if (constraintFromFormat) {
    originTokens.push('format')
  }

  if (constraintFromTemplate) {
    originTokens.push('template')
  }

  const origin = originTokens.join(' and ')

  return {
    min,
    max,
    origin,
  }
}
