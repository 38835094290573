import { ProviderTypes } from '@swiftctrl/api-client'
import { Model, Response } from 'miragejs'
import { getUTCTimestamp } from '../../utils-hooks'
import { MockModel, MockFeature, ServerWithMockModels } from './utils'

type ServerWithMockProvider = ServerWithMockModels<{
  provider: MockModel<ProviderTypes>
}>

const mockProviders: Partial<ProviderTypes>[] = [
  {
    auth_token_ttl: 3600,
    created_at: '2023-12-19T15:42:07Z',
    data: undefined,
    entity_description: '',
    entity_name: 'Xavier email/password provider',
    entity_status: 'ACTIVE',
    entity_type_id: 'provider',
    extra_ints: null,
    extra_json: undefined,
    extra_map: null,
    extra_strings: null,
    max_login_attempts: 0,
    multi_factor_code_ttl: 0,
    multi_factor_enabled: false,
    overseer_id: '3bcd174c-fc51-47d5-811a-cefebd505a00',
    overseer_name: 'Xavier test org 7',
    profile_session_ttl: 3600,
    provider_id: 'e1149d50-1d16-486f-9933-0acd90d1666d',
    provider_type: {
      name: 'email_password',
      provider_type_id: 1,
    },
    updated_at: '',
  },
  {
    auth_token_ttl: 900,
    created_at: '2023-07-13T19:17:59Z',
    data: undefined,
    entity_description: '',
    entity_name: 'Xavier single-use token Provider',
    entity_status: 'ACTIVE',
    entity_type_id: 'provider',
    extra_ints: null,
    extra_json: undefined,
    extra_map: null,
    extra_strings: null,
    max_login_attempts: 0,
    multi_factor_code_ttl: 0,
    multi_factor_enabled: false,
    overseer_id: '3bcd174c-fc51-47d5-811a-cefebd505a00',
    overseer_name: 'Xavier test org 7',
    profile_session_ttl: 900,
    provider_id: 'b9816b56-08dd-4370-bd9e-1f717c9a347b',
    provider_type: {
      name: 'single_use_token',
      provider_type_id: 3,
    },
    updated_at: '2023-12-22T17:51:31Z',
  },
]

const model = () => ({
  provider: Model.extend<Partial<ProviderTypes>>({}),
})

const seed = (server: ServerWithMockProvider) => {
  mockProviders.forEach((provider) => server.create('provider', provider))
}

const route = (server: ServerWithMockProvider) => {
  routeBrowse(server)

  routeRead(server)

  routeEdit(server)
}

const routeBrowse = (server: ServerWithMockProvider) => {
  server.get('/providers', (schema) => {
    const providers = schema.all('provider').models

    return new Response(200, undefined, providers)
  })
}

const routeRead = (server: ServerWithMockProvider) => {
  server.get(
    '/providers/:providerId',
    (schema, request) =>
      schema.findBy('provider', { provider_id: request.params.providerId })
        ?.attrs || new Response(404),
  )
}

const routeEdit = (server: ServerWithMockProvider) => {
  server.put(
    '/providers/:providerId',
    async (schema, request) => {
      const { provider_id, ...providerUpdate } = JSON.parse(request.requestBody)

      const existingProvider = schema.findBy('provider', {
        provider_id: request.params.providerId,
      })

      if (!existingProvider) {
        return new Response(404, {}, { message: 'Provider not found' })
      }

      existingProvider.update({
        ...providerUpdate,
        updated_at: getUTCTimestamp(),
      })

      return existingProvider.attrs
    },
    {
      timing: 1500,
    },
  )
}

export const providerMock: MockFeature = {
  enabled: false,
  model,
  seed,
  route,
}
