import { InputNumberProps } from 'antd'
import { useState } from 'react'
import { BitfieldValue } from '../utils'

type useBitfieldValueLogicParams = {
  startBit?: number
  endBit?: number
  onChange: (value: BitfieldValue) => void
}

export const useBitfieldValueLogic = ({
  startBit: startBitProp,
  endBit: endBitProp,
  onChange,
}: useBitfieldValueLogicParams) => {
  const [startBit, setStartBit] = useState(startBitProp)

  const [endBit, setEndBit] = useState(endBitProp)

  const handleStartChange: InputNumberProps['onChange'] = (value) => {
    if (value === null) {
      setStartBit(undefined)

      return
    }

    if (typeof value !== 'number') {
      return
    }

    setStartBit(value)

    if (endBit !== undefined) {
      onChange({ start_bit: value, end_bit: endBit })
    }
  }

  const handleEndChange: InputNumberProps['onChange'] = (value) => {
    if (value === null) {
      setEndBit(undefined)

      return
    }

    if (typeof value !== 'number') {
      return
    }

    setEndBit(value)

    if (startBit !== undefined) {
      onChange({ start_bit: startBit, end_bit: value })
    }
  }

  return {
    startBit,
    endBit,
    handleStartChange,
    handleEndChange,
  }
}
