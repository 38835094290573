import { ProviderTypes } from '@swiftctrl/api-client'
import { Modal } from 'antd'
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  secondsToMilliseconds,
} from 'date-fns'
import {
  ActionsDropdownItem,
  EditProviderView,
  EnabledDisplay,
  HeaderContent,
  HeaderContentProps,
  invalidateReadScreenQuery,
  ReadScreen,
} from '../components'
import { PROVIDERS_SCREEN_PATH } from '../config/paths'
import { queryClient } from '../data/queryClient'
import { swiftClientWithoutErrorHandling } from '../data/swiftClient'
import { ProviderDetailsContent } from '../screen-components/provider-screen'
import {
  adaptSDKRead,
  cacheKeys,
  showErrorNotification,
  useBoolean,
  useDelayedCacheInvalidationAfterNavigation,
  useDeleteProvider,
} from '../utils-hooks'

export const ProviderScreen = () => {
  const [allowDelete, setAllowDeleteTrue] = useBoolean()

  return (
    <ReadScreen<ProviderTypes>
      paramUuidProps={{
        paramKey: 'providerId',
        entityType: 'provider',
        redirectPath: `/${PROVIDERS_SCREEN_PATH}`,
      }}
      readRequest={adaptSDKRead(swiftClientWithoutErrorHandling.provider)}
      cacheKey={cacheKeys.provider}
      headerContentFactory={(provider) => (
        <ProviderHeader provider={provider} allowDelete={allowDelete} />
      )}
      contentFactory={(provider) => (
        <ProviderDetailsContent
          provider={provider}
          onCanDeleteProvider={setAllowDeleteTrue}
        />
      )}
      entityType="provider"
    />
  )
}

const getItems = (
  allowDelete: boolean,
  onAllowProviderDeletion: (providerId: string) => void,
  providerId: string,
  openEditView: () => void,
): ActionsDropdownItem[] => [
  {
    key: 'edit',
    label: 'Edit',
    onClick: openEditView,
  },
  {
    key: 'delete',
    label: allowDelete ? 'Delete' : 'Delete (disabled)',
    danger: true,
    onClick: () => onAllowProviderDeletion(providerId),
    disabled: !allowDelete,
    tooltipText: !allowDelete
      ? 'This Provider cannot be deleted as it has associated Provider Profiles.'
      : undefined,
  },
]

const ProviderHeader = ({
  provider,
  allowDelete,
}: {
  provider: ProviderTypes
  allowDelete: boolean
}) => {
  const {
    entity_name,
    provider_id,
    entity_description,
    overseer_name,
    overseer_id,
    provider_type: { name: provider_type_name },
    max_login_attempts,
    profile_session_ttl,
    auth_token_ttl,
    created_at,
    updated_at,
    multi_factor_enabled,
    extra_json,
  } = provider

  const { mutate } = useDeleteProvider()

  const { delayedCacheInvalidationAfterNavigation } =
    useDelayedCacheInvalidationAfterNavigation()

  const handleProviderDelete = (providerId: string) => {
    Modal.confirm({
      title: 'Delete Provider ?',
      content: (
        <div>
          Are you sure you want to delete{' '}
          <b> {`${entity_name || provider_id}`}</b> ?
        </div>
      ),
      okText: 'Delete',
      onOk: () =>
        mutate(
          { providerId },
          {
            onSuccess: () => {
              delayedCacheInvalidationAfterNavigation({
                navigatePath: `/${PROVIDERS_SCREEN_PATH}`,
                invalidateCache,
              })
            },
            onError: (error) => {
              showErrorNotification('Error while deleting entity', error)
            },
          },
        ),
      okButtonProps: {
        danger: true,
        type: 'default',
      },
      centered: true,
    })
  }

  const [isEditViewOpen, openEditView, closeEditView] = useBoolean()

  const handleEdited = () => {
    invalidateCache()

    closeEditView()
  }

  const invalidateCache = () => {
    invalidateReadScreenQuery(cacheKeys.provider, provider_id)

    queryClient.invalidateQueries({ queryKey: [cacheKeys.providers] })
  }

  const items = getItems(
    allowDelete,
    handleProviderDelete,
    provider_id,
    openEditView,
  )

  const headerContentProps: HeaderContentProps = {
    entityName: entity_name,
    entityId: provider_id,
    entityDescription: entity_description,
    infoItemsLeft: [
      {
        title: 'Overseer',
        value: overseer_name,
        copyableText: overseer_id,
      },
      {
        title: 'Provider type',
        value: String(provider_type_name),
      },
      {
        title: 'Multi-factor enabled',
        value: <EnabledDisplay enabled={multi_factor_enabled} />,
      },
    ],
    infoItemsRight: [
      {
        title: 'Max login attempts',
        value: max_login_attempts,
        copyableText: max_login_attempts,
      },
      {
        title: 'Profile session TTL',
        value: convertTTL(profile_session_ttl),
        copyableText: profile_session_ttl,
      },
      {
        title: 'Auth token TTL',
        value: convertTTL(auth_token_ttl),
        copyableText: auth_token_ttl,
      },
    ],
    createdAt: created_at,
    updatedAt: updated_at,
    actionDropDownsItems: items,
    breadCrumbPath: `/${PROVIDERS_SCREEN_PATH}`,
    entityType: 'provider',
    extraJson: extra_json,
  }

  return (
    <>
      <HeaderContent {...headerContentProps} />
      <EditProviderView
        open={isEditViewOpen}
        provider={provider}
        onEdited={handleEdited}
        onClose={closeEditView}
        baseIdIsPreset={false}
        key={updated_at || created_at}
      />
    </>
  )
}

const convertTTL = (ttlInSeconds: number) => {
  const ttlDate = new Date(secondsToMilliseconds(ttlInSeconds))

  const baseDate = new Date(0)

  const hours = differenceInHours(ttlDate, baseDate)

  const minutes = differenceInMinutes(ttlDate, baseDate) % 60

  const seconds = differenceInSeconds(ttlDate, baseDate) % 60

  return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
}
