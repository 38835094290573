import { isValidUuid, useBoolean } from '../../../utils-hooks'
import { Input } from '../Input'
import { Constraint } from '../utils'
import { ValidatedFormItem } from '../ValidatedFormItem'
import { DefaultValueSelect } from './DefaultValueSelect'
import { VALID_VALUES_ERROR_MESSAGE } from './utils'

type DefaultUuidInputProps = {
  defaultValue: string | null
  constraint: Constraint | null
  onChange: (value: string) => void
}

export const DefaultUuidInput = ({
  defaultValue,
  constraint,
  onChange,
}: DefaultUuidInputProps) => {
  const [isValid, setValidTrue, setValidFalse] = useBoolean(true)

  if (constraint?.valid_values) {
    const options = new Array<string>()

    let isValid = defaultValue ? false : true

    constraint.valid_values.forEach(({ value }) => {
      const parsedValue = String(value)

      options.push(parsedValue)

      if (defaultValue === parsedValue) {
        isValid = true
      }
    })

    return (
      <ValidatedFormItem
        isValid={isValid}
        validationMessage={VALID_VALUES_ERROR_MESSAGE}
      >
        <DefaultValueSelect
          options={options}
          defaultValue={defaultValue}
          isValid={isValid}
          onChange={onChange}
        />
      </ValidatedFormItem>
    )
  }

  const validate = (value: string) => {
    if (!value) {
      setValidTrue()

      return
    }

    if (isValidUuid(value)) {
      setValidTrue()
    } else {
      setValidFalse()
    }
  }

  return (
    <ValidatedFormItem
      isValid={isValid}
      validationMessage="Default value must be a UUID"
    >
      <Input value={defaultValue} onChange={onChange} onBlur={validate} />
    </ValidatedFormItem>
  )
}
