import { Alert } from '@swiftctrl/swift-component-library'
import { EntityTypeTag } from '../../../components'
import {
  DeleteOrganizationModalContentProps,
  isEntityTypeConfigured,
} from './utils'

export const InvalidView = ({
  entityTypeToEntitiesArray,
}: DeleteOrganizationModalContentProps) => {
  const invalidEntityTypeToEntitiesArray = entityTypeToEntitiesArray!.filter(
    ({ entityType }) => !isEntityTypeConfigured(entityType),
  )

  return (
    <>
      <p>
        This organization contains some descendant entity types that are not
        configured for deletion in this function:
      </p>
      <p>
        {invalidEntityTypeToEntitiesArray.map(({ entityType }) => (
          <EntityTypeTag key={entityType} entityType={entityType} />
        ))}
      </p>
      <Alert
        type="info"
        showIcon
        description="This function is used for deleting organizations created with the onboarding module."
      />
    </>
  )
}
