import { ValueTypeProps } from '@swiftctrl/swift-component-library'
import { isFunction } from 'lodash'
import { FieldKey } from './typeUtils'

export const mapDataToSelectOptions = <T>(
  data: T[],
  valueKey: FieldKey<T> | ((item: T) => ValueTypeProps['value']),
  labelKey: FieldKey<T> | ((item: T) => ValueTypeProps['label']),
  includeData = false,
): ValueTypeProps[] => {
  return data.map((item) => {
    const value = isFunction(valueKey) ? valueKey(item) : String(item[valueKey])

    const label = isFunction(labelKey) ? labelKey(item) : item[labelKey]

    const option = {
      value,
      label,
    } as any

    if (includeData) {
      option.data = item
    }

    return option
  })
}
