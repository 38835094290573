import {
  ColumnsType,
  ExpandableConfig,
  Table,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import {
  BrowseEntitiesContextType,
  useBrowseEntitiesContext,
} from '../../states'
import { FieldKey } from '../../utils-hooks'
import { EmptyPlaceholder } from './EmptyPlaceholder'
import { EntitiesBrowser, EntitiesBrowserStyles } from './EntitiesBrowser'
import { ENTITIES_BROWSER_TOP_BAR_HEIGHT } from './EntitiesBrowserTopBar'

type EntitiesTableBrowserProps<T> = {
  columns:
    | ColumnsType<T>
    | ((context: BrowseEntitiesContextType<T>) => ColumnsType<T>)
  expandable?: ExpandableConfig<T>
} & EntitiesBrowserStyles

export const TABLE_DATA_PER_FETCH = 50

/**
 * These need to be at least 100 in order to work correctly with the
 * `Avatar` component, which uses z-indexes 98 and 99 for layering.
 */
export const BROWSER_COMPONENTS_Z_INDEXES = {
  browserTopBar: {
    defaultValue: 120,
    nested: 110,
  },
  tableHeader: {
    defaultValue: 119,
    nested: 109,
  },
}

export const EntitiesTableBrowser = <T extends object>({
  columns: columnsProp,
  expandable,
  nested,
  ...props
}: EntitiesTableBrowserProps<T>) => {
  const context = useBrowseEntitiesContext<T>()

  const { entityType, setSort, displayAddButton } = context

  const columns =
    typeof columnsProp === 'function' ? columnsProp(context) : columnsProp

  return (
    <EntitiesBrowser
      nested={nested}
      render={(dataSource: T[], dataKey: FieldKey<T>) => (
        <StyledTableWrapper
          $nested={nested}
          $displayAddButton={displayAddButton}
        >
          <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            size="small"
            pagination={false}
            rowKey={String(dataKey)}
            expandable={expandable}
            locale={{ emptyText: <EmptyPlaceholder entityType={entityType} /> }}
            onChange={(_, __, sorter) => {
              if (Array.isArray(sorter)) {
                // We don't need to handle sorting on multiple columns
                return
              }

              const { column, order } = sorter

              if (!column || !order) {
                setSort(undefined)

                return
              }

              setSort({
                columnTitle: String(column.title),
                order,
              })
            }}
          />
        </StyledTableWrapper>
      )}
      {...props}
    />
  )
}

const StyledTableWrapper = styled.div<{
  $nested?: boolean
  $displayAddButton?: boolean
}>`
  .ant-table-thead {
    position: sticky;
    top: ${(props) => {
      const { $nested, $displayAddButton } = props

      if ($displayAddButton) {
        return $nested
          ? `calc(${ENTITIES_BROWSER_TOP_BAR_HEIGHT} + 112px)`
          : `calc(${ENTITIES_BROWSER_TOP_BAR_HEIGHT} + 1em)`
      }
      return $nested ? `54px` : `1em`
    }};
    z-index: ${({ $nested }) => {
      const {
        tableHeader: { defaultValue, nested },
      } = BROWSER_COMPONENTS_Z_INDEXES
      return $nested ? nested : defaultValue
    }};
  }

  .ant-table-cell {
    word-break: break-all;
  }
`
