import {
  Checkbox,
  Form,
  InfoCircleOutlined,
  Tooltip,
  Typography,
} from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { CredentialTemplateFieldComponentProps } from './utils'

export const CredentialTemplateFieldCheckbox = ({
  field,
}: CredentialTemplateFieldComponentProps) => {
  const { is_optional, default_value, display_name, tooltip, read_only } = field

  const defaultChecked = getDefaultChecked(default_value)

  return (
    <Form.Item required={!is_optional}>
      <Checkbox defaultChecked={defaultChecked} disabled={read_only}>
        {display_name}
      </Checkbox>
      <Typography.Text type="secondary">
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoCircleOutlined />
          </Tooltip>
        )}
        {is_optional && <StyledOptionalText> (optional)</StyledOptionalText>}
      </Typography.Text>
    </Form.Item>
  )
}

const getDefaultChecked = (default_value: string | null) => {
  switch (default_value) {
    case 'true':
      return true

    case 'false':
      return false

    default:
      return undefined
  }
}

const StyledOptionalText = styled.span`
  cursor: default;
`
