import {
  EntityTypes,
  ProviderAddTypes,
  ProviderType,
  providerTypesToProviderTypeIds,
} from '@swiftctrl/api-client'
import { minutesToSeconds } from 'date-fns'
import { swiftClient } from '../../data/swiftClient'
import {
  AddEntityConfig,
  transformDurationPickerValueToDuration,
  ValidationRule,
} from '../../utils-hooks'
import { providerFieldLabels } from '../labels'

type ProviderFormData = Pick<
  EntityTypes,
  'entity_name' | 'entity_description' | 'overseer_id'
> & {
  'provider_type.provider_type_id': string
  profile_session_ttl: Date | number | undefined
  auth_token_ttl: Date | number | undefined
  max_login_attempts: string
  'data.openid.issuer': string
  'data.openid.audience': string
  'data.openid.claim_identifier': string
  'data.proxy_login.ip_restriction': string
  'data.proxy_login.authorized_profile_id': string
  'data.proxy_login.case_sensitive_identifier': string
}

const TIME_TO_LIVE_MINIMUM_MINUTES = 15

const MINIMUM_DURATION = minutesToSeconds(TIME_TO_LIVE_MINIMUM_MINUTES)

const timeToLiveValidationRules: ValidationRule[] = [
  {
    validator: (value: any) => {
      const duration = transformDurationPickerValueToDuration(value)

      if (!duration) {
        return false
      }

      const valid = duration >= MINIMUM_DURATION

      return valid
    },
    message: `Time to live must be at least ${TIME_TO_LIVE_MINIMUM_MINUTES} minutes`,
  },
]

export const provider: AddEntityConfig<ProviderFormData, ProviderAddTypes> = {
  fieldGroups: [
    {
      fields: [
        {
          key: 'entity_name',
          label: 'Name',
        },
        {
          key: 'entity_description',
          label: 'Description',
          optional: true,
        },
      ],
    },
    {
      condition: (_, overseerIdIsPreset) => !overseerIdIsPreset,
      fields: [
        {
          key: 'overseer_id',
          label: 'Organization',
          inputType: 'organizationSelect',
          entitySelectType: 'organization',
          editable: false,
        },
      ],
    },
    {
      fields: [
        {
          key: 'provider_type.provider_type_id',
          label: providerFieldLabels.providerType,
          inputType: 'select',
          selectOptions: Object.keys(providerTypesToProviderTypeIds).map(
            (providerType) => ({
              key: providerType,
              label:
                providerFieldLabels.providerTypeLabels[
                  providerType as ProviderType
                ],
            }),
          ),
          editable: false,
        },
        {
          key: 'max_login_attempts',
          label: providerFieldLabels.maxLoginAttempts,
          inputType: 'number',
          optional: true,
          rules: [
            {
              min: 1,
              message: `If set, max login attempts must be greater than 0`,
            },
          ],
        },
        {
          key: 'profile_session_ttl',
          label: `Profile session time to live`,
          inputType: 'duration',
          rules: timeToLiveValidationRules,
        },
        {
          key: 'auth_token_ttl',
          label: `Auth token time to live`,
          inputType: 'duration',
          rules: timeToLiveValidationRules,
        },
      ],
    },
    {
      condition: (values) => {
        const providerType = values[
          'provider_type.provider_type_id'
        ] as ProviderType

        return providerType === 'openid_okta' || providerType === 'openid'
      },
      fields: [
        { key: 'data.openid.issuer', label: providerFieldLabels.issuer },
        { key: 'data.openid.audience', label: providerFieldLabels.audience },
        {
          key: 'data.openid.claim_identifier',
          label: providerFieldLabels.claimIdentifier,
        },
      ],
    },
    {
      condition: (values) =>
        (values['provider_type.provider_type_id'] as ProviderType) ===
        'proxy_login',
      fields: [
        {
          key: 'data.proxy_login.ip_restriction',
          label: providerFieldLabels.ipRestriction,
          optional: true,
        },
        {
          key: 'data.proxy_login.authorized_profile_id',
          label: providerFieldLabels.authorizedProfileId,
          inputType: 'profileSelect',
          entitySelectBaseIdKey: 'overseer_id',
        },
        {
          key: 'data.proxy_login.case_sensitive_identifier',
          label: providerFieldLabels.caseSensitiveIdentifier,
          inputType: 'boolean',
          optional: true,
        },
      ],
    },
  ],
  buildData: (values) => {
    const {
      entity_name,
      entity_description,
      overseer_id,
      profile_session_ttl,
      auth_token_ttl,
      max_login_attempts,
    } = values

    const providerType = values[
      'provider_type.provider_type_id'
    ] as ProviderType

    const data = {
      entity_name,
      entity_description,
      overseer_id,
      provider_type: {
        provider_type_id: providerTypesToProviderTypeIds[providerType],
      },
      profile_session_ttl:
        transformDurationPickerValueToDuration(profile_session_ttl),
      auth_token_ttl: transformDurationPickerValueToDuration(auth_token_ttl),
    } as any

    if (max_login_attempts) {
      data.max_login_attempts = Number(max_login_attempts)
    }

    if (providerType === 'openid_okta' || providerType === 'openid') {
      data.data = {
        openid: {
          issuer: values['data.openid.issuer'],
          audience: values['data.openid.audience'],
          claim_identifier: values['data.openid.claim_identifier'],
        },
      }
    } else if (providerType === 'proxy_login') {
      data.data = {
        proxy_login: {
          ip_restriction: values['data.proxy_login.ip_restriction'],
          authorized_profile_id:
            values['data.proxy_login.authorized_profile_id'],
          case_sensitive_identifier:
            values['data.proxy_login.case_sensitive_identifier'],
        },
      }
    }

    return data
  },
  addRequest: swiftClient.provider.add,
}
