import { BuildingTypes } from '@swiftctrl/api-client'
import {
  ColumnsType,
  Layout,
  Tag,
  Typography,
} from '@swiftctrl/swift-component-library'
import {
  buildBrowseBuildingsFiltersParam,
  CopyableDisplay,
  CreatedUpdatedDateDisplay,
  EntitiesTableBrowser,
  formatAddress,
  TABLE_DATA_PER_FETCH,
} from '../../../components'
import { swiftClient } from '../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  BrowseEntitiesRequestParams,
} from '../../../states'
import {
  adaptSDKBrowseRequest,
  cacheKeys,
  getLargeFilteredBrowseRequest,
} from '../../../utils-hooks'

type DescendantsBuildingsBrowserProps = {
  organizationId: string
}

type CombinedData = {
  key: string
  building: Partial<BuildingTypes>
  isManaged: boolean
}

export const DescendantsBuildingsBrowser = ({
  organizationId,
}: DescendantsBuildingsBrowserProps) => {
  const browse = buildBrowse(organizationId)

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={TABLE_DATA_PER_FETCH}
      baseId={organizationId}
      browse={browse}
      dataKey="key"
      entityType="building"
      searchable
      displayAddButton
    >
      <EntitiesTableBrowser columns={columns} />
    </BrowseEntitiesContextProvider>
  )
}

const buildBrowse = (
  organizationId: string,
): BrowseEntitiesConfig<CombinedData> => ({
  request: async (params) => {
    const { data: buildings, ...response } = await getBuildings(params)

    const { managedBuildingIdSet } = await getManagedBuildingIdSet(
      organizationId,
      buildings,
    )

    const combinedData: CombinedData[] = buildings.map((building) => {
      const { building_id } = building

      const isManaged = managedBuildingIdSet.has(building_id!)

      return {
        key: building_id!,
        building,
        isManaged,
      }
    })

    return { ...response, data: combinedData }
  },
  cacheKey: [cacheKeys.organization_descendant_buildings, organizationId],
})

const getBuildings = async (
  params: BrowseEntitiesRequestParams<CombinedData>,
) => {
  const adapter = adaptSDKBrowseRequest({
    request: swiftClient.building.browse,
    select: [
      'building_id',
      'entity_name',
      'address',
      'created_at',
      'updated_at',
    ],
    getFilters: buildBrowseBuildingsFiltersParam,
    sort: 'entity_name:asc',
  })

  const response = await adapter(params)

  return response
}

const getManagedBuildingIdSet = async (
  organizationId: string,
  buildings: Partial<BuildingTypes>[],
) => {
  const buildingIds = buildings.map((building) => building.building_id!)

  const managedBuildings = await getLargeFilteredBrowseRequest(
    buildingIds,
    (ids) => `space_id ANY ${ids.join(',')}`,
    async (filters) => {
      const response = await swiftClient.organization
        .at(organizationId)
        .spaces.managed.browse({
          baseId: organizationId,
          select: ['space_id'],
          filters,
        })

      return response.data
    },
  )

  const managedBuildingIdSet = new Set(
    managedBuildings.map((building) => building.space_id!),
  )

  return { managedBuildingIdSet }
}

const columns: ColumnsType<CombinedData> = [
  {
    title: 'Building',
    render: (_, entry) => (
      <Layout>
        <Layout horizontal spacing="small">
          <CopyableDisplay
            label={entry.building.entity_name}
            copyableText={entry.building.building_id}
          />
          {entry.isManaged && <Tag>Managed</Tag>}
        </Layout>
        {entry.building.address && (
          <Typography.Text type="secondary">
            {formatAddress(entry.building.address)}
          </Typography.Text>
        )}
      </Layout>
    ),
    width: '50%',
  },
  {
    title: 'Created / Updated Date',
    render: (_, entry) => (
      <CreatedUpdatedDateDisplay
        createdAt={entry.building.created_at}
        updatedAt={entry.building.updated_at}
      />
    ),
    width: '50%',
  },
]
