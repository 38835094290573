import { FIELDS, OperationTypes } from '@swiftctrl/api-client'
import { useQuery } from '@tanstack/react-query'
import { swiftClient } from '../../../../data/swiftClient'
import {
  alphabetize,
  cacheKeys,
  getAllFromBrowseRequest,
  longCacheOptions,
} from '../../../../utils-hooks'

export const useGetOperations = (enabled: boolean) =>
  useQuery({
    queryKey: [cacheKeys.operations],
    queryFn: async ({ signal }) => {
      const operations = (await getAllFromBrowseRequest((offset, limit) =>
        swiftClient.operation.browse(
          {
            offset,
            limit,
            select: FIELDS.OperationBrowse,
          },
          { signal },
        ),
      )) as OperationTypes[]

      operations.sort(alphabetizeByPrefix)

      operations.forEach(alphabetizeSubdata)

      return operations
    },
    enabled,
    ...longCacheOptions,
  })

const alphabetizeByPrefix = (a: OperationTypes, b: OperationTypes) =>
  a.prefix.localeCompare(b.prefix)

const alphabetizeSubdata = (operation: OperationTypes) => {
  operation.actions.sort(alphabetize)

  operation.field_names?.sort(alphabetize)

  operation.modifiers.sort(alphabetize)

  operation.relation_names?.sort(alphabetize)

  operation.scopes.sort(alphabetize)
}
