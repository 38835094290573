import { PreLoader, Tabs, TabsProps } from '@swiftctrl/swift-component-library'
import { JSX, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { EntityType } from '../../data/models'
import { useBooleanEventState } from '../../states'
import { getEntityName, useShowUnsavedChangesModal } from '../../utils-hooks'
import { ErrorBoundary } from '../ErrorBoundary'
import { HTMLTitle } from '../HTMLTitle'
import { StyledScreen } from '../StyledScreen'
import { ParamUuidGuardView, ParamUuidProps } from './ParamUuidGuardView'
import { ReadScreenFallback } from './ReadScreenFallback'
import { ReadScreenHeader } from './ReadScreenHeader'
import { useRead } from './useRead'

type ReadScreenProps<T> = {
  /**
   * If this is an array, each entry's `paramKey` will be validated
   * in the order it is set up in the array.
   *
   * Be sure to set them up in the intended order!
   *
   * Also, the `paramKey` in the last entry in the array will be
   * used for getting the ID for this screen's read request.
   */
  paramUuidProps: ParamUuidProps | ParamUuidProps[]
  readRequest: (id: string) => Promise<T | undefined>
  cacheKey: string
  entityType: EntityType
  /**
   * The returned content entirely replaces the default header content
   */
  headerContentFactory?: (data: T) => JSX.Element
  /**
   * The returned content is appended below the default header content.
   *
   * If `headerContentFactory` is set, `headerExtraContentFactory` is not used.
   */
  headerExtraContentFactory?: (data: T) => JSX.Element
  contentFactory:
    | ((data: T) => JSX.Element)
    | {
        tab: string | undefined
        tabs: readonly string[]
        tabPanesFactory: ContentFactory<T>
        tabBarExtraContent?: (data: T) => JSX.Element
      }
}

type ContentFactory<T> = (data: T) => TabsProps['items']

export const ReadScreen = <T extends object>(props: ReadScreenProps<T>) => {
  const {
    cacheKey,
    contentFactory,
    entityType,
    paramUuidProps,
    readRequest,
    headerContentFactory,
    headerExtraContentFactory,
    ...styleProps
  } = props

  return (
    <StyledScreen {...styleProps}>
      <ParamUuidGuardView paramUuidProps={paramUuidProps}>
        <Content
          cacheKey={cacheKey}
          contentFactory={contentFactory}
          entityType={entityType}
          paramUuidProps={paramUuidProps}
          readRequest={readRequest}
          headerContentFactory={headerContentFactory}
          headerExtraContentFactory={headerExtraContentFactory}
        />
      </ParamUuidGuardView>
    </StyledScreen>
  )
}

const Content = <T extends object>({
  paramUuidProps,
  readRequest,
  cacheKey,
  entityType,
  headerContentFactory,
  headerExtraContentFactory,
  contentFactory,
}: ReadScreenProps<T>) => {
  const params = useParams()

  const currentParamUuidProps = Array.isArray(paramUuidProps)
    ? paramUuidProps[paramUuidProps.length - 1]
    : paramUuidProps

  const { paramKey } = currentParamUuidProps

  const id = params[paramKey]!

  const { data, error } = useRead({
    readRequest,
    cacheKey,
    id,
  })

  const navigate = useNavigate()

  const { pathname } = useLocation()

  useEffect(() => {
    if (typeof contentFactory === 'function') {
      return
    }

    const { tab, tabs } = contentFactory

    if (tab) {
      return
    }

    const url = `${pathname}/${tabs[0]}`

    navigate(url, { replace: true })
  }, [contentFactory, navigate, pathname])

  const hasUnsavedChanges = useBooleanEventState('hasUnsavedChanges')

  const { showUnsavedChangesModal } = useShowUnsavedChangesModal()

  if (error) {
    return (
      <ReadScreenFallback
        error={error}
        buttonClickPath={currentParamUuidProps.redirectPath}
      />
    )
  }

  if (!data) {
    return <PreLoader centered />
  }

  const handleTabClick = (tab: string) => {
    if (typeof contentFactory === 'object' && contentFactory.tab === tab) {
      return
    }

    if (!hasUnsavedChanges) {
      goToTab(tab)

      return
    }

    showUnsavedChangesModal({
      onExit: () => {
        goToTab(tab)
      },
    })
  }

  const goToTab = (tab: string) => {
    const idIndex = pathname.indexOf(id!)

    const baseScreenPath = pathname.substring(0, idIndex + id!.length)

    const url = `${baseScreenPath}/${tab}`

    navigate(url)
  }

  const name = getEntityName(data, entityType)

  return (
    <>
      <HTMLTitle value={name} />
      <ErrorBoundary>
        <ReadScreenHeader
          data={data}
          entityType={entityType}
          headerContentFactory={headerContentFactory}
        >
          {headerExtraContentFactory?.(data)}
        </ReadScreenHeader>
      </ErrorBoundary>
      <ErrorBoundary>
        {typeof contentFactory === 'function' ? (
          contentFactory(data)
        ) : (
          <StyledTabs
            items={contentFactory.tabPanesFactory(data)}
            activeKey={contentFactory.tab}
            onTabClick={handleTabClick}
            tabBarExtraContent={{
              right: contentFactory.tabBarExtraContent?.(data),
            }}
            destroyInactiveTabPane
          />
        )}
      </ErrorBoundary>
    </>
  )
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    align-items: end;
  }

  .ant-tabs-nav {
    background-color: #fff;
  }

  .ant-tabs-nav-wrap {
    padding-left: 1.5em;
  }
  .ant-tabs-nav {
    margin: 0px;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    width: calc(100% - 2em);
    margin: 1em;
    padding: 1em;
  }

  .ant-tabs-extra-content {
    margin: 1em;
  }
`
