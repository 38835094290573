import { InfiniteData } from '@tanstack/react-query'
import { AxiosResponse } from '@swiftctrl/api-client'

export const flattenInfiniteQueryData = <T>(
  data: InfiniteData<AxiosResponse<Partial<T>[], any>, unknown> | undefined,
) => {
  const flattenedData = data?.pages.flatMap((page) => page.data)

  return flattenedData
}
