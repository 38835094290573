import {
  Button,
  CloseOutlined,
  Drawer,
} from '@swiftctrl/swift-component-library'
import { getEntityLabel } from '../../config/labels'
import { EntityType } from '../../data/models'
import { FieldConfig } from '../../utils-hooks'
import { EditEntityForm } from './EditEntityForm'

type EditEntityDrawerProps<FormData> = {
  open: boolean
  entityType: EntityType
  checkBeforeClose: () => void
  fields: FieldConfig<FormData>[]
  fieldsAreValid: boolean
  values: FormData
  setValues: (values: FormData) => void
  isDirty: boolean
  save: () => void
  isSaving: boolean
}

export const EditEntityDrawer = <FormData,>({
  open,
  entityType,
  ...formProps
}: EditEntityDrawerProps<FormData>) => {
  const { isSaving, checkBeforeClose } = formProps

  const entityLabel = getEntityLabel({ entityType, capitalize: false })

  return (
    <Drawer
      open={open}
      title={`Edit ${entityLabel}`}
      closable={false}
      maskClosable={!isSaving}
      destroyOnClose
      onClose={checkBeforeClose}
      extra={
        <Button
          type="text"
          icon={<CloseOutlined />}
          size="small"
          onClick={checkBeforeClose}
          disabled={isSaving}
        />
      }
    >
      <EditEntityForm {...formProps} />
    </Drawer>
  )
}
