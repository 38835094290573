import { Layout, Modal, Typography } from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'
import { styled } from 'styled-components'
import { ManageAssignedDataFooter } from './ManageAssignedDataFooter'

const MODAL_HEADER_HEIGHT = '55px'

const MODAL_FOOTER_HEIGHT = '61px'

const DESCRIPTION_HEIGHT = '52px'

type ManageAssignedDataModalProps = {
  open: boolean
  onClose: () => void
  title: string
  description: string
  filtersBar: ReactNode
  candidatesDataTable: ReactNode
  infoBar: ReactNode
  assignedDataTable: ReactNode
  hasUnsavedChanges: boolean
  onSave: () => void
  isSaving: boolean
  footerLeftContent?: ReactNode
}

export const ManageAssignedDataModal = ({
  open,
  onClose,
  title,
  description,
  filtersBar,
  candidatesDataTable,
  infoBar,
  assignedDataTable,
  hasUnsavedChanges,
  onSave,
  isSaving,
  footerLeftContent,
}: ManageAssignedDataModalProps) => {
  return (
    <StyledModal
      title={title}
      onCancel={onClose}
      destroyOnClose
      open={open}
      maskClosable
      width="calc(100% - 3em)"
      centered
      footer={
        <ManageAssignedDataFooter
          hasUnsavedChanges={hasUnsavedChanges}
          onSave={onSave}
          isSaving={isSaving}
          onClose={onClose}
          leftContent={footerLeftContent}
        />
      }
    >
      <Layout spacing="medium" grow>
        <Typography.Paragraph type="secondary">
          {description}
        </Typography.Paragraph>
        <StyledLayout spacing="xxLarge" horizontal grow>
          <Layout spacing="medium" grow>
            {filtersBar}
            {candidatesDataTable}
          </Layout>
          <Layout spacing="medium" grow>
            {infoBar}
            {assignedDataTable}
          </Layout>
        </StyledLayout>
      </Layout>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    height: calc(100vh - 3em);

    .ant-modal-body {
      height: calc(100% - ${MODAL_HEADER_HEIGHT} - ${MODAL_FOOTER_HEIGHT});
    }
  }
`

const StyledLayout = styled(Layout)`
  height: calc(100% - ${DESCRIPTION_HEIGHT});
`
