import { useMutation } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'

const {
  provider: { at },
} = swiftClientWithoutErrorHandling

export const useDeleteProvider = () => {
  const deleteProvider = ({ providerId }: { providerId: string }) =>
    at(providerId).delete()

  return useMutation({ mutationFn: deleteProvider })
}
