import { styled } from 'styled-components'
import { TABLE_CSS } from '../../data/models'

export const FILTER_OR_INFO_BAR_HEIGHT = '40px'

const FILTER_OR_INFO_BAR_MARGIN = '16px'

export const ManageAssignedDataListContainer = styled.div`
  height: calc(
    100% - ${FILTER_OR_INFO_BAR_HEIGHT} - ${FILTER_OR_INFO_BAR_MARGIN}
  );

  border: ${TABLE_CSS.BORDER};

  .ant-list-item {
    padding: 16px;
  }
`

export const ScrollContainer = styled.div`
  height: calc(100% - ${TABLE_CSS.HEADER_HEIGHT});

  width: 100%;

  overflow: auto;
`
