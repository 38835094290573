import { EpiTypesBrowseTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { SelectProps } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { swiftClient } from '../../data/swiftClient'
import { isValidUuid, mapDataToSelectOptions } from '../../utils-hooks'
import { BaseLiveSearch } from './BaseLiveSearch'

type EpiTypeSelectProps = {
  value?: string
  disabled?: boolean
  onChange: (epiTypeId: string) => void
  size?: SelectProps['size']
  placeholder?: string
  onNoEpiTypeForValue?: () => void
}

export const EpiTypeSelect = ({
  value,
  disabled,
  onChange,
  size,
  placeholder,
  onNoEpiTypeForValue,
}: EpiTypeSelectProps) => {
  const [epiTypeId, setEpiTypeId] = useState<string>(value || '')

  const updateEpiTypeId = (value: string) => {
    setEpiTypeId(value)

    onChange(value)
  }

  const fetchEpiTypes = async (search: string) => {
    const q = getQueryHandler<EpiTypesBrowseTypes>()

    const { data } = await swiftClient.epiTypes.browse({
      select: ['epi_type_id', 'name'],
      filters: [
        q(
          'WHERE',
          isValidUuid(search) ? 'epi_type_id' : 'name',
          isValidUuid(search) ? 'EQ' : 'EQ_IC',
          search,
        ),
      ],
    })

    if (value && onNoEpiTypeForValue) {
      const epiType = data.find((epiType) => epiType.epi_type_id === value)

      if (!epiType) {
        onNoEpiTypeForValue()
      }
    }

    return mapDataToSelectOptions(data, 'epi_type_id', 'name')
  }

  return (
    <BaseLiveSearch
      value={epiTypeId}
      onChange={updateEpiTypeId}
      disabled={disabled}
      fetchOptions={fetchEpiTypes}
      fetchDependencies={[]}
      size={size}
      placeholder={placeholder}
    />
  )
}
