import { ModalProps } from '@swiftctrl/swift-component-library'

type buildCommonModalPropsParams = Omit<
  ModalProps,
  'footer' | 'centered' | 'destroyOnClose'
>

/**
 * Sets up common behavior we want for modals
 */
export const buildCommonModalProps = ({
  ...props
}: buildCommonModalPropsParams): ModalProps => {
  return {
    footer: null,
    centered: true,
    destroyOnClose: true,
    ...props,
  }
}
