import { NovaError } from '@swiftctrl/api-client'
import { FieldConfig, FieldInputType, ValidationRule } from '../addEntityUtils'

export const appendEntityValidationErrors = <T>(
  values: T,
  fields: FieldConfig<T>[],
  alias: string,
  errors: NovaError[],
  operation: 'add' | 'edit',
) => {
  const addError = (key: keyof T, message: string) =>
    errors.push({
      alias,
      field: String(key),
      message,
    })

  fields.forEach((field) => {
    const { editable = true, key, optional, label, inputType, rules } = field

    if (operation === 'edit' && !editable) {
      return
    }

    const value = values[key]

    const valueIsSet = isValueSet(value)

    if (!valueIsSet && !optional && inputType !== 'boolean') {
      addError(key, `${label} is required`)

      return
    }

    if (valueIsSet && rules) {
      rules.forEach((rule) => {
        const { message } = rule

        const isValid = passesRule(value, inputType, rule)

        if (!isValid) {
          addError(key, message)
        }
      })
    }
  })
}

const isValueSet = <T>(value: T[keyof T]): boolean => {
  switch (value) {
    case null:
    case undefined:
      return false
  }

  switch (typeof value) {
    case 'string':
      return value !== ''
  }

  return true
}

const passesRule = (
  value: any,
  inputType: FieldInputType | undefined,
  rule: ValidationRule,
): boolean => {
  const { minLength, maxLength, pattern, min, max, validator } = rule

  switch (inputType) {
    case undefined:
    case 'text':
      if (minLength !== undefined && (value as string).length < minLength) {
        return false
      }

      if (maxLength !== undefined && (value as string).length > maxLength) {
        return false
      }

      if (pattern && !pattern.test(value)) {
        return false
      }
      break

    case 'number':
    case 'duration':
      if (min !== undefined && value < min) {
        return false
      }

      if (max !== undefined && value > max) {
        return false
      }
      break
  }

  if (validator) {
    const result = validator(value)

    if (!result) {
      return false
    }
  }

  return true
}
