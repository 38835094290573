import { CommonEntityTypes } from '@swiftctrl/api-client'
import { Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { Timestamp } from './date/Timestamp'

type EntityTimestampsDisplayProps = Pick<
  CommonEntityTypes,
  'created_at' | 'updated_at'
>

export const EntityTimestampsDisplay = ({
  created_at,
  updated_at,
  ...props
}: EntityTimestampsDisplayProps) => {
  return !(created_at || updated_at) ? null : (
    <StyledTimeStamp {...props}>
      {created_at && (
        <Typography.Paragraph>
          <Timestamp label="Created" value={created_at} />
        </Typography.Paragraph>
      )}
      {updated_at && (
        <Typography.Paragraph>
          <Timestamp label="Updated" value={updated_at} />
        </Typography.Paragraph>
      )}
    </StyledTimeStamp>
  )
}

const StyledTimeStamp = styled.div`
  .ant-typography {
    margin-bottom: 0px;
  }
`
