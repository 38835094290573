const devConfig = {
  apiKey: 'AIzaSyA1hrIAtVpOrSJT7e3YtYMf4LHg5Y0Y7Gg',
  authDomain: 'swiftconnect-dev.firebaseapp.com',
  projectId: 'swiftconnect-dev',
  storageBucket: 'swiftconnect-dev.appspot.com',
  messagingSenderId: '399545925556',
  appId: '1:399545925556:web:f02867585ded18b20abce1',
}

const qaConfig = {
  apiKey: 'AIzaSyB7BVZ0OaLL4qdRYKCFOwVjcCBC6_EAwfI',
  authDomain: 'swiftconnect-qa.firebaseapp.com',
  projectId: 'swiftconnect-qa',
  storageBucket: 'swiftconnect-qa.appspot.com',
  messagingSenderId: '379546648419',
  appId: '1:379546648419:web:5deb147bc24838cd219d56',
}

const prodConfig = {
  apiKey: 'AIzaSyC3Hvr09N81kML4g4gzomO9gfNKRbWOqwk',
  authDomain: 'swiftconnect-35884.firebaseapp.com',
  projectId: 'swiftconnect-35884',
  storageBucket: 'swiftconnect-35884.appspot.com',
  messagingSenderId: '14494983969',
  appId: '1:14494983969:web:45e5e705e777cb13eb4060',
}

// ---

export type Env = 'dev' | 'qa' | 'prod'

export const getFirebaseConfigForEnv = (env: Env) => {
  switch (env) {
    case 'dev':
      return devConfig
    case 'qa':
      return qaConfig
    case 'prod':
      return prodConfig
    default:
      return prodConfig
  }
}
