import { PersonTypes } from '@swiftctrl/api-client'
import { Avatar, Divider, TabsProps } from '@swiftctrl/swift-component-library'
import {
  CopyableDisplay,
  CopyableLink,
  EntityTimestampsDisplay,
  HeaderContent,
  LabelDisplay,
  ReadScreen,
} from '../components'
import {
  ORGANIZATIONS_SCREEN_PATH,
  PEOPLE_SCREEN_PATH,
  PERSON_SCREEN_AUTHENTICATION_TAB,
  PERSON_SCREEN_CREDENTIALS_TAB,
  PERSON_SCREEN_PROFILES_TAB,
  PERSON_SCREEN_ROLES_TAB,
} from '../config/paths'
import { EMPTY_ID } from '../data/models'
import { swiftClientWithoutErrorHandling } from '../data/swiftClient'
import {
  PersonCredentialsBrowser,
  PersonProfilesBrowser,
  PersonProviderProfilesBrowser,
  PersonRolesBrowser,
  useResetPasswordFlow,
} from '../screen-components/person-screen'
import { adaptSDKRead, cacheKeys } from '../utils-hooks'

export const personScreenTabs = [
  PERSON_SCREEN_CREDENTIALS_TAB,
  PERSON_SCREEN_ROLES_TAB,
  PERSON_SCREEN_PROFILES_TAB,
  PERSON_SCREEN_AUTHENTICATION_TAB,
] as const

type PersonScreenTab = (typeof personScreenTabs)[number]

type PersonScreenProps = {
  tab?: PersonScreenTab
}

export const PersonScreen = ({ tab }: PersonScreenProps) => (
  <ReadScreen<PersonTypes>
    paramUuidProps={{
      paramKey: 'personId',
      entityType: 'person',
      redirectPath: `/${PEOPLE_SCREEN_PATH}`,
    }}
    readRequest={adaptSDKRead(swiftClientWithoutErrorHandling.person)}
    cacheKey={cacheKeys.person}
    entityType="person"
    headerContentFactory={(person) => <PersonHeader person={person} />}
    contentFactory={{
      tab,
      tabs: personScreenTabs,
      tabPanesFactory,
      tabBarExtraContent: (person) => (
        <EntityTimestampsDisplay
          created_at={person.primary_profile.created_at}
          updated_at={person.primary_profile.updated_at}
        />
      ),
    }}
  />
)

const PersonHeader = ({ person }: { person: PersonTypes }) => {
  const {
    person_id,
    primary_profile: {
      display_name,
      avatar,
      profile_id,
      organization_name,
      organization_id,
      employee_number,
      email,
      phone_primary,
    },
  } = person

  const organizationUrl = `/${ORGANIZATIONS_SCREEN_PATH}/${organization_id}`

  const { handleResetPasswordClick } = useResetPasswordFlow({
    organization: { id: organization_id, name: organization_name },
    person,
  })

  return (
    <HeaderContent
      entityName={display_name}
      entityId={person_id}
      breadCrumbPath={`/${PEOPLE_SCREEN_PATH}`}
      entityType="person"
      leftContent={
        <Avatar
          name={display_name || '-'}
          src={avatar}
          userId={profile_id}
          size="huge"
        />
      }
      infoItemsLeft={[
        {
          title: 'Organization',
          value:
            organization_id !== EMPTY_ID ? (
              <CopyableLink
                label={organization_name}
                copyableText={organization_id}
                to={organizationUrl}
              />
            ) : (
              '-'
            ),
        },
        {
          title: 'Employee ID',
          value: employee_number ? (
            <CopyableDisplay label={employee_number} />
          ) : (
            '-'
          ),
        },
        {
          title: 'Email',
          value: (
            <>
              {email ? <CopyableDisplay label={email} /> : '-'}
              <Divider type="vertical" />
              <LabelDisplay label="Phone" />
              {phone_primary ? <CopyableDisplay label={phone_primary} /> : '-'}
            </>
          ),
        },
      ]}
      actionDropDownsItems={[
        {
          key: 'reset-password',
          label: 'Reset Password',
          onClick: handleResetPasswordClick,
        },
      ]}
    />
  )
}

const tabPanesFactory = (person: PersonTypes) => {
  const {
    person_id,
    primary_profile: { organization_id },
  } = person

  const tabs: TabsProps['items'] = [
    {
      label: 'Credentials',
      key: PERSON_SCREEN_CREDENTIALS_TAB,
      children: (
        <PersonCredentialsBrowser
          personId={person_id}
          organizationId={organization_id}
        />
      ),
    },
    {
      label: 'Roles',
      key: PERSON_SCREEN_ROLES_TAB,
      children: <PersonRolesBrowser person={person} />,
    },
    {
      label: 'Profiles',
      key: PERSON_SCREEN_PROFILES_TAB,
      children: <PersonProfilesBrowser person={person} />,
    },
    {
      label: 'Authentication',
      key: PERSON_SCREEN_AUTHENTICATION_TAB,
      children: <PersonProviderProfilesBrowser person={person} />,
    },
  ]

  return tabs
}
