import { Modal, Typography } from '@swiftctrl/swift-component-library'
import { queryClient } from '../../../../data/queryClient'
import {
  cacheKeys,
  showErrorNotification,
  showSuccessNotification,
  useDeleteProviderProfile,
} from '../../../../utils-hooks'

export const useDeleteProviderProfileFlow = (providerProfileId: string) => {
  const handleDeleteProviderProfileClick = () => {
    Modal.confirm({
      centered: true,
      title: 'Delete Provider Profile',
      content: (
        <>
          Are you sure you want to delete{' '}
          <Typography.Text code>{providerProfileId}</Typography.Text>?
        </>
      ),
      okText: 'Delete',
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
      onOk: deleteProviderProfile,
    })
  }

  const { mutate } = useDeleteProviderProfile()

  const deleteProviderProfile = () => {
    mutate(
      { providerProfileId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [cacheKeys.provider_profiles],
          })

          queryClient.invalidateQueries({
            queryKey: [cacheKeys.personProviderProfiles],
          })

          queryClient.invalidateQueries({
            queryKey: [cacheKeys.person_emails],
          })

          showSuccessNotification('Provider profile deleted')
        },
        onError: (error) => {
          showErrorNotification('Error while deleting provider profile', error)
        },
      },
    )
  }

  return { handleDeleteProviderProfileClick }
}
