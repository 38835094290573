import debounce from 'lodash/debounce'
import { useEffect, useMemo, useRef, useState } from 'react'
import { generateHexadecimal } from './hexadecimalUtils'

const DATA_ATTRIBUTE = 'data-tree-id'

const DISTANCE_FROM_BOTTOM = 21

export const GET_HEIGHT_DEBOUNCE_TIME = 250

const getDistanceToTop = (element: Element | null) => {
  if (!element) {
    return 0
  }

  const rect = element.getBoundingClientRect()

  const scrollTop = window.scrollY || document.documentElement.scrollTop

  return rect.top + scrollTop
}

/** This hook is used to calculate the height of the tree, which is important for the virtual scroll */
export const useGetHeightProps = () => {
  const treeIdRef = useRef(`tree-${generateHexadecimal(7)}`)

  const [treeHeight, setTreeHeight] = useState(0)

  const calculateHeight = useMemo(
    () =>
      debounce(() => {
        const selector = `[${DATA_ATTRIBUTE}="${treeIdRef.current}"]`

        const element = document.querySelector(selector)

        const distanceToTop = getDistanceToTop(element)

        // The only relevant and arbitrary value is DISTANCE_FROM_BOTTOM
        // The rest is calculated automatically from the top of the inner window
        setTreeHeight(window.innerHeight - distanceToTop - DISTANCE_FROM_BOTTOM)
      }, GET_HEIGHT_DEBOUNCE_TIME),
    [setTreeHeight],
  )

  useEffect(() => {
    calculateHeight()

    window.addEventListener('resize', calculateHeight)

    return () => {
      window.removeEventListener('resize', calculateHeight)
    }
  }, [calculateHeight])

  return {
    treeProps: { [DATA_ATTRIBUTE]: treeIdRef.current, height: treeHeight },
  }
}
