import { EntityTypesIds } from '@swiftctrl/api-client'
import { KeyboardEvent } from 'react'
import { ENTITIES_SCREEN_PATH } from '../../../config/paths'
import {
  getReadEntityScreenPath,
  hasReadEntityScreenPath,
} from '../../../config/readEntityScreenConfig'
import { swiftClientWithoutErrorHandling } from '../../../data/swiftClient'
import {
  isValidUuid,
  showErrorNotification,
  useBoolean,
} from '../../../utils-hooks'

export const useEntitySearchLogic = ({
  onSearchNotValidUuid,
  onEntityNotFound,
  onEntityScreenOpenedInNewTab,
}: {
  onSearchNotValidUuid: () => void
  onEntityNotFound: () => void
  onEntityScreenOpenedInNewTab: () => void
}) => {
  const [isSearching, setSearchingTrue, setSearchingFalse] = useBoolean()

  const search = async (event: KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    if (!isValidUuid(value)) {
      onSearchNotValidUuid()

      return
    }

    setSearchingTrue()

    try {
      const {
        data: { entity_type_id },
      } = await swiftClientWithoutErrorHandling.entity.at(value).read()

      const url = new URL(window.location.href)

      url.pathname = buildPath(entity_type_id, value)

      onEntityScreenOpenedInNewTab()

      window.open(url, '_blank')
    } catch (error: any) {
      if (error?.response?.status === 404) {
        onEntityNotFound()
      } else {
        showErrorNotification('Error while reading entity', error)
      }
    } finally {
      setSearchingFalse()
    }
  }

  return { search, isSearching }
}

const buildPath = (entityTypeId: EntityTypesIds, entityId: string) => {
  let path = '/'

  if (hasReadEntityScreenPath(entityTypeId)) {
    path += getReadEntityScreenPath(entityTypeId)
  } else {
    path += ENTITIES_SCREEN_PATH
  }

  path += `/${entityId}`

  return path
}
