import {
  CredentialFieldConstraint,
  CredentialFieldType,
} from '@swiftctrl/api-client'
import { CredentialTemplateFieldTimeConstraintView } from './CredentialTemplateFieldTimeConstraintView'
import { CredentialTemplateFieldIntegerConstraintView } from './integer-constraint'
import { BitfieldValue, Constraint } from './utils'
import {
  CredentialTemplateFieldStringConstraintView,
  CredentialTemplateFieldUuidConstraintView,
} from './valid-values'

type CredentialTemplateFieldConstraintInputProps = {
  fieldType: CredentialFieldType | undefined
  templateConstraint: Constraint | null
  templateBitfieldValue: BitfieldValue | null
  formatConstraint: CredentialFieldConstraint | undefined
  onChange: (value: Constraint | null) => void
}

export const CredentialTemplateFieldConstraintInput = ({
  fieldType,
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: CredentialTemplateFieldConstraintInputProps) => {
  switch (fieldType) {
    case 'INTEGER':
      return (
        <CredentialTemplateFieldIntegerConstraintView
          templateConstraint={templateConstraint}
          templateBitfieldValue={templateBitfieldValue}
          formatConstraint={formatConstraint}
          onChange={onChange}
        />
      )

    case 'STRING':
      return (
        <CredentialTemplateFieldStringConstraintView
          templateConstraint={templateConstraint}
          formatConstraint={formatConstraint}
          onChange={onChange}
        />
      )

    case 'TIME':
      return (
        <CredentialTemplateFieldTimeConstraintView
          templateConstraint={templateConstraint}
          formatConstraint={formatConstraint}
          onChange={onChange}
        />
      )

    case 'UUID':
      return (
        <CredentialTemplateFieldUuidConstraintView
          templateConstraint={templateConstraint}
          formatConstraint={formatConstraint}
          onChange={onChange}
        />
      )

    default:
      return null
  }
}
