import { EntityTypes } from '@swiftctrl/api-client'
import { useState } from 'react'

export const useManageTargets = (assignedTargets: Partial<EntityTypes>[]) => {
  const [selectedTargets, setSelectedTargets] = useState(assignedTargets)

  const addTarget = (target: Partial<EntityTypes>) => {
    setSelectedTargets((previous) => [target, ...previous])
  }

  const removeTarget = (target: Partial<EntityTypes>) => {
    setSelectedTargets((previous) => {
      const { entity_id } = target

      const update = previous.filter(
        (selectedTarget) => selectedTarget.entity_id !== entity_id,
      )

      return update
    })
  }

  const removeAllTargets = () => {
    setSelectedTargets([])
  }

  const reset = () => {
    setSelectedTargets(assignedTargets)
  }

  return {
    selectedTargets,
    addTarget,
    removeTarget,
    removeAllTargets,
    reset,
  }
}
