import { SinkFilterConfiguration } from '@swiftctrl/api-client'
import { isEmpty } from 'lodash'

export const normalizeFilterConfiguration = (
  filterConfiguration: {} | SinkFilterConfiguration | null | undefined,
) => {
  if (!filterConfiguration || isEmpty(filterConfiguration)) {
    const emptyFilterConfiguration: SinkFilterConfiguration = {
      route_filter: null,
      invert_route_filter: false,
      structure_filter: null,
      invert_structure_filter: false,
      target_filter: null,
      invert_target_filter: false,
      target_filter_switch_mirror: false,
      mirror_target_filter: null,
      invert_mirror_target_filter: false,
      association_filter: null,
      invert_association_filter: false,
    }

    return emptyFilterConfiguration
  }

  const assertedFilterConfiguration =
    filterConfiguration as SinkFilterConfiguration

  return assertedFilterConfiguration
}
