import { RoleArchetype, RolesTypes } from '@swiftctrl/api-client'
import { Layout, Modal } from '@swiftctrl/swift-component-library'
import { invalidateReadScreenQuery } from '../../../../components'
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from '../../../../components/menu'
import {
  cacheKeys,
  showSuccessNotification,
  useEditRole,
} from '../../../../utils-hooks'

interface RoleArchetypeViewProps {
  roleArchetype: RoleArchetype
  role: RolesTypes
}

export const RoleArchetypeView = ({
  roleArchetype,
  role,
}: RoleArchetypeViewProps) => {
  const { role_id, archetypes } = role

  const { mutate: editArchetype } = useEditRole()

  const handleDelete = () => {
    const updatedArchetypes = archetypes?.filter(
      (archetype) => archetype !== roleArchetype,
    )

    editArchetype(
      {
        id: role_id!,
        payload: {
          ...role,
          archetypes: updatedArchetypes,
        },
      },
      {
        onSuccess: () => {
          invalidateReadScreenQuery(cacheKeys.role, role_id)

          showSuccessNotification(`Archetype "${roleArchetype}" removed`)
        },
      },
    )
  }

  return (
    <Layout horizontal justifyContent="space-between" alignItems="center">
      <Layout grow horizontal>
        {roleArchetype}
      </Layout>
      <ActionsDropdown items={getItems(roleArchetype, handleDelete)} />
    </Layout>
  )
}

const getItems = (
  roleArchetype: RoleArchetype,
  onDelete: () => void,
): ActionsDropdownItem[] => [
  {
    key: 'remove',
    label: 'Remove',
    danger: true,
    onClick: confirmArchetypeRemoval(roleArchetype, onDelete),
  },
]

const confirmArchetypeRemoval = (
  roleArchetype: RoleArchetype,
  onDelete: () => void,
) => {
  const onClick = () => {
    Modal.confirm({
      title: 'Remove Archetype',
      content: `Are you sure you want to remove "${roleArchetype}"?`,
      okText: 'Remove',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: onDelete,
      centered: true,
    })
  }

  return onClick
}
