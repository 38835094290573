import { Button, Tooltip } from '@swiftctrl/swift-component-library'
import { CREDENTIAL_TEMPLATE_INVALID_MESSAGE } from './utils'

type CredentialTemplateSaveButtonProps = {
  isDirty: boolean
  isValid: boolean
  save: () => void
  isSaving: boolean
}

export const CredentialTemplateSaveButton = ({
  isDirty,
  isValid,
  save,
  isSaving,
  ...props
}: CredentialTemplateSaveButtonProps) => {
  const tooltipMessage = getTooltipMessage(isDirty, isValid)

  const enabled = isDirty && isValid

  return (
    <Tooltip title={tooltipMessage} {...props}>
      <Button
        onClick={save}
        loading={isSaving}
        disabled={!enabled}
        type="primary"
      >
        Save
      </Button>
    </Tooltip>
  )
}

const getTooltipMessage = (isDirty: boolean, isValid: boolean) => {
  if (!isDirty) {
    return 'No changes to save'
  }

  if (!isValid) {
    return CREDENTIAL_TEMPLATE_INVALID_MESSAGE
  }

  return ''
}
