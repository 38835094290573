import {
  OrganizationTypes,
  SinkTypes,
  SourceTypes,
} from '@swiftctrl/api-client'
import { EntityType } from '../../../data/models'

export type EntityNode = {
  entity_id: string
  entity_name: string
  entity_description: string
  entity_type_id: EntityType
  overseer_name?: string
}

export type Entity = SourceTypes | SinkTypes | OrganizationTypes | EntityNode

export const SOURCE_SINK_RELATIONSHIP_COLOR = '#C96D7F'
