import { ProfilesTypes } from '@swiftctrl/api-client'
import {
  InputProps,
  SelectProps,
  Typography,
} from '@swiftctrl/swift-component-library'
import { AutoComplete } from 'antd'
import { useState } from 'react'
import { useEventContext } from '../../states'

type ProviderProfileLoginDataInputProps = {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  size?: InputProps['size']
  placeholder?: string
}

export const ProviderProfileLoginDataInput = (
  props: ProviderProfileLoginDataInputProps,
) => {
  const [options, setOptions] = useState<SelectProps['options']>([])

  const { useEvent } = useEventContext()

  useEvent('ProfileSelect.change', (profile: ProfilesTypes | undefined) => {
    const options = buildBrowserOptions(profile)

    setOptions(options)
  })

  return <AutoComplete options={options} {...props} />
}

const buildOptions = (email: string, employeeNumber: string) => {
  const options: SelectProps['options'] = []

  if (employeeNumber) {
    options.push(buildOption(employeeNumber, 'Employee number'))
  }

  if (email) {
    options.push(buildOption(email, 'Email'))
  }

  return options
}

const buildOption = (value: string, label: string) => ({
  value,
  label: (
    <>
      {value}
      <Typography.Text type="secondary">{` (${label})`}</Typography.Text>
    </>
  ),
})

const buildBrowserOptions = (profile: ProfilesTypes | undefined) => {
  if (!profile) {
    return []
  }

  const { email, employee_number } = profile

  const options = buildOptions(email, employee_number)

  return options
}
