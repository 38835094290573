import { useEffect } from 'react'
import { RestApiParamsKeys } from '../types'
import {
  showInvalidFilterEntryErrorNotification,
  showNoEntityParamErrorNotification,
} from './notifications'
import { useHandleUrlParams } from './useHandleParams'
import { isValidUuid } from './uuidUtils'

type Params<T> = {
  paramKey: RestApiParamsKeys
  validator: (param: string) => T
  validatorErrorNotificationType: 'invalidFilter' | 'noEntity'
}

/**
 * Ensures that the URL param is a valid UUID and passes custom validation.
 *
 * If the param isn't valid, it will be removed from the URL.
 *
 * If the param isn't a valid UUID, an "Invalid filter" notification will be displayed.
 *
 * If the param doesn't pass custom validation, a notification will be displayed
 * depending on `errorNotificationType`.
 *
 * The UUID validation happens before the custom validation, so if the UUID validation
 * triggers the notification, the custom validation won't run.
 */
export const useGetValidUuidParam = <T>({
  paramKey,
  validator,
  validatorErrorNotificationType,
}: Params<T>) => {
  const { currentParams, cleanParams } = useHandleUrlParams()

  const param = String(currentParams[paramKey] || '')

  const passedUuidValidation = isValidUuid(param)

  const validatedParam = passedUuidValidation ? validator(param) : ''

  useEffect(() => {
    if (!param || validatedParam === param) {
      return
    }

    cleanParams([paramKey])

    if (!passedUuidValidation) {
      showInvalidFilterEntryErrorNotification(paramKey)

      return
    }

    switch (validatorErrorNotificationType) {
      case 'invalidFilter':
        showInvalidFilterEntryErrorNotification(paramKey)

        break

      case 'noEntity':
        showNoEntityParamErrorNotification(paramKey)

        break
    }
  })

  return validatedParam
}
