export enum Platform {
  Mac = 'Mac',
  Windows = 'Windows',
  Linux = 'Linux',
}

export const getPlatform = (): Platform => {
  if (navigator.userAgent.includes('Win')) {
    return Platform.Windows
  }

  if (navigator.userAgent.includes('Linux')) {
    return Platform.Linux
  }

  return Platform.Mac
}
