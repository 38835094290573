import { ProviderProfileTypes, ProviderTypes } from '@swiftctrl/api-client'
import { ProviderProfileFormData } from '../../../config/add-entity-types'
import { FieldGroupConfig } from '../../../utils-hooks'
import { buildInitialValues } from '../buildInitialValues'

export const buildProviderProfileInitialValues = (
  fieldGroups: FieldGroupConfig<ProviderProfileFormData>[],
  providerProfile: ProviderProfileTypes,
  provider: ProviderTypes,
) => {
  const { initialValues: baseInitialValues } = buildInitialValues(
    fieldGroups,
    providerProfile,
    'provider_profile',
  )

  const initialValues: ProviderProfileFormData = {
    ...baseInitialValues,
    preset_organization_id: provider.overseer_id,
    preset_provider: provider,
  }

  return { initialValues }
}
