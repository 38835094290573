import { ActionsDropdown, ActionsDropdownItem } from '../menu'

interface EntityCardActionsDropdownProps {
  onEditClick?: () => void
  onDuplicateClick?: () => void
  onDeleteClick?: () => void
}

export const EntityCardActionsDropdown = ({
  onEditClick,
  onDuplicateClick,
  onDeleteClick,
}: EntityCardActionsDropdownProps) => {
  if (!onEditClick && !onDuplicateClick && !onDeleteClick) {
    return null
  }

  const items = new Array<ActionsDropdownItem>()

  if (onEditClick) {
    items.push({
      key: 'edit',
      label: 'Edit',
      onClick: onEditClick,
    })
  }

  if (onDuplicateClick) {
    items.push({
      key: 'duplicate',
      label: 'Duplicate',
      onClick: onDuplicateClick,
    })
  }

  if ((onEditClick || onDuplicateClick) && onDeleteClick) {
    items.push({ type: 'divider' })
  }

  if (onDeleteClick) {
    items.push({
      key: 'delete',
      label: 'Delete',
      onClick: onDeleteClick,
      danger: true,
    })
  }

  return <ActionsDropdown items={items} />
}
