import { Swift, Tooltip, Typography } from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'
import { isValidUuid } from '../../utils-hooks'

type BaseProps = {
  label: ReactNode
  /**
   * If omitted, defaults to the same as `label`
   */
  copyableText?: string
  /**
   * If omitted, defaults to the same as `copyableText`
   */
  tooltip?: ReactNode
  secondary?: boolean
  copyIconTooltip?: string
  disableTooltipForText?: boolean
}

type TitleProps = BaseProps & {
  title?: boolean
  strong?: never
}

type TextProps = BaseProps & {
  strong?: boolean
  title?: never
}

export type CopyableDisplayProps = TitleProps | TextProps

export const CopyableDisplay = ({
  disableTooltipForText,
  ...props
}: CopyableDisplayProps) => {
  const { tooltip, copyableText, label } = props

  const tooltipTitle = tooltip || copyableText || label

  const displayText = label || tooltipTitle

  return (
    <TextContainer {...props}>
      {disableTooltipForText ? (
        displayText
      ) : (
        <Tooltip title={tooltipTitle}>{displayText}</Tooltip>
      )}
    </TextContainer>
  )
}

const TextContainer: Swift.FC<CopyableDisplayProps> = ({
  copyableText,
  title,
  strong,
  label,
  secondary,
  children,
  copyIconTooltip,
  ...props
}) => {
  const copyText = copyableText || String(label)

  const copyConfig = {
    text: copyText,
    tooltips: copyIconTooltip || `Copy (${copyText})`,
  }

  const isLabelUuid =
    isValidStringUuid(label) || (label === '' && isValidStringUuid(copyText))

  const TypographyComponent = title ? Typography.Title : Typography.Text

  return (
    <TypographyComponent
      copyable={copyConfig}
      strong={!title && strong}
      type={secondary ? 'secondary' : undefined}
      level={title ? 4 : undefined}
      {...props}
    >
      {isLabelUuid ? (
        <Typography.Text code>{children}</Typography.Text>
      ) : (
        children
      )}
    </TypographyComponent>
  )
}

const isValidStringUuid = (str: ReactNode | undefined) => {
  return typeof str === 'string' && str.length > 0 && isValidUuid(str)
}
