import { SinkRuleField } from '@swiftctrl/api-client'
import { Button } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { JsonDisplay } from '../../components'
import { ListFilterModal } from './ListFilterModal'
import { PatchModal } from './PatchModal'
import { RuleFieldMapModal } from './RuleFieldMapModal'

interface RulesTableValueProps {
  record: SinkRuleField
}

export const RulesTableValue = ({ record }: RulesTableValueProps) => {
  const [openModal, setOpenModal] = useState<string | null>(null)

  const additionalActionFieldKey = Object.keys(record).find((key) =>
    ['map', 'patch', 'predicate'].includes(key),
  )

  const additionalFieldKeys = Object.keys(record).find((key) =>
    [
      'integer',
      'string',
      'boolean',
      'strings',
      'string',
      'uuid',
      'time',
      'bool',
    ].includes(key),
  )

  if (additionalFieldKeys) {
    const key = additionalFieldKeys as keyof SinkRuleField

    const value = record[key]

    return typeof value === 'object' ? (
      <JsonDisplay value={value} />
    ) : (
      <span>{String(value)}</span>
    )
  }

  if (additionalActionFieldKey) {
    return (
      <>
        <Button
          size="small"
          type="primary"
          onClick={() => setOpenModal(additionalActionFieldKey!)}
        >
          Open
        </Button>
        <PatchModal
          isVisible={openModal === 'patch'}
          onClose={() => setOpenModal(null)}
          record={record}
        />
        <ListFilterModal
          isVisible={openModal === 'predicate'}
          onClose={() => setOpenModal(null)}
          record={record}
        />
        <RuleFieldMapModal
          isVisible={openModal === 'map'}
          onClose={() => setOpenModal(null)}
          record={record}
        />
      </>
    )
  }
  return <span>-</span>
}
