import { InputProps, TimePicker } from '@swiftctrl/swift-component-library'
import {
  hoursToSeconds,
  minutesToSeconds,
  secondsToHours,
  secondsToMinutes,
} from 'date-fns'

type DurationPickerProps = {
  value?: Date | number
  defaultValue?: Date | number
  onOk: (value: Date | undefined) => void
  disabled?: boolean
  size: InputProps['size']
  placeholder?: string
}

/**
 * Allows picking a duration in the range of 0:00:00 to 23:59:59 inclusive.
 *
 * Durations are displayed in hours, minutes and seconds.
 *
 * The actual value is a Date or number,
 * depending on where in the lifecycle it's accessed.
 *
 * Use the util function `transformDurationPickerValueToDuration` to
 * transform the value from this component to a number,
 * which is the duration in seconds.
 */
export const DurationPicker = ({
  value,
  defaultValue,
  onOk,
  placeholder = '',
  ...restProps
}: DurationPickerProps) => {
  return (
    <TimePicker
      use12Hours={false}
      minuteStep={1}
      showSecond={true}
      showNow={false}
      format="H:mm:ss"
      value={transformValueToDate(value)}
      defaultValue={transformValueToDate(defaultValue)}
      onOk={onOk}
      placeholder={placeholder}
      {...restProps}
    />
  )
}

const transformValueToDate = (value: Date | number | undefined) => {
  if (!value) {
    return undefined
  }

  if (typeof value === 'number') {
    return transformDurationToDate(value)
  }

  return value
}

const transformDurationToDate = (duration: number) => {
  const hoursAndMinutesAndSeconds = duration

  const hours = secondsToHours(hoursAndMinutesAndSeconds)

  const minutesAndSeconds = hoursAndMinutesAndSeconds - hoursToSeconds(hours)

  const minutes = secondsToMinutes(minutesAndSeconds)

  const seconds = minutesAndSeconds - minutesToSeconds(minutes)

  const date = new Date(0, 0, 0, hours, minutes, seconds, 0)

  return date
}
