import { useQuery } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { adaptSDKReadRequest } from '../adaptSDKReadRequest'
import { cacheKeys } from '../cacheKeysUtils'

export const useReadPerson = (personId: string) =>
  useQuery({
    queryKey: [cacheKeys.person, personId],
    queryFn: adaptSDKReadRequest(
      swiftClientWithoutErrorHandling.person,
      personId,
    ),
  })
