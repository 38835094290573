import { CloseCircleFilled } from '@ant-design/icons'
import { InputNumber, InputNumberProps } from 'antd'
import { useRef } from 'react'
import { styled } from 'styled-components'
import { parseBigInt, parseToStringOrUndefined } from '../../../utils-hooks'
import {
  getCredentialTemplateFieldNumberInputMax,
  getCredentialTemplateFieldNumberInputMin,
} from '../integer-constraint/utils'
import { BitfieldValue, Constraint } from '../utils'
import { ValidatedFormItem } from '../ValidatedFormItem'
import { DefaultValueSelect } from './DefaultValueSelect'
import { VALID_VALUES_ERROR_MESSAGE } from './utils'

type DefaultIntegerInputProps = {
  defaultValue: string | null
  constraint: Constraint | null
  bitfieldValue: BitfieldValue | null
  onChange: (value: string) => void
}

export const DefaultIntegerInput = ({
  defaultValue,
  constraint,
  bitfieldValue,
  onChange,
}: DefaultIntegerInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  if (constraint?.valid_values) {
    const options = new Array<string>()

    let isValid = defaultValue ? false : true

    constraint.valid_values.forEach(({ value }) => {
      const parsedValue = String(value)

      options.push(parsedValue)

      if (defaultValue === parsedValue) {
        isValid = true
      }
    })

    return (
      <ValidatedFormItem
        isValid={isValid}
        validationMessage={VALID_VALUES_ERROR_MESSAGE}
      >
        <DefaultValueSelect
          options={options}
          defaultValue={defaultValue}
          isValid={isValid}
          onChange={onChange}
        />
      </ValidatedFormItem>
    )
  }

  const value = getValue(defaultValue)

  const handleChange: InputNumberProps['onChange'] = (value) => {
    if (value === null) {
      onChange('')
    } else {
      onChange(String(value))
    }
  }

  const min = getCredentialTemplateFieldNumberInputMin(
    constraint,
    bitfieldValue,
  )

  const max = getCredentialTemplateFieldNumberInputMax(
    constraint,
    bitfieldValue,
  )

  const { isValid, validationMessage } = validate(value, min, max)

  return (
    <ValidatedFormItem isValid={isValid} validationMessage={validationMessage}>
      <Container>
        <InputNumber
          type="number"
          value={parseToStringOrUndefined(value)}
          onChange={handleChange}
          bordered={false}
          controls={false}
          min={parseToStringOrUndefined(min)}
          max={parseToStringOrUndefined(max)}
          ref={inputRef}
          stringMode
        />
        {value !== undefined && (
          <StyledClearIcon
            onClick={() => {
              onChange('')

              inputRef.current?.focus()
            }}
          />
        )}
      </Container>
    </ValidatedFormItem>
  )
}

const getValue = (defaultValueProp: string | null) => {
  if (!defaultValueProp) {
    return undefined
  }

  const defaultValueNumber = Number(defaultValueProp)

  if (isNaN(defaultValueNumber)) {
    return undefined
  }

  return defaultValueNumber
}

const StyledClearIcon = styled(CloseCircleFilled)`
  position: absolute;
  top: 50%;
  right: 0px;

  width: 12px;
  height: 12px;
  margin-top: -6px;

  color: rgba(0, 0, 0, 0.25);

  cursor: pointer;

  transition: color 0.3s ease, opacity 0.15s ease;

  opacity: 0;

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
`

const Container = styled.div`
  .ant-input-number {
    width: 100%;

    input {
      padding: 0px;
    }
  }

  &:hover ${StyledClearIcon} {
    opacity: 1;
  }
`

const validate = (
  value: number | undefined,
  min: number | bigint | undefined,
  max: number | bigint | undefined,
): { isValid: boolean; validationMessage: string } => {
  if (value === undefined) {
    return {
      isValid: true,
      validationMessage: '',
    }
  }

  if (min === undefined || max === undefined) {
    return {
      isValid: true,
      validationMessage: '',
    }
  }

  const parsedValue = BigInt(value)

  const parsedMin = parseBigInt(min)

  const parsedMax = parseBigInt(max)

  if (parsedValue >= parsedMin && parsedValue <= parsedMax) {
    return {
      isValid: true,
      validationMessage: '',
    }
  }

  return {
    isValid: false,
    validationMessage: `Default value must be between ${min} and ${max}`,
  }
}
