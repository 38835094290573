import { EntityTypes, FIELDS } from '@swiftctrl/api-client'
import { useQuery } from '@tanstack/react-query'
import {
  buildEntityTypeFilter,
  cacheKeys,
  getAllFromBrowseRequest,
  getCachedAccountsEntity,
} from '../utils-hooks'
import { EntityType, SWIFT_ROOT_ENTITY_ID } from './models'
import { swiftClient } from './swiftClient'

const includedEntityTypes: EntityType[] = [
  'organization',
  'role',
  'system',
  'epi',
  'building',
  'room',
  'entity',
  'provider',
]

export const useGetAllEntities = () => {
  const { data, error, refetch, isLoading } = useQuery({
    queryKey: [cacheKeys.entityTree],
    queryFn: async ({ signal }) => {
      const entities = await getAllFromBrowseRequest((offset, limit) =>
        swiftClient.entity.browse(
          {
            baseId: SWIFT_ROOT_ENTITY_ID,
            limit,
            filters: [buildEntityTypeFilter(...includedEntityTypes)],
            select: FIELDS.EntityBrowse,
            offset,
          },
          {
            signal,
          },
        ),
      )

      const castEntities = entities as unknown as EntityTypes[]

      castEntities.sort(sortEntities)

      return castEntities
    },
  })

  return {
    entities: data,
    refetchEntities: refetch,
    refetchingEntities: isLoading,
    getEntitiesError: error,
  }
}

const sortEntities = (a: EntityTypes, b: EntityTypes): number => {
  const { entity_type_id: aEntityType, entity_name: aName } = a

  const { entity_type_id: bEntityType, entity_name: bName } = b

  if (isAccountsEntity(a)) {
    return -1
  }

  if (isAccountsEntity(b)) {
    return 1
  }

  if (aEntityType === 'entity' && bEntityType !== 'entity') {
    return -1
  }

  if (aEntityType !== 'entity' && bEntityType === 'entity') {
    return 1
  }

  if (aEntityType !== bEntityType) {
    return aEntityType.localeCompare(bEntityType)
  }

  if (aName && !bName) {
    return -1
  }

  if (!aName && bName) {
    return 1
  }

  return aName.localeCompare(bName)
}

const isAccountsEntity = (entity: EntityTypes) =>
  entity.entity_id === getCachedAccountsEntity().entity_id

export const isIncludedInEntityTree = (entityType: EntityType) => {
  const check = includedEntityTypes.includes(entityType)

  return check
}
