import { CredentialTemplateFieldConstraintViewProps } from '../utils'
import { ValidValuesView } from './ValidValuesView'

export const CredentialTemplateFieldStringConstraintView = ({
  templateConstraint,
  formatConstraint,
  onChange,
}: CredentialTemplateFieldConstraintViewProps) => {
  return (
    <ValidValuesView
      templateConstraint={templateConstraint}
      formatConstraint={formatConstraint}
      customValidValuesEntryType="string"
      onChange={onChange}
    />
  )
}
