import { DatePicker } from '@swiftctrl/swift-component-library'
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker'
import {
  buildFakeLocalDateFromISODate,
  buildISOTimestampFromLocalDate,
} from '../../utils-hooks'

type DateRangePickerProps = {
  defaultValue?: { start: string; end: string }
  min?: string
  max?: string
  onChange: (value: { start: string; end: string }) => void
}

/**
 * Accepts and returns ISO timestamps
 */
export const DateRangePicker = ({
  defaultValue: defaultValueProp,
  min,
  max,
  onChange,
}: DateRangePickerProps) => {
  const defaultValue = buildDefaultValue(defaultValueProp)

  const minDate = buildFakeLocalDateFromISODate(min)

  const maxDate = buildFakeLocalDateFromISODate(max)

  const disabledDate = (date: Date) => {
    if (!minDate || !maxDate) {
      return false
    }

    return date < minDate || date > maxDate
  }

  return (
    <DatePicker.RangePicker
      placeholder={['Start', 'End']}
      defaultValue={defaultValue}
      disabledDate={disabledDate}
      suffixIcon={null}
      allowClear={false}
      onChange={handleChange(onChange)}
    />
  )
}

const buildDefaultValue = (
  defaultValueProp: DateRangePickerProps['defaultValue'],
) => {
  if (!defaultValueProp) {
    return undefined
  }

  const { start, end } = defaultValueProp

  const startDate = buildFakeLocalDateFromISODate(start)

  const endDate = buildFakeLocalDateFromISODate(end)

  return [startDate, endDate] as RangePickerProps<Date>['defaultValue']
}

const handleChange = (onChangeProp: DateRangePickerProps['onChange']) => {
  const onChange: RangePickerProps<Date>['onChange'] = (values) => {
    if (!values) {
      return
    }

    const [start, end] = values

    if (!start || !end) {
      return
    }

    const adjustedStart = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      0,
      0,
      0,
      0,
    )

    const adjustedEnd = new Date(
      end.getFullYear(),
      end.getMonth(),
      end.getDate(),
      23,
      59,
      59,
      0,
    )

    const startTimestamp = buildISOTimestampFromLocalDate(adjustedStart)

    const endTimestamp = buildISOTimestampFromLocalDate(adjustedEnd)

    onChangeProp({ start: startTimestamp, end: endTimestamp })
  }

  return onChange
}
