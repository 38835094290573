import { Col, Row } from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { useBrowseEntitiesContext } from '../../states'
import { FieldKey } from '../../utils-hooks'
import { EmptyPlaceholder } from './EmptyPlaceholder'
import { EntitiesBrowser } from './EntitiesBrowser'

type EntitiesCardsBrowserProps<T> = {
  render: (data: T) => ReactNode
}

export const CARDS_DATA_PER_FETCH = 48

export const EntitiesCardsBrowser = <T,>({
  render,
}: EntitiesCardsBrowserProps<T>) => {
  const { entityType } = useBrowseEntitiesContext()

  return (
    <EntitiesBrowser
      render={(dataSource: T[], dataKey: FieldKey<T>) => (
        <Row gutter={[16, 16]}>
          {dataSource.length === 0 ? (
            <EmptyPlaceholder entityType={entityType} />
          ) : (
            dataSource.map((data) => (
              <StyledCol key={String(data[dataKey])} xs={24} xl={8} md={12}>
                {render(data)}
              </StyledCol>
            ))
          )}
        </Row>
      )}
    />
  )
}

const StyledCol = styled(Col)`
  display: flex;
`
