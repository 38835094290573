import {
  CredentialFieldType,
  CredentialFormatsTypes,
} from '@swiftctrl/api-client'
import { CredentialTemplateField, getCredentialFormatField } from '../utils'
import { integerValidator } from './integerValidator'
import { stringValidator } from './stringValidator'
import { timeValidator } from './timeValidator'
import { CredentialFieldValidator } from './utils'
import { uuidValidator } from './uuidValidator'

const defaultValidator: CredentialFieldValidator = () => true

const mapFieldTypeToValidator: Record<
  CredentialFieldType,
  CredentialFieldValidator
> = {
  BOOL: defaultValidator,
  BYTE_ARRAY: defaultValidator,
  INTEGER: integerValidator,
  STRING: stringValidator,
  TIME: timeValidator,
  UUID: uuidValidator,
}

export const validateCredentialTemplate = (
  templateFields: CredentialTemplateField[],
  credentialFormat: CredentialFormatsTypes,
): boolean => {
  for (let i = 0; i < templateFields.length; i++) {
    const templateField = templateFields[i]

    const { display_name, field_type } = templateField

    if (display_name.length === 0) {
      return false
    }

    const formatField = getCredentialFormatField(
      credentialFormat,
      templateField,
    )

    const validator = mapFieldTypeToValidator[field_type]

    const isFieldValid = validator(templateField, formatField)

    if (!isFieldValid) {
      return false
    }
  }

  return true
}
