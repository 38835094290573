import { PersonRoleArcheTypes, RoleArcheTypes } from '@swiftctrl/api-client'
import { Select, SelectProps } from '@swiftctrl/swift-component-library'
import { useQuery } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../../data/swiftClient'
import { useBrowseEntitiesContext } from '../../../states'
import { cacheKeys, longCacheOptions } from '../../../utils-hooks'

type OrganizationArchetypesSelectProps = {
  organizationId: string
  value?: Partial<RoleArcheTypes>
  onChange?: (value: Partial<RoleArcheTypes> | undefined) => void
}

export const OrganizationArchetypesSelect = ({
  organizationId,
  value,
  onChange,
}: OrganizationArchetypesSelectProps) => {
  const { data, isPending } = useBrowseOrganizationArchetypes(organizationId)

  const { dataSource } = useBrowseEntitiesContext<PersonRoleArcheTypes>()

  const options = data ? buildOptions(data.data, dataSource) : undefined

  const handleChange = (value: number) => {
    const archetype = data?.data.find(
      (archetype) => archetype.role_archetype_id === value,
    )

    onChange?.(archetype)
  }

  return (
    <Select
      value={value?.role_archetype_id}
      onChange={handleChange}
      options={options}
      loading={isPending}
    />
  )
}

const useBrowseOrganizationArchetypes = (organizationId: string) =>
  useQuery({
    queryKey: [cacheKeys.organization_role_archetypes, organizationId],
    queryFn: async ({ signal }) => {
      return swiftClientWithoutErrorHandling.organization
        .at(organizationId)
        .roleArcheTypes.browse(
          {},
          {
            signal,
          },
        )
    },
    ...longCacheOptions,
  })

const buildOptions = (
  archetypes: Partial<RoleArcheTypes>[],
  personRoleArchetypes: PersonRoleArcheTypes[] | undefined,
) => {
  const transformed = archetypes.filter(
    (archetype) => archetype.role_archetype !== 'MOBILE_CREDENTIAL_USER',
  )

  transformed.sort((a, b) => a.role_archetype!.localeCompare(b.role_archetype!))

  const options: SelectProps['options'] = transformed.map((archetype) => {
    const isAssigned = Boolean(
      personRoleArchetypes?.find(
        (personRoleArchetype) =>
          personRoleArchetype.role_archetype_id === archetype.role_archetype_id,
      ),
    )

    return {
      value: archetype.role_archetype_id,
      label: archetype.role_archetype,
      disabled: isAssigned,
    }
  })

  return options
}
