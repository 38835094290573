import { OrganizationTypes } from '@swiftctrl/api-client'
import {
  CARDS_DATA_PER_FETCH,
  EntitiesCardsBrowser,
  HTMLTitle,
  StyledEntitiesBrowserScreen,
} from '../components'
import { swiftClient } from '../data/swiftClient'
import {
  AddOrganizationView,
  OrganizationCard,
} from '../screen-components/organizations-screen'
import { BrowseEntitiesConfig, BrowseEntitiesContextProvider } from '../states'
import {
  cacheKeys,
  combineEntitiesWithProfile,
  EntityWithProfile,
  isValidUuid,
  useBoolean,
} from '../utils-hooks'

type CombinedData = EntityWithProfile<OrganizationTypes>

export const OrganizationsScreen = () => {
  const [
    addOrganizationViewOpen,
    openAddOrganizationView,
    closeAddOrganizationView,
  ] = useBoolean()

  return (
    <>
      <StyledEntitiesBrowserScreen>
        <HTMLTitle value="Organizations" />
        <BrowseEntitiesContextProvider
          dataPerFetch={CARDS_DATA_PER_FETCH}
          browse={browse}
          dataKey="key"
          searchable
          entityType="organization"
          displayAddButton
          onAddButtonClick={openAddOrganizationView}
        >
          <EntitiesCardsBrowser render={render} />
        </BrowseEntitiesContextProvider>
      </StyledEntitiesBrowserScreen>
      <AddOrganizationView
        open={addOrganizationViewOpen}
        onClose={closeAddOrganizationView}
      />
    </>
  )
}

const browseCombinedData: BrowseEntitiesConfig<CombinedData>['request'] =
  async ({ baseId, pageParam, dataPerFetch, search, signal }) => {
    const response = await swiftClient.organization.browse(
      {
        baseId,
        offset: pageParam || 0,
        limit: dataPerFetch,
        select: [
          'organization_id',
          'profile_id',
          'overseer_name',
          'entity_name',
          'entity_description',
          'created_at',
          'updated_at',
        ],
        filters: search
          ? isValidUuid(search)
            ? `organization_id EQ ${search} OR overseer_id EQ ${search}`
            : `entity_name EQ_IC %${search}% OR entity_description EQ_IC %${search}%`
          : undefined,
        sort: 'entity_name:asc',
      },
      { signal },
    )

    const organizations = response.data as OrganizationTypes[]

    const combinedData = await combineEntitiesWithProfile(
      organizations,
      'organization_id',
      baseId,
      signal,
    )

    return {
      ...response,
      data: combinedData,
    }
  }

const browse: BrowseEntitiesConfig<CombinedData> = {
  request: browseCombinedData,
  cacheKey: cacheKeys.organizations,
}

const render = ({ entity, profile }: CombinedData) => (
  <OrganizationCard organization={entity} profile={profile} />
)
