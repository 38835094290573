import { areCurrentFieldsValid } from '../add-entities'
import { FieldGroupConfig, getCurrentFieldConfigs } from '../../utils-hooks'

export const getFields = <FormData>(
  fieldGroups: FieldGroupConfig<FormData>[],
  values: FormData,
  overseerIdIsPreset: boolean,
) => {
  const { currentFieldGroups } = getCurrentFieldConfigs(
    fieldGroups,
    values,
    overseerIdIsPreset,
  )

  const fields = currentFieldGroups.flatMap((group) => group.fields)

  const fieldsAreValid = areCurrentFieldsValid(fields, values, 'edit')

  return { fields, fieldsAreValid }
}
