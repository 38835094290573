import { formatDistance, formatISO9075, parseISO } from 'date-fns'

const DATE_SUBSTRING_LENGTH = 'YYYY-MM-DD'.length

export const formatDateTime = (strDate: string) => {
  if (isNaN(Date.parse(strDate))) {
    return ''
  }
  return formatISO9075(parseISO(strDate))
}

export const formatTimeAgo = (strDate: string) =>
  formatDistance(parseISO(strDate), new Date(), { addSuffix: true })

export const formatEntityTimestamp = (strDate: string | undefined) =>
  strDate ? `${formatDateTime(strDate)} (${formatTimeAgo(strDate)})` : 'N/A'

export const trimDateTimeToDate = (strDate: string) =>
  strDate.substring(0, DATE_SUBSTRING_LENGTH)
