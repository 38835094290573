import { CredentialFieldConstraint } from '@swiftctrl/api-client'
import { BitfieldValue, Constraint } from '../utils'
import { CustomValidValuesView } from './CustomValidValuesView'
import { getBitfieldValueMax, getBitfieldValueMin } from './utils'

type TemplateBitfieldValueAndValidValuesProps = {
  templateConstraint: Constraint
  templateBitfieldValue: BitfieldValue
  formatConstraint: CredentialFieldConstraint | undefined
  onChange: (constraint: Constraint | null) => void
}

export const TemplateBitfieldValueAndValidValues = ({
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: TemplateBitfieldValueAndValidValuesProps) => {
  const min = getBitfieldValueMin(templateBitfieldValue)

  const max = getBitfieldValueMax(templateBitfieldValue)

  return (
    <CustomValidValuesView
      templateConstraint={templateConstraint}
      templateBitfieldValue={templateBitfieldValue}
      formatConstraint={formatConstraint}
      min={min}
      max={max}
      constraintFromTemplate
      onChange={onChange}
    />
  )
}
