import { Button, ButtonProps, Modal } from '@swiftctrl/swift-component-library'
import { useBoolean } from '../../utils-hooks'
import { JsonEditor } from '../json-editor'

type EpiConfigButtonProps = {
  value: Record<string, any> | undefined
  onChange: (value: Record<string, any>) => void
  disabled?: boolean
  size?: ButtonProps['size']
}

export const EpiConfigButton = ({
  value,
  onChange,
  disabled,
  size,
}: EpiConfigButtonProps) => {
  const [isEditorOpen, openEditor, closeEditor] = useBoolean()

  const handleConfirm = (json: Record<string, any>) => {
    closeEditor()

    onChange(json)
  }

  return (
    <>
      <Button size={size} disabled={disabled} onClick={openEditor}>
        Edit config
      </Button>
      <Modal open={isEditorOpen} footer={null} closable={false} width="80%">
        <JsonEditor
          json={value || {}}
          confirmButtonLabel="Close"
          onConfirm={handleConfirm}
        />
      </Modal>
    </>
  )
}
