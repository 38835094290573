import { FilterOutlined } from '@ant-design/icons'
import { styled } from 'styled-components'

export const EntitiesBrowserFilterLabel = () => {
  return (
    <Container>
      <FilterOutlined />
      Filters:
    </Container>
  )
}

const Container = styled.div`
  display: flex;

  align-items: center;

  gap: 4px;
`
