import { RoleProfile } from './buildRoleProfile'

export const isProfileInArray = (
  profile: RoleProfile,
  profiles: RoleProfile[],
) => {
  const { profile_id: seekingId } = profile.profile

  const profileIsInArray = profiles.some(
    (profile) => profile.profile.profile_id === seekingId,
  )

  return profileIsInArray
}
