import { RolesTypes } from '@swiftctrl/api-client'
import { EditRolePayload } from '../../../../utils-hooks'

export const buildEditRolePayload = (role: RolesTypes) => {
  const { entity_name, entity_description, granted_on, archetypes } = role

  const payload: EditRolePayload['payload'] = {
    entity_name,
    entity_description,
    granted_on,
    archetypes,
  }

  return payload
}
