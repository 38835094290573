import { SinkTemplateAddTypes } from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../../../data/queryClient'
import { swiftClient } from '../../../../../data/swiftClient'
import { cacheKeys, showSuccessNotification } from '../../../../../utils-hooks'
import { SINK_TEMPLATE_ID_FIELD_NAME } from './addSinkUtils'

const addSinkTemplate = async ({
  sinkTemplateId,
  variables,
}: SinkTemplateAddTypes & { sinkTemplateId: string }) =>
  swiftClient.sinkTemplate.at(sinkTemplateId).publish({ variables })

export const useAddSinkMutation = ({
  sourceId,
  onSinkAdded,
}: {
  sourceId: string
  onSinkAdded: () => void
}) => {
  const { mutate, isPending } = useMutation({ mutationFn: addSinkTemplate })

  const addSink = (values: any) => {
    const argument = buildRequestArgument(values)

    mutate(argument, {
      onSuccess: () => {
        showSuccessNotification('Sink added')

        queryClient.invalidateQueries({ queryKey: [cacheKeys.sinks, sourceId] })

        onSinkAdded()
      },
    })
  }

  return {
    mutate: addSink,
    isPending,
  }
}

const buildRequestArgument = (values: any) => {
  let sinkTemplateId = ''

  const variables = new Array<{ name: string; value: any }>()

  Object.entries(values).forEach(([key, value]) => {
    if (key === SINK_TEMPLATE_ID_FIELD_NAME) {
      sinkTemplateId = String(value)
    } else {
      variables.push({
        name: key,
        value,
      })
    }
  })

  return {
    sinkTemplateId,
    variables,
  }
}
