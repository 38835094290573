import { Form } from '@swiftctrl/swift-component-library'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { INVALID_PHONE_FORMAT_MESSAGE } from '../../../../data/models'
import { buildCommonFormItemProps } from '../../../../utils-hooks'
import { PhoneInput } from '../../../phone-input'
import { validateEmailOrPhone } from './utils'

type FormItemPhoneInputProps = {
  required: boolean
  requireEmailOrPhone: boolean
  email: string | undefined
  isPending: boolean
}

export const AddProfileFormItemPhoneInput = ({
  required,
  requireEmailOrPhone,
  email,
  isPending,
}: FormItemPhoneInputProps) => {
  return (
    <Form.Item
      {...buildCommonFormItemProps({
        name: 'phone',
        label: 'Phone',
        required,
        rules: [
          {
            validator: (_, value) =>
              validateEmailOrPhone(requireEmailOrPhone, email, value),
          },
          {
            validator: (_, value) => {
              if (!value || isPossiblePhoneNumber(value)) {
                return Promise.resolve()
              }

              return Promise.reject(INVALID_PHONE_FORMAT_MESSAGE)
            },
          },
        ],
      })}
    >
      <PhoneInput
        international
        onChange={() => {
          /**
           * no-op because `PhoneInput` requires `onChange`,
           * even though the data is actually handled by the wrapper Form
           */
        }}
        disabled={isPending}
      />
    </Form.Item>
  )
}
