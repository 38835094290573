import {
  ManagedSpacesTypes,
  PersonTypes,
  RoleArcheTypes,
} from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { VALID_EMAIL_FORMAT } from '../../../data/models'
import {
  BackendError,
  swiftClientWithoutErrorHandling,
} from '../../../data/swiftClient'
import {
  hasSomeValue,
  showSuccessNotification,
  useCheckBeforeClose,
} from '../../../utils-hooks'
import { invalidatePersonRoleQueries } from './invalidatePersonRoleQueries'

type FormData = {
  email?: string
  archetype?: Partial<RoleArcheTypes>
  buildingIds?: string | string[]
}

export const useAddArchetypeLogic = (
  person: PersonTypes,
  buildings: Partial<ManagedSpacesTypes>[],
  onClose: () => void,
) => {
  const initialValues: FormData = {}

  const [values, setValues] = useState<FormData>(initialValues)

  const [error, setError] = useState<BackendError | undefined>()

  const reset = () => {
    setValues(initialValues)

    setError(undefined)
  }

  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      const { archetype } = values

      const email = values.email || person.primary_profile.email

      const buildingIds = getBuildingIds(values, buildings)

      const buildingsPayload = buildingIds.map((buildingId) => ({
        building_id: buildingId,
      }))

      return swiftClientWithoutErrorHandling.person
        .at(person.person_id)
        .invitation.add({
          email,
          role_archetype_id: archetype!.role_archetype_id!,
          buildings: buildingsPayload.length > 0 ? buildingsPayload : undefined,
        })
    },
    onSuccess: () => {
      invalidatePersonRoleQueries()

      showSuccessNotification('Archetype added')

      reset()

      onClose()
    },
    onError: setError,
  })

  const isDirty = hasSomeValue(values)

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending,
    isDirty,
    close: onClose,
    reset,
  })

  const handleFinish = () => {
    setError(undefined)

    mutate()
  }

  const isValid = validate(values, person)

  return {
    values,
    setValues,
    error,
    checkBeforeClose,
    handleFinish,
    isPending,
    isValid,
  }
}

const validate = (values: FormData, person: PersonTypes) => {
  const { email, archetype, buildingIds } = values

  if (!person.primary_profile.email) {
    if (!email) {
      return false
    }

    if (!VALID_EMAIL_FORMAT.test(email)) {
      return false
    }
  }

  if (!archetype) {
    return false
  }

  if (archetype.role_archetype !== 'TENANT_ADMIN') {
    switch (archetype.building_selection) {
      case 'one':
        if (!buildingIds) {
          return false
        }

        break

      case 'multiple':
        if (!buildingIds || buildingIds.length === 0) {
          return false
        }

        break
    }
  }

  return true
}

const getBuildingIds = (
  values: FormData,
  buildings: Partial<ManagedSpacesTypes>[],
) => {
  const { buildingIds, archetype } = values

  if (!buildingIds || buildingIds.length === 0) {
    if (archetype!.role_archetype === 'TENANT_ADMIN') {
      const buildingIds = buildings.map((building) => building.space_id!)

      return buildingIds
    }

    switch (archetype?.building_selection) {
      case 'all':
      case 'none':
        return []

      case 'multiple':
      case 'one':
        throw new Error('Unhandled case')
    }
  }

  const normalizedBuildingIds = Array.isArray(buildingIds)
    ? buildingIds
    : [buildingIds!]

  return normalizedBuildingIds
}
