import { Pair, SinkRuleField } from '@swiftctrl/api-client'
import {
  ColumnsType,
  Layout,
  Modal,
  Table,
} from '@swiftctrl/swift-component-library'
import { Typography } from 'antd'
import { styled } from 'styled-components'

interface RuleFieldMapModalProps {
  isVisible: boolean
  onClose: () => void
  record: SinkRuleField
}

export const RuleFieldMapModal = ({
  isVisible,
  onClose,
  record,
}: RuleFieldMapModalProps) => {
  if (!('map' in record)) {
    return null
  }

  const getTitle = () => {
    if (
      'map' in record &&
      'user_defined_field' in record &&
      record.user_defined_field
    ) {
      return record.user_defined_field
    } else {
      return 'RULE_FIELD_MAP'
    }
  }

  const pairWithId = record.map.pairs.map((pair, index) => ({
    ...pair,
    key: index,
  }))

  const columns = getColumns()

  return (
    <StyledModal
      title={getTitle()}
      open={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <StyledTable
        bordered
        pagination={false}
        dataSource={pairWithId}
        columns={columns}
      />
    </StyledModal>
  )
}

const getColumns = (): ColumnsType<Pair> => {
  const columns: ColumnsType<Pair> = [
    {
      title: 'Key',
      key: 'key',
      width: '50%',
      render: (_, record) => {
        const key1 = Object.keys(record).find((key) => key.includes('1'))
        return key1 ? (
          <TextDisplay
            primaryText={record[key1 as keyof Pair] as string}
            secondaryText={key1}
          />
        ) : null
      },
    },
    {
      title: 'Value',
      key: 'value',
      width: '50%',
      render: (_, record) => {
        const key2 = Object.keys(record).find((key) => key.includes('2'))
        return key2 ? (
          <TextDisplay
            primaryText={record[key2 as keyof Pair] as string}
            secondaryText={key2}
          />
        ) : null
      },
    },
  ]

  return columns
}

const StyledModal = styled(Modal)`
  min-width: 60%;
  @media (max-width: 1350px) {
    min-width: 80%;
  }
`

const StyledTable: typeof Table = styled(Table)`
  .ant-table-cell > pre {
    margin: 0px;
  }
`

const TextDisplay = ({
  primaryText,
  secondaryText,
}: {
  primaryText: string
  secondaryText: string
}) => (
  <Layout>
    <Typography.Text>{primaryText}</Typography.Text>
    <Typography.Text type="secondary">{secondaryText}</Typography.Text>
  </Layout>
)
