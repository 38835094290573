import { EpiTypes } from '@swiftctrl/api-client'
import { TabsProps } from '@swiftctrl/swift-component-library'
import {
  CopyableDisplay,
  EnabledDisplay,
  EntityAncestryView,
  EntityTimestampsDisplay,
  GenericEditEntityDrawer,
  HeaderContent,
  HeaderContentProps,
  invalidateReadScreenQuery,
  ReadScreen,
} from '../components'
import {
  EPIS_SCREEN_PATH,
  EPI_SCREEN_ANCESTRY_TAB,
  EPI_SCREEN_CONFIG_TAB,
  EPI_SCREEN_FIELD_MAPPINGS_TAB,
} from '../config/paths'
import { swiftClientWithoutErrorHandling } from '../data/swiftClient'
import {
  EpiConfigView,
  FieldMappingsView,
} from '../screen-components/epi-screen'
import {
  adaptSDKRead,
  buildEpiSubtypeLabel,
  buildLabel,
  cacheKeys,
  useBoolean,
} from '../utils-hooks'

export const epiScreenTabs = [
  EPI_SCREEN_FIELD_MAPPINGS_TAB,
  EPI_SCREEN_CONFIG_TAB,
  EPI_SCREEN_ANCESTRY_TAB,
] as const

type EpiScreenTab = typeof epiScreenTabs[number]

type EpiScreenProps = {
  tab?: EpiScreenTab
}

export const EpiScreen = ({ tab }: EpiScreenProps) => (
  <ReadScreen<EpiTypes>
    paramUuidProps={{
      paramKey: 'epiId',
      entityType: 'epi',
      redirectPath: `/${EPIS_SCREEN_PATH}`,
    }}
    readRequest={adaptSDKRead(swiftClientWithoutErrorHandling.epi)}
    cacheKey={cacheKeys.epi}
    entityType="epi"
    headerContentFactory={(epi) => <EpiHeader epi={epi} />}
    contentFactory={{
      tab,
      tabs: epiScreenTabs,
      tabPanesFactory,
      tabBarExtraContent: (epi) => (
        <EntityTimestampsDisplay
          created_at={epi.created_at!}
          updated_at={epi.updated_at!}
        />
      ),
    }}
  />
)

const EpiHeader = ({ epi }: { epi: EpiTypes }) => {
  const {
    entity_name,
    entity_description,
    overseer_id,
    overseer_name,
    epi_id,
    type: { epi_type_id, name: epi_type_name, type_description },
    subtype: {
      epi_subtype_id,
      product_name: epi_subtype_name,
      sub_type_description,
      vendor,
    },
    on_premises,
    extra_json,
  } = epi

  const [isEditViewOpen, openEditView, closeEditView] = useBoolean()

  const handleEdited = () => {
    invalidateReadScreenQuery(cacheKeys.epi, epi_id)

    closeEditView()
  }

  const headerContentProps: HeaderContentProps = {
    entityName: entity_name,
    entityId: epi_id,
    breadCrumbPath: `/${EPIS_SCREEN_PATH}`,
    entityType: 'epi',
    entityDescription: entity_description,
    infoItemsLeft: [
      {
        title: 'Overseer',
        value: overseer_name,
        copyableText: overseer_id,
      },
      {
        title: 'Type',
        value: (
          <CopyableDisplay
            label={epi_type_name}
            copyableText={epi_type_id}
            tooltip={
              type_description && (
                <>
                  <p>{type_description}</p>
                  ID: {epi_type_id}
                </>
              )
            }
          />
        ),
      },
      {
        title: 'Subtype',
        value: (
          <CopyableDisplay
            label={buildEpiSubtypeLabel(epi.subtype)}
            copyableText={epi_subtype_id}
            tooltip={
              <>
                <p>Vendor: {vendor}</p>
                <p>Product name: {epi_subtype_name}</p>
                <p>ID: {epi_subtype_id}</p>
                {sub_type_description}
              </>
            }
          />
        ),
      },
    ],
    infoItemsRight: [
      {
        title: 'On-premises',
        value: <EnabledDisplay enabled={on_premises} />,
      },
    ],
    actionDropDownsItems: [
      {
        key: 'edit',
        label: 'Edit',
        onClick: openEditView,
      },
    ],
    extraJson: extra_json,
  }

  return (
    <>
      <HeaderContent {...headerContentProps} />
      <GenericEditEntityDrawer
        open={isEditViewOpen}
        entity={epi}
        entityType="epi"
        onEdited={handleEdited}
        onClose={closeEditView}
      />
    </>
  )
}

const tabPanesFactory = (epi: EpiTypes) => {
  const { entity_fields_map, epi_id } = epi

  const fieldMappingsTabLabel = buildLabel(
    'Field mappings',
    entity_fields_map.length,
  )

  const tabs: TabsProps['items'] = [
    {
      label: fieldMappingsTabLabel,
      key: EPI_SCREEN_FIELD_MAPPINGS_TAB,
      children: <FieldMappingsView epi={epi} />,
    },
    {
      label: 'Config',
      key: EPI_SCREEN_CONFIG_TAB,
      children: <EpiConfigView epi={epi} />,
    },
    {
      label: 'Ancestry',
      key: EPI_SCREEN_ANCESTRY_TAB,
      children: <EntityAncestryView entity={{ ...epi, entity_id: epi_id }} />,
    },
  ]

  return tabs
}
