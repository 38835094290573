import { Alert } from '@swiftctrl/swift-component-library'
import React, { PropsWithChildren } from 'react'
import { getErrorMessage } from '../utils-hooks'

type ErrorBoundaryProps = PropsWithChildren

type ErrorBoundaryState = {
  error: Error | undefined
}

const initialState: ErrorBoundaryState = {
  error: undefined,
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: PropsWithChildren) {
    super(props)

    this.state = initialState
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  render() {
    if (this.state.error) {
      const errorMessage = getErrorMessage(this.state.error)

      return <Alert type="error" showIcon icon="❌" message={errorMessage} />
    }

    return this.props.children
  }
}
