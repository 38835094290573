import styled from 'styled-components'

export const CollapseContainer = styled.div`
  .ant-collapse {
    background-color: transparent;
  }

  .ant-collapse-item {
    background-color: white;
    margin-bottom: 16px;
    border-bottom: none;
  }
  .ant-collapse-header {
    align-items: center !important;
  }
`
