import { DataNode } from 'antd/lib/tree'
import { SWIFT_ROOT_ENTITY_ID } from '../../../data/models'
import { getCachedAccountsEntity } from '../../../utils-hooks'
import { findParentKeysPath } from './findParentKeysPath'
import { getEntitiesWithChildren } from './getEntitiesWithChildren'

export const getInitialExpandedKeys = (
  entityTree: DataNode[],
  selectedEntityId: string,
  search: string,
) => {
  if (search) {
    const entitiesWithChildren = getEntitiesWithChildren(entityTree)

    return entitiesWithChildren
  }

  if (selectedEntityId) {
    const selectedKeyPath = findParentKeysPath(entityTree, selectedEntityId)

    if (selectedKeyPath) {
      return selectedKeyPath
    }
  }

  const accounts = getCachedAccountsEntity()

  return [SWIFT_ROOT_ENTITY_ID, accounts.entity_id!]
}
