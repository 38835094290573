import { Input } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { isValidUuid } from '../../utils-hooks'
import {
  CredentialTemplateFieldComponentProps,
  CredentialTemplateFieldFormItem,
} from './utils'

export const CredentialTemplateFieldUuidInput = ({
  field,
}: CredentialTemplateFieldComponentProps) => {
  const { placeholder, default_value, read_only } = field

  const [value, setValue] = useState(default_value || '')

  const isValid = value ? isValidUuid(value) : true

  return (
    <CredentialTemplateFieldFormItem
      field={field}
      help={isValid ? undefined : 'Value is not a UUID'}
      validateStatus={isValid ? undefined : 'error'}
    >
      <Input
        placeholder={placeholder || undefined}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        disabled={read_only}
      />
    </CredentialTemplateFieldFormItem>
  )
}
