import { ProviderProfileBrowseTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { useQuery } from '@tanstack/react-query'
import { SWIFT_ROOT_ENTITY_ID } from '../../data/models'
import { swiftClient } from '../../data/swiftClient'
import { cacheKeys } from '../cacheKeysUtils'

export const useBrowseProviderProfile = ({
  providerId,
  enabled,
  limit,
  totalCountInHeader,
}: {
  providerId: string
  enabled: boolean
  limit?: number
  totalCountInHeader?: boolean
}) =>
  useQuery({
    queryKey: [cacheKeys.provider_profiles, providerId],
    queryFn: () => {
      const q = getQueryHandler<ProviderProfileBrowseTypes>()

      return swiftClient.providerProfile.browse({
        baseId: SWIFT_ROOT_ENTITY_ID,
        filters: [q('WHERE', 'overseer_id', 'EQ', providerId)],
        limit,
        totalCountInHeader,
      })
    },
    enabled,
  })
