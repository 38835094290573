import {
  EntityMirrorBrowseTypes,
  EntityMirrorTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { ColumnsType } from '@swiftctrl/swift-component-library'
import { Link } from 'react-router-dom'
import {
  ORGANIZATIONS_SCREEN_PATH,
  ORGANIZATION_SCREEN_SINKS_PATH,
} from '../../../../config/paths'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  FETCH_ALL_DATA,
} from '../../../../states'
import { cacheKeys, useBoolean } from '../../../../utils-hooks'
import { CopyableCodeDisplay, CopyableDisplay } from '../../../data-display'
import { EntitiesTableBrowser } from '../../EntitiesTableBrowser'
import { AddEntityMirrorView } from './AddEntityMirrorView'
import { DeleteEntityMirrorButton } from './DeleteEntityMirrorButton'
import { AddEntityMirrorEntity } from './models'

interface EntityMirrorsBrowserProps {
  entity: AddEntityMirrorEntity
  organizationId: string
  idToNameMap: Map<string, string>
  nested?: boolean
}

type KeyedEntityMirror = Partial<EntityMirrorTypes> & {
  key: string
}

export const EntityMirrorsBrowser = (props: EntityMirrorsBrowserProps) => {
  const { entity, organizationId, idToNameMap, nested } = props

  const browse = buildBrowse(entity.entity_id)

  const columns = getColumn(organizationId, idToNameMap)

  const [isAddViewOpen, openAddView, closeAddView] = useBoolean()

  return (
    <>
      <BrowseEntitiesContextProvider
        dataPerFetch={FETCH_ALL_DATA}
        browse={browse}
        dataKey="key"
        entityType="entity_mirror"
        displayAddButton
        onAddButtonClick={openAddView}
        {...props}
      >
        <EntitiesTableBrowser
          nested={nested}
          columns={columns}
          background={false}
        />
      </BrowseEntitiesContextProvider>
      <AddEntityMirrorView
        open={isAddViewOpen}
        onClose={closeAddView}
        entity={entity}
        organizationId={organizationId}
      />
    </>
  )
}

const buildBrowse = (
  entityId: string,
): BrowseEntitiesConfig<KeyedEntityMirror> => ({
  request: async ({ baseId, dataPerFetch, pageParam, signal }) => {
    const q = getQueryHandler<EntityMirrorBrowseTypes>()

    const response = await swiftClient.entityMirror.browse(
      {
        baseId,
        offset: pageParam,
        limit: dataPerFetch,
        select: [
          'entity_id',
          'entity_overseer_id',
          'mirror_id',
          'mirror_overseer_id',
          'sink_id',
          'sink_name',
        ],
        filters: [
          q('WHERE', 'entity_id', 'EQ', entityId),
          q('OR', 'mirror_id', 'EQ', entityId),
        ],
      },
      {
        signal,
      },
    )

    response.data = response.data.map(addKey)

    /**
     * We can sort in here because there's a small number of mirrors
     * for any given entity
     */
    response.data.sort(sortBySinkName)

    return response
  },
  cacheKey: [cacheKeys.entity_mirrors, entityId],
})

const getColumn = (
  organizationId: string,
  idToNameMap: Map<string, string>,
) => {
  const columns: ColumnsType<EntityMirrorTypes> = [
    {
      title: 'Sink name',
      render: ({ sink_name, sink_id }: EntityMirrorTypes) => (
        <Link
          to={`/${ORGANIZATIONS_SCREEN_PATH}/${organizationId}/${ORGANIZATION_SCREEN_SINKS_PATH}/${sink_id}`}
        >
          {sink_name || sink_id}
        </Link>
      ),
      width: '20%',
    },
    {
      title: 'Entity',
      render: ({ entity_id }: EntityMirrorTypes) => (
        <EntityDisplay id={entity_id} idToNameMap={idToNameMap} />
      ),
      width: '20%',
    },
    {
      title: 'Mirror',
      render: ({ mirror_id }: EntityMirrorTypes) => (
        <EntityDisplay id={mirror_id} idToNameMap={idToNameMap} />
      ),
      width: '20%',
    },
    {
      title: 'Entity Overseer',
      render: ({ entity_overseer_id }: EntityMirrorTypes) => (
        <EntityDisplay id={entity_overseer_id} idToNameMap={idToNameMap} />
      ),
      width: '20%',
    },
    {
      title: 'Mirror Overseer',
      render: ({ mirror_overseer_id }: EntityMirrorTypes) => (
        <EntityDisplay id={mirror_overseer_id} idToNameMap={idToNameMap} />
      ),
      width: '20%',
    },
    {
      render: (entityMirror: EntityMirrorTypes) => (
        <DeleteEntityMirrorButton
          entityMirror={entityMirror}
          idToNameMap={idToNameMap}
        />
      ),
    },
  ]

  return columns
}

const addKey = (
  entityMirror: Partial<EntityMirrorTypes>,
): KeyedEntityMirror => {
  const { entity_id, mirror_id } = entityMirror

  return {
    ...entityMirror,
    key: `${entity_id}|${mirror_id}`,
  }
}

const sortBySinkName = (
  a: Partial<EntityMirrorTypes>,
  b: Partial<EntityMirrorTypes>,
) => a.sink_name!.localeCompare(b.sink_name!)

const EntityDisplay = ({
  id,
  idToNameMap,
}: {
  id: string
  idToNameMap: Map<string, string>
}) => {
  const name = idToNameMap.get(id)

  if (name) {
    return <CopyableDisplay label={name} copyableText={id} />
  }

  return <CopyableCodeDisplay label={id} />
}
