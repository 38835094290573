import {
  CredentialFieldConstraint,
  CredentialFieldValidValues,
} from '@swiftctrl/api-client'
import { AddConstraintButton } from '../AddConstraintButton'
import {
  BitfieldValue,
  Constraint,
  CredentialTemplateFieldConstraintViewProps,
} from '../utils'
import { PresetValidValuesView } from '../valid-values/PresetValidValuesView'
import { CustomValidValuesView } from './CustomValidValuesView'
import { FormatBitfieldValueWithTemplateIntegerRangeView } from './FormatBitfieldValueWithTemplateIntegerRangeView'
import { FormatBitfieldValueWithTemplateValidValues } from './FormatBitfieldValueWithTemplateValidValues'
import { FormatIntegerRangeView } from './FormatIntegerRangeView'
import { FormatIntegerRangeWithTemplateBitfieldValueAndValidValuesView } from './FormatIntegerRangeWithTemplateBitfieldValueAndValidValuesView'
import { FormatIntegerRangeWithTemplateBitfieldValueView } from './FormatIntegerRangeWithTemplateBitfieldValueView'
import { FormatIntegerRangeWithTemplateValidValues } from './FormatIntegerRangeWithTemplateValidValues'
import { TemplateBitfieldValueAndValidValues } from './TemplateBitfieldValueAndValidValues'
import { TemplateBitfieldValueWithTemplateIntegerRangeView } from './TemplateBitfieldValueWithTemplateIntegerRangeView'
import { TemplateIntegerRangeView } from './TemplateIntegerRangeView'
import { buildInitialIntegerRange } from './utils'

enum View {
  FormatIntegerRange,
  FormatBitfieldValue,
  FormatBitfieldValueWithTemplateIntegerRange,
  FormatBitfieldValueWithTemplateValidValues,
  FormatIntegerRangeWithTemplateBitfieldValue,
  FormatIntegerRangeWithTemplateValidValues,
  FormatIntegerRangeWithTemplateBitfieldValueAndValidValues,
  FormatValidValues,
  TemplateIntegerRange,
  TemplateBitfieldValue,
  TemplateBitfieldValueAndIntegerRange,
  TemplateBitfieldValueAndValidValues,
  TemplateValidValues,
  None,
}

export const CredentialTemplateFieldIntegerConstraintView = ({
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: CredentialTemplateFieldConstraintViewProps) => {
  const view = getView(
    templateConstraint,
    templateBitfieldValue,
    formatConstraint,
  )

  switch (view) {
    case View.FormatIntegerRange:
      return (
        <FormatIntegerRangeView
          templateConstraint={templateConstraint!}
          templateBitfieldValue={templateBitfieldValue}
          formatConstraint={formatConstraint!}
          onChange={onChange}
        />
      )

    case View.FormatBitfieldValueWithTemplateIntegerRange:
      return (
        <FormatBitfieldValueWithTemplateIntegerRangeView
          templateConstraint={templateConstraint!}
          templateBitfieldValue={templateBitfieldValue!}
          formatConstraint={formatConstraint!}
          onChange={onChange}
        />
      )

    case View.FormatBitfieldValueWithTemplateValidValues:
      return (
        <FormatBitfieldValueWithTemplateValidValues
          templateConstraint={templateConstraint!}
          templateBitfieldValue={templateBitfieldValue}
          formatConstraint={formatConstraint!}
          onChange={onChange}
        />
      )

    case View.FormatIntegerRangeWithTemplateBitfieldValue:
      return (
        <FormatIntegerRangeWithTemplateBitfieldValueView
          templateConstraint={templateConstraint!}
          templateBitfieldValue={templateBitfieldValue!}
          formatConstraint={formatConstraint!}
          onChange={onChange}
        />
      )

    case View.FormatIntegerRangeWithTemplateValidValues:
      return (
        <FormatIntegerRangeWithTemplateValidValues
          templateConstraint={templateConstraint!}
          templateBitfieldValue={templateBitfieldValue}
          formatConstraint={formatConstraint!}
          onChange={onChange}
        />
      )

    case View.FormatIntegerRangeWithTemplateBitfieldValueAndValidValues:
      return (
        <FormatIntegerRangeWithTemplateBitfieldValueAndValidValuesView
          templateConstraint={templateConstraint!}
          templateBitfieldValue={templateBitfieldValue!}
          formatConstraint={formatConstraint!}
          onChange={onChange}
        />
      )

    case View.FormatValidValues:
      return (
        <PresetValidValuesView
          formatValidValues={
            formatConstraint!.value as CredentialFieldValidValues
          }
          templateValidValues={templateConstraint!.valid_values!}
          onChange={onChange}
        />
      )

    case View.TemplateIntegerRange:
      return (
        <TemplateIntegerRangeView
          templateConstraint={templateConstraint!}
          templateBitfieldValue={templateBitfieldValue}
          formatConstraint={formatConstraint}
          onChange={onChange}
        />
      )

    case View.TemplateBitfieldValueAndIntegerRange:
      return (
        <TemplateBitfieldValueWithTemplateIntegerRangeView
          templateConstraint={templateConstraint!}
          templateBitfieldValue={templateBitfieldValue!}
          formatConstraint={formatConstraint}
          onChange={onChange}
        />
      )

    case View.TemplateBitfieldValueAndValidValues:
      return (
        <TemplateBitfieldValueAndValidValues
          templateConstraint={templateConstraint!}
          templateBitfieldValue={templateBitfieldValue!}
          formatConstraint={formatConstraint}
          onChange={onChange}
        />
      )

    case View.TemplateValidValues:
      return (
        <CustomValidValuesView
          templateConstraint={templateConstraint!}
          templateBitfieldValue={templateBitfieldValue}
          formatConstraint={formatConstraint}
          onChange={onChange}
        />
      )

    case View.FormatBitfieldValue:
    case View.TemplateBitfieldValue:
    case View.None:
      return (
        <AddConstraintButton
          onClick={() =>
            onChange({
              integer_range: buildInitialIntegerRange(
                templateConstraint,
                templateBitfieldValue,
                formatConstraint,
              ),
              date_range: null,
              valid_values: null,
            })
          }
        />
      )
  }
}

const getView = (
  templateConstraint: Constraint | null,
  templateBitfieldValue: BitfieldValue | null | undefined,
  formatConstraint: CredentialFieldConstraint | undefined,
): View => {
  if (formatConstraint) {
    switch (formatConstraint.type) {
      case 'integer_range':
        if (templateBitfieldValue) {
          if (templateConstraint?.valid_values) {
            return View.FormatIntegerRangeWithTemplateBitfieldValueAndValidValues
          }

          return View.FormatIntegerRangeWithTemplateBitfieldValue
        }

        if (templateConstraint?.valid_values) {
          return View.FormatIntegerRangeWithTemplateValidValues
        }

        return View.FormatIntegerRange

      case 'bitfield_value':
        if (templateConstraint?.integer_range) {
          return View.FormatBitfieldValueWithTemplateIntegerRange
        }

        if (templateConstraint?.valid_values) {
          return View.FormatBitfieldValueWithTemplateValidValues
        }

        return View.FormatBitfieldValue

      case 'valid_values':
        return View.FormatValidValues
    }
  }

  if (templateConstraint?.integer_range) {
    if (templateBitfieldValue) {
      return View.TemplateBitfieldValueAndIntegerRange
    }

    return View.TemplateIntegerRange
  }

  if (templateConstraint?.valid_values) {
    if (templateBitfieldValue) {
      return View.TemplateBitfieldValueAndValidValues
    }

    return View.TemplateValidValues
  }

  return View.None
}
