import { useState } from 'react'
import { RoleProfile } from './buildRoleProfile'
import { isProfileInArray } from './isProfileInArray'

export const useManageProfilesAtRole = (
  processedAssignedProfiles: RoleProfile[],
  processedCandidateProfiles: RoleProfile[],
) => {
  const [addedProfiles, setAddedProfiles] = useState<RoleProfile[]>([])

  const [removedProfiles, setRemovedProfiles] = useState<RoleProfile[]>([])

  const addProfile = (profile: RoleProfile) => {
    setRemovedProfiles((prev: RoleProfile[]) => {
      const update = prev.filter(
        (item: RoleProfile) =>
          item.profile.profile_id !== profile.profile.profile_id,
      )

      if (update.length === prev.length) {
        setAddedProfiles((prev: RoleProfile[]) => [profile, ...prev])

        return prev
      }

      return update
    })
  }

  const removeProfile = (profile: RoleProfile) => {
    const addedProfileIndex = addedProfiles.findIndex(
      (item: RoleProfile) =>
        item.profile.profile_id === profile.profile.profile_id,
    )

    setAddedProfiles((prev: RoleProfile[]) =>
      prev.filter(
        (item: RoleProfile) =>
          item.profile.profile_id !== profile.profile.profile_id,
      ),
    )

    const removedProfileIndex = removedProfiles.findIndex(
      (item: RoleProfile) =>
        item.profile.profile_id === profile.profile.profile_id,
    )

    if (removedProfileIndex === -1 && addedProfileIndex === -1) {
      setRemovedProfiles((prev: RoleProfile[]) => [profile, ...prev])
    }
  }

  const removeAllProfiles = () => {
    setAddedProfiles([])

    setRemovedProfiles(processedAssignedProfiles)
  }

  const reset = () => {
    setAddedProfiles([])

    setRemovedProfiles([])
  }

  const selectedProfiles = buildSelectedProfiles(
    processedAssignedProfiles,
    addedProfiles,
    removedProfiles,
  )

  const candidateProfiles = buildCandidateProfiles(
    processedCandidateProfiles,
    processedAssignedProfiles,
    addedProfiles,
    removedProfiles,
  )

  return {
    selectedProfiles,
    candidateProfiles,
    addedProfiles,
    removedProfiles,
    addProfile,
    removeProfile,
    removeAllProfiles,
    reset,
  }
}

const buildSelectedProfiles = (
  processedAssignedProfiles: RoleProfile[],
  addedProfiles: RoleProfile[],
  removedProfiles: RoleProfile[],
) => {
  const selectedProfiles = [...addedProfiles]

  processedAssignedProfiles.forEach((profile) => {
    if (!isProfileInArray(profile, removedProfiles)) {
      selectedProfiles.push(profile)
    }
  })

  return selectedProfiles
}

const buildCandidateProfiles = (
  processedCandidateProfiles: RoleProfile[],
  processedAssignedProfiles: RoleProfile[],
  addedProfiles: RoleProfile[],
  removedProfiles: RoleProfile[],
) => {
  const candidateProfiles = processedCandidateProfiles.map((profile) => {
    profile.selected = getProfileSelectedState(
      profile,
      addedProfiles,
      removedProfiles,
      processedAssignedProfiles,
    )

    return profile
  })

  return candidateProfiles
}

const getProfileSelectedState = (
  profile: RoleProfile,
  addedProfiles: RoleProfile[],
  removedProfiles: RoleProfile[],
  processedAssignedProfiles: RoleProfile[],
) => {
  if (isProfileInArray(profile, addedProfiles)) {
    return true
  }

  if (isProfileInArray(profile, removedProfiles)) {
    return false
  }

  const assigned = isProfileInArray(profile, processedAssignedProfiles)

  return assigned
}
