import { AxiosResponse } from '@swiftctrl/api-client'

export const buildAxiosResponse = <T>(data: T) => {
  const response: AxiosResponse<T, any> = {
    data,
    config: {},
    status: 200,
    statusText: 'OK',
    headers: {},
  }

  return response
}
