import { ProviderTypes } from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { swiftClient } from '../../data/swiftClient'

type Params = {
  providerId: string
  data: Partial<ProviderTypes>
}

export const useEditProvider = () =>
  useMutation({
    mutationFn: ({ providerId, data }: Params) =>
      swiftClient.provider.at(providerId).edit(data),
  })
