import { EntityTypes } from '@swiftctrl/api-client'
import { EntityTypeTag } from '../components'
import { EntityType } from '../data/models'

export const getEntityDisplayName = ({ entity_name, entity_id }: EntityTypes) =>
  entity_name || entity_id

export const buildEntityTypeTagsList = (entityTypes: EntityType[]) => {
  if (entityTypes.length === 0) {
    return '-'
  }

  return entityTypes.map((entityType) => (
    <EntityTypeTag key={entityType} entityType={entityType} />
  ))
}

export const alphabetizeEntities = (
  a: Partial<EntityTypes>,
  b: Partial<EntityTypes>,
) => {
  const aName = a.entity_name || ''

  const bName = b.entity_name || ''

  return aName.localeCompare(bName)
}
