import {
  CredentialTemplatesBrowser,
  HTMLTitle,
  StyledEntitiesBrowserScreen,
} from '../components'

export const CredentialTemplatesScreen = () => (
  <StyledEntitiesBrowserScreen>
    <HTMLTitle value="Credential Templates" />
    <CredentialTemplatesBrowser />
  </StyledEntitiesBrowserScreen>
)
