import { Form, Input } from '@swiftctrl/swift-component-library'
import {
  INVALID_EMAIL_FORMAT_MESSAGE,
  VALID_EMAIL_FORMAT,
} from '../../../../data/models'
import { buildCommonFormItemProps } from '../../../../utils-hooks'
import { validateEmailOrPhone } from './utils'

type FormItemEmailInputProps = {
  required: boolean
  requireEmailOrPhone: boolean
  phone: string | undefined
}

export const AddProfileFormItemEmailInput = ({
  required,
  requireEmailOrPhone,
  phone,
}: FormItemEmailInputProps) => {
  return (
    <Form.Item
      {...buildCommonFormItemProps({
        name: 'email',
        label: 'Email',
        required,
        rules: [
          {
            validator: (_, value) =>
              validateEmailOrPhone(requireEmailOrPhone, value, phone),
          },
          {
            pattern: VALID_EMAIL_FORMAT,
            message: INVALID_EMAIL_FORMAT_MESSAGE,
          },
        ],
      })}
    >
      <Input />
    </Form.Item>
  )
}
