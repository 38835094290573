import {
  Button,
  CloseOutlined,
  Drawer,
  Modal,
} from '@swiftctrl/swift-component-library'
import { getEntityLabel } from '../../config/labels'
import { EntityType } from '../../data/models'
import {
  ConfiguredEntityTypes,
  isEntityTypeConfiguredForAdding,
  showSuccessNotification,
  useBoolean,
} from '../../utils-hooks'
import { AddEntityForm } from './AddEntityForm'

type AddEntityViewProps = {
  open: boolean
  entityType: EntityType
  /**
   * If defined, sets the initial value(s) for the add data
   */
  addDataTemplate?: object
  /**
   * If omitted, a searchable select will be added that the user can use
   * to find and select the overseer.
   */
  overseerId?: string
  onEntityAdded: () => void
  onClose: () => void
}

export const AddEntityView = ({
  open,
  entityType,
  addDataTemplate,
  onEntityAdded,
  onClose,
  overseerId,
}: AddEntityViewProps) => {
  const entityTypeIsConfigured = isEntityTypeConfiguredForAdding(entityType)

  const entityLabel = getEntityLabel({ entityType, capitalize: false })

  const title = entityTypeIsConfigured
    ? `Add ${entityLabel}`
    : `${entityLabel} not configured`

  const [dirty, setDirtyTrue, setDirtyFalse] = useBoolean()

  const [addingEntity, setAddingEntityTrue, setAddingEntityFalse] = useBoolean()

  const checkBeforeClose = () => {
    if (addingEntity) {
      return
    }

    if (!dirty) {
      resetAndClose()

      return
    }

    Modal.confirm({
      title: 'Are you sure you want to discard your changes?',
      okText: 'Discard',
      onOk: resetAndClose,
      centered: true,
    })
  }

  const resetAndClose = () => {
    setDirtyFalse()

    setAddingEntityFalse()

    onClose()
  }

  const handleEntityAdded = () => {
    setDirtyFalse()

    showSuccessNotification(`${getEntityLabel({ entityType })} added`)

    onEntityAdded()
  }

  return (
    <Drawer
      title={title}
      onClose={checkBeforeClose}
      open={open}
      destroyOnClose
      closable={false}
      extra={
        <Button
          type="text"
          icon={<CloseOutlined />}
          size="small"
          onClick={checkBeforeClose}
          disabled={addingEntity}
        />
      }
    >
      {entityTypeIsConfigured ? (
        <AddEntityForm
          entityType={entityType as ConfiguredEntityTypes}
          addDataTemplate={addDataTemplate}
          overseerId={overseerId}
          onDirty={setDirtyTrue}
          onAddingEntityStarted={setAddingEntityTrue}
          onAddingEntityStopped={setAddingEntityFalse}
          onEntityAdded={handleEntityAdded}
          onCancel={checkBeforeClose}
        />
      ) : (
        <>
          <p>{`${entityLabel} hasn't been configured for addition!`}</p>
          <p>Ask your friendly ConfigCloud team to configure it 🧑‍💻</p>
        </>
      )}
    </Drawer>
  )
}
