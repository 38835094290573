import { BuildingAddTypes } from '@swiftctrl/api-client'
import { countries, ICountry } from 'countries-list'
import timezones from 'timezones-list'
import { swiftClient } from '../../data/swiftClient'
import { AddEntityConfig } from '../../utils-hooks'

type FormData = {
  overseer_id: string
  entity_name: string
  entity_description: string
  'address.country': string
  'address.address_line_1': string
  'address.address_line_2': string
  'address.city': string
  'address.state': string
  'address.zip': string
  'address.timezone': string
}

const timeZoneOptions = timezones.map((timezone) => {
  const { tzCode, label } = timezone

  const [name, offset] = label.split(' ')

  return {
    key: tzCode,
    label: `${offset} ${name}`,
  }
})

const alphabetizeCountries = (a: ICountry, b: ICountry) =>
  a.name.localeCompare(b.name)

const countryOptions = Object.values(countries)
  .sort(alphabetizeCountries)
  .map((country) => {
    const { name } = country

    return {
      key: name,
      label: name,
    }
  })

export const building: AddEntityConfig<FormData, BuildingAddTypes> = {
  fieldGroups: [
    {
      condition: (_, staticOverseerId) => !staticOverseerId,
      fields: [
        {
          key: 'overseer_id',
          label: 'Organization',
          inputType: 'organizationSelect',
          entitySelectType: 'organization',
        },
      ],
    },
    {
      fields: [
        {
          key: 'entity_name',
          label: 'Name',
        },
        {
          key: 'entity_description',
          label: 'Description',
          optional: true,
        },
      ],
    },
    {
      fields: [
        {
          title: 'Address',
          key: 'address.country',
          label: 'Country',
          optional: true,
          inputType: 'select',
          selectOptions: countryOptions,
        },
        {
          key: 'address.address_line_1',
          label: 'Address line',
          optional: true,
        },
        {
          key: 'address.address_line_2',
          label: 'Address line 2',
          optional: true,
        },
        {
          key: 'address.city',
          label: 'City',
          optional: true,
        },
        {
          key: 'address.state',
          label: 'State',
          optional: true,
        },
        {
          key: 'address.zip',
          label: 'Zip',
          optional: true,
        },
        {
          key: 'address.timezone',
          label: 'Time Zone',
          optional: true,
          inputType: 'select',
          selectOptions: timeZoneOptions,
        },
      ],
    },
  ],
  buildData: (values) => {
    const { overseer_id, entity_name, entity_description } = values

    const addressLine1 = values['address.address_line_1']

    const addressLine2 = values['address.address_line_2']

    const city = values['address.city']

    const country = values['address.country']

    const state = values['address.state']

    const timezone = values['address.timezone']

    const zip = values['address.zip']

    const data: BuildingAddTypes = {
      overseer_id,
      entity_name,
      entity_description,
    }

    if (
      addressLine1 ||
      addressLine2 ||
      city ||
      country ||
      state ||
      timezone ||
      zip
    ) {
      /**
       * The SDK is not yet ready to update, due to some BE issues.
       *
       * Once the BE is fixed and the OpenAPI is updated, the SDK should be updated.
       *
       * This @ts-ignore can then be removed
       */
      // @ts-ignore
      data.address = {
        address_line_1: addressLine1 || undefined,
        address_line_2: addressLine2 || undefined,
        city: city || undefined,
        country: country || undefined,
        state: state || undefined,
        timezone: timezone || undefined,
        zip: zip || undefined,
      }
    }

    return data
  },
  addRequest: (data) => swiftClient.building.add(data),
}
