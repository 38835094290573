import { Typography } from '@swiftctrl/swift-component-library'
import { LabelDisplay } from './LabelDisplay'

interface EmailDisplayProps {
  email: string | undefined
}

export const EmailDisplay = ({ email }: EmailDisplayProps) => (
  <div>
    <LabelDisplay label="Email" />
    {email ? (
      <Typography.Text copyable>{email}</Typography.Text>
    ) : (
      <Typography.Text type="secondary">N/A</Typography.Text>
    )}
  </div>
)
