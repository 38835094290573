import { getStatusCode } from '../../../../data/swiftClient'
import { getErrorMessage } from '../../../../utils-hooks'
import { ActionError } from './models'

export const buildErrorMessage = (actionError: ActionError) => {
  const { action, error } = actionError

  const message = getErrorMessage(error)

  const statusCode = getStatusCode(error)

  if (!statusCode) {
    return message
  }

  const errorMessage = `Status code ${statusCode} while adding ${action}: ${message}`

  return errorMessage
}
