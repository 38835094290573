import { EntityTypes, EntityTypesIds, RolesTypes } from '@swiftctrl/api-client'
import { Modal } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import {
  showSuccessNotification,
  targetPluralLabelConfig,
  useBoolean,
} from '../../../../utils-hooks'
import {
  CurrentSubDataInfoBar,
  FiltersBar,
  RoleSubDataManagerModal,
} from '../../manage-subdata'
import { AllTargetsTable } from './AllTargetsTable'
import { useBatchAddDeleteTargets, useManageTargets } from './hooks'
import { SelectedTargetsTable } from './SelectedTargetsTable'
import { TargetEntityTypeFilter } from './TargetEntityTypeFilter'
import { checkSelectedTargetsDirtiness, getChangesToSave } from './utils'

type ManageTargetsModalProps = {
  role: RolesTypes
  targets: Partial<EntityTypes>[]
  open: boolean
  onClose: () => void
}

export const ManageTargetsModal = ({
  role,
  targets: assignedTargets,
  open,
  onClose,
}: ManageTargetsModalProps) => {
  const { role_id } = role

  const { selectedTargets, addTarget, removeTarget, removeAllTargets, reset } =
    useManageTargets(assignedTargets)

  const [search, setSearch] = useState('')

  const [entityTypeFilter, setEntityTypeFilter] =
    useState<EntityTypesIds | undefined>()

  const [savingChanges, startSavingChanges, stopSavingChanges] = useBoolean()

  const { batchAddDeleteTargets } = useBatchAddDeleteTargets({
    roleId: role_id,
    onSuccess: () => {
      showSuccessNotification('Targets saved')

      onClose()
    },
    onSettled: stopSavingChanges,
  })

  const save = async () => {
    const { targetsToAdd, targetsToRemove } = getChangesToSave(
      assignedTargets,
      selectedTargets,
    )

    startSavingChanges()

    batchAddDeleteTargets(targetsToAdd, targetsToRemove)
  }

  const isDirty = checkSelectedTargetsDirtiness(
    assignedTargets,
    selectedTargets,
  )

  const checkBeforeClose = () => {
    if (!isDirty) {
      onClose()

      return
    }

    Modal.confirm({
      title: 'Are you sure you want to discard your changes?',
      okText: 'Discard',
      onOk: resetAndClose,
      okButtonProps: {
        danger: true,
      },
      centered: true,
    })
  }

  const resetAndClose = () => {
    reset()

    onClose()
  }

  return (
    <RoleSubDataManagerModal
      open={open}
      onClose={checkBeforeClose}
      title="Manage Targets"
      description="Select all Targets you want to associate to this role"
      filtersBar={
        <FiltersBar
          onSearch={setSearch}
          extraFilter={
            <TargetEntityTypeFilter onChange={setEntityTypeFilter} />
          }
        />
      }
      candidatesSubDataTable={
        <AllTargetsTable
          search={search}
          entityTypeFilter={entityTypeFilter}
          selectedTargets={selectedTargets}
          addTarget={addTarget}
        />
      }
      infoBar={
        <CurrentSubDataInfoBar
          labels={targetPluralLabelConfig}
          count={selectedTargets.length}
          onRemoveAll={removeAllTargets}
        />
      }
      selectedSubDataTable={
        <SelectedTargetsTable
          selectedTargets={selectedTargets}
          removeTarget={removeTarget}
        />
      }
      saveButtonState={{
        disabled: !isDirty,
        loading: savingChanges,
      }}
      onSave={save}
    />
  )
}
