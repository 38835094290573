import { Button, CheckOutlined } from '@swiftctrl/swift-component-library'
import React from 'react'
import { styled } from 'styled-components'

interface AddTableActionProps {
  showAddButton: boolean
  onAdd: () => void
}

const WIDTH = '45px'

export const AddTableAction = ({
  showAddButton,
  onAdd,
}: AddTableActionProps) => {
  return showAddButton ? (
    <StyledButton
      size="small"
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation()

        onAdd()
      }}
    >
      Add
    </StyledButton>
  ) : (
    <StyledDiv>
      <StyledCheckOutlined />
    </StyledDiv>
  )
}

const StyledCheckOutlined = styled(CheckOutlined)`
  text-align: center;
  color: #389e0d;
`

const StyledDiv = styled.div`
  text-align: center;
  height: 24px;
  width: ${WIDTH};
`

const StyledButton = styled(Button)`
  border-color: #531dab;
  color: #531dab;
  width: ${WIDTH};
`
