import { EntityType } from '../data/models'

const mapEntityTypeToIdKey: Record<EntityType, string> = {
  access_level: 'access_level_id',
  access_level_credential: 'access_level_credential_id',
  access_level_reader_schedule: 'access_level_reader_schedule_id',
  acs_hardware: 'acs_hardware_id',
  acs_transaction: 'acs_transaction_id',
  address: 'address_id',
  archetype: 'role_archetype_id',
  association: 'association_id',
  booking: 'booking_id',
  building: 'building_id',
  campus: 'campus_id',
  controller: 'controller_id',
  credential: 'credential_id',
  credential_format: 'credential_format_id',
  credential_template: 'credential_format_config_id',
  department: 'department_id',
  device: 'device_id',
  division: 'division_id',
  email: 'email_id',
  entity: 'entity_id',
  entity_counter: 'entity_counter_id',
  entity_mirror: 'entity_mirror_id',
  epi: 'epi_id',
  floor: 'floor_id',
  image: 'image_id',
  meeting: 'meeting_id',
  meeting_invitation: 'meeting_invitation_id',
  opening_hours: 'opening_hours_id',
  organization: 'organization_id',
  pass: 'pass_id',
  payment: 'payment_id',
  permission: 'permission_id',
  person: 'person_id',
  phone: 'phone_id',
  portfolio: 'portfolio_id',
  profile: 'profile_id',
  profile_role: 'profile_role_id',
  provider: 'provider_id',
  provider_profile: 'provider_profile_id',
  reader: 'reader_id',
  reference: 'reference_id',
  report: 'report_id',
  role: 'role_id',
  room: 'room_id',
  room_price: 'room_price_id',
  schedule: 'schedule_id',
  session: 'session_id',
  sink: 'sink_id',
  source: 'source_id',
  system: 'system_id',
  zone: 'zone_id',
}

export const getEntityId = <T>(entity: T, entityType: EntityType) => {
  const idKey = mapEntityTypeToIdKey[entityType] as keyof T

  const id = entity[idKey]

  return String(id)
}
