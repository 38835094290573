import { SinkRule } from '@swiftctrl/api-client'

export const getInitialStreamId = (
  streamIds: string[],
  rules: SinkRule[],
  ruleId: string | undefined,
) => {
  if (streamIds.length === 0) {
    return ''
  }

  if (!ruleId) {
    return streamIds[0]
  }

  for (let i = 0; i < rules.length; i++) {
    const { rule_id, stream_id } = rules[i]

    if (rule_id === ruleId) {
      return stream_id
    }
  }

  return streamIds[0]
}
