import { isValidUuid } from '../../../utils-hooks'
import { CredentialTemplateFieldConstraintViewProps } from '../utils'
import { ValidValuesView } from './ValidValuesView'

export const CredentialTemplateFieldUuidConstraintView = ({
  templateConstraint,
  formatConstraint,
  onChange,
}: CredentialTemplateFieldConstraintViewProps) => {
  const validator = (value: string | number) => {
    if (typeof value === 'number' || !isValidUuid(value)) {
      return 'Value is not a UUID'
    }
  }

  return (
    <ValidValuesView
      templateConstraint={templateConstraint}
      formatConstraint={formatConstraint}
      customValidValuesValidator={validator}
      customValidValuesEntryType="uuid"
      onChange={onChange}
    />
  )
}
