import { parseISO } from 'date-fns'

export const buildFakeLocalDateFromISODate = (value: string | undefined) => {
  if (!value) {
    return undefined
  }

  const utcDate = parseISO(value)

  const localDate = new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
    utcDate.getUTCHours(),
    utcDate.getUTCMinutes(),
    utcDate.getUTCSeconds(),
    0,
  )

  return localDate
}
