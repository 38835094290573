import { AddEntityMirrorFormData } from './models'

export const validateAddEntityMirrorFormData = (
  values: AddEntityMirrorFormData,
) => {
  const {
    sink,
    setEntityAs,
    entity_id,
    entity_overseer_id,
    mirror_id,
    mirror_overseer_id,
  } = values

  if (!sink || !setEntityAs) {
    return false
  }

  if (setEntityAs === 'entity' && (!mirror_id || !mirror_overseer_id)) {
    return false
  }

  if (setEntityAs === 'mirror' && (!entity_id || !entity_overseer_id)) {
    return false
  }

  return true
}
