import { CredentialFieldType } from '@swiftctrl/api-client'
import { BitfieldValue, Constraint } from '../utils'
import { DefaultBoolSelect } from './DefaultBoolSelect'
import { DefaultIntegerInput } from './DefaultIntegerInput'
import { DefaultStringInput } from './DefaultStringInput'
import { DefaultTimePicker } from './DefaultTimePicker'
import { DefaultUuidInput } from './DefaultUuidInput'

type CredentialTemplateFieldDefaultValueInputProps = {
  fieldType: CredentialFieldType | undefined
  defaultValue: string | null
  constraint: Constraint | null
  bitfieldValue: BitfieldValue | null
  onChange: (value: string) => void
}

export const CredentialTemplateFieldDefaultValueInput = ({
  fieldType,
  defaultValue,
  constraint,
  bitfieldValue,
  onChange,
}: CredentialTemplateFieldDefaultValueInputProps) => {
  switch (fieldType) {
    case 'INTEGER':
      return (
        <DefaultIntegerInput
          defaultValue={defaultValue}
          constraint={constraint}
          bitfieldValue={bitfieldValue}
          onChange={onChange}
        />
      )

    case 'STRING':
      return (
        <DefaultStringInput
          defaultValue={defaultValue}
          constraint={constraint}
          onChange={onChange}
        />
      )

    case 'BOOL':
      return (
        <DefaultBoolSelect defaultValue={defaultValue} onChange={onChange} />
      )

    case 'TIME':
      return (
        <DefaultTimePicker
          defaultValue={defaultValue}
          constraint={constraint}
          onChange={onChange}
        />
      )

    case 'UUID':
      return (
        <DefaultUuidInput
          defaultValue={defaultValue}
          constraint={constraint}
          onChange={onChange}
        />
      )

    default:
      return null
  }
}
