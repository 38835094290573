import { Layout, PreLoader } from '@swiftctrl/swift-component-library'
import { List } from 'antd'
import { ReactNode } from 'react'
import { InfiniteScrollTrigger } from '../InfiniteScrollTrigger'
import { ManageAssignedDataHeader } from './ManageAssignedDataHeader'
import { ManageAssignedDataListContainer, ScrollContainer } from './utils'

export type ManageAssignedDataInfiniteListProps<T> = {
  header: ReactNode
  data: T[]
  isFetching: boolean
  isFetchingFirstTime: boolean
  fetchNextPage: () => void
  renderItem: (item: T) => ReactNode
}

export const ManageAssignedDataInfiniteList = <T,>({
  header,
  data,
  isFetching,
  isFetchingFirstTime,
  fetchNextPage,
  renderItem,
}: ManageAssignedDataInfiniteListProps<T>) => {
  return (
    <ManageAssignedDataListContainer>
      <ManageAssignedDataHeader>{header}</ManageAssignedDataHeader>
      <ScrollContainer>
        {!isFetchingFirstTime && (
          <List dataSource={data} renderItem={renderItem} />
        )}
        {isFetching && (
          <Layout paddingTop="medium" alignItems="center">
            <PreLoader size="small" />
          </Layout>
        )}
        <InfiniteScrollTrigger callback={fetchNextPage} />
      </ScrollContainer>
    </ManageAssignedDataListContainer>
  )
}
