import { CredentialFieldConstraint } from '@swiftctrl/api-client'

export const hasValidValues = (
  formatConstraint: CredentialFieldConstraint | undefined,
) => {
  if (!formatConstraint) {
    return false
  }

  return formatConstraint.type === 'valid_values'
}
