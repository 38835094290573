import {
  AssignedSpacesBrowseTypes,
  AssignedSpacesTypes,
  OrganizationTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import {
  ColumnsType,
  Layout,
  PreLoader,
  Typography,
} from '@swiftctrl/swift-component-library'
import {
  CopyableDisplay,
  EntitiesTableBrowser,
  TABLE_DATA_PER_FETCH,
} from '../../../components'
import { swiftClient } from '../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
} from '../../../states'
import {
  adaptSDKBrowseRequest,
  cacheKeys,
  isValidUuid,
  useGetOverseerOrganization,
} from '../../../utils-hooks'
import { AssignedAccessLevelsButton } from './AssignedAccessLevelsButton'

type AssignedBuildingsBrowserProps = {
  tenant: OrganizationTypes
}

export const AssignedBuildingsBrowser = ({
  tenant,
}: AssignedBuildingsBrowserProps) => {
  const { organization_id: tenantId } = tenant

  const { data: landlord } = useGetOverseerOrganization({ entity: tenant })

  if (!landlord) {
    return <PreLoader centered />
  }

  const browse = buildBrowse(tenantId)

  const columns = buildColumns(landlord.entity_id, tenant)

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={TABLE_DATA_PER_FETCH}
      browse={browse}
      dataKey="space_id"
      entityType="building"
      searchable
    >
      <EntitiesTableBrowser columns={columns} />
    </BrowseEntitiesContextProvider>
  )
}

const buildBrowse = (
  organizationId: string,
): BrowseEntitiesConfig<AssignedSpacesTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClient.organization.at(organizationId).spaces.assigned.browse,
    select: [
      'space_id',
      'name',
      'location',
      'assigned_access_level_count',
      'assigned_default_access_level_count',
    ],
    getFilters: (search) => {
      const q = getQueryHandler<AssignedSpacesBrowseTypes>()

      const filters = [q('WHERE', 'type', 'EQ', 'building')]

      if (!search) {
        return filters
      }

      if (isValidUuid(search)) {
        filters.push(q('AND', 'space_id', 'EQ', search))
      } else {
        filters.push(q('AND', 'name', 'EQ_IC', search))
      }

      return filters
    },
    sort: 'name:asc',
  }),
  cacheKey: [cacheKeys.organization_assigned_buildings, organizationId],
})

const buildColumns = (
  landlordId: string,
  tenant: OrganizationTypes,
): ColumnsType<AssignedSpacesTypes> => [
  {
    title: 'Building',
    render: (_, space) => (
      <Layout>
        <CopyableDisplay label={space.name} copyableText={space.space_id} />
        {space.location && (
          <Typography.Text type="secondary">{space.location}</Typography.Text>
        )}
      </Layout>
    ),
    width: '50%',
  },
  {
    title: 'Allocated Access Levels',
    render: (_, space) => (
      <AssignedAccessLevelsButton
        landlordId={landlordId}
        tenant={tenant}
        space={space}
      />
    ),
    width: '50%',
  },
]
