import {
  ProviderProfileTypes,
  ProviderProfileUpdateTypes,
  ProviderTypes,
} from '@swiftctrl/api-client'
import {
  ProviderProfileFormData,
  provider_profile as ProviderProfileConfig,
} from '../../../config/add-entity-types'
import { useBrowseEntitiesContext } from '../../../states'
import {
  FieldGroupConfig,
  invalidateReadProviderProfileQuery,
  showSuccessNotification,
  useEditProviderProfile,
} from '../../../utils-hooks'
import { useEditEntityCommonLogic } from '../useEditEntityCommonLogic'
import { buildProviderProfileInitialValues } from './buildProviderProfileInitialValues'

type Params = {
  providerProfile: ProviderProfileTypes
  provider: ProviderTypes
  onEdited: () => void
  onClose: () => void
}

export const useEditProviderProfileLogic = ({
  providerProfile,
  provider,
  onEdited,
  onClose,
}: Params) => {
  const { provider_profile_id } = providerProfile

  const { fieldGroups, buildData } = ProviderProfileConfig

  const modifiedFieldGroups = removePasswordField(fieldGroups)

  const { initialValues } = buildProviderProfileInitialValues(
    modifiedFieldGroups,
    providerProfile,
    provider,
  )

  const { mutate, isPending: isSaving } = useEditProviderProfile(() => {
    showSuccessNotification('Provider profile saved')
  })

  const { baseIdIsPreset } = useBrowseEntitiesContext()

  const {
    values,
    setValues,
    fields,
    fieldsAreValid,
    isDirty,
    checkBeforeClose,
    reset,
  } = useEditEntityCommonLogic(
    initialValues,
    modifiedFieldGroups,
    isSaving,
    onClose,
    baseIdIsPreset,
  )

  const save = () => {
    const data = buildData!(values) as ProviderProfileUpdateTypes

    mutate({ id: provider_profile_id, data }, { onSuccess })
  }

  const onSuccess = () => {
    reset()

    invalidateReadProviderProfileQuery(provider_profile_id)

    invalidateReadProviderProfileQuery(provider_profile_id)

    onEdited()
  }

  return {
    values,
    setValues,
    fields,
    fieldsAreValid,
    save,
    isSaving,
    isDirty,
    checkBeforeClose,
  }
}

const removePasswordField = (
  fieldGroups: FieldGroupConfig<ProviderProfileFormData>[],
) => {
  const update = fieldGroups.map((fieldGroup) => {
    const { condition, fields } = fieldGroup

    const update: FieldGroupConfig<ProviderProfileFormData> = {
      condition,
      fields: fields.filter(
        (field) => field.key !== 'login_data.email_password.password',
      ),
    }

    return update
  })

  return update
}
