import {
  CredentialFieldBitfieldParity,
  CredentialFieldBitfieldValue,
  CredentialFieldFormat,
  CredentialFieldIntegerRange,
  CredentialFormatsTypes,
} from '@swiftctrl/api-client'
import {
  BitfieldValue,
  CredentialTemplateField,
  getCredentialFormatField,
} from '../utils'
import { AddBitfieldValueView } from './AddBitfieldValueView'
import { BitfieldParityView } from './BitfieldParityView'
import { FormatBitfieldValueView } from './FormatBitfieldValueView'
import { FormatIntegerRangeTemplateBitfieldValueView } from './FormatIntegerRangeTemplateBitfieldValueView'
import { TemplateBitfieldValueView } from './TemplateBitfieldValueView'

type CredentialTemplateFieldIntegerBitfieldPropertiesViewProps = {
  field: CredentialTemplateField
  credentialFormat: CredentialFormatsTypes
  onChange: (value: BitfieldValue | null) => void
}

enum View {
  FormatBitfieldParity,
  FormatBitfieldValue,
  FormatIntegerRangeWithTemplateBitfieldValue,
  TemplateBitfieldValue,
  None,
}

export const CredentialTemplateFieldIntegerBitfieldPropertiesView = ({
  field,
  credentialFormat,
  onChange,
}: CredentialTemplateFieldIntegerBitfieldPropertiesViewProps) => {
  const credentialFormatField = getCredentialFormatField(
    credentialFormat,
    field,
  )

  const view = getView(field, credentialFormatField)

  switch (view) {
    case View.FormatBitfieldParity:
      return (
        <BitfieldParityView
          bitfieldParity={
            credentialFormatField.constraint!
              .value as CredentialFieldBitfieldParity
          }
        />
      )

    case View.FormatBitfieldValue:
      return (
        <FormatBitfieldValueView
          bitfieldValue={
            credentialFormatField.constraint!
              .value as CredentialFieldBitfieldValue
          }
          onChange={onChange}
        />
      )

    case View.FormatIntegerRangeWithTemplateBitfieldValue:
      return (
        <FormatIntegerRangeTemplateBitfieldValueView
          formatIntegerRange={
            credentialFormatField.constraint!
              .value as CredentialFieldIntegerRange
          }
          templateBitfieldValue={field.bitfield_value!}
          onChange={onChange}
        />
      )

    case View.TemplateBitfieldValue:
      return (
        <TemplateBitfieldValueView
          bitfieldValue={field.bitfield_value!}
          onChange={onChange}
        />
      )

    case View.None:
      return <AddBitfieldValueView onChange={onChange} />
  }
}

const getView = (
  templateField: CredentialTemplateField,
  formatField: CredentialFieldFormat,
): View => {
  if (formatField.constraint) {
    switch (formatField.constraint.type) {
      case 'bitfield_parity':
        return View.FormatBitfieldParity

      case 'bitfield_value':
        return View.FormatBitfieldValue

      case 'integer_range':
        if (templateField.bitfield_value) {
          return View.FormatIntegerRangeWithTemplateBitfieldValue
        }

        return View.None
    }
  }

  if (templateField.bitfield_value) {
    return View.TemplateBitfieldValue
  }

  return View.None
}
