import { Input } from '@swiftctrl/swift-component-library'
import { debounce } from 'lodash'
import { ChangeEvent, useRef } from 'react'
import { styled } from 'styled-components'
import { validateBrowseSearch } from '../../utils-hooks'

type RemoteFilterBarProps = {
  onDebouncedSearch: (value: string) => void
  isSaving: boolean
}

export const RemoteFilterBar = ({
  onDebouncedSearch,
  isSaving,
}: RemoteFilterBarProps) => {
  const debouncedSearch = useRef(debounce(onDebouncedSearch, 500))

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { isValidSearch, validatedSearch } = validateBrowseSearch(
      event.target.value,
    )

    if (!isValidSearch) {
      return
    }

    debouncedSearch.current.cancel()

    debouncedSearch.current(validatedSearch)
  }

  return (
    <StyledSearch
      placeholder="Search"
      onChange={handleChange}
      disabled={isSaving}
      allowClear
    />
  )
}

const StyledSearch = styled(Input.Search)`
  max-width: 320px;
`
