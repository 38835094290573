import { DatePicker } from '@swiftctrl/swift-component-library'
import { buildFakeLocalDateFromISODate } from '../../utils-hooks'
import {
  CredentialTemplateFieldComponentProps,
  CredentialTemplateFieldFormItem,
  getCredentialTemplateFieldDateTimePickerDisabledDate,
} from './utils'

export const CredentialTemplateFieldDateTimePicker = ({
  field,
}: CredentialTemplateFieldComponentProps) => {
  const { constraint, placeholder, default_value, read_only } = field

  const defaultValue = default_value
    ? buildFakeLocalDateFromISODate(default_value)
    : undefined

  const disabledDate =
    getCredentialTemplateFieldDateTimePickerDisabledDate(constraint)

  return (
    <CredentialTemplateFieldFormItem field={field}>
      <DatePicker
        showTime
        placeholder={placeholder || ''}
        disabledDate={disabledDate}
        showNow={false}
        defaultValue={defaultValue}
        disabled={read_only}
      />
    </CredentialTemplateFieldFormItem>
  )
}
