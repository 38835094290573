import { useEffect } from 'react'
import { RestApiParamsKeys } from '../types'
import { showInvalidFilterEntryErrorNotification } from './notifications'
import { useHandleUrlParams } from './useHandleParams'

type Params<T> = {
  paramKey: RestApiParamsKeys
  validator: (param: string) => T
}

/**
 * Ensures that the URL param is valid.
 *
 * If the param isn't valid, it will be removed from the URL.
 *
 * An "Invalid filter" notification will also be displayed.
 */
export const useGetValidParam = <T>({ paramKey, validator }: Params<T>) => {
  const { currentParams, cleanParams } = useHandleUrlParams()

  const param = String(currentParams[paramKey] || '')

  const validatedParam = validator(param)

  useEffect(() => {
    if (!param || validatedParam === param) {
      return
    }

    showInvalidFilterEntryErrorNotification(paramKey)

    cleanParams([paramKey])
  })

  return validatedParam
}
