import { Swift, Typography } from '@swiftctrl/swift-component-library'

type NumericDisplayProps = {
  value: any
}

export const NumericDisplay: Swift.FC<NumericDisplayProps> = ({ value }) => (
  <Typography.Text code>
    {Number(value).toLocaleString('en-US')}
  </Typography.Text>
)
