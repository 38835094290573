import { Card } from 'antd'
import { PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'
import { hasDeleteRequest } from '../../config/deleteEntityConfig'
import { hasEditEntityConfig } from '../../config/editEntityConfig'
import { getEntityLabel } from '../../config/labels'
import { useBrowseEntitiesContext } from '../../states'
import {
  getEntityId,
  getEntityName,
  getField,
  showSuccessNotification,
  useBoolean,
  useDeleteEntityFlow,
} from '../../utils-hooks'
import { EditEntityDelegator } from '../edit-entities'
import { EntityCardInfo } from './EntityCardInfo'

type EntityCardProps<T> = PropsWithChildren<{
  entity: T
  /**
   * If omitted the details button won't show
   */
  detailsNavigatePath?: string
  /**
   * Displayed below the timestamps, if any,
   * and to the right of the details button, if it exists.
   */
  extraButton?: ReactNode
  childrenAboveOverseer?: ReactNode
  onDuplicateClick?: () => void
}>

/**
 * Children of this entity are only displayed when it's in
 * view mode.
 *
 * In edit mode, only the name and optional description are
 * available to see and edit.
 */
export const EntityCard = <T extends object>({
  entity,
  children,
  detailsNavigatePath,
  extraButton,
  childrenAboveOverseer,
  onDuplicateClick,
}: EntityCardProps<T>) => {
  const { entityType, invalidateData } = useBrowseEntitiesContext()

  const [isEditing, startEditing, stopEditing] = useBoolean()

  const entityId = getEntityId(entity, entityType)

  const createdAt = getField(entity, 'created_at')

  const updatedAt = getField(entity, 'updated_at')

  const canEditEntity = hasEditEntityConfig(entityType)

  const { handleDeleteClick } = useDeleteEntityFlow({
    entityType,
    entityName: getEntityName(entity, entityType),
    entityId,
    onSuccess: () => {
      invalidateData()

      showSuccessNotification(`${getEntityLabel({ entityType })} deleted`)
    },
  })

  const onDeleteClick = hasDeleteRequest(entityType)
    ? handleDeleteClick
    : undefined

  const handleEntityEdited = () => {
    stopEditing()

    invalidateData()
  }

  return (
    <StyledCard>
      <EntityCardInfo
        entity={entity}
        entityId={entityId}
        onEditClick={canEditEntity ? startEditing : undefined}
        onDeleteClick={onDeleteClick}
        onDuplicateClick={onDuplicateClick}
        detailsNavigatePath={detailsNavigatePath}
        extraButton={extraButton}
        childrenAboveOverseer={childrenAboveOverseer}
      >
        {children}
      </EntityCardInfo>
      {canEditEntity && (
        <EditEntityDelegator
          open={isEditing}
          entityType={entityType}
          entity={entity}
          key={updatedAt || createdAt}
          onEdited={handleEntityEdited}
          onClose={stopEditing}
        />
      )}
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  width: 100%;

  .ant-card-body {
    width: 100%;
    height: 100%;
  }
`
