import { RoleOperationTypes } from '@swiftctrl/api-client'

export const addRoleOperationKey = (roleOperation: RoleOperationTypes) => {
  const { prefix, action, scope, modifier, grants_on } = roleOperation

  let key = `${prefix}-${action}-${scope}-${modifier}`

  if (grants_on) {
    key += `-${grants_on.entity_id}`
  }

  return {
    ...roleOperation,
    key,
  }
}
