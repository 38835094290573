import { RoleArchetype, RolesTypes } from '@swiftctrl/api-client'
import { Modal } from '@swiftctrl/swift-component-library'
import { isEmpty, isEqual } from 'lodash'
import { useEffect } from 'react'
import { invalidateReadScreenQuery } from '../../../../components'
import {
  archetypePluralLabelConfig,
  cacheKeys,
  showSuccessNotification,
  useBoolean,
  useEditRole,
} from '../../../../utils-hooks'
import { CurrentSubDataInfoBar, FiltersBar } from '../../manage-subdata'
import { RoleSubDataManagerModal } from '../../manage-subdata/RoleSubDataManagerModal'
import { buildEditRolePayload } from './buildEditRolePayload'
import { CandidateArchetypesTable } from './CandidateArchetypesTable'
import { SelectedArchetypesTable } from './SelectedArchetypesTable'
import { useManageArchetypes } from './useManageArchetypes'

interface ManageArchetypesModalProps {
  open: boolean
  onClose: () => void
  role: RolesTypes
  roleArchetypeCandidates: RoleArchetype[]
}

export const ManageArchetypesModal = ({
  open,
  onClose,
  role,
  roleArchetypeCandidates,
}: ManageArchetypesModalProps) => {
  const [dirty, setDirtyTrue, setDirtyFalse] = useBoolean()

  const { mutate: editRole } = useEditRole()

  const existingRoleArchetypes = role.archetypes

  const {
    archetypes,
    selectedArchetypes,
    addArchetype,
    removeArchetype,
    removeAllArchetype,
    handleSearch,
    setSelectedArchetypes,
    resetArchetypes,
  } = useManageArchetypes(roleArchetypeCandidates, existingRoleArchetypes)

  useEffect(() => {
    setSelectedArchetypes(existingRoleArchetypes)
  }, [existingRoleArchetypes, setSelectedArchetypes])

  const resetAndClose = () => {
    setDirtyFalse()

    resetArchetypes()

    if (isEmpty(existingRoleArchetypes)) {
      removeAllArchetype()
    } else {
      setSelectedArchetypes(existingRoleArchetypes)
    }

    onClose()
  }

  const checkBeforeClose = () => {
    if (!dirty) {
      resetAndClose()

      return
    }
    Modal.confirm({
      title: 'Are you sure you want to discard your changes?',
      okText: 'Discard',
      onOk: resetAndClose,
      okButtonProps: {
        danger: true,
      },
      centered: true,
    })
  }

  const [savingChanges, startSavingChanges, stopSavingChanges] = useBoolean()

  const handleSave = () => {
    startSavingChanges()

    editRole(
      {
        id: role.role_id,
        payload: {
          ...buildEditRolePayload(role),
          archetypes: selectedArchetypes,
        },
      },
      {
        onSuccess: () => {
          invalidateReadScreenQuery(cacheKeys.role, role.role_id)

          setDirtyFalse()

          showSuccessNotification('Archetypes saved')

          resetArchetypes()

          onClose()
        },
        onSettled: () => {
          stopSavingChanges()
        },
      },
    )
  }

  useEffect(() => {
    if (!isEqual(existingRoleArchetypes, selectedArchetypes)) {
      setDirtyTrue()
    } else {
      setDirtyFalse()
    }

    return () => {
      setDirtyFalse()

      stopSavingChanges()
    }
  }, [
    setDirtyTrue,
    setDirtyFalse,
    stopSavingChanges,
    existingRoleArchetypes,
    selectedArchetypes,
  ])

  return (
    <RoleSubDataManagerModal
      open={open}
      onClose={checkBeforeClose}
      title="Manage Archetypes"
      description="Select all Archetypes you want to associate to this role"
      filtersBar={<FiltersBar onSearch={handleSearch} isLocalSearch />}
      candidatesSubDataTable={
        <CandidateArchetypesTable
          data={archetypes}
          selectedArchetypes={selectedArchetypes}
          onAddArchetype={addArchetype}
        />
      }
      infoBar={
        <CurrentSubDataInfoBar
          labels={archetypePluralLabelConfig}
          count={selectedArchetypes.length}
          onRemoveAll={removeAllArchetype}
        />
      }
      selectedSubDataTable={
        <SelectedArchetypesTable
          data={selectedArchetypes}
          onRemoveArchetype={removeArchetype}
        />
      }
      saveButtonState={{
        disabled: !dirty,
        loading: savingChanges,
      }}
      onSave={handleSave}
    />
  )
}
