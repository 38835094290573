import {
  Button,
  DeleteOutlined,
  Form,
  Input,
  PlusOutlined,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { buildFormItemProps } from './addSinkUtils'

type ArrayInputProps = {
  name: string
  label: string
}

export const ArrayInput = ({ name, label }: ArrayInputProps) => (
  <>
    <Label>{label}</Label>
    <Form.List
      name={name}
      rules={[
        {
          validator: (_, values) => {
            if (!values || values.length === 0) {
              return Promise.reject(`${name} is required`)
            }

            return Promise.resolve()
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map(({ key, name, ...itemProps }) => (
            <EntryFormItem key={key} {...itemProps}>
              <Form.Item noStyle {...buildFormItemProps(String(key), 'Entry')}>
                <Input />
              </Form.Item>
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                onClick={() => remove(name)}
              />
            </EntryFormItem>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              block
              icon={<PlusOutlined />}
              onClick={() => add()}
            >
              Add an entry
            </Button>
          </Form.Item>
          <Form.ErrorList errors={errors} />
        </>
      )}
    </Form.List>
  </>
)

const Label = styled.p`
  margin-bottom: 8px;
`

const EntryFormItem = styled(Form.Item)`
  margin-bottom: 0.5em;

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
`
