import { CredentialTemplateTypes } from '@swiftctrl/api-client'
import { Typography } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { CREDENTIAL_TEMPLATES_SCREEN_PATH } from '../../../../config/paths'
import { useBoolean, useReadCredentialTemplate } from '../../../../utils-hooks'
import { CopyableDisplay, LabelDisplay } from '../../../data-display'
import { EntityCard } from '../../EntityCard'
import { CredentialTemplatePreviewButton } from './CredentialTemplatePreviewButton'
import { DuplicateCredentialTemplateView } from './DuplicateCredentialTemplateView'
import { useValidateCredentialTemplateBeforeCallback } from './utils'

type CredentialTemplateCardProps = {
  credentialTemplate: CredentialTemplateTypes
}

export const CredentialTemplateCard = ({
  credentialTemplate,
}: CredentialTemplateCardProps) => {
  const {
    credential_format_config_id,
    credential_format_id,
    credential_format_name,
  } = credentialTemplate

  const detailsNavigatePath = `/${CREDENTIAL_TEMPLATES_SCREEN_PATH}/${credential_format_config_id}`

  const [isDuplicateViewOpen, openDuplicateView, closeDuplicateView] =
    useBoolean()

  const { data: fullCredentialTemplate } = useReadCredentialTemplate({
    id: credential_format_config_id,
    enabled: isDuplicateViewOpen,
    showErrorNotification: true,
  })

  const { validateCredentialTemplateBeforeCallback } =
    useValidateCredentialTemplateBeforeCallback(
      credential_format_config_id,
      openDuplicateView,
    )

  return (
    <>
      <EntityCard
        entity={credentialTemplate}
        detailsNavigatePath={detailsNavigatePath}
        extraButton={
          <CredentialTemplatePreviewButton
            credentialTemplateId={credential_format_config_id}
          />
        }
        childrenAboveOverseer={
          <StyledParagraph>
            <LabelDisplay label="Core Credential Format" />
            <CopyableDisplay
              label={credential_format_name}
              copyableText={credential_format_id}
            />
          </StyledParagraph>
        }
        onDuplicateClick={validateCredentialTemplateBeforeCallback}
      />
      {fullCredentialTemplate && (
        <DuplicateCredentialTemplateView
          open={isDuplicateViewOpen}
          credentialTemplate={fullCredentialTemplate}
          onClose={closeDuplicateView}
        />
      )}
    </>
  )
}

const StyledParagraph = styled(Typography.Paragraph)`
  padding-bottom: ${({ theme }) => theme.spacing.xSmall};
`
