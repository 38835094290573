import { Result } from 'antd'
import { RedirectButton, RedirectButtonProps } from '../navigation'

interface ErrorResultProps extends RedirectButtonProps {
  error: string
}

export const ErrorResult = ({ error, ...buttonProps }: ErrorResultProps) => {
  const is404Error = error.includes('404')
  return (
    <Result
      status={is404Error ? '404' : 'error'}
      title={is404Error ? '404 Not Found' : error}
      subTitle={is404Error ? 'No entity found with the corresponding id' : null}
      extra={<RedirectButton {...buttonProps} />}
    />
  )
}
