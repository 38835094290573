import { RolesTypes } from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { swiftClient } from '../../data/swiftClient'

const {
  role: { at },
} = swiftClient

export interface EditRolePayload {
  id: string
  payload: Pick<
    RolesTypes,
    'entity_name' | 'entity_description' | 'granted_on' | 'archetypes'
  >
}

export const useEditRole = () => {
  const editRole = ({ id, payload }: EditRolePayload) => at(id).edit(payload)

  return useMutation({ mutationFn: editRole })
}
