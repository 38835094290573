import { Swift, Typography } from '@swiftctrl/swift-component-library'
import { Popover } from 'antd'
import { validate as isValidUuid } from 'uuid'
import {
  entityDetailsConfig,
  FieldConfig,
  FieldConfigMap,
} from '../../config/entityDetailsConfig'
import { EntityType } from '../../data/models'
import {
  BooleanDisplay,
  JsonDisplay,
  NumericDisplay,
  TextDisplay,
  UuidDisplay,
} from '../data-display'
import { EntityDetailLine } from './EntityDetailLine'

type AdditionalDetailsProps = {
  entityType: EntityType
  readResponseData: any
}

export const AdditionalDetails: Swift.FC<AdditionalDetailsProps> = ({
  entityType,
  readResponseData,
}) => {
  const typeConfig = entityDetailsConfig.types[entityType]

  if (!typeConfig) {
    return (
      <>
        <p>{`Entity type "${entityType}" hasn't been configured for additional details!`}</p>
        <p>Ask your friendly ConfigCloud team to configure it 🧑‍💻</p>
      </>
    )
  }

  const fields = extractFields(readResponseData, [], typeConfig.fields)

  if (fields.length === 0) {
    return <p>(No additional details)</p>
  }

  fields.sort(([aKey, aData], [bKey, bData]) => {
    const aLabel = (aData.fieldConfig && getLabel(aData.fieldConfig)) || aKey

    const bLabel = (bData.fieldConfig && getLabel(bData.fieldConfig)) || bKey

    return aLabel.localeCompare(bLabel)
  })

  return <>{fields.map(fieldDisplayFactory)}</>
}

const extractFields = (
  data: any,
  fields: Array<[string, { value: any; fieldConfig?: FieldConfig }]>,
  fieldsConfig: FieldConfigMap,
  keyPrefix?: string,
) => {
  for (const [key, value] of Object.entries(data)) {
    const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key

    let fieldConfig: FieldConfig | undefined = undefined

    if (fullKey in fieldsConfig) {
      fieldConfig = fieldsConfig[fullKey]
    } else if (fullKey in entityDetailsConfig.common) {
      fieldConfig = entityDetailsConfig.common[fullKey]
    }

    if (typeof fieldConfig === 'object' && fieldConfig.display === 'hide') {
      continue
    }

    if (value === null) {
      continue
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      extractFields(value, fields, fieldsConfig, fullKey)
    } else {
      fields.push([fullKey, { value, fieldConfig }])
    }
  }

  return fields
}

const fieldDisplayFactory = ([key, data]: [
  string,
  { value: any; fieldConfig?: FieldConfig },
]) => {
  const { value, fieldConfig } = data

  return (
    <EntityDetailLine key={key}>
      {renderLabel(key, fieldConfig)}
      {': '}
      {Array.isArray(value)
        ? value.map((arrayValue) => renderValue(arrayValue, fieldConfig, key))
        : renderValue(value, fieldConfig)}
    </EntityDetailLine>
  )
}

const renderLabel = (key: string, fieldConfig?: FieldConfig) => {
  const keyElement = <Typography.Text code>{key}</Typography.Text>

  if (!fieldConfig) {
    return keyElement
  }

  const label = getLabel(fieldConfig)

  if (!label) {
    return keyElement
  }

  return <Popover content={keyElement}>{label}</Popover>
}

const renderValue = (
  value: any,
  fieldConfig: FieldConfig | undefined,
  keyPrefix?: string,
) => {
  const key = `${keyPrefix}.${value}`

  if (typeof fieldConfig === 'object') {
    switch (fieldConfig.display) {
      case 'json':
        return <JsonDisplay key={key} value={value} />
      case 'number':
        return <NumericDisplay key={key} value={value} />
      case 'text':
        return <TextDisplay key={key} value={value} />
      case 'uuid':
        return <UuidDisplay key={key} value={value} />
      case 'boolean':
        return <BooleanDisplay key={key} value={value} />
    }
  }

  const detectedNumeric = !isNaN(Number(value)) && value !== ''

  if (detectedNumeric) {
    return <NumericDisplay key={key} value={value} />
  }

  const detectedUuid = isValidUuid(value)

  if (detectedUuid) {
    return <UuidDisplay key={key} value={value} />
  }

  const detectedObject = typeof value === 'object'

  if (detectedObject) {
    return <JsonDisplay key={key} value={value} />
  }

  return <TextDisplay key={key} value={value} />
}

const getLabel = (fieldConfig: FieldConfig) =>
  typeof fieldConfig === 'string' ? fieldConfig : fieldConfig.label
