import { CredentialFieldConstraint } from '@swiftctrl/api-client'
import { Select, SelectProps } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { BitfieldValue, Constraint } from '../utils'
import { buildInitialIntegerRange } from './utils'

type IntegerConstraintTypeViewProps = {
  value: 'integer_range' | 'valid_values'
  templateConstraint: Constraint | null
  templateBitfieldValue: BitfieldValue | null | undefined
  formatConstraint: CredentialFieldConstraint | undefined
  onChange: (constraint: Constraint) => void
}

export const IntegerConstraintTypeView = ({
  value,
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: IntegerConstraintTypeViewProps) => {
  const options: SelectProps['options'] = [
    {
      value: 'integer_range',
      label: 'integer_range',
    },
    {
      value: 'valid_values',
      label: 'valid_values',
    },
  ]

  const handleChange: SelectProps['onChange'] = (_, option) => {
    if (Array.isArray(option)) {
      // not a multi-select
      return
    }

    switch (option.value) {
      case 'integer_range':
        onChange({
          integer_range: buildInitialIntegerRange(
            templateConstraint,
            templateBitfieldValue,
            formatConstraint,
          ),
          date_range: null,
          valid_values: null,
        })

        break

      case 'valid_values':
        onChange({ valid_values: [], date_range: null, integer_range: null })

        break
    }
  }

  return (
    <StyledSelect
      size="middle"
      showLabel={false}
      bordered={false}
      options={options}
      value={value}
      onChange={handleChange}
    />
  )
}

const StyledSelect = styled(Select)`
  width: 136px;
`
