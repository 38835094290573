import { CredentialFormatsTypes } from '@swiftctrl/api-client'
import { ColumnsType } from '@swiftctrl/swift-component-library'
import { isEqual } from 'lodash'
import { Dispatch, SetStateAction, useState } from 'react'
import { styled } from 'styled-components'
import { ErrorBoundary } from '../../components'
import { CredentialTemplateFieldIntegerBitfieldPropertiesView } from './bitfield-properties'
import { CredentialTemplateFieldConstraintInput } from './CredentialTemplateFieldConstraintInput'
import { CredentialTemplateFieldNameView } from './CredentialTemplateFieldNameView'
import { CredentialTemplateFieldOptionsView } from './CredentialTemplateFieldOptionsView'
import { CredentialTemplateFieldPlaceholderInput } from './CredentialTemplateFieldPlaceholderInput'
import { CredentialTemplateFieldTooltipInput } from './CredentialTemplateFieldTooltipInput'
import { CredentialTemplateFieldDefaultValueInput } from './default-value'
import { DraggableRowTable } from './DraggableRowTable'
import {
  CredentialTemplateField,
  EditCredentialTemplateFieldFunction,
  getCredentialFormatField,
} from './utils'

type CredentialTemplateFieldsTableProps = {
  fields: CredentialTemplateField[]
  credentialFormat: CredentialFormatsTypes
  editField: EditCredentialTemplateFieldFunction
  setFields: Dispatch<SetStateAction<CredentialTemplateField[]>>
}

const EXPANDED_ROW_CLASS = 'CredentialTemplateFieldsTable-expandedRowClass'

const HIDDEN_ROW_CLASS = 'CredentialTemplateFieldsTable-hiddenRowClass'

export const CredentialTemplateFieldsTable = ({
  fields,
  credentialFormat,
  editField,
  setFields,
}: CredentialTemplateFieldsTableProps) => {
  const [expandedFieldIds, setExpandedFieldIds] = useState(new Array<string>())

  const expandFieldId = (id: string) => {
    setExpandedFieldIds((previous) => {
      const update = [...previous, id]

      return update
    })
  }

  const collapseFieldId = (id: string) => {
    setExpandedFieldIds((previous) => {
      const update = previous.filter((previousId) => previousId !== id)

      return update
    })
  }

  const columns = buildColumns(
    credentialFormat,
    editField,
    expandedFieldIds,
    expandFieldId,
    collapseFieldId,
  )

  return (
    <StyledDraggableRowTable
      columns={columns}
      dataSource={fields}
      pagination={false}
      rowKey="credential_field_format_id"
      rowClassName={(field) => (field.is_hidden ? HIDDEN_ROW_CLASS : '')}
      setDataSource={setFields}
      expandable={{
        expandedRowKeys: expandedFieldIds,
        expandedRowRender: (field) => (
          <CredentialTemplateFieldIntegerBitfieldPropertiesView
            field={field}
            credentialFormat={credentialFormat}
            onChange={(value) =>
              editField(
                field.credential_field_format_id,
                'bitfield_value',
                value,
              )
            }
          />
        ),
        expandedRowClassName: () => EXPANDED_ROW_CLASS,
        showExpandColumn: false,
      }}
    />
  )
}

const buildColumns = (
  credentialFormat: CredentialFormatsTypes,
  editField: EditCredentialTemplateFieldFunction,
  expandedFieldIds: string[],
  expandFieldId: (id: string) => void,
  collapseFieldId: (id: string) => void,
): ColumnsType<CredentialTemplateField> => [
  {
    title: 'Field Name',
    render: (_, field) => {
      const {
        credential_field_format_id,
        field_name,
        display_name,
        field_type,
      } = field

      const credentialFormatField = getCredentialFormatField(
        credentialFormat,
        field,
      )

      const formatHasValidValues =
        credentialFormatField.constraint?.type === 'valid_values'

      return (
        <CredentialTemplateFieldNameView
          fieldId={credential_field_format_id}
          fieldName={field_name}
          displayName={display_name}
          fieldType={field_type}
          formatHasValidValues={formatHasValidValues}
          expandedFieldIds={expandedFieldIds}
          onDisplayNameChange={(value) =>
            editField(credential_field_format_id, 'display_name', value)
          }
          expandFieldId={expandFieldId}
          collapseFieldId={collapseFieldId}
        />
      )
    },
    shouldCellUpdate: (next, previous) => {
      const shouldCellUpdate = areDifferent(
        'credential_field_format_id',
        'field_name',
        'display_name',
        'field_type',
      )

      const fieldsHaveChanged = shouldCellUpdate(next, previous)

      if (fieldsHaveChanged) {
        return true
      }

      return next.field_type === 'INTEGER'
    },
    width: '163px',
  },
  {
    title: 'Constraint',
    render: (_, field) => {
      const {
        credential_field_format_id,
        field_type,
        constraint,
        bitfield_value,
      } = field

      const credentialFormatField = getCredentialFormatField(
        credentialFormat,
        field,
      )

      return (
        <ErrorBoundary>
          <CredentialTemplateFieldConstraintInput
            fieldType={field_type}
            templateConstraint={constraint}
            templateBitfieldValue={bitfield_value}
            formatConstraint={credentialFormatField.constraint}
            onChange={(value) =>
              editField(credential_field_format_id, 'constraint', value)
            }
          />
        </ErrorBoundary>
      )
    },
    shouldCellUpdate: areDifferent(
      'credential_field_format_id',
      'field_type',
      'constraint',
      'bitfield_value',
    ),
    width: '418px',
  },
  {
    title: 'Default',
    render: (_, field) => {
      const {
        credential_field_format_id,
        field_type,
        default_value,
        constraint,
        bitfield_value,
      } = field

      return (
        <CredentialTemplateFieldDefaultValueInput
          fieldType={field_type}
          defaultValue={default_value}
          constraint={constraint}
          bitfieldValue={bitfield_value}
          onChange={(value) =>
            editField(credential_field_format_id, 'default_value', value)
          }
        />
      )
    },
    shouldCellUpdate: areDifferent(
      'credential_field_format_id',
      'field_type',
      'default_value',
      'constraint',
      'bitfield_value',
    ),
    width: '180px',
  },
  {
    title: 'Placeholder',
    render: (_, field) => {
      const { credential_field_format_id, field_type, placeholder } = field

      return (
        <CredentialTemplateFieldPlaceholderInput
          fieldType={field_type}
          placeholder={placeholder}
          onChange={(value) =>
            editField(credential_field_format_id, 'placeholder', value)
          }
        />
      )
    },
    shouldCellUpdate: areDifferent(
      'credential_field_format_id',
      'field_type',
      'placeholder',
    ),
    width: '166px',
  },
  {
    title: 'Tooltip',
    render: (_, field) => {
      const { credential_field_format_id, tooltip, field_type } = field

      return (
        <CredentialTemplateFieldTooltipInput
          fieldType={field_type}
          tooltip={tooltip}
          onChange={(value) =>
            editField(credential_field_format_id, 'tooltip', value)
          }
        />
      )
    },
    shouldCellUpdate: areDifferent(
      'credential_field_format_id',
      'tooltip',
      'field_type',
    ),
    width: '247px',
  },
  {
    title: 'Options',
    render: (_, field) => {
      const {
        credential_field_format_id,
        is_optional,
        field_type,
        is_hidden,
        read_only,
      } = field

      return (
        <CredentialTemplateFieldOptionsView
          fieldType={field_type}
          isOptional={is_optional}
          readOnly={read_only}
          isHidden={is_hidden}
          onOptionalChange={(value) =>
            editField(credential_field_format_id, 'is_optional', value)
          }
          onReadOnlyChange={(value) =>
            editField(credential_field_format_id, 'read_only', value)
          }
          onHiddenChange={(value) =>
            editField(credential_field_format_id, 'is_hidden', value)
          }
        />
      )
    },
    shouldCellUpdate: areDifferent(
      'credential_field_format_id',
      'is_optional',
      'field_type',
      'is_hidden',
      'read_only',
    ),
    width: '91px',
  },
]

const areDifferent = (
  ...fieldsToCompare: (keyof CredentialTemplateField)[]
) => {
  const shouldCellUpdate = (
    next: CredentialTemplateField,
    previous: CredentialTemplateField,
  ) => {
    for (let i = 0; i < fieldsToCompare.length; i++) {
      const fieldName = fieldsToCompare[i] as keyof CredentialTemplateField

      if (!isEqual(next[fieldName], previous[fieldName])) {
        return true
      }
    }

    return false
  }

  return shouldCellUpdate
}

const StyledDraggableRowTable: typeof DraggableRowTable = styled(
  DraggableRowTable,
)`
  width: 100%;

  .${EXPANDED_ROW_CLASS} > td {
    padding-top: ${({ theme }) => theme.spacing.large};
    padding-bottom: ${({ theme }) => theme.spacing.large};

    padding-left: 51px;
    padding-right: 51px;
  }

  .${HIDDEN_ROW_CLASS} {
    background: #fafafa;
  }
`
