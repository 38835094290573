import { PropsWithChildren } from 'react'
import { styled } from 'styled-components'
import { TABLE_CSS } from '../../data/models'

export const ManageAssignedDataHeader = ({ children }: PropsWithChildren) => {
  return <Container>{children}</Container>
}

const Container = styled.div`
  background-color: #fafafa;
  height: ${TABLE_CSS.HEADER_HEIGHT};
  border-bottom: ${TABLE_CSS.BORDER};
  padding: 16px;
  font-weight: 500;
`
