import { AccessLevelTypes } from '@swiftctrl/api-client'
import { Button, Layout, Typography } from '@swiftctrl/swift-component-library'
import { List } from 'antd'
import { styled } from 'styled-components'
import {
  ManageAssignedDataInfiniteList,
  ManageAssignedDataInfiniteListProps,
} from '../../../../components'

type CandidateAccessLevelsTableProps = Omit<
  ManageAssignedDataInfiniteListProps<Partial<AccessLevelTypes>>,
  'header' | 'renderItem'
> & {
  buildingName: string
  onAddAccessLevel: (accessLevel: Partial<AccessLevelTypes>) => void
  removedAccessLevelIds: string[]
  isSaving: boolean
}

const REMOVED_BACKGROUND = '#ff000010'

export const CandidateAccessLevelsTable = ({
  buildingName,
  onAddAccessLevel,
  removedAccessLevelIds,
  data,
  isFetching,
  isFetchingFirstTime,
  fetchNextPage,
  isSaving,
}: CandidateAccessLevelsTableProps) => {
  return (
    <ManageAssignedDataInfiniteList
      header={`Access Levels in ${buildingName}`}
      data={data}
      isFetching={isFetching}
      isFetchingFirstTime={isFetchingFirstTime}
      fetchNextPage={fetchNextPage}
      renderItem={(accessLevel) => (
        <StyledListItem
          key={accessLevel.access_level_id}
          $removed={removedAccessLevelIds.includes(
            accessLevel.access_level_id!,
          )}
        >
          <StyledLayout
            horizontal
            justifyContent="space-between"
            alignItems="center"
          >
            <Layout grow>
              {accessLevel.entity_name}
              <Typography.Text type="secondary">
                {accessLevel.entity_description}
              </Typography.Text>
            </Layout>
            <Button
              size="small"
              type="primary"
              ghost
              onClick={() => onAddAccessLevel(accessLevel)}
              disabled={isSaving}
            >
              Add
            </Button>
          </StyledLayout>
        </StyledListItem>
      )}
    />
  )
}

const StyledListItem = styled(List.Item)<{ $removed: boolean }>`
  background: ${({ $removed }) => ($removed ? REMOVED_BACKGROUND : undefined)};
`

const StyledLayout = styled(Layout)`
  flex: 1;
`
