import { sample } from 'lodash'

const hexChars = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
]

export const generateHexadecimal = (length: number) => {
  const chars = []

  for (let i = 0; i < length; i++) {
    chars.push(sample(hexChars))
  }

  const value = chars.join('')

  return value
}
