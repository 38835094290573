import { ProviderProfileTypes } from '@swiftctrl/api-client'
import { getProviderTypeFromProviderProfile } from '../../../../utils-hooks'
import { ActionsDropdown, ActionsDropdownItem } from '../../../menu'
import { useDeleteProviderProfileFlow } from './useDeleteProviderProfileFlow'
import { useResetPasswordFlow } from './useResetPasswordFlow'

type ProviderProfileActionsDropdownProps = {
  providerProfile: Partial<ProviderProfileTypes>
}

export const ProviderProfileActionsDropdown = ({
  providerProfile,
}: ProviderProfileActionsDropdownProps) => {
  const { provider_profile_id, login_data } = providerProfile

  const items = new Array<ActionsDropdownItem>()

  const providerType = getProviderTypeFromProviderProfile(providerProfile)

  const { handleResetPasswordClick } = useResetPasswordFlow()

  if (providerType === 'email_password') {
    items.push({
      key: 'reset-password',
      label: 'Reset Password',
      onClick: () =>
        handleResetPasswordClick(login_data!.email_password!.email),
    })
  }

  const { handleDeleteProviderProfileClick } = useDeleteProviderProfileFlow(
    provider_profile_id!,
  )

  items.push({
    key: 'delete',
    label: 'Delete',
    danger: true,
    onClick: handleDeleteProviderProfileClick,
  })

  return <ActionsDropdown items={items} />
}
