import { SinkTemplateBrowseTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { useState } from 'react'
import { swiftClient } from '../../../../../data/swiftClient'
import { isValidUuid, mapDataToSelectOptions } from '../../../../../utils-hooks'
import { BaseLiveSearch } from '../../../../add-entities'

type SinkTemplateSelectProps = {
  onChange: (sinkTemplateId: string) => void
}

export const SinkTemplateSelect = ({ onChange }: SinkTemplateSelectProps) => {
  const [value, setValue] = useState<string>('')

  const handleChange = (value: string) => {
    onChange(value)

    setValue(value)
  }

  return (
    <BaseLiveSearch
      value={value}
      onChange={handleChange}
      fetchOptions={fetchSinkTemplates}
      fetchDependencies={[]}
      size="large"
    />
  )
}

const fetchSinkTemplates = async (search: string) => {
  const q = getQueryHandler<SinkTemplateBrowseTypes>()

  const { data } = await swiftClient.sinkTemplate.browse({
    select: ['sink_template_id', 'name'],
    filters: [
      q(
        'WHERE',
        isValidUuid(search) ? 'sink_template_id' : 'name',
        isValidUuid(search) ? 'EQ' : 'EQ_IC',
        search,
      ),
    ],
  })

  return mapDataToSelectOptions(data, 'sink_template_id', 'name')
}
