import {
  CredentialTemplateBrowseTypes,
  CredentialTemplateTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
} from '../../../../states'
import { cacheKeys, isValidUuid, useBoolean } from '../../../../utils-hooks'
import {
  CARDS_DATA_PER_FETCH,
  EntitiesCardsBrowser,
} from '../../EntitiesCardsBrowser'
import { AddCredentialTemplateView } from './AddCredentialTemplateView'
import { CredentialTemplateCard } from './CredentialTemplateCard'

export const CredentialTemplatesBrowser = () => {
  const [
    isAddCredentialTemplateViewOpen,
    openAddCredentialTemplateView,
    closeAddCredentialTemplateView,
  ] = useBoolean()

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={CARDS_DATA_PER_FETCH}
      browse={browse}
      dataKey="credential_format_config_id"
      entityType="credential_template"
      searchable
      displayAddButton
      onAddButtonClick={openAddCredentialTemplateView}
      filters={{
        baseIdType: 'organization',
        relatedEntityType: 'credential_format',
        relatedEntityLabel: 'Core Credential Format',
      }}
    >
      <EntitiesCardsBrowser render={render} />
      <AddCredentialTemplateView
        open={isAddCredentialTemplateViewOpen}
        onClose={closeAddCredentialTemplateView}
      />
    </BrowseEntitiesContextProvider>
  )
}

const browse: BrowseEntitiesConfig<CredentialTemplateTypes> = {
  request: ({
    baseId,
    search,
    relatedEntityId,
    pageParam,
    dataPerFetch,
    signal,
  }) =>
    swiftClientWithoutErrorHandling.credentialTemplate.browse(
      {
        organizationId: baseId,
        select: [
          'created_at',
          'credential_format_config_id',
          'template_description',
          'template_name',
          'overseer_id',
          'overseer_name',
          'updated_at',
          'credential_format_id',
          'credential_format_name',
        ],
        filters: getFilters(search, relatedEntityId),
        sort: 'template_name:asc',
        offset: pageParam,
        limit: dataPerFetch,
      },
      {
        signal,
      },
    ),
  cacheKey: [cacheKeys.credential_templates],
}

const getFilters = (search: string, relatedEntityId: string | undefined) => {
  const searchFilterParams = getSearchFilterParams(search)

  const relatedEntityIdFilterParams =
    getRelatedEntityFilterParams(relatedEntityId)

  const q = getQueryHandler<CredentialTemplateBrowseTypes>()

  if (searchFilterParams && relatedEntityIdFilterParams) {
    return [...relatedEntityIdFilterParams, q('AND', searchFilterParams)]
  }

  if (searchFilterParams) {
    return searchFilterParams
  }

  if (relatedEntityIdFilterParams) {
    return relatedEntityIdFilterParams
  }

  return undefined
}

const getSearchFilterParams = (search: string) => {
  if (!search) {
    return undefined
  }

  const q = getQueryHandler<CredentialTemplateBrowseTypes>()

  if (isValidUuid(search)) {
    return [
      q('WHERE', 'credential_format_config_id', 'EQ', search),
      q('OR', 'overseer_id', 'EQ', search),
      q('OR', 'credential_format_id', 'EQ', search),
    ]
  }

  return [
    q('WHERE', 'template_name', 'EQ_IC', search),
    q('OR', 'template_description', 'EQ_IC', search),
  ]
}

const getRelatedEntityFilterParams = (relatedEntityId: string | undefined) => {
  if (!relatedEntityId) {
    return undefined
  }

  const q = getQueryHandler<CredentialTemplateBrowseTypes>()

  return [q('WHERE', 'credential_format_id', 'EQ', relatedEntityId)]
}

const render = (credentialTemplate: CredentialTemplateTypes) => (
  <CredentialTemplateCard credentialTemplate={credentialTemplate} />
)
