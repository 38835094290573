/**
 * Adapted from Ant Charts: https://github.com/ant-design/ant-design-charts/blob/master/packages/graphs/src/utils/set-ellipsis.ts
 */
export const truncateCanvasText = (
  text: string,
  maxWidth: number,
  fontSize = 12,
  fontWeight = 'normal',
  fontFamily = 'Arial, sans-serif',
) => {
  const canvas = document.querySelector('canvas')

  if (!canvas) {
    console.warn('Trying to truncate text without canvas. Returning input text')

    return text
  }

  const context = canvas.getContext('2d')

  if (!context) {
    console.warn(
      'Trying to truncate text without 2D context. Returning input text',
    )

    return text
  }

  context.font = `${fontSize}px ${fontWeight} ${fontFamily}`

  const { width } = context.measureText(text)

  if (width <= maxWidth) {
    return text
  }

  for (let length = text.length - 1; length >= 0; length--) {
    const truncatedText = text.substring(0, length) + '…'

    const { width } = context.measureText(truncatedText)

    if (width <= maxWidth) {
      return truncatedText
    }
  }

  return ''
}
