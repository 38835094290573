import { EntityTypes } from '@swiftctrl/api-client'
import { Modal } from '@swiftctrl/swift-component-library'
import {
  ActionsDropdown,
  ActionsDropdownItem,
  invalidateReadScreenQuery,
} from '../../../../components'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import {
  cacheKeys,
  showErrorNotification,
  showSuccessNotification,
} from '../../../../utils-hooks'

type RoleTargetActionsDropdownProps = {
  roleId: string
  target: Partial<EntityTypes>
}

export const RoleTargetActionsDropdown = ({
  roleId,
  target,
}: RoleTargetActionsDropdownProps) => {
  const items: ActionsDropdownItem[] = [
    {
      key: 'remove',
      label: 'Remove',
      danger: true,
      onClick: confirmTargetRemoval(roleId, target),
    },
  ]

  return <ActionsDropdown items={items} />
}

const confirmTargetRemoval = (roleId: string, target: Partial<EntityTypes>) => {
  const { entity_name, entity_id } = target

  const onClick = () => {
    Modal.confirm({
      title: 'Remove Target',
      content: `Are you sure you want to remove "${entity_name}"?`,
      okText: 'Remove',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      centered: true,
      onOk: removeTarget(roleId, entity_id!, entity_name!),
    })
  }

  return onClick
}

const removeTarget = (roleId: string, targetId: string, targetName: string) => {
  const onOk = async () => {
    try {
      await swiftClientWithoutErrorHandling.role
        .at(roleId)
        .grantsOn.at(targetId)
        .delete()

      invalidateReadScreenQuery(cacheKeys.role, roleId)

      showSuccessNotification(`Target "${targetName}" removed`)
    } catch (error) {
      showErrorNotification('Error while removing target', error)
    }
  }

  return onOk
}
