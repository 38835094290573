import { EntityType } from '../data/models'

export const getEntityNameKey = (entityType: EntityType) => {
  switch (entityType) {
    case 'credential_template':
      return 'template_name'

    case 'profile':
      return 'display_name'

    case 'archetype':
      return 'role_archetype'

    default:
      return 'entity_name'
  }
}
