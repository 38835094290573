import { EntityBrowseTypes, EntityTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { useQuery } from '@tanstack/react-query'
import { EntityType, SWIFT_ROOT_ENTITY_ID } from '../../../../../data/models'
import { swiftClient } from '../../../../../data/swiftClient'
import { cacheKeys } from '../../../../../utils-hooks'

const excludedEntityTypes: EntityType[] = [
  'acs_transaction',
  'acs_hardware',
  'credential',
  'profile',
  'phone',
  'email',
  'address',
]

export const useGetTargetChildren = (target: Partial<EntityTypes>) => {
  const { entity_id } = target

  return useQuery({
    queryKey: [cacheKeys.role_target_children, entity_id],
    queryFn: () => {
      const q = getQueryHandler<EntityBrowseTypes>()

      const filters = [
        q('WHERE', 'overseer_id', 'EQ', entity_id),
        q('AND', 'entity_type_id', 'N_ANY', excludedEntityTypes),
      ]

      return swiftClient.entity.browse({
        baseId: SWIFT_ROOT_ENTITY_ID,
        select: ['entity_id', 'entity_name', 'entity_type_id'],
        filters,
      })
    },
  })
}
