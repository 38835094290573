import { uniq } from 'lodash'

const SIZE_THRESHOLD = 35

/**
 * Use when the filters for a BE request might be too large,
 * causing the URL to be too long which then causes a server error.
 *
 * The assumption is that the filters are for the specified IDs,
 * and because the IDs are long, the URL ends up being too long.
 *
 * Under the hood, multiple requests may be made in parallel,
 * and then the resulting data are combined before being
 * delivered to the consumer.
 */
export const getLargeFilteredBrowseRequest = async <T>(
  ids: string[],
  filterFactory: (ids: string[]) => string,
  browseRequest: (filters: string) => Promise<T[]>,
) => {
  const uniqueIds = uniq(ids)

  const requestsCount = Math.ceil(uniqueIds.length / SIZE_THRESHOLD)

  const requests: Promise<T[]>[] = []

  for (let i = 0; i < requestsCount; i++) {
    const idsSubsetStartIndex = i * SIZE_THRESHOLD

    const idsSubsetEndIndex = Math.min(
      (i + 1) * SIZE_THRESHOLD,
      uniqueIds.length,
    )

    const idsSubset = uniqueIds.slice(idsSubsetStartIndex, idsSubsetEndIndex)

    const filters = filterFactory(idsSubset)

    requests.push(browseRequest(filters))
  }

  const response = await Promise.all(requests)

  const data = response.reduce((accumulator, current) => {
    accumulator.push(...current)

    return accumulator
  }, [] as T[])

  return data
}
