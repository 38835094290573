import { createServer } from 'miragejs'
import { config } from '../../config/config'
import { credentialTemplateMock } from './credentialTemplateMock'
import { operationMock } from './operationMock'
import { providerMock } from './providerMock'
import { MockFeature } from './utils'

const mockImplementations: MockFeature[] = [
  credentialTemplateMock,
  operationMock,
  providerMock,
]

const mockedFeatures = mockImplementations.filter((mock) => mock.enabled)

export const initMockServer = () => {
  createServer({
    models: buildModels(),
    factories: {},
    seeds(server) {
      mockedFeatures.forEach((mock) => mock.seed(server))
    },
    routes() {
      fixMirageAxiosRequestBug()

      this.urlPrefix = config.swiftConnectApi.baseUrl

      mockedFeatures.forEach((mock) => mock.route(this))

      this.passthrough()

      this.passthrough('https://cdn.fe.dev.swiftconnect.io/**')

      this.passthrough('https://firebaseremoteconfig.googleapis.com/**')

      this.passthrough('https://firebaseinstallations.googleapis.com/**')
    },
  })
}

const buildModels = () => {
  let models = {}

  mockedFeatures.forEach((mock) => {
    models = { ...models, ...mock.model() }
  })

  return models
}

export const isAnyMockFeatureEnabled = () => {
  return mockedFeatures.length > 0
}

/**
 * Fix for Mirage not able to let requests passthrough with an Axios version later than 0.21.1
 */
const fixMirageAxiosRequestBug = () => {
  const NativeXMLHttpRequest = window.XMLHttpRequest

  window.XMLHttpRequest = function XMLHttpRequest() {
    const request = new NativeXMLHttpRequest()

    //@ts-ignore
    delete request.onloadend

    return request
  } as any
}
