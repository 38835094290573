import {
  CredentialFormatsTypes,
  CredentialTemplateEditTypes,
  CredentialTemplateFieldAddTypes,
  CredentialTemplateFieldTypes,
  CredentialTemplateTypes,
} from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import { Dispatch, SetStateAction, useState } from 'react'
import {
  invalidateReadScreenQuery,
  sortCredentialTemplateFieldsByRank,
} from '../../components'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { useEventEmitterEffect } from '../../states'
import {
  cacheKeys,
  showErrorNotification,
  showSuccessNotification,
  usePreventTabUnload,
} from '../../utils-hooks'
import {
  CredentialTemplateField,
  EditCredentialTemplateFieldFunction,
} from './utils'
import { validateCredentialTemplate } from './validate'

export const useCredentialTemplateFieldsLogic = (
  credentialTemplate: CredentialTemplateTypes,
  credentialFormat: CredentialFormatsTypes,
) => {
  const { credential_field_configs, credential_format_config_id } =
    credentialTemplate

  const { initialFields, initialFieldsMap } = processInitialFields(
    credential_field_configs,
  )

  const [fields, innerSetFields] =
    useState<CredentialTemplateField[]>(initialFields)

  const editField: EditCredentialTemplateFieldFunction = (id, key, value) => {
    innerSetFields((previous) => {
      const update = structuredClone(previous) as CredentialTemplateFieldTypes[]

      const configFieldToUpdate = update.find(
        (fieldConfig) => fieldConfig.credential_field_format_id === id,
      )!

      // @ts-expect-error TS resolves the type for `configFieldToUpdate[key]` as `null`
      configFieldToUpdate[key] = value

      return update
    })
  }

  const setFields: Dispatch<SetStateAction<CredentialTemplateField[]>> = (
    value,
  ) => {
    innerSetFields(value)

    innerSetFields((previous) => {
      const update = previous.map(updateRank)

      return update
    })
  }

  const isDirty = checkDirtiness(fields, initialFieldsMap)

  usePreventTabUnload(isDirty)

  useEventEmitterEffect('hasUnsavedChanges', isDirty)

  const isValid = validateCredentialTemplate(fields, credentialFormat)

  useEventEmitterEffect('credentialTemplate.valid', isValid)

  const { mutate: saveMutation, isPending: isSaving } = useMutation({
    mutationFn: () => {
      const payload: CredentialTemplateEditTypes = {
        ...credentialTemplate,
        credential_field_configs: buildEditFields(fields),
      }

      return swiftClientWithoutErrorHandling.credentialTemplate
        .at(credential_format_config_id)
        .edit(payload)
    },
    onSuccess: () => {
      invalidateReadScreenQuery(
        cacheKeys.credential_template,
        credential_format_config_id,
      )

      showSuccessNotification('Credential template saved')
    },
    onError: (error) => {
      showErrorNotification('Error while saving credential template', error)
    },
  })

  return {
    fields,
    editField,
    /**
     * Warning: should not be used for updating the fields! Use `editField` instead.
     *
     * `setFields` is exposed only to enable the dragging of fields to change their ranks.
     */
    setFields,
    isDirty,
    isValid,
    save: () => saveMutation(),
    isSaving,
  }
}

const processInitialFields = (
  credentialFieldConfigs: CredentialTemplateFieldTypes[],
) => {
  const initialFields = structuredClone(
    credentialFieldConfigs,
  ) as CredentialTemplateFieldTypes[]

  initialFields.sort(sortCredentialTemplateFieldsByRank)

  const initialFieldsMap = new Map<string, CredentialTemplateFieldTypes>()

  initialFields.forEach((field) => {
    initialFieldsMap.set(field.credential_field_format_id, field)

    if (field.constraint?.integer_range) {
      delete field.constraint.integer_range.open

      delete field.constraint.integer_range.close
    }
  })

  return { initialFields, initialFieldsMap }
}

const checkDirtiness = (
  currentFields: CredentialTemplateField[],
  initialFieldsMap: Map<string, CredentialTemplateFieldTypes>,
) => {
  for (let i = 0; i < currentFields.length; i++) {
    const {
      credential_field_format_id,
      rank,
      display_name,
      constraint,
      bitfield_value,
      bitfield_parity,
      default_value,
      placeholder,
      tooltip,
      is_optional,
      read_only,
      is_hidden,
    } = currentFields[i]

    const initialField = initialFieldsMap.get(credential_field_format_id)!

    if (rank !== initialField.rank) {
      return true
    }

    if (display_name !== initialField.display_name) {
      return true
    }

    if (!isEqual(constraint, initialField.constraint)) {
      return true
    }

    if (!isEqual(bitfield_value, initialField.bitfield_value)) {
      return true
    }

    if (!isEqual(bitfield_parity, initialField.bitfield_parity)) {
      return true
    }

    if (
      checkNullableStringDirtiness(default_value, initialField.default_value)
    ) {
      return true
    }

    if (checkNullableStringDirtiness(placeholder, initialField.placeholder)) {
      return true
    }

    if (checkNullableStringDirtiness(tooltip, initialField.tooltip)) {
      return true
    }

    if (is_optional !== initialField.is_optional) {
      return true
    }

    if (read_only !== initialField.read_only) {
      return true
    }

    if (is_hidden !== initialField.is_hidden) {
      return true
    }
  }

  return false
}

const checkNullableStringDirtiness = (
  current: string | null,
  initial: string | null,
) => {
  const normalizedCurrent = current || ''

  const normalizedInitial = initial || ''

  return normalizedCurrent !== normalizedInitial
}

const updateRank = (field: CredentialTemplateField, index: number) => ({
  ...field,
  rank: index,
})

const buildEditFields = (fields: CredentialTemplateField[]) => {
  const editFields = fields.map((field) => {
    const { default_value, placeholder, tooltip } = field

    const editField = {
      ...field,
      default_value: default_value || null,
      placeholder: placeholder || null,
      tooltip: tooltip || null,
    } as CredentialTemplateFieldAddTypes

    return editField
  })

  return editFields
}
