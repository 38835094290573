import { Modal } from '@swiftctrl/swift-component-library'
import { RoleProfile } from './buildRoleProfile'

export const checkBeforeRemoval = (
  roleId: string,
  removeProfile: (profile: RoleProfile) => void,
) => {
  const removeSubdata = (profile: RoleProfile) => {
    const { overseer_id, display_name } = profile.profile

    if (overseer_id !== roleId) {
      removeProfile(profile)

      return
    }

    Modal.confirm({
      title: 'Delete profile?',
      content: (
        <>
          In order to remove <b>{display_name}</b> from this role, you must
          delete the profile. Indeed, this Profile is associated with this role
          through a parent/child relationship. Do you still want to delete{' '}
          <b>{display_name}</b>? The deletion will happen when you click "Save".
        </>
      ),
      okText: 'Delete',
      okButtonProps: {
        danger: true,
      },
      onOk: () => {
        removeProfile(profile)
      },
      centered: true,
    })
  }

  return removeSubdata
}
