import { parseISO } from 'date-fns'
import { EntityType } from '../../data/models'
import {
  FieldGroupConfig,
  FieldInputType,
  flattenEntityForFormData,
} from '../../utils-hooks'

export const buildInitialValues = <FormData, EntityData>(
  fieldGroups: FieldGroupConfig<FormData>[],
  data: EntityData,
  entityType: EntityType,
) => {
  const flattenedData = flattenEntityForFormData(data, {}, '', entityType)

  const initialValues = fieldGroups.reduce((obj, fieldGroup) => {
    fieldGroup.fields.forEach((field) => {
      const { inputType, key } = field

      obj[key] = getValue(
        key,
        flattenedData,
        inputType,
      ) as FormData[keyof FormData]
    })

    return obj
  }, {} as FormData)

  return { initialValues }
}

const getValue = <FormData>(
  key: keyof FormData,
  flattenedData: FormData,
  inputType: FieldInputType | undefined,
) => {
  const valueFromData = flattenedData[key]

  if (valueFromData) {
    if (inputType === 'datetime') {
      const date = parseISO(String(valueFromData))

      return date
    }

    return valueFromData
  }

  if (inputType === 'boolean') {
    return false
  }

  return ''
}
