import { AddressTypes, BuildingBrowseTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { isValidUuid } from '../../../../utils-hooks'

export const formatAddress = (address: AddressTypes) => {
  const { address_line_1, address_line_2, city, state, zip, country } = address

  const tokens = new Array<string>()

  if (address_line_1) {
    tokens.push(address_line_1)
  }

  if (address_line_2) {
    tokens.push(address_line_2)
  }

  if (city) {
    tokens.push(city)
  }

  if (state && zip) {
    tokens.push(`${state} ${zip}`)
  } else if (state) {
    tokens.push(state)
  } else if (zip) {
    tokens.push(zip)
  }

  if (country) {
    tokens.push(country)
  }

  const value = tokens.join(', ')

  return value
}

export const buildBrowseBuildingsFiltersParam = (search: string) => {
  if (!search) {
    return undefined
  }

  const q = getQueryHandler<BuildingBrowseTypes>()

  if (isValidUuid(search)) {
    return [
      q('WHERE', 'building_id', 'EQ', search),
      q('OR', 'overseer_id', 'EQ', search),
    ]
  }

  return [
    q('WHERE', 'entity_name', 'EQ_IC', search),
    q('OR', 'entity_description', 'EQ_IC', search),
  ]
}
