import { ProviderBrowseTypes, ProviderTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  EntitiesBrowserProps,
} from '../../../../states'
import {
  adaptSDKBrowseRequest,
  cacheKeys,
  isValidUuid,
} from '../../../../utils-hooks'
import {
  CARDS_DATA_PER_FETCH,
  EntitiesCardsBrowser,
} from '../../EntitiesCardsBrowser'
import { ProviderCard } from './ProviderCard'

export const ProvidersBrowser = (props: EntitiesBrowserProps) => (
  <BrowseEntitiesContextProvider
    dataPerFetch={CARDS_DATA_PER_FETCH}
    browse={browse}
    dataKey="provider_id"
    searchable
    entityType="provider"
    {...props}
  >
    <EntitiesCardsBrowser render={render} />
  </BrowseEntitiesContextProvider>
)

const browse: BrowseEntitiesConfig<ProviderTypes> = {
  request: adaptSDKBrowseRequest({
    request: swiftClient.provider.browse,
    select: [
      'provider_id',
      'entity_name',
      'entity_description',
      'created_at',
      'updated_at',
      'overseer_id',
      'overseer_name',
      'provider_type',
    ],
    getFilters: (search) => {
      if (!search) {
        return undefined
      }

      const q = getQueryHandler<ProviderBrowseTypes>()

      if (isValidUuid(search)) {
        return [
          q('WHERE', 'provider_id', 'EQ', search),
          q('OR', 'overseer_id', 'EQ', search),
        ]
      }

      return [
        q('WHERE', 'entity_name', 'EQ_IC', search),
        q('OR', 'entity_description', 'EQ_IC', search),
      ]
    },
    sort: 'entity_name:asc',
  }),
  cacheKey: cacheKeys.providers,
}

const render = (provider: ProviderTypes) => <ProviderCard provider={provider} />
