import {
  OperationAction,
  OperationModifier,
  OperationPrefix,
  OperationScope,
  RoleOperationTypes,
} from '@swiftctrl/api-client'
import { RoleOperationsGroup } from '../models'

const SCOPES_SORT_ORDER: OperationScope[] = [
  'UNDEFINED',
  'STEM',
  'DESCENDANTS',
  'NODE',
]

const MODIFIERS_SORT_ORDER: (OperationModifier | null)[] = [
  null,
  'UNDEFINED',
  'SELF',
  'SPACE',
]

export const buildGroupedData = (roleOperations: RoleOperationTypes[]) => {
  const prefixes = new Set<OperationPrefix>()

  const prefixesToActions = new Map<OperationPrefix, Set<OperationAction>>()

  const prefixesToRoleOperations = new Map<
    OperationPrefix,
    RoleOperationTypes[]
  >()

  roleOperations.forEach((roleOperation) => {
    const { prefix, action, field_names, relation_names } = roleOperation

    prefixes.add(prefix)

    if (prefixesToActions.has(prefix)) {
      prefixesToActions.get(prefix)!.add(action)
    } else {
      prefixesToActions.set(prefix, new Set([action]))
    }

    if (prefixesToRoleOperations.has(prefix)) {
      prefixesToRoleOperations.get(prefix)!.push(roleOperation)
    } else {
      prefixesToRoleOperations.set(prefix, [roleOperation])
    }

    field_names?.sort(alphabetize)

    relation_names?.sort(alphabetize)
  })

  const groupedData: RoleOperationsGroup[] = Array.from(prefixes)
    .sort(alphabetize)
    .map((prefix) => ({
      prefix,
      actions: Array.from(prefixesToActions.get(prefix)!).sort(alphabetize),
      roleOperations: Array.from(
        prefixesToRoleOperations.get(prefix)!.sort(sortOperations),
      ),
    }))

  return { groupedData }
}

const alphabetize = (a: string, b: string) => a.localeCompare(b)

const sortOperations = (a: RoleOperationTypes, b: RoleOperationTypes) => {
  const orderByActions = alphabetize(a.action, b.action)

  if (orderByActions !== 0) {
    return orderByActions
  }

  const orderByScope = sortScopes(a.scope, b.scope)

  if (orderByScope !== 0) {
    return orderByScope
  }

  const orderByModifier = sortModifiers(a.modifier, b.modifier)

  return orderByModifier
}

const sortScopes = (a: OperationScope, b: OperationScope) => {
  const aSortValue = SCOPES_SORT_ORDER.indexOf(a)

  const bSortValue = SCOPES_SORT_ORDER.indexOf(b)

  return aSortValue - bSortValue
}

const sortModifiers = (
  a: OperationModifier | null,
  b: OperationModifier | null,
) => {
  const aSortValue = MODIFIERS_SORT_ORDER.indexOf(a)

  const bSortValue = MODIFIERS_SORT_ORDER.indexOf(b)

  return aSortValue - bSortValue
}
