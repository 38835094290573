import {
  ProviderProfileBrowseTypes,
  ProviderProfileTypes,
  ProviderTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import {
  ColumnsType,
  Modal,
  PreLoader,
} from '@swiftctrl/swift-component-library'
import {
  CreatedUpdatedDateDisplay,
  EntitiesTableBrowser,
  LoginDataDisplay,
  ProfileDisplay,
  StyledEntitiesBrowserScreen,
  TABLE_DATA_PER_FETCH,
} from '../../components'
import { SWIFT_ROOT_ENTITY_ID } from '../../data/models'
import { queryClient } from '../../data/queryClient'
import { swiftClient } from '../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
} from '../../states'
import {
  adaptSDKBrowseRequest,
  cacheKeys,
  showErrorNotification,
  useDeleteProviderProfile,
  useGetOverseerOrganization,
} from '../../utils-hooks'
import { ProviderProfileActionsDropdown } from './ProviderProfileActionsDropdown'
import { ProviderProfileNameView } from './ProviderProfileNameView'

interface ProviderDetailsContentProps {
  provider: ProviderTypes
  onCanDeleteProvider: () => void
}

export const ProviderDetailsContent = ({
  provider,
  onCanDeleteProvider,
}: ProviderDetailsContentProps) => {
  const { provider_id } = provider

  const browse = buildBrowse(provider_id, onCanDeleteProvider)

  const { mutate } = useDeleteProviderProfile()

  const handleProviderProfileDelete = (
    providerProfileId: string,
    providerProfileName: String,
  ) => {
    Modal.confirm({
      title: 'Delete Provider Profile ?',
      content: (
        <div>
          Are you sure you want to delete{' '}
          <b> {`${providerProfileName || providerProfileId}`}</b> ?
        </div>
      ),
      okText: 'Delete',
      onOk: () =>
        mutate(
          { providerProfileId },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: [cacheKeys.provider_profiles],
              })
            },
            onError: (error) => {
              showErrorNotification('Error while deleting entity', error)
            },
          },
        ),
      okButtonProps: {
        danger: true,
        type: 'default',
      },
      centered: true,
    })
  }

  const columns = getColumns(provider, handleProviderProfileDelete)

  const { data: overseerOrganization, isPending } = useGetOverseerOrganization({
    entity: provider,
  })

  if (isPending) {
    return <PreLoader centered />
  }

  return (
    <StyledEntitiesBrowserScreen>
      <BrowseEntitiesContextProvider
        dataPerFetch={TABLE_DATA_PER_FETCH}
        browse={browse}
        dataKey="provider_profile_id"
        entityType="provider_profile"
        capitalizeTotalLabel
        baseId={SWIFT_ROOT_ENTITY_ID}
        searchable
        displayAddButton
        addDataTemplate={{
          preset_organization_id: overseerOrganization!.entity_id,
          preset_provider: provider,
        }}
      >
        <EntitiesTableBrowser columns={columns} />
      </BrowseEntitiesContextProvider>
    </StyledEntitiesBrowserScreen>
  )
}

const buildBrowse = (
  providerId: string,
  onCanDeleteProvider: () => void,
): BrowseEntitiesConfig<ProviderProfileTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClient.providerProfile.browse,
    getFilters: () => {
      const q = getQueryHandler<ProviderProfileBrowseTypes>()
      return [q('WHERE', 'overseer_id', 'EQ', providerId)]
    },
    getSearch: (search: string) => search,
    select: [
      'profile_id',
      'display_name',
      'overseer_name',
      'overseer_id',
      'entity_name',
      'login_data',
      'created_at',
      'updated_at',
      'entity_description',
    ],
  }),
  onDataReceived: (data) => {
    if (data.length === 0) {
      onCanDeleteProvider()
    }
  },
  cacheKey: [cacheKeys.provider_profiles, providerId],
})

const getColumns = (
  provider: ProviderTypes,
  onProviderProfileDelete: (
    providerProfileId: string,
    providerProfileName: String,
  ) => void,
): ColumnsType<ProviderProfileTypes> => [
  {
    title: 'Provider Profile Name',
    render: (providerProfile: ProviderProfileTypes) => (
      <ProviderProfileNameView
        name={providerProfile.entity_name}
        description={providerProfile.entity_description}
        id={providerProfile.provider_profile_id}
      />
    ),
  },
  {
    title: 'Profile',
    render: (providerProfile: ProviderProfileTypes) => (
      <ProfileDisplay
        profile={{
          profile_id: providerProfile.profile_id,
          display_name: providerProfile.display_name,
        }}
      />
    ),
  },
  {
    title: 'Login data',
    render: (providerProfile: ProviderProfileTypes) => (
      <LoginDataDisplay providerProfile={providerProfile} />
    ),
  },
  {
    title: 'Created/Updated Date',
    render: (providerProfile: ProviderProfileTypes) => (
      <CreatedUpdatedDateDisplay
        updatedAt={providerProfile.updated_at}
        createdAt={providerProfile.created_at}
      />
    ),
  },
  {
    key: 'action',
    render: (providerProfile: Partial<ProviderProfileTypes>) => (
      <ProviderProfileActionsDropdown
        providerProfile={providerProfile}
        provider={provider}
        onProviderProfileDelete={onProviderProfileDelete}
      />
    ),
  },
]
