import { RolesTypes } from '@swiftctrl/api-client'
import { useQuery } from '@tanstack/react-query'
import { SWIFT_ROOT_ENTITY_ID } from '../../../data/models'
import { swiftClient } from '../../../data/swiftClient'
import { cacheKeys, getLargeFilteredBrowseRequest } from '../../../utils-hooks'

export const useGetRoleTargets = (role: RolesTypes) => {
  const { role_id, granted_on } = role

  return useQuery({
    queryKey: [role_id, cacheKeys.role_targets, granted_on],
    queryFn: async () => {
      if (!granted_on) {
        return []
      }

      const data = await getLargeFilteredBrowseRequest(
        granted_on,
        (ids) => `entity_id ANY ${ids.join(',')}`,
        async (filters) => {
          const { data } = await swiftClient.entity.browse({
            baseId: SWIFT_ROOT_ENTITY_ID,
            select: [
              'entity_id',
              'entity_name',
              'entity_type_id',
              'overseer_name',
            ],
            filters,
          })

          return data
        },
      )

      return data
    },
  })
}
