import {
  InputNumber,
  InputNumberProps,
  SwapRightOutlined,
} from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { styled } from 'styled-components'
import { MAX_SAFE_BIG_INT } from '../../../data/models'
import { parseToStringOrUndefined } from '../../../utils-hooks'
import { ValidatedFormItem } from '../ValidatedFormItem'

type IntegerRangeInputProps = {
  startDefaultValue?: number | bigint
  endDefaultValue?: number | bigint
  min?: number
  max?: number | bigint
  onChange: (
    start: number | bigint | undefined,
    end: number | bigint | undefined,
  ) => void
}

const MAX_INTEGER_RANGE_BIG_INT = MAX_SAFE_BIG_INT - BigInt(1)

export const IntegerRangeInput = ({
  startDefaultValue,
  endDefaultValue,
  min,
  max,
  onChange,
}: IntegerRangeInputProps) => {
  const [start, setStart] = useState(parseToBigInt(startDefaultValue))

  const [end, setEnd] = useState(parseToBigInt(endDefaultValue))

  const handleStartChange: InputNumberProps['onChange'] = (value) => {
    const parsedValue = parseInputValue(value)

    setStart(parsedValue)

    handleChange(parsedValue, end)
  }

  const handleChange = (start: bigint | undefined, end: bigint | undefined) => {
    const parsedStart = parseToOnChangeCallback(start)

    const parsedEnd = parseToOnChangeCallback(end)

    onChange(parsedStart, parsedEnd)
  }

  const handleEndChange: InputNumberProps['onChange'] = (value) => {
    const parsedValue = parseInputValue(value)

    setEnd(parsedValue)

    handleChange(start, parsedValue)
  }

  const { isValid, validationMessage } = validate(start, end, min, max)

  return (
    <ValidatedFormItem isValid={isValid} validationMessage={validationMessage}>
      <Container $isValid={isValid}>
        <StyledStartInput
          placeholder="Start"
          value={parseToStringOrUndefined(start)}
          type="number"
          size="small"
          bordered={false}
          controls={false}
          onChange={handleStartChange}
          min={parseToStringOrUndefined(min)}
          max={getMax(end)}
          stringMode
        />
        <StyledSwapRightOutlined />
        <StyledEndInput
          placeholder="End"
          value={parseToStringOrUndefined(end)}
          type="number"
          size="small"
          bordered={false}
          controls={false}
          onChange={handleEndChange}
          min={parseToStringOrUndefined(start)}
          max={getMax(max)}
          stringMode
        />
      </Container>
    </ValidatedFormItem>
  )
}

const Container = styled.span<{ $isValid: boolean }>`
  width: 192px;
  height: 32px;

  border: 1px solid transparent;
  border-radius: 2px;

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &:hover {
    border-color: #d9d9d9;
  }

  input {
    color: ${({ $isValid }) => ($isValid ? '#000' : 'var(--ant-error-color)')};
  }
`

const StyledStartInput = styled(InputNumber)`
  width: 68px;
`

const StyledEndInput = styled(InputNumber)`
  width: 68px;
`

const StyledSwapRightOutlined = styled(SwapRightOutlined)`
  color: #bfbfbf;
`

const parseInputValue = (value: string | number | null) => {
  if (value === null) {
    return undefined
  }

  const parsedValue = BigInt(value)

  return parsedValue
}

const validate = (
  start: bigint | undefined,
  end: bigint | undefined,
  min: number | undefined,
  max: number | bigint | undefined,
): { isValid: boolean; validationMessage: string } => {
  if (start === undefined) {
    return {
      isValid: false,
      validationMessage: 'Start is required',
    }
  }

  if (end === undefined) {
    return {
      isValid: false,
      validationMessage: 'End is required',
    }
  }

  if (min === undefined || max === undefined) {
    return {
      isValid: true,
      validationMessage: '',
    }
  }

  if (start >= BigInt(min) && end <= BigInt(max)) {
    return {
      isValid: true,
      validationMessage: '',
    }
  }

  return {
    isValid: false,
    validationMessage: `The range must be between ${min.toLocaleString()} and ${max.toLocaleString()}`,
  }
}

const getMax = (value: number | bigint | undefined) => {
  if (typeof value === 'number') {
    return String(value)
  }

  if (value === undefined || value > MAX_INTEGER_RANGE_BIG_INT) {
    return String(MAX_INTEGER_RANGE_BIG_INT)
  }

  return String(value)
}

const parseToBigInt = (value: number | bigint | undefined) => {
  if (value === undefined) {
    return undefined
  }

  const parsedValue = BigInt(value)

  return parsedValue
}

const parseToOnChangeCallback = (value: bigint | undefined) => {
  if (value === undefined) {
    return undefined
  }

  if (value <= BigInt(Number.MAX_SAFE_INTEGER)) {
    const parsedValue = Number(value)

    return parsedValue
  }

  return value
}
