import {
  Avatar,
  Col,
  LogOutOutlined,
  MenuProps,
  Row,
} from '@swiftctrl/swift-component-library'
import { Menu, Typography } from 'antd'
import styled from 'styled-components'
import { useUserContext } from '../../states'

export const UserMenu = () => {
  const { user, signOut } = useUserContext()

  const { profile_id, display_name, email } = user

  const menuItems: MenuProps['items'] = [
    {
      key: 'signout',
      label: 'Sign out',
      icon: <StyledLogOutOutlined size="medium" />,
      onClick: signOut,
    },
  ]

  return (
    <StyledUserMenu>
      <StyledUserInfoRow gutter={[16, 0]}>
        <Col>
          <Avatar userId={profile_id} name={display_name} size="large" />
        </Col>
        <Col>
          <StyledUserName>{display_name}</StyledUserName>
          <Typography.Text>{email}</Typography.Text>
        </Col>
      </StyledUserInfoRow>
      <StyledMenu items={menuItems} />
    </StyledUserMenu>
  )
}

const StyledUserMenu = styled.div`
  width: 320px;
`

const StyledMenu = styled(Menu)`
  margin-bottom: 20px;

  .ant-menu-item {
    font-size: 16px;
    padding-left: 32px;
    display: flex;
    align-items: center;
  }

  .ant-menu-item .ant-menu-item-icon + span {
    margin-left: 0;
  }
`

const StyledUserInfoRow = styled(Row)`
  padding: 20px;
`

const StyledUserName = styled(Typography.Text)`
  display: block;
  font-size: 16px;
`

const StyledLogOutOutlined = styled(LogOutOutlined)`
  margin-right: 4px;
`
