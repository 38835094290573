import {
  CredentialFieldConstraint,
  CredentialFieldIntegerRange,
} from '@swiftctrl/api-client'
import { getIntegerRangeStartEnd } from '../../../components'
import { BitfieldValue, Constraint } from '../utils'
import { CustomValidValuesView } from './CustomValidValuesView'

type FormatIntegerRangeWithTemplateValidValuesProps = {
  templateConstraint: Constraint
  templateBitfieldValue: BitfieldValue | null | undefined
  formatConstraint: CredentialFieldConstraint
  onChange: (constraint: Constraint | null) => void
}

export const FormatIntegerRangeWithTemplateValidValues = ({
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: FormatIntegerRangeWithTemplateValidValuesProps) => {
  const formatIntegerRange =
    formatConstraint.value as CredentialFieldIntegerRange

  const { start, end } = getIntegerRangeStartEnd(formatIntegerRange)

  return (
    <CustomValidValuesView
      templateConstraint={templateConstraint}
      templateBitfieldValue={templateBitfieldValue}
      formatConstraint={formatConstraint}
      min={start}
      max={end}
      constraintFromFormat
      onChange={onChange}
    />
  )
}
