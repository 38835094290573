import { SinkRule } from '@swiftctrl/api-client'

const MAIN_STREAM_ID = 'main'

export const getStreamData = (rules: SinkRule[]) => {
  const streamIdToCountMap = rules.reduce((map, rule) => {
    const { stream_id } = rule

    const count = map.get(stream_id) || 0

    map.set(stream_id, count + 1)

    return map
  }, new Map<string, number>())

  const streamIds = Array.from(streamIdToCountMap.keys())

  streamIds.sort(compare)

  return {
    streamIds,
    streamIdToCountMap,
  }
}

const compare = (a: string, b: string) => {
  if (a === MAIN_STREAM_ID) {
    return -1
  }

  if (b === MAIN_STREAM_ID) {
    return 1
  }

  return a.localeCompare(b)
}
