import { EntityTypes } from '@swiftctrl/api-client'
import { Button, PlusOutlined } from '@swiftctrl/swift-component-library'
import { queryClient } from '../../../../data/queryClient'
import { isCanceledError } from '../../../../data/swiftClient'
import {
  cacheKeys,
  showNoEntityParamErrorNotification,
  useBoolean,
  useHandleUrlParams,
} from '../../../../utils-hooks'
import {
  AddEntityView,
  EntitySelect,
  EpiTypeSelect,
} from '../../../add-entities'
import { EntitiesBrowserFilterLabel } from '../../EntitiesBrowserFilterLabel'
import { EntitiesBrowserSearchInput } from '../../EntitiesBrowserSearchInput'
import {
  EntitiesBrowserTopBarSearchAndFilterGroup,
  StyledEntitiesBrowserTopBar,
  EntitiesBrowserTopBarFilterContainer,
} from '../../EntitiesBrowserTopBar'

type EpisBrowserTopBarProps = {
  displayOrganizationFilter: boolean
  search: string
  organizationId: string
  epiType: string
  onSearch: (value: string) => void
  onOrganizationFilter: (organizationId: string) => void
  onEpiTypeFilter: (epiType: string) => void
}

export const EpisBrowserTopBar = ({
  displayOrganizationFilter,
  search: searchProp,
  organizationId,
  epiType,
  onSearch,
  onOrganizationFilter,
  onEpiTypeFilter,
}: EpisBrowserTopBarProps) => {
  const { updateParams } = useHandleUrlParams()

  const handleOrganizationChange = (entity: EntityTypes | undefined) => {
    const organizationId = entity?.entity_id || ''

    updateParams({ 'organization-id': organizationId })

    onOrganizationFilter(organizationId)
  }

  const handleEpiTypeChange = (epiType: string) => {
    updateParams({ 'epi-type-id': epiType })

    onEpiTypeFilter(epiType)
  }

  const [addEntityViewOpen, openAddEntityView, closeAddEntityView] =
    useBoolean()

  const handleEntityAdded = () => {
    closeAddEntityView()

    queryClient.invalidateQueries({ queryKey: [cacheKeys.epis] })
  }

  return (
    <StyledEntitiesBrowserTopBar $background>
      <EntitiesBrowserTopBarSearchAndFilterGroup>
        <EntitiesBrowserSearchInput
          initialSearch={searchProp}
          onDebouncedChange={onSearch}
        />
        <EntitiesBrowserFilterLabel />
        {displayOrganizationFilter && (
          <EntitiesBrowserTopBarFilterContainer>
            <EntitySelect
              entityType="organization"
              defaultEntityId={organizationId}
              onChange={handleOrganizationChange}
              placeholder="Organization"
              onDefaultEntityError={(error) => {
                if (isCanceledError(error)) {
                  return
                }

                showNoEntityParamErrorNotification('organization-id')

                handleOrganizationChange(undefined)
              }}
              excludeSwiftRoot
            />
          </EntitiesBrowserTopBarFilterContainer>
        )}
        <EntitiesBrowserTopBarFilterContainer>
          <EpiTypeSelect
            key={epiType}
            size="middle"
            value={epiType}
            onChange={handleEpiTypeChange}
            placeholder="EPI type"
            onNoEpiTypeForValue={() => {
              showNoEntityParamErrorNotification('epi-type-id')

              handleEpiTypeChange('')
            }}
          />
        </EntitiesBrowserTopBarFilterContainer>
      </EntitiesBrowserTopBarSearchAndFilterGroup>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        size="middle"
        onClick={openAddEntityView}
      >
        Add EPI
      </Button>
      <AddEntityView
        open={addEntityViewOpen}
        entityType="epi"
        onEntityAdded={handleEntityAdded}
        onClose={closeAddEntityView}
        overseerId={organizationId}
      />
    </StyledEntitiesBrowserTopBar>
  )
}
