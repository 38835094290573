import {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

type EnabledDisplayProps = {
  enabled: boolean
}

export const EnabledDisplay = ({ enabled }: EnabledDisplayProps) =>
  enabled ? <StyledCheckIcon /> : <StyledCrossIcon />

const StyledCheckIcon = styled(CheckCircleOutlined)`
  color: #52c41a;
`

const StyledCrossIcon = styled(CloseCircleOutlined)`
  color: #f5222d;
`
