import { InfoCircleOutlined } from '@ant-design/icons'
import {
  CredentialTemplateFieldBitfieldValue,
  CredentialTemplateFieldConstraint,
  CredentialTemplateFieldTypes,
} from '@swiftctrl/api-client'
import { Form } from '@swiftctrl/swift-component-library'
import { FormItemProps } from 'antd'
import { styled } from 'styled-components'
import { buildFakeLocalDateFromISODate } from '../../utils-hooks'

export type CredentialTemplateFieldComponentProps = {
  field: CredentialTemplateFieldTypes
}

type CredentialTemplateFieldFormItemProps = Omit<
  FormItemProps,
  'label' | 'requiredMark' | 'required' | 'tooltip'
> & {
  field: CredentialTemplateFieldTypes
}

export const CredentialTemplateFieldFormItem = ({
  field,
  children,
}: CredentialTemplateFieldFormItemProps) => {
  const { display_name, is_optional, tooltip } = field

  return (
    <StyledFormItem
      label={display_name}
      requiredMark="optional"
      required={!is_optional}
      tooltip={tooltip && { title: tooltip, icon: <InfoCircleOutlined /> }}
    >
      {children}
    </StyledFormItem>
  )
}

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label > label .ant-form-item-tooltip {
    cursor: default;
  }
`

export const hasValidValues = (field: CredentialTemplateFieldTypes) => {
  return Boolean(field.constraint?.valid_values)
}

export const sortCredentialTemplateFieldsByRank = (
  a: CredentialTemplateFieldTypes,
  b: CredentialTemplateFieldTypes,
) => a.rank - b.rank

export const getCredentialTemplateFieldNumberInputMin = (
  constraint: CredentialTemplateFieldConstraint | null,
  bitfieldValue: CredentialTemplateFieldBitfieldValue | null,
) => {
  if (constraint?.integer_range) {
    return constraint.integer_range.start
  }

  if (bitfieldValue) {
    return getBitfieldValueMin(bitfieldValue)
  }

  return undefined
}

export const getBitfieldValueMin = (
  bitfieldValue: CredentialTemplateFieldBitfieldValue | null,
) => {
  if (bitfieldValue) {
    return 0
  }

  return undefined
}

export const getCredentialTemplateFieldNumberInputMax = (
  constraint: CredentialTemplateFieldConstraint | null,
  bitfieldValue: CredentialTemplateFieldBitfieldValue | null,
) => {
  if (constraint?.integer_range) {
    return constraint.integer_range.end
  }

  if (bitfieldValue) {
    return getBitfieldValueMax(bitfieldValue)
  }

  return undefined
}

export const getBitfieldValueMax = (
  bitfieldValue: CredentialTemplateFieldBitfieldValue | null,
) => {
  if (!bitfieldValue) {
    return undefined
  }

  const { start_bit, end_bit } = bitfieldValue

  return getBitfieldValueDerivedMax(start_bit, end_bit)
}

export const getBitfieldValueDerivedMax = (
  startBit: number,
  endBit: number,
) => {
  const bitfieldLength = endBit - startBit + 1

  const max = BigInt(2) ** BigInt(bitfieldLength) - BigInt(1)

  return max
}

export const getCredentialTemplateFieldDateTimePickerDisabledDate = (
  constraint: CredentialTemplateFieldConstraint | null,
) => {
  const dateRangeStart = buildFakeLocalDateFromISODate(
    constraint?.date_range?.start,
  )

  const dateRangeEnd = buildFakeLocalDateFromISODate(
    constraint?.date_range?.end,
  )

  const disabledDate = (date: Date) => {
    if (!dateRangeStart || !dateRangeEnd) {
      return false
    }

    const roundedDate = new Date(date)

    roundedDate.setMilliseconds(0)

    return roundedDate < dateRangeStart || roundedDate > dateRangeEnd
  }

  return disabledDate
}
