import { RolesTypes } from '@swiftctrl/api-client'
import { TabsProps } from '@swiftctrl/swift-component-library'
import {
  EntityAncestryView,
  EntityTimestampsDisplay,
  ReadScreen,
  StyledScreen,
} from '../components'
import { getEntityLabel } from '../config/labels'
import {
  ROLES_SCREEN_PATH,
  ROLE_SCREEN_ANCESTRY_TAB,
  ROLE_SCREEN_ARCHETYPES_TAB,
  ROLE_SCREEN_PERMISSIONS_TAB,
  ROLE_SCREEN_PROFILES_TAB,
  ROLE_SCREEN_TARGETS_TAB,
} from '../config/paths'
import { swiftClientWithoutErrorHandling } from '../data/swiftClient'
import {
  RoleArchetypesView,
  RolePermissionsView,
  RoleProfilesView,
  RoleScreenHeader,
  RoleTargetsView,
} from '../screen-components/role-screen'
import {
  adaptSDKRead,
  archetypePluralLabelConfig,
  cacheKeys,
  getPluralLabel,
  permissionPluralLabelConfig,
  targetPluralLabelConfig,
} from '../utils-hooks'

export const roleScreenTabs = [
  ROLE_SCREEN_PROFILES_TAB,
  ROLE_SCREEN_PERMISSIONS_TAB,
  ROLE_SCREEN_ARCHETYPES_TAB,
  ROLE_SCREEN_TARGETS_TAB,
  ROLE_SCREEN_ANCESTRY_TAB,
] as const

type RoleScreenTab = typeof roleScreenTabs[number]

type RoleScreenProps = {
  tab?: RoleScreenTab
}

export const RoleScreen = ({ tab }: RoleScreenProps) => (
  <ReadScreen<RolesTypes>
    paramUuidProps={{
      paramKey: 'roleId',
      entityType: 'role',
      redirectPath: `/${ROLES_SCREEN_PATH}`,
    }}
    readRequest={adaptSDKRead(swiftClientWithoutErrorHandling.role)}
    cacheKey={cacheKeys.role}
    headerContentFactory={(role) => <RoleScreenHeader role={role} />}
    entityType="role"
    contentFactory={{
      tab,
      tabs: roleScreenTabs,
      tabPanesFactory,
      tabBarExtraContent: (role) => (
        <EntityTimestampsDisplay
          created_at={role.created_at!}
          updated_at={role.updated_at!}
        />
      ),
    }}
  />
)

const tabPanesFactory = (role: RolesTypes) => {
  const profilesTabLabel = getEntityLabel({
    entityType: 'profile',
    singular: false,
  })

  const permissionsTabLabel = getPluralLabel({
    labels: permissionPluralLabelConfig,
    singular: false,
  })

  const targetsTabLabel = getPluralLabel({
    labels: targetPluralLabelConfig,
    singular: false,
  })

  const archetypesTabLabel = getPluralLabel({
    labels: archetypePluralLabelConfig,
    singular: false,
  })

  const tabs: TabsProps['items'] = [
    {
      key: ROLE_SCREEN_PROFILES_TAB,
      label: profilesTabLabel,
      children: (
        <StyledScreen>
          <RoleProfilesView role={role} />
        </StyledScreen>
      ),
    },
    {
      key: ROLE_SCREEN_PERMISSIONS_TAB,
      label: permissionsTabLabel,
      children: <RolePermissionsView role={role} />,
    },
    {
      key: ROLE_SCREEN_TARGETS_TAB,
      label: targetsTabLabel,
      children: <RoleTargetsView role={role} />,
    },
    {
      key: ROLE_SCREEN_ARCHETYPES_TAB,
      label: archetypesTabLabel,
      children: <RoleArchetypesView role={role} />,
    },
    {
      key: ROLE_SCREEN_ANCESTRY_TAB,
      label: 'Ancestry',
      children: (
        <EntityAncestryView entity={{ ...role, entity_id: role.role_id }} />
      ),
    },
  ]

  return tabs
}
