import { Layout } from '@swiftctrl/swift-component-library'
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import { styled } from 'styled-components'
import { CopyableDisplay } from './CopyableDisplay'

type ContactInfoProps = {
  email: string
  phone: string
}

export const ContactInfo = ({ email, phone }: ContactInfoProps) => {
  return (
    <Layout>
      <StyledLayout horizontal alignItems="center">
        <StyledEmailIcon />
        {email ? <CopyableDisplay label={email} /> : '-'}
      </StyledLayout>
      <StyledLayout horizontal alignItems="center">
        <StyledPhoneIcon />
        {phone ? <CopyableDisplay label={phone} /> : '-'}
      </StyledLayout>
    </Layout>
  )
}

const StyledLayout = styled(Layout)`
  gap: ${({ theme }) => theme.spacing.xSmall};
`

const StyledEmailIcon = styled(AiOutlineMail)`
  color: ${({ theme }) => theme.designTokens.colors.content.secondary.accent};
`

const StyledPhoneIcon = styled(AiOutlinePhone)`
  color: ${({ theme }) => theme.designTokens.colors.content.secondary.accent};
`
