import { PropsWithChildren } from 'react'
import { useParams } from 'react-router-dom'
import { InvalidIdResult } from '../read-entity'
import { isValidUuid } from '../../utils-hooks'
import { getEntityLabel } from '../../config/labels'
import { EntityType } from '../../data/models'

type ParamUuidGuardViewProps = PropsWithChildren<{
  /**
   * If this is an array, each entry's `paramKey` will be validated
   * in the order it is set up in the array.
   *
   * Be sure to set them up in the intended order!
   */
  paramUuidProps: ParamUuidProps | ParamUuidProps[]
}>

export type ParamUuidProps = {
  paramKey: string
  entityType: EntityType
  redirectPath: string
  /**
   * If omitted, the redirect button label will be "Go to X screen",
   * where X is the plural form of the `entityType` prop.
   *
   * E.g. 'role' -> "Go to roles screen"
   */
  redirectButtonLabel?: string
}

export const ParamUuidGuardView = ({
  paramUuidProps,
  children,
}: ParamUuidGuardViewProps) => {
  const normalizedProps = Array.isArray(paramUuidProps)
    ? paramUuidProps
    : [paramUuidProps]

  const params = useParams()

  for (let i = 0; i < normalizedProps.length; i++) {
    const { paramKey, entityType, redirectButtonLabel, redirectPath } =
      normalizedProps[i]

    const id = params[paramKey]

    const label =
      redirectButtonLabel ||
      `Go to ${getEntityLabel({
        entityType,
        capitalize: false,
        singular: false,
      })} screen`

    if (!isValidUuid(id)) {
      return (
        <InvalidIdResult
          entityType={entityType}
          label={label}
          redirectPath={redirectPath}
        />
      )
    }
  }

  return <>{children}</>
}
