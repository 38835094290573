export const providerFieldLabels = {
  providerType: 'Provider type',
  providerTypeLabels: {
    email_password: 'Email/password',
    openid_okta: 'Okta',
    single_use_token: 'Single-use token',
    refresh_token: 'Refresh token',
    proxy_login: 'Proxy login',
    openid: 'OpenID',
  },
  maxLoginAttempts: 'Max login attempts',
  profileSessionTimeToLive: 'Profile session time to live (seconds)',
  authTokenTimeToLive: 'Auth token time to live (seconds)',
  issuer: 'Issuer',
  audience: 'Audience',
  claimIdentifier: 'Claim identifier',
  ipRestriction: 'IP restriction',
  authorizedProfileId: 'Authorized profile',
  caseSensitiveIdentifier: 'Case-sensitive identifier',
}
