import { CredentialFieldType } from '@swiftctrl/api-client'
import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { CredentialTemplateFieldIntegerExpandButton } from './CredentialTemplateFieldIntegerExpandButton'
import { FieldNameTooltip } from './FieldNameTooltip'
import { Input } from './Input'
import { ValidatedFormItem } from './ValidatedFormItem'

type CredentialTemplateFieldNameViewProps = {
  fieldId: string
  fieldName: string
  displayName: string
  fieldType: CredentialFieldType
  formatHasValidValues: boolean
  expandedFieldIds: string[]
  onDisplayNameChange: (value: string) => void
  expandFieldId: (id: string) => void
  collapseFieldId: (id: string) => void
}

export const CredentialTemplateFieldNameView = ({
  fieldId,
  fieldName,
  displayName,
  fieldType,
  formatHasValidValues,
  expandedFieldIds,
  onDisplayNameChange,
  expandFieldId,
  collapseFieldId,
}: CredentialTemplateFieldNameViewProps) => {
  const isValid = displayName.length > 0

  return (
    <Layout>
      <StyledLayout horizontal alignItems="center">
        <ValidatedFormItem
          isValid={isValid}
          validationMessage="Field Name is required"
        >
          <Input
            value={displayName}
            onChange={onDisplayNameChange}
            allowClear={false}
            htmlSize={displayName.length}
          />
        </ValidatedFormItem>
        {displayName !== fieldName && (
          <FieldNameTooltip
            fieldName={fieldName}
            onResetClick={() => onDisplayNameChange(fieldName)}
          />
        )}
        {fieldType === 'INTEGER' && (
          <CredentialTemplateFieldIntegerExpandButton
            fieldId={fieldId}
            expandedFieldIds={expandedFieldIds}
            formatHasValidValues={formatHasValidValues}
            expandFieldId={expandFieldId}
            collapseFieldId={collapseFieldId}
          />
        )}
      </StyledLayout>
      <Typography.Text type="secondary">{fieldType}</Typography.Text>
    </Layout>
  )
}

const StyledLayout = styled(Layout)`
  gap: ${({ theme }) => theme.spacing.xSmall};
`
