import { AxiosRequestConfig } from '@swiftctrl/api-client'

export const buildEntityBrowserNextPageParam = ({
  data,
  config,
}: {
  data: any[]
  config: AxiosRequestConfig<any>
}) => {
  const offset = config?.params?.offset

  let limit = config?.params?.limit

  if (limit === undefined) {
    console.warn(
      'config.params is missing limit. Defaulting it to 50, but this may cause non-sequential data to be fetched. Be sure to set your request limit!',
    )

    limit = 50
  }

  if (data.length !== limit) {
    return undefined
  }

  if (offset === undefined || !limit) {
    return undefined
  }

  return offset + limit
}
