import { EntityMirrorAddTypes } from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import {
  BackendError,
  swiftClientWithoutErrorHandling,
} from '../../../../data/swiftClient'
import { AddEntityMirrorEntity, AddEntityMirrorFormData } from './models'

export const useAddEntityMirror = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void
  onError: (error: BackendError) => void
}) =>
  useMutation({
    mutationFn: ({
      values,
      entity,
    }: {
      values: AddEntityMirrorFormData
      entity: AddEntityMirrorEntity
    }) => {
      const {
        sink,
        setEntityAs,
        entity_id,
        entity_overseer_id,
        mirror_id,
        mirror_overseer_id,
      } = values

      const {
        entity_id: currentEntityId,
        overseer_id: currentEntityOverseerId,
      } = entity

      const payload: EntityMirrorAddTypes = {
        sink_id: sink!.entity_id!,
        entity_id: setEntityAs === 'entity' ? currentEntityId! : entity_id!,
        mirror_id: setEntityAs === 'mirror' ? currentEntityId! : mirror_id!,
        /**
         * Core has a minor bug where it expects the entity_overseer_id
         * and mirror_overseer_id.
         *
         * Will be fixed in this ticket:
         * https://app.clickup.com/t/2352446/CORE-3262
         *
         * After that ticket is done, we can remove those fields from here.
         */
        // @ts-ignore
        entity_overseer_id:
          setEntityAs === 'entity'
            ? currentEntityOverseerId
            : entity_overseer_id!,
        // @ts-ignore
        mirror_overseer_id:
          setEntityAs === 'mirror'
            ? currentEntityOverseerId
            : mirror_overseer_id!,
      }

      return swiftClientWithoutErrorHandling.entityMirror.add(payload)
    },
    onSuccess,
    onError,
  })
