import { PersonTypes, RolesTypes } from '@swiftctrl/api-client'
import {
  ManageAssignedDataModal,
  RemoteFilterBar,
} from '../../../../components'
import {
  buildSetFromArrayField,
  useCheckBeforeClose,
} from '../../../../utils-hooks'
import { SelectedRolesList } from './SelectedRolesList'
import { CandidateRolesList } from './CandidateRolesList'
import { InfoBar } from './InfoBar'
import { useAssignPersonRolesLogic } from './useAssignPersonRolesLogic'

type ManagePersonRolesModalProps = {
  person: PersonTypes
  open: boolean
  onClose: () => void
}

export const ManagePersonRolesModal = ({
  person,
  open,
  onClose,
}: ManagePersonRolesModalProps) => {
  const {
    candidateRoles,
    fetchMoreCandidateRoles,
    isFetchingCandidateRoles,
    isFetchingCandidateRolesFirstTime,
    selectedRoles,
    setSearch,
    addRole,
    removeRole,
    removeAllRoles,
    isDirty,
    reset,
    save,
    isSaving,
  } = useAssignPersonRolesLogic({
    person,
    open,
    onClose,
  })

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending: isSaving,
    isDirty,
    reset,
    close: onClose,
  })

  const selectedRoleIds = buildSetFromArrayField<Partial<RolesTypes>, string>(
    selectedRoles,
    'role_id',
  )

  return (
    <ManageAssignedDataModal
      open={open}
      onClose={checkBeforeClose}
      title="Assign Roles"
      description={`Select all Roles you want to Assign to ${person.primary_profile.display_name}`}
      filtersBar={
        <RemoteFilterBar onDebouncedSearch={setSearch} isSaving={isSaving} />
      }
      candidatesDataTable={
        <CandidateRolesList
          data={candidateRoles}
          isFetching={isFetchingCandidateRoles}
          isFetchingFirstTime={isFetchingCandidateRolesFirstTime}
          fetchNextPage={fetchMoreCandidateRoles}
          selectedRoleIds={selectedRoleIds}
          onAddRole={addRole}
          isSaving={isSaving}
        />
      }
      infoBar={
        <InfoBar
          selectedRolesCount={selectedRoles.length}
          onRemoveAllClick={removeAllRoles}
        />
      }
      assignedDataTable={
        <SelectedRolesList
          data={selectedRoles}
          onRemoveRole={removeRole}
          isSaving={isSaving}
        />
      }
      hasUnsavedChanges={isDirty}
      onSave={save}
      isSaving={isSaving}
    />
  )
}
