const MINIMUM_LENGTH = 3

type ReturnType = {
  isValidSearch: boolean
  validatedSearch: string
}

export const validateBrowseSearch = (search: string): ReturnType => {
  if (search.length === 0) {
    return { isValidSearch: true, validatedSearch: search }
  }

  const trimmedSearch = search.trim()

  if (trimmedSearch.length >= MINIMUM_LENGTH) {
    return {
      isValidSearch: true,
      validatedSearch: trimmedSearch,
    }
  }

  return {
    isValidSearch: false,
    validatedSearch: '',
  }
}
