import { EntityTypes } from '@swiftctrl/api-client'
import { useQuery } from '@tanstack/react-query'
import { getReadRequest, hasReadRequest } from '../config/readEntityConfig'

export const useReadEntityDetails = (entity: EntityTypes | undefined) => {
  const { data, isLoading } = useQuery({
    queryKey: [entity?.entity_type_id, entity?.entity_id],
    queryFn: async ({ signal }) => {
      if (entity) {
        const { entity_id, entity_type_id } = entity

        const readRequest = getReadRequest(entity_type_id)

        const { data } = await readRequest.at(entity_id).read({ signal })

        return data
      }
    },
    enabled: entity && hasReadRequest(entity.entity_type_id),
  })

  return {
    entityDetails: data,
    isReadingEntityDetails: isLoading,
  }
}
