import { EntityTypesIds } from '@swiftctrl/api-client'
import { Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import styled from 'styled-components'
import { EntityTypeTag } from '../../../../components'
import { TARGET_ENTITY_TYPES } from './models'

type TargetEntityTypeFilterProps = {
  onChange: (entityType: EntityTypesIds | undefined) => void
}

export const TargetEntityTypeFilter = ({
  onChange,
}: TargetEntityTypeFilterProps) => {
  const options = buildOptions()

  return (
    <StyledSelect
      placeholder="All entity types"
      options={options}
      allowClear={true}
      onChange={onChange}
      size="large"
    />
  )
}

const buildOptions = () => {
  const options: DefaultOptionType[] = TARGET_ENTITY_TYPES.map(
    (entityType) => ({
      value: entityType,
      label: <EntityTypeTag entityType={entityType} />,
    }),
  )

  return options
}

const StyledSelect: typeof Select = styled(Select)`
  max-width: 170px;
`
