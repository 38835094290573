import { FlowAnalysisGraph } from '@ant-design/graphs'
import {
  OrganizationTypes,
  SinkTypes,
  SourceTypes,
} from '@swiftctrl/api-client'
import { PreLoader } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import styled from 'styled-components'
import { useGetValidUuidParam, useHandleUrlParams } from '../../../utils-hooks'
import { buildChartConfig } from './buildChartConfig'
import { ChartKey } from './ChartKey'
import { EntityDetailsView } from './EntityDetailsView'
import { Entity, EntityNode } from './models'
import { ChartData, useGetChartData } from './useGetChartData'

interface SourcesAndSinksChartProps {
  organization: OrganizationTypes
}

export const SourcesAndSinksChart = ({
  organization,
}: SourcesAndSinksChartProps) => {
  const data = useGetChartData(organization)

  if (!data) {
    return <PreLoader centered />
  }

  return <Content {...data} />
}

const Content = ({ idToEntityMap, chartData }: ChartData) => {
  const { updateParams, cleanParams } = useHandleUrlParams()

  const selectedEntityIdParam = useGetValidUuidParam({
    paramKey: 'entity-id',
    validator: (param) => validateSelectedEntityId(param, idToEntityMap),
    validatorErrorNotificationType: 'noEntity',
  })

  const [selectedEntityId, setSelectedEntityId] = useState(
    selectedEntityIdParam,
  )

  const selectedEntity = selectedEntityId
    ? idToEntityMap.get(selectedEntityId)
    : undefined

  const updateSelectedEntity = (entityId: string) => {
    const entity = idToEntityMap.get(entityId)

    if (!entity) {
      throw new Error(`could not find entity for ID ${entityId}`)
    }

    setSelectedEntityId(entityId)

    updateParams({ 'entity-id': entityId })
  }

  const clearSelectedEntity = () => {
    setSelectedEntityId(undefined)

    cleanParams(['entity-id'])
  }

  const config = buildChartConfig(
    chartData,
    selectedEntityId,
    updateSelectedEntity,
    clearSelectedEntity,
  )

  return (
    <StyledContainer>
      <FlowAnalysisGraph {...config} />
      <ChartKey />
      {selectedEntity && <EntityDetailsView entity={selectedEntity} />}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  height: 70vh;
`

const validateSelectedEntityId = (
  param: string,
  idToEntityMap: Map<string, Entity>,
) => {
  const entity = idToEntityMap.get(param)

  if (!entity) {
    return ''
  }

  switch (entity.entity_type_id) {
    case 'source':
      return (entity as Partial<SourceTypes>).source_id

    case 'sink':
      return (entity as Partial<SinkTypes>).sink_id

    case 'organization':
      return (entity as OrganizationTypes).organization_id

    default:
      return (entity as EntityNode).entity_id
  }
}
