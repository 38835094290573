import {
  ProfileBrowseTypes,
  ProfilesTypes,
  RolesTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { ColumnsType, Layout } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import {
  EntitiesTableBrowser,
  TABLE_DATA_PER_FETCH,
} from '../../../../components'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
} from '../../../../states'
import {
  adaptSDKBrowseRequest,
  isValidUuid,
  useBoolean,
  useGetOverseerOrganization,
} from '../../../../utils-hooks'
import { ManageSubdataButton } from '../../ManageSubdataButton'
import { ManageProfilesRoleModal } from '../manage'
import { buildRoleProfilesCacheKey } from '../utils'
import { RoleProfileActionsDropdown } from './RoleProfileActionsDropdown'
import { RoleProfileView } from './RoleProfileView'

type RoleProfilesViewProps = {
  role: RolesTypes
}

export const RoleProfilesView = ({ role }: RoleProfilesViewProps) => {
  const { role_id } = role

  const [
    isManageProfilesModalOpen,
    openManageProfileModal,
    closeManageProfileModal,
  ] = useBoolean()

  const browse = buildBrowse(role_id)

  const columns = buildColumns(role_id)

  const { data: overseerOrganization, isPending } = useGetOverseerOrganization({
    entity: role,
  })

  return (
    <>
      <StyledLayout horizontal justifyContent="end">
        <ManageSubdataButton
          label="Manage Profiles"
          onClick={openManageProfileModal}
          loading={isPending}
        />
      </StyledLayout>
      <BrowseEntitiesContextProvider
        dataPerFetch={TABLE_DATA_PER_FETCH}
        browse={browse}
        dataKey="profile_id"
        entityType="profile"
        capitalizeTotalLabel
        searchable
      >
        <EntitiesTableBrowser columns={columns} />
      </BrowseEntitiesContextProvider>
      {overseerOrganization && (
        <ManageProfilesRoleModal
          open={isManageProfilesModalOpen}
          onClose={closeManageProfileModal}
          role={role}
          roleOrganizationId={overseerOrganization.entity_id}
        />
      )}
    </>
  )
}

const buildBrowse = (
  roleId: string,
): BrowseEntitiesConfig<Partial<ProfilesTypes>> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClient.role.at(roleId).profiles.browse,
    select: [
      'profile_id',
      'display_name',
      'overseer_name',
      'overseer_id',
      'email',
      'phone_primary',
    ],
    getFilters: (search: string) => {
      if (!isValidUuid(search)) {
        return undefined
      }

      const q = getQueryHandler<ProfileBrowseTypes>()

      return [
        q('WHERE', 'profile_id', 'EQ', search),
        q('OR', 'overseer_id', 'EQ', search),
      ]
    },
    getSearch: (search: string) => {
      if (isValidUuid(search)) {
        return undefined
      }

      return search
    },
  }),
  cacheKey: buildRoleProfilesCacheKey(roleId),
})

const buildColumns = (roleId: string): ColumnsType<Partial<ProfilesTypes>> => [
  {
    title: 'Profile',
    render: (_, profile) => (
      <RoleProfileView
        profile={profile}
        actionContainer={
          <RoleProfileActionsDropdown roleId={roleId} profile={profile} />
        }
      />
    ),
  },
]

const StyledLayout = styled(Layout)`
  padding-bottom: 2px;
`
