import { EntityMirrorTypes } from '@swiftctrl/api-client'
import {
  Button,
  DeleteOutlined,
  Modal,
  Typography,
} from '@swiftctrl/swift-component-library'
import { useMutation } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import { useBrowseEntitiesContext } from '../../../../states'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../utils-hooks'

type DeleteEntityMirrorButtonProps = {
  entityMirror: EntityMirrorTypes
  idToNameMap: Map<string, string>
}

export const DeleteEntityMirrorButton = ({
  entityMirror,
  idToNameMap,
}: DeleteEntityMirrorButtonProps) => {
  const { entity_id, mirror_id, sink_id } = entityMirror

  const { invalidateData } = useBrowseEntitiesContext()

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      swiftClientWithoutErrorHandling.entityMirror
        .at({
          entityId: entity_id,
          mirrorId: mirror_id,
          sinkId: sink_id,
        })
        .delete(),
    onSuccess: () => {
      invalidateData()

      showSuccessNotification('Entity mirror deleted')
    },
    onError: (error) => {
      showErrorNotification('Error while deleting entity mirror', error)
    },
  })

  const handleDeleteClick = () => {
    const entityDisplay = buildDisplay(entity_id, idToNameMap)

    const mirrorDisplay = buildDisplay(mirror_id, idToNameMap)

    Modal.confirm({
      title: `Delete Entity mirror?`,
      content: (
        <>
          Are you sure you want to delete the entity mirror between{' '}
          {entityDisplay} and {mirrorDisplay}?
        </>
      ),
      okText: 'Delete',
      okButtonProps: {
        danger: true,
      },
      onOk: () => mutate(),
      centered: true,
    })
  }

  return (
    <Button
      type="text"
      danger
      icon={<DeleteOutlined />}
      onClick={handleDeleteClick}
      loading={isPending}
    />
  )
}

const buildDisplay = (id: string, idToNameMap: Map<string, string>) => {
  const name = idToNameMap.get(id)

  if (name) {
    return <b>{name}</b>
  }

  return <Typography.Text code>{id}</Typography.Text>
}
