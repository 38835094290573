import { Layout, WarningOutlined } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { Status } from '../../components'

interface InvertStatusProps {
  isInverted: boolean
}

export const InvertStatus = ({ isInverted }: InvertStatusProps) => (
  <Layout horizontal alignItems="center" spacing="small">
    {isInverted && <StyledWarningOutlined />}
    <Status status={isInverted ? 'Completed' : 'Required'}>Inverted</Status>
  </Layout>
)

const StyledWarningOutlined = styled(WarningOutlined)`
  color: #fa8c16;
`
