import { useQuery } from '@tanstack/react-query'
import { queryClient } from '../../data/queryClient'
import { swiftClient } from '../../data/swiftClient'
import { cacheKeys } from '../cacheKeysUtils'

export const useReadProviderProfile = (id: string, enabled = true) =>
  useQuery({
    queryKey: getQueryKey(id),
    queryFn: () => swiftClient.providerProfile.at(id).read(),
    enabled,
  })

const getQueryKey = (id: string) => [cacheKeys.provider_profile, id]

export const invalidateReadProviderProfileQuery = (id: string) => {
  queryClient.invalidateQueries({ queryKey: getQueryKey(id) })
}
