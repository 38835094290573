import { ProviderTypes } from '@swiftctrl/api-client'
import { EntityType } from '../../data/models'
import { GenericEditEntityDrawer } from './GenericEditEntityDrawer'
import { ReadEditProviderView } from './provider'

type EditEntityDelegatorProps<T> = {
  open: boolean
  entityType: EntityType
  entity: T
  onEdited: () => void
  onClose: () => void
}

export const EditEntityDelegator = <T extends object>({
  open,
  entityType,
  entity,
  onEdited,
  onClose,
}: EditEntityDelegatorProps<T>) => {
  switch (entityType) {
    case 'provider':
      return (
        <ReadEditProviderView
          open={open}
          provider={entity as Partial<ProviderTypes>}
          onEdited={onEdited}
          onClose={onClose}
        />
      )

    default:
      return (
        <GenericEditEntityDrawer
          open={open}
          entity={entity}
          entityType={entityType}
          onEdited={onEdited}
          onClose={onClose}
        />
      )
  }
}
