import { ProfilesTypes } from '@swiftctrl/api-client'
import { Modal } from '@swiftctrl/swift-component-library'
import {
  ActionsDropdown,
  ActionsDropdownItem,
  invalidateReadScreenQuery,
} from '../../../../components'
import { queryClient } from '../../../../data/queryClient'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import {
  buildRoleProfilesInfiniteCacheKey,
  cacheKeys,
  showErrorNotification,
  showSuccessNotification,
} from '../../../../utils-hooks'
import { buildRoleProfilesCacheKey } from '../utils'

type RoleProfileActionsDropdownProps = {
  roleId: string
  profile: Partial<ProfilesTypes>
}

export const RoleProfileActionsDropdown = ({
  roleId,
  profile,
}: RoleProfileActionsDropdownProps) => {
  const items: ActionsDropdownItem[] = [
    {
      key: 'remove',
      label: 'Remove',
      danger: true,
      onClick: confirmProfileRemoval(roleId, profile),
    },
  ]

  return <ActionsDropdown items={items} />
}

const confirmProfileRemoval = (
  roleId: string,
  profile: Partial<ProfilesTypes>,
) => {
  const { display_name, profile_id } = profile

  const onClick = () => {
    Modal.confirm({
      title: 'Remove Profile',
      content: `Are you sure you want to remove "${display_name}"?`,
      okText: 'Remove',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: removeProfile(roleId, profile_id!, display_name!),
      centered: true,
    })
  }

  return onClick
}

const removeProfile = (
  roleId: string,
  profileId: string,
  profileName: string,
) => {
  const onOk = async () => {
    try {
      await swiftClientWithoutErrorHandling.profile
        .at(profileId)
        .role.at(roleId)
        .delete()

      invalidateReadScreenQuery(cacheKeys.role, roleId)

      queryClient.invalidateQueries({
        queryKey: buildRoleProfilesCacheKey(roleId),
      })

      queryClient.resetQueries({
        queryKey: buildRoleProfilesInfiniteCacheKey(roleId),
      })

      showSuccessNotification(`Profile "${profileName}" removed`)
    } catch (error) {
      showErrorNotification('Error while removing profile', error)
    }
  }

  return onOk
}
