import { ProviderTypes } from '@swiftctrl/api-client'
import { EditEntityDrawer } from '../EditEntityDrawer'
import { useEditProviderLogic } from './useEditProviderLogic'

type EditProviderViewProps = {
  open: boolean
  provider: ProviderTypes
  onEdited: () => void
  onClose: () => void
  baseIdIsPreset: boolean
}

export const EditProviderView = ({
  open,
  provider,
  onEdited,
  onClose,
  baseIdIsPreset,
}: EditProviderViewProps) => {
  const props = useEditProviderLogic({
    provider,
    onEdited,
    onClose,
    baseIdIsPreset,
  })

  return <EditEntityDrawer open={open} entityType="provider" {...props} />
}
