import { useSetAppTitle } from '../utils-hooks'

type HTMLTitleProps = {
  value: string | undefined
}

export const HTMLTitle = ({ value }: HTMLTitleProps) => {
  useSetAppTitle(value)

  return null
}
