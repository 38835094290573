import {
  CredentialFieldBitfieldValue,
  CredentialFieldConstraint,
} from '@swiftctrl/api-client'
import { getBitfieldValueMax, getBitfieldValueMin } from '../../../components'
import { BitfieldValue, Constraint } from '../utils'
import { CustomValidValuesView } from './CustomValidValuesView'
import {
  getBitfieldValueMax as getTemplateBitfieldValueMax,
  getBitfieldValueMin as getTemplateBitfieldValueMin,
} from './utils'

type FormatBitfieldValueWithTemplateValidValuesProps = {
  templateConstraint: Constraint
  templateBitfieldValue: BitfieldValue | null | undefined
  formatConstraint: CredentialFieldConstraint
  onChange: (constraint: Constraint | null) => void
}

export const FormatBitfieldValueWithTemplateValidValues = ({
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: FormatBitfieldValueWithTemplateValidValuesProps) => {
  const formatBitfieldValue =
    formatConstraint.value as CredentialFieldBitfieldValue

  const min = templateBitfieldValue
    ? getTemplateBitfieldValueMin(templateBitfieldValue)
    : getBitfieldValueMin(formatBitfieldValue)

  const max = templateBitfieldValue
    ? getTemplateBitfieldValueMax(templateBitfieldValue)
    : getBitfieldValueMax(formatBitfieldValue)

  return (
    <CustomValidValuesView
      templateConstraint={templateConstraint}
      templateBitfieldValue={templateBitfieldValue}
      formatConstraint={formatConstraint}
      min={min}
      max={max}
      constraintFromFormat
      onChange={onChange}
    />
  )
}
