import { ProfileBrowseTypes } from '@swiftctrl/api-client'
import { useInfiniteQuery } from '@tanstack/react-query'
import { TABLE_DATA_PER_FETCH } from '../../components'
import { swiftClient } from '../../data/swiftClient'
import { buildEntityBrowserNextPageParam } from '../buildEntityBrowserNextPageParam'
import { cacheKeys } from '../cacheKeysUtils'

interface useInfiniteBrowseProfilesAtRoleParams {
  baseId: string
  roleId: string
  select: ProfileBrowseTypes[]
  enabled: boolean
  search?: string
  totalCountInHeader?: boolean
}

export const useInfiniteBrowseProfilesAtRole = ({
  roleId,
  select,
  search,
  baseId,
  enabled,
  totalCountInHeader,
}: useInfiniteBrowseProfilesAtRoleParams) => {
  return useInfiniteQuery({
    queryKey: [...buildRoleProfilesInfiniteCacheKey(roleId), baseId, search],
    queryFn: ({ pageParam, signal }) =>
      swiftClient.role.at(roleId).profiles.browse(
        {
          baseId,
          select,
          search,
          limit: TABLE_DATA_PER_FETCH,
          offset: pageParam,
          totalCountInHeader,
        },
        {
          signal,
        },
      ),
    enabled,
    initialPageParam: 0,
    getNextPageParam: buildEntityBrowserNextPageParam,
  })
}

export const buildRoleProfilesInfiniteCacheKey = (roleId: string) => {
  return [roleId, cacheKeys.role_profiles_infinite]
}
