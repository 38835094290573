import { Swift, Typography } from '@swiftctrl/swift-component-library'

type TextDisplayProps = {
  value: any
  copyable?: boolean
}

export const TextDisplay: Swift.FC<TextDisplayProps> = ({
  value,
  copyable,
}) => (
  <Typography.Text code copyable={copyable}>
    {value}
  </Typography.Text>
)
