export const measureCanvasTextWidth = (
  text: string,
  fontSize = 12,
  fontWeight = 'normal',
  fontFamily = 'Arial, sans-serif',
) => {
  const canvas = document.querySelector('canvas')

  if (!canvas) {
    console.warn('Trying to measure text without canvas. Returning 0')

    return 0
  }

  const context = canvas.getContext('2d')

  if (!context) {
    console.warn('Trying to measure text without 2D context. Returning 0')

    return 0
  }

  context.font = `${fontSize}px ${fontWeight} ${fontFamily}`

  const { width } = context.measureText(text)

  return width
}
