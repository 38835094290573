import { List } from 'antd'
import styled from 'styled-components'
import { TABLE_CSS } from '../../../data/models'
import { FILTER_OR_INFO_BAR_HEIGHT } from './models'
import { RoleSubDataTableHeader } from './RoleSubDataTableHeader'

type RoleSubdataTableProps<T> = {
  header: string
  dataSource: T[]
  renderItem: (item: T) => React.ReactNode
}

export const RoleSubdataTable = <T,>({
  header,
  dataSource,
  renderItem,
}: RoleSubdataTableProps<T>) => {
  return (
    <RoleSubDataTableContainer>
      <RoleSubDataTableHeader label={header} />
      <ScrollContainer>
        <List dataSource={dataSource} renderItem={renderItem} />
      </ScrollContainer>
    </RoleSubDataTableContainer>
  )
}

export const RoleSubDataTableContainer = styled.div`
  height: calc(100% - ${FILTER_OR_INFO_BAR_HEIGHT});

  border: ${TABLE_CSS.BORDER};

  .ant-list-item {
    padding: 16px;
  }
`

export const ScrollContainer = styled.div`
  height: calc(100% - ${TABLE_CSS.HEADER_HEIGHT});

  width: 100%;

  overflow: auto;
`

export const RoleSubDataTableItem = styled(List.Item)`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-content: center;
`
