import { Button, Layout, Typography } from '@swiftctrl/swift-component-library'
import { getPluralLabelByCount, PluralLabelConfig } from '../../../utils-hooks'

interface CurrentSubDataInfoBarProps {
  labels: PluralLabelConfig
  onRemoveAll: () => void
  count: number
}

export const CurrentSubDataInfoBar = ({
  labels,
  onRemoveAll,
  count,
}: CurrentSubDataInfoBarProps) => {
  const label = getPluralLabelByCount({
    labels: labels,
    count,
  })

  return (
    <Layout horizontal justifyContent="space-between" alignItems="center">
      <Typography.Title
        level={4}
      >{`${count} ${label} selected`}</Typography.Title>
      <Button danger onClick={onRemoveAll} type="link">
        Remove all
      </Button>
    </Layout>
  )
}
