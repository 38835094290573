import {
  Col,
  message,
  Modal,
  PreLoader,
  Row,
  Text,
} from '@swiftctrl/swift-component-library'
import { Card } from 'antd'
import { MessageType } from 'antd/lib/message'
import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { DeleteEntityView } from '../../../components'
import { useGetAllEntities } from '../../../data/EntityTreeData'
import {
  APP_HEADER_HEIGHT,
  getEntityDisplayName,
  TABS_HEIGHT,
  useBoolean,
  useHandleUrlParams,
  useReadEntityDetails,
} from '../../../utils-hooks'
import { EntityTree } from './EntityTree'
import { EntityTreeToolbar } from './EntityTreeToolbar'
import { ExtendedEntityDetails } from './ExtendedEntityDetails'

const PADDING = '21px'

const ENTITY_TREE_SUMMARY_HEIGHT = '46px'

export const EntityTreeTab = () => {
  const { entities, refetchEntities, refetchingEntities, getEntitiesError } =
    useGetAllEntities()

  const { currentParams } = useHandleUrlParams()

  const selectedEntityId = currentParams['entity-id'] || ''

  const selectedEntity = entities?.find(
    (entity) => entity.entity_id === selectedEntityId,
  )

  const [selectedEntityNumChildren, setSelectedEntityNumChildren] =
    useState<number>(0)

  const [
    confirmDeletionModalOpen,
    showConfirmDeletionModal,
    hideConfirmDeletionModal,
  ] = useBoolean()

  const { entityDetails, isReadingEntityDetails } =
    useReadEntityDetails(selectedEntity)

  const refreshMessageRef = useRef<MessageType>()

  const refreshEntityTree = () => {
    refetchEntities()

    refreshMessageRef.current = message.loading('Refreshing...')
  }

  useEffect(() => {
    if (!refetchingEntities && refreshMessageRef.current) {
      // This triggers closing the message
      refreshMessageRef.current()

      refreshMessageRef.current = undefined
    }
  }, [refetchingEntities])

  const handleDeleteClick = async () => {
    const displayName = getEntityDisplayName(selectedEntity!)

    if (selectedEntityNumChildren > 0) {
      Modal.warning({
        title: `Unable to delete "${displayName}"`,
        content: `"${displayName}" has children, so it cannot be deleted!`,
      })
    } else {
      showConfirmDeletionModal()
    }
  }

  const handleEntityDeleted = () => {
    refreshEntityTree()

    hideConfirmDeletionModal()
  }

  return (
    <>
      {getEntitiesError ? (
        <Text>Error while getting entities: {getEntitiesError.message}</Text>
      ) : !entities ? (
        <PreLoader centered />
      ) : (
        <StyledContainer>
          <StyledEntityTreeToolbar
            entities={entities}
            refreshing={refetchingEntities}
            onRefreshClick={refreshEntityTree}
          />
          <Row gutter={24}>
            <Col span={12}>
              <EntityTree
                entities={entities!}
                onNumChildrenChange={setSelectedEntityNumChildren}
              />
            </Col>
            <Col span={12}>
              {selectedEntity ? (
                <StyledExtendedEntityDetails
                  entity={selectedEntity}
                  performingRead={isReadingEntityDetails}
                  readResponseData={entityDetails}
                  onDeleteClick={handleDeleteClick}
                  bordered={false}
                />
              ) : (
                <StyledCard title="No entity selected" bordered={false}>
                  Click an entity to the left to see its details
                </StyledCard>
              )}
            </Col>
          </Row>
        </StyledContainer>
      )}
      {selectedEntity && (
        <DeleteEntityView
          open={confirmDeletionModalOpen}
          entity={{
            entityType: selectedEntity.entity_type_id,
            entityId: selectedEntity.entity_id,
            entityName: selectedEntity.entity_name,
          }}
          onEntityDeleted={handleEntityDeleted}
          onClose={hideConfirmDeletionModal}
        />
      )}
    </>
  )
}

const StyledContainer = styled.div`
  padding: ${PADDING};

  background-color: var(--light-gray-background-color);
`

const baseContentStyle = css`
  max-height: calc(
    100vh - ${APP_HEADER_HEIGHT} - ${TABS_HEIGHT} - 2 * ${PADDING} -
      ${ENTITY_TREE_SUMMARY_HEIGHT}
  );
  overflow-y: auto;
`

const StyledExtendedEntityDetails = styled(ExtendedEntityDetails)`
  ${baseContentStyle}
`

const StyledCard = styled(Card)`
  ${baseContentStyle};
`

const StyledEntityTreeToolbar = styled(EntityTreeToolbar)`
  padding-bottom: ${PADDING};
`
