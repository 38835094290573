import { ProfilesTypes } from '@swiftctrl/api-client'
import {
  Tooltip,
  Typography,
  UserOutlined,
} from '@swiftctrl/swift-component-library'
import { Link } from 'react-router-dom'
import { PEOPLE_SCREEN_PATH } from '../config/paths'
import { isValidUuid } from '../utils-hooks'

type ProfileDisplayProps = {
  profile: Partial<ProfilesTypes>
}

export const ProfileDisplay = ({ profile }: ProfileDisplayProps) => {
  const { profile_id, display_name, person_id } = profile

  return (
    <>
      <Typography.Text
        copyable={{
          text: profile_id,
          tooltips: `Copy profile ID (${profile_id})`,
        }}
      >
        <Tooltip title={profile_id}>
          {isValidUuid(display_name) ? (
            <Typography.Text code>{display_name}</Typography.Text>
          ) : (
            display_name
          )}
        </Tooltip>
      </Typography.Text>
      {person_id && (
        <>
          {' | '}
          <Tooltip title="View person">
            <Link
              to={`/${PEOPLE_SCREEN_PATH}?search=${profile.person_id}`}
              target="_blank"
            >
              <UserOutlined />
            </Link>
          </Tooltip>
        </>
      )}
    </>
  )
}
