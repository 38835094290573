import { InputNumber, Layout } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'

export const BitfieldPropertiesContainer = styled(Layout)`
  height: 72px;

  background-color: #fff;

  gap: ${({ theme }) => theme.spacing.medium};

  border-bottom: 1px solid #f0f0f0;
`

BitfieldPropertiesContainer.defaultProps = {
  horizontal: true,
  padding: 'medium',
  alignItems: 'center',
}

export const BitfieldBitIndexInput = styled(InputNumber)`
  width: 160px;
`

BitfieldBitIndexInput.defaultProps = {
  type: 'number',
  bordered: false,
  controls: false,
}

/**
 * This is derived from the max value of the backend's `int64` type
 */
const bitfieldValueStartEndMaxSafeDifferenceMax = 62

export const getStartBitMin = (
  endBit: number | undefined,
  differenceMax = bitfieldValueStartEndMaxSafeDifferenceMax,
) => {
  if (endBit === undefined) {
    return 0
  }

  const min = Math.max(endBit - differenceMax, 0)

  return min
}

export const getEndBitMax = (
  startBit: number | undefined,
  differenceMax = bitfieldValueStartEndMaxSafeDifferenceMax,
) => {
  if (startBit === undefined) {
    return undefined
  }

  const max = startBit + differenceMax

  return max
}
