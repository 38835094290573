import { Layout, Modal } from '@swiftctrl/swift-component-library'
import {
  showErrorNotification,
  showSuccessNotification,
  useRequestPasswordReset,
} from '../../../../utils-hooks'

export const useResetPasswordFlow = () => {
  const handleResetPasswordClick = (email: string) => {
    Modal.confirm({
      centered: true,
      title: 'Reset Password?',
      content: (
        <Layout spacing="medium">
          <div>
            The user will receive password reset instructions at the following
            email:
          </div>
          <div>{email}</div>
        </Layout>
      ),
      okText: 'Send Email',
      onOk: () => requestPasswordReset(email),
    })
  }

  const { mutate } = useRequestPasswordReset()

  const requestPasswordReset = (email: string) => {
    mutate(email, {
      onSuccess: () => {
        showSuccessNotification('Password reset requested')
      },
      onError: (error) => {
        showErrorNotification('Error while requesting password reset', error)
      },
    })
  }

  return { handleResetPasswordClick }
}
