import { RoleArchetype } from '@swiftctrl/api-client'
import { useState } from 'react'

export const useManageArchetypes = (
  roleArchetypeCandidates: RoleArchetype[],
  existingArchetypes: RoleArchetype[],
) => {
  const [archetypes, setArchetypes] = useState(roleArchetypeCandidates)

  const [selectedArchetypes, setSelectedArchetypes] = useState([
    ...existingArchetypes,
  ])

  const [search, setSearch] = useState<string>('')

  const addArchetype = (archetype: RoleArchetype) => {
    setSelectedArchetypes((prev) => [archetype, ...prev])
  }

  const removeArchetype = (archetype: RoleArchetype) => {
    setSelectedArchetypes((prev) => prev.filter((item) => item !== archetype))
  }

  const removeAllArchetype = () => {
    setSelectedArchetypes([])
  }

  const handleSearch = (value: string) => {
    setSearch(value)

    if (!value) {
      setArchetypes(roleArchetypeCandidates)

      return
    }

    const lowercaseValue = value.toLowerCase()

    const filteredArchetypes = roleArchetypeCandidates.filter((archetype) => {
      if (archetype.toLowerCase().includes(lowercaseValue)) {
        return true
      }

      return false
    })

    setArchetypes(filteredArchetypes)
  }

  const resetArchetypes = () => {
    setArchetypes(roleArchetypeCandidates)
  }

  return {
    archetypes,
    selectedArchetypes,
    search,
    addArchetype,
    setArchetypes,
    removeArchetype,
    removeAllArchetype,
    handleSearch,
    setSelectedArchetypes,
    resetArchetypes,
  }
}
