import { OrganizationTypes } from '@swiftctrl/api-client'
import { Tabs, TabsProps } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { SourcesBrowser } from '../../components'
import { useGetValidParam, useHandleUrlParams } from '../../utils-hooks'
import { SourcesAndSinksChart } from './sources-and-sinks-chart'

interface SourcesTabViewProps {
  organization: OrganizationTypes
}

enum SourcesTabViewKey {
  List = 'list',
  Chart = 'chart',
}

export const SourcesTabView = ({ organization }: SourcesTabViewProps) => {
  const { updateParams } = useHandleUrlParams()

  const viewParam = useGetValidParam({
    paramKey: 'view',
    validator: validateViewParam,
  })

  const updateUrl = (view: string) => {
    updateParams({ view })
  }

  const tabItems: TabsProps['items'] = [
    {
      key: SourcesTabViewKey.List,
      label: 'List',
      children: (
        <SourcesBrowser
          baseId={organization.organization_id}
          displayAddButton
        />
      ),
    },
    {
      key: SourcesTabViewKey.Chart,
      label: 'Chart',
      children: <SourcesAndSinksChart organization={organization} />,
    },
  ]

  return (
    <StyledContainer>
      <Tabs
        activeKey={viewParam || SourcesTabViewKey.List}
        onTabClick={updateUrl}
        size="large"
        items={tabItems}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  // Align Tabs in the middle
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
`

const validateViewParam = (viewParam: string | undefined) => {
  switch (viewParam) {
    case SourcesTabViewKey.List:
    case SourcesTabViewKey.Chart:
      return viewParam
  }

  return ''
}
