import { EpiTypes } from '@swiftctrl/api-client'
import { Typography } from '@swiftctrl/swift-component-library'
import { buildEpiSubtypeLabel } from '../../../../utils-hooks'
import { CopyableDisplay } from '../../../data-display'

type EpiSubtypeDisplayProps = {
  epi: EpiTypes
}

export const EpiSubtypeDisplay = ({ epi }: EpiSubtypeDisplayProps) => {
  const {
    subtype: { epi_subtype_id, vendor, product_name, sub_type_description },
  } = epi

  return (
    <>
      <Typography.Text strong>Subtype: </Typography.Text>
      <CopyableDisplay
        label={buildEpiSubtypeLabel(epi.subtype)}
        copyableText={epi_subtype_id}
        tooltip={
          <>
            <p>Vendor: {vendor}</p>
            <p>Product name: {product_name}</p>
            <p>ID: {epi_subtype_id}</p>
            {sub_type_description}
          </>
        }
      />
    </>
  )
}
