export const getUTCTimestamp = () => {
  const date = new Date()

  const year = date.getUTCFullYear()

  const month = date.getUTCMonth() + 1

  const day = date.getUTCDate()

  const hours = date.getUTCHours()

  const minutes = date.getUTCMinutes()

  const seconds = date.getUTCSeconds()

  const formattedDate = `${year}-${pad(month)}-${pad(day)}T${pad(hours)}:${pad(
    minutes,
  )}:${pad(seconds)}Z`

  return formattedDate
}

const pad = (value: number) => String(value).padStart(2, '0')
