import { EntityTypes } from '@swiftctrl/api-client'
import { List, Popover } from 'antd'
import { getEntityLabelByCount } from '../../../config/labels'
import { EntityType } from '../../../data/models'

type DeletableEntityTypeViewProps = {
  entities: Partial<EntityTypes>[]
  entityType: EntityType
}

export const DeletableEntityTypeView = ({
  entities,
  entityType,
}: DeletableEntityTypeViewProps) => {
  return (
    <Popover
      content={
        <List
          split={false}
          size="small"
          dataSource={entities}
          renderItem={(entity) => (
            <List.Item>
              {entity.entity_name || <em>No entity_name</em>}
            </List.Item>
          )}
        />
      }
    >
      {entities.length}{' '}
      {getEntityLabelByCount({
        entityType,
        count: entities.length,
        capitalize: false,
      })}
    </Popover>
  )
}
