import { EntityTypes } from '@swiftctrl/api-client'
import { Button, Input, Swift } from '@swiftctrl/swift-component-library'
import { Popover } from 'antd'
import { ChangeEvent, ReactNode } from 'react'
import styled from 'styled-components'
import { EntityTypeTag } from '../../../components'
import { EntityType } from '../../../data/models'
import { useHandleUrlParams } from '../../../utils-hooks'

type EntityTreeSummaryProps = {
  entities: EntityTypes[]
  refreshing: boolean
  onRefreshClick: () => void
}

export const EntityTreeToolbar: Swift.FC<EntityTreeSummaryProps> = ({
  entities,
  refreshing,
  onRefreshClick,
  ...props
}) => {
  const { currentParams, updateParams } = useHandleUrlParams(false)

  const search = currentParams.search || ''

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
    updateParams({ search: event.target.value })

  return (
    <ToolBar {...props}>
      <StyledInput
        size="small"
        placeholder="Search Entity by name"
        onChange={handleSearch}
        allowClear
        defaultValue={search}
      />
      <Popover content={getPopoverContent(entities)} placement="bottomLeft">
        <StyledTotalEntitiesText>
          {`Total entities: ${entities.length}`}
        </StyledTotalEntitiesText>
      </Popover>
      <StyledButton size="small" onClick={onRefreshClick} disabled={refreshing}>
        Refresh
      </StyledButton>
    </ToolBar>
  )
}

const getPopoverContent = (entities: EntityTypes[]) => {
  const map: Map<EntityType, number> = new Map()

  entities.forEach(({ entity_type_id }) => {
    const currentValue = map.get(entity_type_id) || 0

    map.set(entity_type_id, currentValue + 1)
  })

  const entityTypesToCounts: { entityType: EntityType; count: number }[] = []

  map.forEach((count, entityType) =>
    entityTypesToCounts.push({ entityType, count }),
  )

  entityTypesToCounts.sort(
    ({ entityType: aEntityType }, { entityType: bEntityType }) =>
      aEntityType.localeCompare(bEntityType),
  )

  const content: ReactNode[] = entityTypesToCounts.map(
    ({ entityType, count }) => (
      <p key={entityType}>
        <EntityTypeTag entityType={entityType} />
        {count} entities
      </p>
    ),
  )

  return content
}

const StyledTotalEntitiesText = styled.p`
  font-size: 16px;
  text-decoration: dotted underline;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
`

const StyledButton = styled(Button)`
  margin-left: 1em;
`

const StyledInput = styled(Input)`
  margin-right: 1em;
  width: 300px;
`

const ToolBar = styled.div`
  display: flex;
  align-items: center;
`
