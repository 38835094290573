import { isEqual } from 'lodash'
import { Column, Entry, ValidationRule } from './types'

export const buildDataIndexToValidationRules = <T>(columns: Column<T>[]) => {
  const map = columns.reduce((map, column) => {
    if (column.validationRules) {
      map.set(column.dataIndex, column.validationRules)
    }

    return map
  }, new Map<string, ValidationRule<T>[]>())

  return { dataIndexToValidationRules: map }
}

export const buildErrorKey = (rowKey: string, dataIndex: string) => {
  const key = `${rowKey}.${dataIndex}`

  return key
}

export const calculateColumnWidth = <T>(columns: Column<T>[]) => {
  const { length } = columns

  if (length === 0) {
    return '100%'
  }

  return `${Math.round(100 / length)}%`
}

export const isEquivalent = <T>(
  initialData: T[],
  dataSource: Entry<T>[],
): boolean => {
  const keylessDataSource = dataSource.map((data) => {
    const { key, ...keylessData } = data

    return keylessData
  })

  return isEqual(initialData, keylessDataSource)
}
