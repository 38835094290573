import { SinkRule } from '@swiftctrl/api-client'
import { Empty } from '@swiftctrl/swift-component-library'
import { Collapse } from 'antd'
import { Fragment, useEffect, useRef, useState } from 'react'
import { CollapseContainer } from '../../components'
import { normalizeFilterConfiguration } from '../../utils-hooks'
import { FilterGroupsView } from './FilterGroupsView'
import { RuleFields } from './RuleFields'
import { getInitialStreamId } from './rules/getInitialStreamId'
import { getStreamData } from './rules/getStreamData'
import { useLocationStateRuleId } from './rules/useLocationStateRuleId'
import { RulesPanelHeader } from './RulesPanelHeader'
import { StreamIdFilters } from './StreamIdFilters'

type RulesViewProps = {
  rules: SinkRule[]
}

const TAB_CHANGE_ANIMATION_TIME = 400

export const RulesView = ({ rules }: RulesViewProps) => {
  const { streamIds, streamIdToCountMap } = getStreamData(rules)

  const ruleId = useLocationStateRuleId()

  const [streamId, setStreamId] = useState(
    getInitialStreamId(streamIds, rules, ruleId),
  )

  const filteredRules: SinkRule[] = rules.filter(
    (rule) => rule.stream_id === streamId,
  )

  const rulePanelRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ruleId) {
      return
    }

    setTimeout(() => {
      rulePanelRef.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }, TAB_CHANGE_ANIMATION_TIME)
  }, [ruleId])

  if (rules.length === 0) {
    return <Empty description="No rules" />
  }

  return (
    <CollapseContainer>
      {streamIds.length > 1 && (
        <StreamIdFilters
          streamIds={streamIds}
          streamIdToCountMap={streamIdToCountMap}
          onChange={setStreamId}
          selectedStreamId={streamId}
        />
      )}
      <Collapse accordion bordered={false} defaultActiveKey={ruleId}>
        {filteredRules.map((rule) => {
          const { rule_id, rule_fields, filter_configuration } = rule

          const filterConfiguration =
            normalizeFilterConfiguration(filter_configuration)

          return (
            <Fragment key={rule_id}>
              {rule_id === ruleId && <div ref={rulePanelRef} />}
              <Collapse.Panel
                key={rule_id}
                header={<RulesPanelHeader rule={rule} />}
              >
                <RuleFields ruleFields={rule_fields} />

                <FilterGroupsView filterConfiguration={filterConfiguration} />
              </Collapse.Panel>
            </Fragment>
          )
        })}
      </Collapse>
    </CollapseContainer>
  )
}
