import { measureCanvasTextWidth } from '../measureCanvasTextWidth'

const cache = new Map<string, number>()

export const getCachedEntityTypeLabelWidth = (
  label: string,
  fontSize: number,
) => {
  if (cache.has(label)) {
    return cache.get(label)!
  }

  const width = measureCanvasTextWidth(label, fontSize)

  cache.set(label, width)

  return width
}
