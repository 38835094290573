import { useQuery } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import {
  alphabetize,
  cacheKeys,
  getAllFromBrowseRequest,
  longCacheOptions,
} from '../../../../utils-hooks'

export const useGetRoleArchetypeCandidates = (enabled: boolean) =>
  useQuery({
    queryKey: [cacheKeys.role_archetypes_candidates],
    queryFn: async ({ signal }) => {
      const archetypes = await getAllFromBrowseRequest((offset, limit) =>
        swiftClientWithoutErrorHandling.roleArchetype.browse(
          { offset, limit },
          { signal },
        ),
      )

      archetypes.sort(alphabetize)

      return archetypes
    },
    enabled,
    ...longCacheOptions,
  })
