import { Swift, Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

type UuidDisplayProps = {
  value: any
}

export const UuidDisplay: Swift.FC<UuidDisplayProps> = ({ value }) => (
  <StyledText code copyable>
    {value}
  </StyledText>
)

const StyledText = styled(Typography.Paragraph)`
  margin-bottom: 0 !important;

  display: inline;
`
