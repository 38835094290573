import { AxiosError } from '@swiftctrl/api-client'
import { Result } from 'antd'
import { Link } from 'react-router-dom'

interface ReadScreenFallbackProps {
  error?: any
  buttonClickPath: string
}

type ErrorCode = '404' | '500' | 'error'
interface ErrorProps {
  status: ErrorCode
  title: string
  subTitle?: string
}

export const ReadScreenFallback = ({
  error,
  buttonClickPath,
}: ReadScreenFallbackProps) => {
  const errorProps = getErrorProps(error)

  return (
    <Result {...errorProps} extra={<Link to={buttonClickPath}>Go back</Link>}>
      {String(error)}
    </Result>
  )
}

const mapErrorCodeToErrorProps: Record<404 | 500 | 'default', ErrorProps> = {
  '404': {
    status: '404',
    title: '404',
    subTitle: 'Not found',
  },
  '500': {
    status: '500',
    title: 'Sorry, an error occured',
  },
  default: {
    status: 'error',
    title: 'Something went wrong',
  },
}

const getErrorProps = (error: any): ErrorProps => {
  if (typeof error === 'string') {
    if (error.includes('404')) {
      return mapErrorCodeToErrorProps['404']
    }
    if (error.includes('500')) {
      return mapErrorCodeToErrorProps['500']
    }
    return mapErrorCodeToErrorProps['default']
  }
  if (typeof error === 'object') {
    switch ((error as AxiosError).response?.status) {
      case 404:
        return mapErrorCodeToErrorProps['404']
      case 500:
        return mapErrorCodeToErrorProps['500']
      default:
        return mapErrorCodeToErrorProps['default']
    }
  }
  return mapErrorCodeToErrorProps['default']
}
