import { DateDisplay } from './date/DateDisplay'

interface CreatedUpdatedDateDisplayProps {
  createdAt: string | undefined
  updatedAt: string | undefined
  appendTimeAgo?: boolean
}

export const CreatedUpdatedDateDisplay = ({
  createdAt,
  updatedAt,
  appendTimeAgo,
}: CreatedUpdatedDateDisplayProps) => (
  <>
    <DateDisplay
      label="Created"
      date={createdAt}
      appendTimeAgo={appendTimeAgo}
    />
    <DateDisplay
      label="Updated"
      date={updatedAt}
      appendTimeAgo={appendTimeAgo}
    />
  </>
)
