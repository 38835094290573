import { Tag } from '@swiftctrl/swift-component-library'
import { cloneElement } from 'react'
import styled from 'styled-components'
import { EntityType } from '../../data/models'

type EntityTypeTagProps = {
  entityType: EntityType
  onClick?: (entityType: EntityType) => void
}

const entityTypeToColor: { [type in EntityType]?: string } = {
  building: '#BAC0F6',
  controller: '#1EB805',
  entity: '#FFFFFF',
  epi: '#C7E8AC',
  organization: '#C1E4F7',
  profile: '#FFA940',
  provider: '#FFD9D9',
  provider_profile: '#FF8F8F',
  reader: '#A4A72D',
  role: '#FCCE14',
  room: '#76B7F3',
  sink: '#FF4D4F',
  source: '#389E0D',
  system: '#F9A252',
}

export const EntityTypeTag = ({ entityType, onClick }: EntityTypeTagProps) => {
  const tag = (
    <StyledTag color={entityTypeToColor[entityType]} style={{ color: '#000' }}>
      {entityType}
    </StyledTag>
  )

  if (onClick === undefined) {
    return tag
  }

  const handleClick = () => onClick(entityType)

  return cloneElement(tag, { onClick: handleClick })
}

const StyledTag = styled(Tag)`
  cursor: ${({ onClick }) => (onClick !== undefined ? 'pointer' : 'inherit')};
`
