import { useBrowseProfileList } from './useBrowseProfileList'

export const useInfiniteBrowseCandidateProfiles = ({
  roleId,
  search,
  organizationId,
  enabled,
}: {
  roleId: string
  search: string
  organizationId: string
  enabled: boolean
}) => {
  const { data, fetchNextPage, isFetching } = useBrowseProfileList({
    roleId,
    search,
    organizationId,
    enabled,
  })

  const listOfProfiles =
    data?.pages.flatMap((page) => page.listOfProfiles) || []

  return {
    rawCandidateProfiles: listOfProfiles,
    fetchMoreRawCandidateProfiles: fetchNextPage,
    isFetchingCandidateProfiles: isFetching,
    isFetchingCandidateProfilesFirstTime: !data,
  }
}
