import { useState } from 'react'

const getSideMenuCollapseStatus = () =>
  localStorage.getItem('sideMenuCollapseStatus')

const setSideMenuCollapseStatus = (collapse: '1' | '0') => {
  localStorage.setItem('sideMenuCollapseStatus', collapse)
}

const getDefaultStateValue = () => {
  let value = getSideMenuCollapseStatus()

  if (value === null || !['1', '0'].includes(value)) {
    value = '0'

    setSideMenuCollapseStatus('0')
  }

  return value !== '0'
}

export const useSideMenuState = () => {
  const [sideMenuCollapse, setSideMenuCollapse] = useState(
    getDefaultStateValue(),
  )

  const handleSideMenuCollapse = (collapse: boolean) => {
    setSideMenuCollapse(collapse)

    setSideMenuCollapseStatus(collapse ? '1' : '0')
  }

  return { sideMenuCollapse, handleSideMenuCollapse }
}
