import {
  CredentialTemplateFieldTypes,
  CredentialTemplateTypes,
} from '@swiftctrl/api-client'
import {
  Button,
  Drawer,
  Empty,
  Form,
  Layout,
  PreviewOutlined,
  Typography,
} from '@swiftctrl/swift-component-library'
import { Link } from 'react-router-dom'
import { CREDENTIAL_TEMPLATES_SCREEN_PATH } from '../../config/paths'
import { appTheme } from '../../theme'
import { buildCommonDrawerProps } from '../../utils-hooks'
import { CredentialTemplateFieldCheckbox } from './CredentialTemplateFieldCheckbox'
import { CredentialTemplateFieldDateTimePicker } from './CredentialTemplateFieldDateTimePicker'
import { CredentialTemplateFieldInput } from './CredentialTemplateFieldInput'
import { CredentialTemplateFieldNumberInput } from './CredentialTemplateFieldNumberInput'
import { CredentialTemplateFieldSelect } from './CredentialTemplateFieldSelect'
import { CredentialTemplateFieldUuidInput } from './CredentialTemplateFieldUuidInput'
import { hasValidValues, sortCredentialTemplateFieldsByRank } from './utils'

type CredentialTemplatePreviewViewProps = {
  open: boolean
  credentialTemplate: CredentialTemplateTypes
  onClose: () => void
  displayViewDetailsButton?: boolean
}

export const CredentialTemplatePreviewView = ({
  open,
  credentialTemplate: credentialFormat,
  onClose,
  displayViewDetailsButton,
}: CredentialTemplatePreviewViewProps) => {
  const {
    template_name,
    template_description,
    credential_field_configs,
    credential_format_config_id,
  } = credentialFormat

  const fields = credential_field_configs
    .filter(filterByVisibility)
    .sort(sortCredentialTemplateFieldsByRank)

  const detailsNavigatePath = `/${CREDENTIAL_TEMPLATES_SCREEN_PATH}/${credential_format_config_id}`

  return (
    <Drawer
      {...buildCommonDrawerProps({
        open,
        onClose,
        title: (
          <>
            <PreviewOutlined /> Credential Template Preview
          </>
        ),
        width: appTheme.sheet.sizes.small,
      })}
    >
      <Form layout="vertical" size="large">
        <Form.Item>
          {template_name}
          <br />
          {template_description && (
            <Typography.Text type="secondary">
              {template_description}
            </Typography.Text>
          )}
        </Form.Item>
        {fields.length === 0 && <Empty description="All fields are hidden" />}
        {fields.map((field) => {
          const { field_type, rank } = field

          switch (field_type) {
            case 'STRING':
              if (hasValidValues(field)) {
                return (
                  <CredentialTemplateFieldSelect key={rank} field={field} />
                )
              }

              return <CredentialTemplateFieldInput key={rank} field={field} />

            case 'INTEGER':
              if (hasValidValues(field)) {
                return (
                  <CredentialTemplateFieldSelect key={rank} field={field} />
                )
              }

              return (
                <CredentialTemplateFieldNumberInput key={rank} field={field} />
              )

            case 'BOOL':
              return (
                <CredentialTemplateFieldCheckbox key={rank} field={field} />
              )

            case 'TIME':
              if (hasValidValues(field)) {
                return (
                  <CredentialTemplateFieldSelect key={rank} field={field} />
                )
              }

              return (
                <CredentialTemplateFieldDateTimePicker
                  key={rank}
                  field={field}
                />
              )

            case 'UUID':
              if (hasValidValues(field)) {
                return (
                  <CredentialTemplateFieldSelect key={rank} field={field} />
                )
              }

              return (
                <CredentialTemplateFieldUuidInput key={rank} field={field} />
              )

            case 'BYTE_ARRAY':
              // Byte arrays are always hidden, so no need to handle them here
              return null

            default:
              return (
                <Form.Item key={rank}>
                  <Typography.Text type="danger" key={rank}>
                    Unhandled type: {field_type}
                  </Typography.Text>
                </Form.Item>
              )
          }
        })}
        {displayViewDetailsButton && (
          <Layout horizontal justifyContent="end">
            <Form.Item>
              <Link to={detailsNavigatePath}>
                <Button type="primary" ghost>
                  View Details
                </Button>
              </Link>
            </Form.Item>
          </Layout>
        )}
      </Form>
    </Drawer>
  )
}

const filterByVisibility = (fieldConfig: CredentialTemplateFieldTypes) =>
  !fieldConfig.is_hidden
