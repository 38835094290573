import { EpiTypes } from '@swiftctrl/api-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getReadScreenQueryKey, JsonEditor } from '../../components'
import { swiftClient } from '../../data/swiftClient'
import {
  cacheKeys,
  showErrorNotification,
  showSuccessNotification,
} from '../../utils-hooks'

interface Props {
  epi: EpiTypes
}

export const EpiConfigView = ({ epi }: Props) => {
  const queryClient = useQueryClient()

  const editEpiMutation = useMutation({
    mutationFn: swiftClient.epi.at(epi.epi_id).edit,
    onSuccess: (data) => {
      showSuccessNotification('Config saved')

      queryClient.setQueryData(
        getReadScreenQueryKey(cacheKeys.epi, epi.epi_id),
        data.data,
      )
    },
    onError: (error) =>
      showErrorNotification('Error while saving config', error),
  })

  const save = (json: Record<string, any>) => {
    editEpiMutation.mutate({
      ...epi,
      config: json,
    } as Partial<EpiTypes>)
  }

  return (
    <JsonEditor
      json={epi.config || {}}
      confirmButtonLabel="Save"
      confirmButtonLoading={editEpiMutation.isPending}
      disableConfirmButtonWhenDataUnchanged
      onConfirm={save}
    />
  )
}
