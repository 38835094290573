import { ProfilesTypes } from '@swiftctrl/api-client'
import { Layout } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { CopyableDisplay, EntityCard, LabelDisplay } from '../../components'
import {
  PEOPLE_SCREEN_PATH,
  PERSON_SCREEN_PROFILES_TAB,
} from '../../config/paths'

type ProfileCardProps = {
  profile: ProfilesTypes
}

export const ProfileCard = ({ profile }: ProfileCardProps) => {
  const { person_id, profile_id, first_name, last_name, email, phone_primary } =
    profile

  const path = `/${PEOPLE_SCREEN_PATH}/${person_id}/${PERSON_SCREEN_PROFILES_TAB}/${profile_id}`

  return (
    <EntityCard detailsNavigatePath={path} entity={profile}>
      <Layout paddingTop="xSmall" spacing="xSmall">
        <StyledLayout horizontal>
          <LabelDisplay label="First name" />
          {first_name}
        </StyledLayout>
        <StyledLayout horizontal>
          <LabelDisplay label="Last name" />
          {last_name}
        </StyledLayout>
        <StyledLayout horizontal>
          <LabelDisplay label="Email" />
          {email ? <CopyableDisplay label={email} /> : '-'}
        </StyledLayout>
        <StyledLayout horizontal>
          <LabelDisplay label="Phone" />
          {phone_primary ? <CopyableDisplay label={phone_primary} /> : '-'}
        </StyledLayout>
      </Layout>
    </EntityCard>
  )
}

const StyledLayout = styled(Layout)`
  gap: ${({ theme }) => theme.spacing.xSmall};
`
