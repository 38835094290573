import { ProviderProfileTypes, ProviderType } from '@swiftctrl/api-client'

export const getProviderTypeFromProviderProfile = (
  providerProfile: Partial<ProviderProfileTypes>,
): ProviderType => {
  const { login_data } = providerProfile

  if (!login_data) {
    throw new Error(
      'tried to get provider type but missing provider profile login_data',
    )
  }

  const { email_password, openid, proxy_login, single_use_token } = login_data

  if (email_password) {
    return 'email_password'
  }

  if (openid) {
    return 'openid'
  }

  if (proxy_login) {
    return 'proxy_login'
  }

  if (single_use_token) {
    return 'single_use_token'
  }

  return 'refresh_token'
}
