import { AssociationFilter } from '@swiftctrl/api-client'
import { Card } from 'antd'
import styled from 'styled-components'
import { AssociationFilterView } from './AssociationFilterView'
import { InvertStatus } from './InvertStatus'

type AssociationFiltersViewProps = {
  filters: AssociationFilter[] | null | undefined
  invert: boolean
}

export const AssociationFiltersView = ({
  filters,
  invert,
}: AssociationFiltersViewProps) => {
  return (
    <StyledCard
      title="Association filter"
      extra={<InvertStatus isInverted={invert} />}
    >
      {filters && filters.length > 0
        ? filters.map((filter, index) => (
            <AssociationFilterView key={index} filter={filter} />
          ))
        : '-'}
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  width: 100%;
`
