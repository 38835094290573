import {
  OperationAction,
  OperationModifier,
  OperationPrefix,
  OperationScope,
  OperationTypes,
  RoleOperationTypes,
} from '@swiftctrl/api-client'
import { Model, Response } from 'miragejs'
import { MockFeature, MockModel, ServerWithMockModels } from './utils'

type ServerWithMockOperation = ServerWithMockModels<{
  operation: MockModel<OperationTypes>
  roleOperation: MockModel<RoleOperationTypes>
}>

const mockOperations: OperationTypes[] = [
  {
    prefix: 'ACCESS_LEVEL',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'AC_DELEGATOR',
    actions: ['ADD', 'DELETE'],
    field_names: ['entity_name'],
    relation_names: ['test'],
    scopes: ['DESCENDANTS'],
    modifiers: ['SELF', 'SPACE'],
  },
  {
    prefix: 'AC_LANDLORD_TENANT_SPACE',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'ACS_HARDWARE',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'BUILDING',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: ['archetypes', 'entity_name', 'extra_strings'],
    relation_names: ['lsdkfj', 'alskjfl', 'dflk'],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'CAMPUS',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'CREDENTIAL',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'EPI',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'EPI.CONFIG',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'FLOOR',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'MEETING',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'ORGANIZATION',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'PORTFOLIO',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'PROVIDER',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'PROVIDER_PROFILE',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'READER',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'ROLE',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
  {
    prefix: 'ROOM',
    actions: ['ADD', 'DELETE', 'EDIT', 'LIST', 'READ'],
    field_names: [],
    relation_names: [],
    scopes: ['DESCENDANTS', 'NODE', 'STEM', 'UNDEFINED'],
    modifiers: ['SELF', 'SPACE', 'UNDEFINED'],
  },
]

const mockRoleOperations: RoleOperationTypes[] = [
  {
    prefix: 'ACCESS_LEVEL',
    action: 'READ',
    field_names: ['entity_name', 'entity_description'],
    relation_names: ['string'],
    scope: 'DESCENDANTS',
    modifier: 'SPACE',
    grants_on: {
      entity_id: 'e3873ad3-4cb1-42de-adc1-845d38e4b205',
      entity_name: 'Xavier building 7-A',
      entity_type_id: 'building',
    },
    grantable: false,
  },
  {
    prefix: 'ACCESS_LEVEL',
    action: 'READ',
    field_names: ['entity_name', 'entity_description'],
    relation_names: ['string'],
    scope: 'DESCENDANTS',
    modifier: 'SPACE',
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'ACCESS_LEVEL',
    action: 'LIST',
    field_names: null,
    relation_names: ['string'],
    scope: 'STEM',
    modifier: 'UNDEFINED',
    grants_on: {
      entity_id: '3bcd174c-fc51-47d5-811a-cefebd505a00',
      entity_name: 'Xavier test org 7',
      entity_type_id: 'organization',
    },
    grantable: false,
  },

  {
    prefix: 'ACS_HARDWARE',
    action: 'READ',
    field_names: ['entity_name', 'entity_description'],
    relation_names: null,
    scope: 'STEM',
    modifier: 'UNDEFINED',
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'AC_DELEGATOR',
    action: 'READ',
    field_names: null,
    relation_names: null,
    scope: 'DESCENDANTS',
    modifier: null,
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'BUILDING',
    action: 'LIST',
    field_names: null,
    relation_names: null,
    scope: 'STEM',
    modifier: null,
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'CAMPUS',
    action: 'LIST',
    field_names: null,
    relation_names: null,
    scope: 'STEM',
    modifier: null,
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'CREDENTIAL',
    action: 'LIST',
    field_names: null,
    relation_names: null,
    scope: 'STEM',
    modifier: null,
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'EPI',
    action: 'LIST',
    field_names: null,
    relation_names: null,
    scope: 'STEM',
    modifier: null,
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'FLOOR',
    action: 'LIST',
    field_names: null,
    relation_names: null,
    scope: 'STEM',
    modifier: null,
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'ORGANIZATION',
    action: 'LIST',
    field_names: null,
    relation_names: null,
    scope: 'STEM',
    modifier: null,
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'PROVIDER',
    action: 'LIST',
    field_names: null,
    relation_names: null,
    scope: 'STEM',
    modifier: null,
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'PROVIDER_PROFILE',
    action: 'LIST',
    field_names: null,
    relation_names: null,
    scope: 'STEM',
    modifier: null,
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'READER',
    action: 'LIST',
    field_names: null,
    relation_names: null,
    scope: 'STEM',
    modifier: null,
    grants_on: null,
    grantable: false,
  },
  {
    prefix: 'ROLE',
    action: 'LIST',
    field_names: null,
    relation_names: null,
    scope: 'STEM',
    modifier: null,
    grants_on: null,
    grantable: false,
  },
]

const model = () => ({
  operation: Model.extend<Partial<OperationTypes>>({}),
  roleOperation: Model.extend<Partial<RoleOperationTypes>>({}),
})

const seed = (server: ServerWithMockOperation) => {
  mockOperations.forEach((operation) => server.create('operation', operation))

  mockRoleOperations.forEach((roleOperation) =>
    server.create('roleOperation', roleOperation),
  )
}

const route = (server: ServerWithMockOperation) => {
  routeBrowse(server)

  routeAdd(server)

  routeDelete(server)
}

const routeBrowse = (server: ServerWithMockOperation) => {
  server.get('/operations', (schema) => {
    const operations = schema.all('operation').models

    return new Response(200, undefined, operations)
  })

  server.get('/roles/:roleId/operations', (schema) => {
    const roleOperations = schema.all('roleOperation').models

    return new Response(200, undefined, roleOperations)
  })
}

const routeAdd = (server: ServerWithMockOperation) => {
  server.post('/roles/:roleId/operations', (schema, request) => {
    const roleOperation: RoleOperationTypes = JSON.parse(request.requestBody)

    schema.create('roleOperation', roleOperation)

    return roleOperation
  })
}

const routeDelete = (server: ServerWithMockOperation) => {
  server.delete(
    '/roles/:roleId/operations/:operationKey',
    (schema, request) => {
      const { operationKey } = request.params

      const [prefix, action, scope, modifier] = operationKey.split('-')

      const operation = schema.findBy('roleOperation', {
        prefix: prefix as OperationPrefix,
        action: action as OperationAction,
        scope: scope as OperationScope,
        modifier: modifier as OperationModifier,
      })

      if (!operation) {
        return new Response(404, undefined, {
          message: 'Role operation not found',
        })
      }

      operation.destroy()

      return new Response(204)
    },
  )

  server.delete(
    '/roles/:roleId/granted-on/:grantedOnId/operations/:operationKey',
    (schema, request) => {
      const { operationKey } = request.params

      const [prefix, action, scope, modifier] = operationKey.split('-')

      const operation = schema.findBy('roleOperation', {
        prefix: prefix as OperationPrefix,
        action: action as OperationAction,
        scope: scope as OperationScope,
        modifier: modifier as OperationModifier,
      })

      if (!operation) {
        return new Response(404, undefined, {
          message: 'Role operation not found',
        })
      }

      if (!operation.grants_on) {
        return new Response(422, undefined, {
          message: 'Role operation does not have grants-on',
        })
      }

      if (operation.grants_on.entity_id !== request.params.grantedOnId) {
        return new Response(422, undefined, {
          message: 'Role operation grants-on does not match the param ID',
        })
      }

      operation.update({
        grants_on: null,
      })

      return new Response(204)
    },
  )
}

export const operationMock: MockFeature = {
  enabled: false,
  model,
  seed,
  route,
}
