import { EntityTypes, RoleAddTypes } from '@swiftctrl/api-client'
import { swiftClient } from '../../data/swiftClient'
import { AddEntityConfig } from '../../utils-hooks'

type FormData = {
  entity_name: string
  entity_description: string
  overseer_id: string | EntityTypes
}

export const role: AddEntityConfig<FormData, RoleAddTypes> = {
  fieldGroups: [
    {
      fields: [
        {
          key: 'entity_name',
          label: 'Name',
        },
        {
          key: 'entity_description',
          label: 'Description',
          optional: true,
        },
      ],
    },
    {
      condition: (_, overseerIdIsPreset) => !overseerIdIsPreset,
      fields: [
        {
          key: 'overseer_id',
          label: 'Overseer',
          inputType: 'entitySelect',
          entitySelectType: [
            'entity',
            'organization',
            'building',
            'epi',
            'role',
          ],
        },
      ],
    },
  ],
  buildData: (values) => {
    const { entity_name, entity_description, overseer_id } = values

    return {
      entity_name,
      entity_description,
      overseer_id:
        typeof overseer_id === 'object' ? overseer_id.entity_id : overseer_id,
    }
  },
  addRequest: swiftClient.role.add,
}
