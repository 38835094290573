import { FirebaseApp, initializeApp } from 'firebase/app'
import { getRemoteConfig as firebaseGetRemoteConfig } from 'firebase/remote-config'
import { Env, getFirebaseConfigForEnv } from '../config/firebaseConfig'

const firebaseApps: { [env in Env]?: FirebaseApp } = {}

const getFirebaseApp = (env: Env) => {
  if (env in firebaseApps) {
    return firebaseApps[env]!
  }

  const firebaseApp = initializeApp(getFirebaseConfigForEnv(env), env)

  firebaseApps[env] = firebaseApp

  return firebaseApp
}

export const getRemoteConfig = (env: Env) => {
  const firebaseApp = getFirebaseApp(env)

  return firebaseGetRemoteConfig(firebaseApp)
}
