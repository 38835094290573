import { EpiRemoteFieldDefinitionTypes } from '@swiftctrl/api-client'
import { SelectProps, Typography } from '@swiftctrl/swift-component-library'
import { AutoComplete } from 'antd'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useGetRemoteFieldDefinitions } from '../../../utils-hooks'

type RemoteFieldInputProps = {
  value: string
  onChange: (value: string) => void
}

type Option = {
  value: string
  productName: string
  label: ReactNode
}

export const RemoteFieldInput = ({
  value,
  onChange,
}: RemoteFieldInputProps) => {
  const { allOptions } = useGetAllOptions()

  const [options, setOptions] = useState<SelectProps['options']>([])

  useEffect(() => {
    const search = value.toLocaleLowerCase()

    const options = allOptions.filter((option) => {
      const { productName, value } = option

      if (productName.includes(search)) {
        return true
      }

      return value.toLocaleLowerCase().includes(search)
    })

    setOptions(options)
  }, [allOptions, value])

  return <AutoComplete value={value} onChange={onChange} options={options} />
}

const useGetAllOptions = () => {
  const { data } = useGetRemoteFieldDefinitions()

  const allOptions = useMemo(() => {
    if (!data?.data) {
      return []
    }

    data.data.sort(alphabetize)

    const options = data.data.map((definition) => {
      const { remote_field, product_name } = definition

      const option: Option = {
        value: remote_field!,
        productName: product_name!.toLocaleLowerCase(),
        label: (
          <>
            {remote_field}
            <Typography.Text type="secondary">{` (${product_name})`}</Typography.Text>
          </>
        ),
      }

      return option
    })

    return options
  }, [data?.data])

  return { allOptions }
}

const alphabetize = (
  a: Partial<EpiRemoteFieldDefinitionTypes>,
  b: Partial<EpiRemoteFieldDefinitionTypes>,
) => {
  if (a.product_name === b.product_name) {
    return a.remote_field!.localeCompare(b.remote_field!)
  }

  return a.product_name!.localeCompare(b.product_name!)
}
