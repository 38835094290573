import { useQuery } from '@tanstack/react-query'
import { swiftClient } from '../../data/swiftClient'
import { adaptSDKReadRequest } from '../adaptSDKReadRequest'
import { cacheKeys } from '../cacheKeysUtils'

export const useReadEntity = (entityId: string, enabled = true) =>
  useQuery({
    queryKey: [cacheKeys.entity, entityId],
    queryFn: adaptSDKReadRequest(swiftClient.entity, entityId),
    enabled,
  })
