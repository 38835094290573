import { Select, SelectProps } from '@swiftctrl/swift-component-library'
import {
  CredentialTemplateFieldComponentProps,
  CredentialTemplateFieldFormItem,
} from './utils'

export const CredentialTemplateFieldSelect = ({
  field,
}: CredentialTemplateFieldComponentProps) => {
  const { constraint, placeholder, default_value, read_only } = field

  const options: SelectProps['options'] = constraint!.valid_values!.map(
    ({ value }) => ({
      value,
      label: value,
    }),
  )

  return (
    <CredentialTemplateFieldFormItem field={field}>
      <Select
        showSearch
        options={options}
        placeholder={placeholder}
        defaultValue={default_value || undefined}
        disabled={read_only}
      />
    </CredentialTemplateFieldFormItem>
  )
}
