import { Alert } from '@swiftctrl/swift-component-library'
import { List } from 'antd'
import { EntityTypeTag } from '../../../components'
import { DeletableEntityTypeView } from './DeletableEntityTypeView'
import { DeleteOrganizationModalContentProps } from './utils'

export const AwaitingConfirmationView = ({
  entityTypeToEntitiesArray,
  entitiesCount,
}: DeleteOrganizationModalContentProps) => {
  return (
    <>
      Entities that will be deleted (total {entitiesCount}):
      <List
        split={false}
        size="small"
        dataSource={entityTypeToEntitiesArray}
        renderItem={({ entityType, entities }) => (
          <List.Item>
            <span>
              <EntityTypeTag entityType={entityType} />
              <DeletableEntityTypeView
                entities={entities}
                entityType={entityType}
              />
            </span>
          </List.Item>
        )}
      />
      <Alert
        type="info"
        showIcon
        description="This function is used for deleting organizations created with the onboarding module."
      />
    </>
  )
}
