import { Button, PlusSquareOutlined } from '@swiftctrl/swift-component-library'
import { BitfieldValue } from '../utils'
import { BitfieldPropertiesContainer } from './utils'

type AddBitfieldValueViewProps = {
  onChange: (value: BitfieldValue | null) => void
}

export const AddBitfieldValueView = ({
  onChange,
}: AddBitfieldValueViewProps) => {
  return (
    <BitfieldPropertiesContainer>
      <Button
        type="link"
        size="small"
        onClick={() => onChange({ start_bit: undefined, end_bit: undefined })}
        icon={<PlusSquareOutlined />}
      >
        Add bitfield value
      </Button>
    </BitfieldPropertiesContainer>
  )
}
