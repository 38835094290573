import {
  PersonTypes,
  ProviderProfileBrowseTypes,
  ProviderProfileTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import {
  AddProviderProfileView,
  BaseProviderProfilesBrowser,
} from '../../components'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { BrowseEntitiesConfig, useEventContext } from '../../states'
import { cacheKeys, useBoolean } from '../../utils-hooks'

type PersonProviderProfilesBrowserProps = {
  person: PersonTypes
}

export const PersonProviderProfilesBrowser = ({
  person,
}: PersonProviderProfilesBrowserProps) => {
  const { primary_profile } = person

  const { organization_id } = primary_profile

  const browse = buildBrowse(person)

  const [isAddViewOpen, openAddView, closeAddView] = useBoolean()

  const { useEvent } = useEventContext()

  useEvent(
    'PersonHasNoEmailPasswordProviderProfile.CreateNewButton.click',
    openAddView,
  )

  return (
    <BaseProviderProfilesBrowser
      browse={browse}
      onAddButtonClick={openAddView}
      addView={
        <AddProviderProfileView
          organizationId={organization_id}
          profile={primary_profile}
          open={isAddViewOpen}
          onClose={closeAddView}
        />
      }
    />
  )
}

const buildBrowse = (
  person: PersonTypes,
): BrowseEntitiesConfig<ProviderProfileTypes> => ({
  request: async ({ baseId, dataPerFetch, pageParam, search, signal }) => {
    const q = getQueryHandler<ProviderProfileBrowseTypes>()

    const filters = [q('WHERE', 'profile_id', 'ANY', person.profile_ids)]

    return swiftClientWithoutErrorHandling.providerProfile.browse(
      {
        baseId,
        limit: dataPerFetch,
        offset: pageParam,
        search,
        select: [
          'entity_name',
          'provider_profile_id',
          'overseer_name',
          'overseer_id',
          'login_data',
          'login_attempts',
          'multi_factor_code_expiry',
          'created_at',
          'updated_at',
        ],
        filters,
        sort: search ? undefined : 'entity_name:asc',
      },
      {
        signal,
      },
    )
  },
  cacheKey: [cacheKeys.personProviderProfiles, person.person_id],
})
