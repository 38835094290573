import { Alert } from '@swiftctrl/swift-component-library'
import { BackendError } from '../data/swiftClient'
import { getErrorMessage } from '../utils-hooks'

type BackendErrorAlertProps = {
  error: BackendError | undefined
}

export const BackendErrorAlert = ({ error }: BackendErrorAlertProps) => {
  if (!error) {
    return null
  }

  const errorMessage = buildErrorMessage(error)

  return <Alert message={errorMessage} type="error" />
}

const buildErrorMessage = (error: BackendError) => {
  const statusCode = error.response?.data.code

  const message = error.response?.data.message

  if (!statusCode || !message) {
    return getErrorMessage(error)
  }

  const errorMessage = `Status code ${statusCode}: ${message}`

  return errorMessage
}
