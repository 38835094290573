import { EntityBrowseTypes, EntityTypesIds } from '@swiftctrl/api-client'
import { FilterParams, getQueryHandler } from '@swiftctrl/api-helpers'
import { useInfiniteQuery } from '@tanstack/react-query'
import { TABLE_DATA_PER_FETCH } from '../../../../../components'
import { SWIFT_ROOT_ENTITY_ID } from '../../../../../data/models'
import { swiftClient } from '../../../../../data/swiftClient'
import {
  buildEntityBrowserNextPageParam,
  buildEntityTypeFilter,
  cacheKeys,
} from '../../../../../utils-hooks'
import { TARGET_ENTITY_TYPES } from '../models'

export const useInfiniteBrowseTargets = (
  search: string,
  entityType: EntityTypesIds | undefined,
) =>
  useInfiniteQuery({
    queryKey: [cacheKeys.role_targets_candidates, search, entityType],
    queryFn: ({ pageParam, signal }) => {
      const filters = new Array<FilterParams<EntityBrowseTypes>>()

      if (entityType) {
        filters.push(buildEntityTypeFilter(entityType))
      } else {
        filters.push(buildEntityTypeFilter(...TARGET_ENTITY_TYPES))
      }

      if (search) {
        const q = getQueryHandler<EntityBrowseTypes>()

        filters.push(
          q('AND', [
            q('WHERE', 'entity_name', 'EQ_IC', search),
            q('OR', 'entity_description', 'EQ_IC', search),
          ]),
        )
      }

      return swiftClient.entity.browse(
        {
          baseId: SWIFT_ROOT_ENTITY_ID,
          select: [
            'entity_id',
            'entity_type_id',
            'entity_name',
            'overseer_name',
          ],
          filters,
          offset: pageParam || 0,
          limit: TABLE_DATA_PER_FETCH,
          totalCountInHeader: true,
        },
        {
          signal,
        },
      )
    },
    initialPageParam: 0,
    getNextPageParam: buildEntityBrowserNextPageParam,
  })
