import { Modal } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { FieldGroupConfig } from '../../utils-hooks'
import { getFields } from './getFields'
import { getIsDirty } from './getIsDirty'

export const useEditEntityCommonLogic = <FormData>(
  initialValues: FormData,
  fieldGroups: FieldGroupConfig<FormData>[],
  isSaving: boolean,
  onClose: () => void,
  baseIdIsPreset: boolean,
) => {
  const [values, setValues] = useState<FormData>(initialValues)

  const { fields, fieldsAreValid } = getFields(
    fieldGroups,
    values,
    baseIdIsPreset,
  )

  const isDirty = getIsDirty(initialValues, values, fields)

  const checkBeforeClose = () => {
    if (isSaving) {
      return
    }

    if (isDirty) {
      Modal.confirm({
        title: 'Are you sure you want to discard your changes?',
        okText: 'Discard',
        onOk: resetAndClose,
        centered: true,
      })

      return
    }

    resetAndClose()
  }

  const resetAndClose = () => {
    reset()

    onClose()
  }

  const reset = () => {
    setValues(initialValues)
  }

  return {
    values,
    setValues,
    fields,
    fieldsAreValid,
    isDirty,
    checkBeforeClose,
    reset,
  }
}
