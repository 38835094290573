import {
  CredentialFieldBitfieldValue,
  CredentialFieldConstraint,
} from '@swiftctrl/api-client'
import {
  getBitfieldValueMax as getFormatBitfieldValueMax,
  getBitfieldValueMin as getFormatBitfieldValueMin,
} from '../../../components'
import { RemoveConstraintButton } from '../RemoveConstraintButton'
import { TooltipIcon } from '../TooltipIcon'
import { BitfieldValue, Constraint } from '../utils'
import { IntegerConstraintTypeView } from './IntegerConstraintTypeView'
import { IntegerRangeInput } from './IntegerRangeInput'
import {
  getBitfieldValueMax as getTemplateBitfieldValueMax,
  getBitfieldValueMin as getTemplateBitfieldValueMin,
  IntegerConstraintContainer,
} from './utils'

type FormatBitfieldValueWithTemplateIntegerRangeViewProps = {
  templateConstraint: Constraint
  templateBitfieldValue: BitfieldValue
  formatConstraint: CredentialFieldConstraint
  onChange: (constraint: Constraint | null) => void
}

export const FormatBitfieldValueWithTemplateIntegerRangeView = ({
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: FormatBitfieldValueWithTemplateIntegerRangeViewProps) => {
  const formatBitfieldValue =
    formatConstraint.value as CredentialFieldBitfieldValue

  const formatMin = getFormatBitfieldValueMin(formatBitfieldValue)!

  const formatMax = getFormatBitfieldValueMax(formatBitfieldValue)!

  const templateMin = getTemplateBitfieldValueMin(templateBitfieldValue)!

  const templateMax = getTemplateBitfieldValueMax(templateBitfieldValue)!

  const templateIntegerRange = templateConstraint.integer_range!

  const startDefaultValue =
    templateIntegerRange.start !== undefined
      ? templateIntegerRange.start
      : templateMin

  const endDefaultValue =
    templateIntegerRange.end !== undefined
      ? templateIntegerRange.end
      : templateMax

  const tooltip = getTooltip(formatMin, formatMax, templateMin, templateMax)

  return (
    <IntegerConstraintContainer>
      <IntegerConstraintTypeView
        templateConstraint={templateConstraint}
        templateBitfieldValue={templateBitfieldValue}
        formatConstraint={formatConstraint}
        value="integer_range"
        onChange={onChange}
      />
      <TooltipIcon message={tooltip} />
      <IntegerRangeInput
        startDefaultValue={startDefaultValue}
        endDefaultValue={endDefaultValue}
        min={templateMin}
        max={templateMax}
        onChange={(start, end) => {
          onChange({
            integer_range: { start, end },
            date_range: null,
            valid_values: null,
          })
        }}
      />
      <RemoveConstraintButton onClick={() => onChange(null)} />
    </IntegerConstraintContainer>
  )
}

const getTooltip = (
  formatMin: number,
  formatMax: bigint,
  templateMin: number,
  templateMax: bigint,
) => {
  const origin =
    templateMin === formatMin && templateMax === formatMax
      ? 'format'
      : 'template'

  const minLabel = templateMin.toLocaleString()

  const maxLabel = templateMax.toLocaleString()

  return `Due to the ${origin} field constraints, the integer range min and max are ${minLabel} and ${maxLabel}`
}
