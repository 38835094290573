import {
  Input as ComponentLibraryInput,
  InputProps as ComponentLibraryInputProps,
} from '@swiftctrl/swift-component-library'
import { ChangeEventHandler, FocusEventHandler } from 'react'
import { styled } from 'styled-components'

type InputProps = Omit<
  ComponentLibraryInputProps,
  'value' | 'onChange' | 'onBlur'
> & {
  value: string | null
  onChange: (value: string) => void
  onBlur?: (value: string) => void
}

export const Input = ({
  value,
  onChange,
  onBlur,
  allowClear = true,
  ...props
}: InputProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    onChange(event.target.value)

  const handleBlur: FocusEventHandler<HTMLInputElement> | undefined = onBlur
    ? (event) => onBlur(event.target.value)
    : undefined

  return (
    <StyledInput
      value={value || ''}
      onChange={handleChange}
      onBlur={handleBlur}
      bordered={false}
      size="small"
      allowClear={allowClear}
      {...props}
    />
  )
}

const StyledInput = styled(ComponentLibraryInput)`
  padding: 0px;

  .ant-input-suffix {
    margin-left: 0px;
  }

  .ant-input-clear-icon {
    display: none;
  }

  &:hover .ant-input-clear-icon {
    display: block;
  }
`
