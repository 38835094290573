import { useQuery } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { adaptSDKReadRequest } from '../adaptSDKReadRequest'
import { cacheKeys } from '../cacheKeysUtils'

export const useReadCredentialFormat = (id: string, enabled = true) =>
  useQuery({
    queryKey: [cacheKeys.credential_format, id],
    queryFn: adaptSDKReadRequest(
      swiftClientWithoutErrorHandling.credential.formats,
      id,
    ),
    enabled,
  })
