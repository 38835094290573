import { Button, EditOutlined } from '@swiftctrl/swift-component-library'

type ManageSubdataButtonProps = {
  label: string
  onClick: () => void
  enabled?: boolean
  loading?: boolean
}

export const ManageSubdataButton = ({
  label,
  onClick,
  enabled = true,
  loading = false,
}: ManageSubdataButtonProps) => (
  <Button
    type="primary"
    size="middle"
    icon={<EditOutlined />}
    onClick={onClick}
    disabled={!enabled}
    loading={loading}
  >
    {label}
  </Button>
)
