import { CredentialFieldType } from '@swiftctrl/api-client'
import { Input } from './Input'

type CredentialTemplateFieldPlaceholderInputProps = {
  fieldType: CredentialFieldType | undefined
  placeholder: string | null
  onChange: (value: string) => void
}

export const CredentialTemplateFieldPlaceholderInput = ({
  fieldType,
  placeholder,
  onChange,
}: CredentialTemplateFieldPlaceholderInputProps) => {
  switch (fieldType) {
    case 'BOOL':
    case 'BYTE_ARRAY':
      return null
  }

  return <Input value={placeholder || ''} onChange={onChange} />
}
