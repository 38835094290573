import { SelectProps } from '@swiftctrl/swift-component-library'
import { AutoComplete } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import {
  INDEX_REGEX_TOKEN,
  useGetLocalFieldDefinitions,
} from '../../../utils-hooks'

type LocalFieldInputProps = {
  value: string
  onChange: (value: string) => void
}

export const LocalFieldInput = ({ value, onChange }: LocalFieldInputProps) => {
  const allOptions = useGetLocalFieldOptions()

  const [options, setOptions] = useState<SelectProps['options']>([])

  useEffect(() => {
    const search = value.toLocaleLowerCase()

    const results = allOptions.filter((option) =>
      option.value.toLocaleLowerCase().includes(search),
    )

    setOptions(results)
  }, [allOptions, value])

  return <AutoComplete value={value} onChange={onChange} options={options} />
}

const useGetLocalFieldOptions = () => {
  const { data } = useGetLocalFieldDefinitions()

  const options = useMemo(() => {
    if (!data?.data) {
      return []
    }

    return data.data.map((option) => {
      const tokens = option.split(INDEX_REGEX_TOKEN)

      const updatedOption = tokens.join('[INDEX]')

      return { value: updatedOption, label: updatedOption }
    })
  }, [data?.data])

  return options
}
