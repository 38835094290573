import { RoleOperationTypes } from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../utils-hooks'
import { invalidateGetRoleOperationsQuery } from './useGetRoleOperations'

export const useRemoveRoleOperation = (roleId: string) =>
  useMutation({
    mutationFn: (roleOperation: RoleOperationTypes) => {
      const { prefix, action, scope, modifier, grants_on } = roleOperation

      const roleOperationEndpoint = swiftClientWithoutErrorHandling.role
        .at(roleId)
        .operations.at(prefix, action, scope, modifier)

      if (grants_on) {
        return roleOperationEndpoint.grantedOn.at(grants_on.entity_id).delete()
      }

      return roleOperationEndpoint.delete()
    },
    onSuccess: () => {
      showSuccessNotification('Permission removed')

      invalidateGetRoleOperationsQuery(roleId)
    },
    onError: (error) => {
      showErrorNotification('Error while removing permission', error)
    },
  })
