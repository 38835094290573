import {
  ProfilesTypes,
  ProviderProfileBrowseTypes,
  ProviderProfileTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { ColumnsType } from '@swiftctrl/swift-component-library'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
} from '../../../../states'
import {
  adaptSDKBrowseRequest,
  cacheKeys,
  useBoolean,
} from '../../../../utils-hooks'
import {
  CopyableCodeDisplay,
  CopyableDisplay,
  CreatedUpdatedDateDisplay,
} from '../../../data-display'
import {
  EntitiesTableBrowser,
  TABLE_DATA_PER_FETCH,
} from '../../EntitiesTableBrowser'
import { AddProviderProfileView } from './AddProviderProfileView'
import { LoginDataDisplay } from './LoginDataDisplay'
import { ProviderProfileProviderDisplay } from './ProviderProfileProviderDisplay'

type ProviderProfilesBrowserProps = {
  organizationId: string
  profile: ProfilesTypes
}

export const ProviderProfilesBrowser = ({
  organizationId,
  profile,
}: ProviderProfilesBrowserProps) => {
  const browse = buildBrowse(profile.profile_id)

  const [isAddViewOpen, openAddView, closeAddView] = useBoolean()

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={TABLE_DATA_PER_FETCH}
      browse={browse}
      dataKey="provider_profile_id"
      searchable
      entityType="provider_profile"
      displayAddButton
      onAddButtonClick={openAddView}
    >
      <EntitiesTableBrowser columns={columns} />
      <AddProviderProfileView
        organizationId={organizationId}
        profile={profile}
        open={isAddViewOpen}
        onClose={closeAddView}
      />
    </BrowseEntitiesContextProvider>
  )
}

const buildBrowse = (
  profileId: string,
): BrowseEntitiesConfig<ProviderProfileTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClient.providerProfile.browse,
    select: [
      'entity_name',
      'provider_profile_id',
      'overseer_name',
      'overseer_id',
      'login_data',
      'login_attempts',
      'multi_factor_code_expiry',
      'created_at',
      'updated_at',
    ],
    getFilters: () => {
      const q = getQueryHandler<ProviderProfileBrowseTypes>()

      return [q('WHERE', 'profile_id', 'EQ', profileId)]
    },
    getSearch: (search) => search,
    sort: 'entity_name:asc',
  }),
  cacheKey: [cacheKeys.provider_profiles, profileId],
})

const columns: ColumnsType<Partial<ProviderProfileTypes>> = [
  {
    title: 'Provider Profile Name',
    render: (_, providerProfile) => {
      const { entity_name, provider_profile_id } = providerProfile

      if (entity_name) {
        return (
          <CopyableDisplay
            label={entity_name}
            copyableText={provider_profile_id}
          />
        )
      }

      return <CopyableCodeDisplay label={provider_profile_id!} />
    },
    width: '25%',
  },
  {
    title: 'Provider',
    render: (_, providerProfile) => (
      <ProviderProfileProviderDisplay providerProfile={providerProfile} />
    ),
    width: '25%',
  },
  {
    title: 'Login Data',
    render: (_, providerProfile) => (
      <LoginDataDisplay providerProfile={providerProfile} />
    ),
    width: '25%',
  },
  {
    title: 'Created / Updated Date',
    render: (_, providerProfile) => {
      const { created_at, updated_at } = providerProfile

      return (
        <CreatedUpdatedDateDisplay
          createdAt={created_at}
          updatedAt={updated_at}
        />
      )
    },
    width: '25%',
  },
]
