import { EntityTypes } from '@swiftctrl/api-client'
import { getReadScreenQueryKey } from '../../../../../components'
import { swiftClient } from '../../../../../data/swiftClient'
import {
  cacheKeys,
  useBatchAddDeleteRequests,
} from '../../../../../utils-hooks'

type Params = {
  roleId: string
  onSuccess: () => void
  onSettled: () => void
}

export const useBatchAddDeleteTargets = ({
  roleId,
  onSuccess,
  onSettled,
}: Params) => {
  const { batchAddDeleteRequests } = useBatchAddDeleteRequests<
    Partial<EntityTypes>
  >({
    addRequest: (target) =>
      swiftClient.role.at(roleId).grantsOn.add(target.entity_id!),
    buildAddErrorMessage: (target, statusCode: string, errorMessage: string) =>
      `Status code ${statusCode} while adding target "${target.entity_name}": "${errorMessage}"`,
    deleteRequest: (target) =>
      swiftClient.role.at(roleId).grantsOn.at(target.entity_id!).delete(),
    buildDeleteErrorMessage: (
      target,
      statusCode: string,
      errorMessage: string,
    ) =>
      `Status code ${statusCode} while removing target "${target.entity_name}": "${errorMessage}"`,
    invalidateQueryKeys: getReadScreenQueryKey(cacheKeys.role, roleId),
    onSuccess,
    onSettled,
  })

  return { batchAddDeleteTargets: batchAddDeleteRequests }
}
