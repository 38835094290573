import {
  CredentialFieldConstraint,
  CredentialFormatsTypes,
  CredentialTemplateFieldConstraint,
  CredentialTemplateFieldTypes,
} from '@swiftctrl/api-client'

export type EditCredentialTemplateFieldFunction = (
  id: string,
  key: keyof Pick<
    CredentialTemplateFieldTypes,
    | 'display_name'
    | 'constraint'
    | 'default_value'
    | 'is_hidden'
    | 'is_optional'
    | 'placeholder'
    | 'tooltip'
    | 'bitfield_value'
    | 'read_only'
  >,
  value: string | boolean | Constraint | BitfieldValue | null,
) => void

export type CredentialTemplateFieldConstraintViewProps = {
  templateConstraint: Constraint | null
  templateBitfieldValue?: BitfieldValue | null
  formatConstraint: CredentialFieldConstraint | undefined
  onChange: (constraint: Constraint | null) => void
}

export type CredentialTemplateField = Omit<
  CredentialTemplateFieldTypes,
  'constraint' | 'bitfield_value'
> & {
  constraint: Constraint | null
  bitfield_value: BitfieldValue | null
}

export type Constraint = Omit<
  CredentialTemplateFieldConstraint,
  'integer_range'
> & {
  integer_range: IntegerRange | null
}

export type IntegerRange = {
  start: number | bigint | undefined
  end: number | bigint | undefined
}

export const getCredentialFormatField = (
  credentialFormat: CredentialFormatsTypes,
  field: CredentialTemplateField,
) => {
  const { credential_field_formats } = credentialFormat

  const { credential_field_format_id } = field

  const credentialFormatField = credential_field_formats.find(
    (field) => field.credential_field_format_id === credential_field_format_id,
  )

  if (!credentialFormatField) {
    throw new Error(
      `No credential format field for template field ${credential_field_format_id}`,
    )
  }

  return credentialFormatField
}

export type BitfieldValue = {
  start_bit: number | undefined
  end_bit: number | undefined
}

export const CREDENTIAL_TEMPLATE_INVALID_MESSAGE =
  'One or more fields are invalid'
