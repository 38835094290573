import { Empty } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { EntityType } from '../../data/models'
import { getEntityLabel } from '../../config/labels'

interface EmptyPlaceholderProps {
  entityType: EntityType
}

export const EmptyPlaceholder = ({ entityType }: EmptyPlaceholderProps) => (
  <StyledEmptyPlaceholder>
    <Empty
      description={`No ${getEntityLabel({
        entityType,
        singular: false,
        capitalize: false,
      })}`}
    />
  </StyledEmptyPlaceholder>
)

const StyledEmptyPlaceholder = styled.div`
  width: 100%;
`
