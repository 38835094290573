import {
  Button,
  PreviewOutlined,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import { useBoolean, useReadCredentialTemplate } from '../../../../utils-hooks'
import { CredentialTemplatePreviewView } from '../../../credential-template-preview'
import { useValidateCredentialTemplateBeforeCallback } from './utils'

type CredentialTemplatePreviewButtonProps = {
  credentialTemplateId: string
}

export const CredentialTemplatePreviewButton = ({
  credentialTemplateId,
}: CredentialTemplatePreviewButtonProps) => {
  const [isPreviewOpen, openPreview, closePreview] = useBoolean()

  const { data, error } = useReadCredentialTemplate({
    id: credentialTemplateId,
    enabled: isPreviewOpen,
  })

  const tooltipMessage = error
    ? `Error while reading credential template: ${String(error)}`
    : null

  const { validateCredentialTemplateBeforeCallback } =
    useValidateCredentialTemplateBeforeCallback(
      credentialTemplateId,
      openPreview,
    )

  return (
    <>
      <Tooltip title={tooltipMessage}>
        <Button
          size="middle"
          icon={<PreviewOutlined />}
          onClick={validateCredentialTemplateBeforeCallback}
          loading={isPreviewOpen && !data && !error}
          disabled={Boolean(error)}
        >
          Preview
        </Button>
      </Tooltip>
      {data && (
        <CredentialTemplatePreviewView
          open={isPreviewOpen}
          credentialTemplate={data}
          onClose={closePreview}
          displayViewDetailsButton
        />
      )}
    </>
  )
}
