import { PersonRoleArcheTypes, PersonTypes } from '@swiftctrl/api-client'
import { ActionsDropdown } from '../../../components'
import { swiftClientWithoutErrorHandling } from '../../../data/swiftClient'
import { useHandleDeleteClick } from '../../../utils-hooks'
import { personRoleQueryKeys } from './invalidatePersonRoleQueries'

type ArchetypeActionsDropdownProps = {
  archetype: Partial<PersonRoleArcheTypes>
  person: PersonTypes
}

export const ArchetypeActionsDropdown = ({
  archetype,
  person,
}: ArchetypeActionsDropdownProps) => {
  const { handleDeleteClick } = useHandleDeleteClick({
    entity: archetype,
    entityType: 'archetype',
    action: 'remove',
    mutationFn: () =>
      swiftClientWithoutErrorHandling.person
        .at(person.person_id)
        .roleArcheTypes.at(String(archetype.role_archetype_id))
        .unassign(),
    invalidateQueryKeys: personRoleQueryKeys,
  })

  return (
    <ActionsDropdown
      items={[
        {
          key: 'remove',
          label: 'Remove',
          danger: true,
          onClick: handleDeleteClick,
        },
      ]}
    />
  )
}
