import { useQuery } from '@tanstack/react-query'
import { queryClient } from '../../data/queryClient'

const READ_SCREEN_QUERY_KEY = 'ReadScreen'

export const useRead = <T>({
  readRequest,
  cacheKey,
  id,
  enabled,
}: {
  readRequest: (id: string) => Promise<T>
  cacheKey: string
  id: string
  enabled?: boolean
}) =>
  useQuery({
    queryKey: getReadScreenQueryKey(cacheKey, id),
    queryFn: () => readRequest(id),
    enabled,
  })

export const getReadScreenQueryKey = (cacheKey: string, id: string) => [
  READ_SCREEN_QUERY_KEY,
  cacheKey,
  id,
]

export const invalidateReadScreenQuery = (cacheKey: string, id: string) => {
  const queryKey = getReadScreenQueryKey(cacheKey, id)

  queryClient.invalidateQueries({ queryKey })
}
