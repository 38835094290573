import SyntaxHighlighter from 'react-syntax-highlighter'
import styled from 'styled-components'
import { stringifyJson } from '../../utils-hooks'

type JsonDisplayProps = {
  value: unknown
}

export const JsonDisplay = ({ value }: JsonDisplayProps) => (
  <StyledSyntaxHighlighter wrapLines language="json" wrapLongLines>
    {stringifyJson(value)}
  </StyledSyntaxHighlighter>
)

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  font-size: 13px;
`
