import { Button, Layout, Text } from '@swiftctrl/swift-component-library'
import { getEntityLabelByCount } from '../../../../config/labels'

type InfoBarProps = {
  selectedRolesCount: number
  onRemoveAllClick: () => void
}

export const InfoBar = ({
  selectedRolesCount,
  onRemoveAllClick,
}: InfoBarProps) => {
  const rolesLabel = getEntityLabelByCount({
    entityType: 'role',
    count: selectedRolesCount,
  })

  const countLabel = selectedRolesCount > 0 ? selectedRolesCount : 'No'

  const label = `${countLabel} ${rolesLabel} selected`

  return (
    <Layout horizontal justifyContent="space-between" alignItems="center">
      <Text size="20px">{label}</Text>
      <Button danger type="text" onClick={onRemoveAllClick}>
        Remove all
      </Button>
    </Layout>
  )
}
