import { Button, PlusSquareOutlined } from '@swiftctrl/swift-component-library'

type AddConstraintButtonProps = {
  onClick: () => void
}

export const AddConstraintButton = ({ onClick }: AddConstraintButtonProps) => {
  return (
    <Button
      type="link"
      size="small"
      onClick={onClick}
      icon={<PlusSquareOutlined />}
    >
      Add constraint
    </Button>
  )
}
