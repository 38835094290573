import { EntityType } from '../data/models'
import {
  EPIS_SCREEN_PATH,
  ORGANIZATIONS_SCREEN_PATH,
  PROVIDERS_SCREEN_PATH,
  ROLES_SCREEN_PATH,
} from './paths'

type ReadEntityScreenConfig = {
  [entityType in EntityType]?: string
}

const readEntityScreenConfig: ReadEntityScreenConfig = {
  epi: EPIS_SCREEN_PATH,
  organization: ORGANIZATIONS_SCREEN_PATH,
  provider: PROVIDERS_SCREEN_PATH,
  role: ROLES_SCREEN_PATH,
}

export const hasReadEntityScreenPath = (entityType: EntityType) =>
  entityType in readEntityScreenConfig

export const getReadEntityScreenPath = (entityType: EntityType) => {
  const path = readEntityScreenConfig[entityType]

  if (path) {
    return path
  }

  throw new Error(
    `Read screen path for entity type ${entityType} is being obtained without first checking if it exists. This is a developer error - please let your friendly ConfigCloud team know about this 🧑‍💻`,
  )
}
