import { FieldConfig } from '../../utils-hooks'

export const getIsDirty = <FormData>(
  initial: FormData,
  current: FormData,
  fields: FieldConfig<FormData>[],
) => {
  const fieldsForDirtyCheck = getFieldsForDirtyCheck(fields)

  const dirty = fieldsForDirtyCheck.some((field) =>
    isValueDirty(initial, current, field.key),
  )

  return dirty
}

const getFieldsForDirtyCheck = <FormData>(fields: FieldConfig<FormData>[]) => {
  const fieldsForDirtyCheck = fields.filter((field) => {
    const { editable = true } = field

    return editable
  })

  return fieldsForDirtyCheck
}

const isValueDirty = <FormData>(
  initial: FormData,
  current: FormData,
  key: keyof FormData,
) => {
  const initialValue = initial[key]

  const currentValue = current[key]

  if (!initialValue && !currentValue) {
    return false
  }

  if (initialValue === currentValue) {
    return false
  }

  return true
}
