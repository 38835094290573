import { ProviderProfileTypes, ProviderTypes } from '@swiftctrl/api-client'
import {
  ActionsDropdown,
  ActionsDropdownItem,
  EditProviderProfileView,
} from '../../components'
import { useBrowseEntitiesContext } from '../../states'
import { useBoolean } from '../../utils-hooks'

type ProviderProfileActionsDropdownProps = {
  providerProfile: Partial<ProviderProfileTypes>
  provider: ProviderTypes
  onProviderProfileDelete: (
    providerProfileId: string,
    providerProfileName: String,
  ) => void
}

export const ProviderProfileActionsDropdown = ({
  providerProfile,
  provider,
  onProviderProfileDelete,
}: ProviderProfileActionsDropdownProps) => {
  const { provider_profile_id, entity_name, updated_at, created_at } =
    providerProfile

  const [isEditing, startEditing, stopEditing] = useBoolean()

  const { invalidateData } = useBrowseEntitiesContext()

  const handleEdited = () => {
    invalidateData()

    stopEditing()
  }

  const items: ActionsDropdownItem[] = [
    {
      key: 'edit',
      label: 'Edit',
      onClick: startEditing,
    },
    {
      key: 'delete',
      label: 'Delete',
      danger: true,
      onClick: () =>
        onProviderProfileDelete(provider_profile_id!, entity_name!),
    },
  ]

  return (
    <>
      <ActionsDropdown items={items} />
      <EditProviderProfileView
        key={updated_at || created_at}
        open={isEditing}
        providerProfile={providerProfile}
        provider={provider}
        onEdited={handleEdited}
        onClose={stopEditing}
      />
    </>
  )
}
