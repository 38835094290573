import { useQuery } from '@tanstack/react-query'
import { queryClient } from '../../data/queryClient'
import { swiftClient } from '../../data/swiftClient'
import { cacheKeys } from '../cacheKeysUtils'

export const useReadProvider = (providerId: string, enabled = true) =>
  useQuery({
    queryKey: getQueryKey(providerId),
    queryFn: () => swiftClient.provider.at(providerId).read(),
    enabled,
  })

const getQueryKey = (providerId: string) => [cacheKeys.provider, providerId]

export const invalidateReadProviderQuery = (providerId: string) => {
  queryClient.invalidateQueries({ queryKey: getQueryKey(providerId) })
}
