import { SinkTypes } from '@swiftctrl/api-client'
import { TabsProps } from '@swiftctrl/swift-component-library'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  EnabledDisplay,
  EntityAncestryView,
  EntityTimestampsDisplay,
  HeaderContent,
  HeaderContentProps,
  ReadScreen,
} from '../components'
import {
  ORGANIZATIONS_SCREEN_PATH,
  ORGANIZATION_SCREEN_SOURCES_TAB,
  SINK_SCREEN_ANCESTRY_TAB,
  SINK_SCREEN_FILTER_GROUPS_TAB,
  SINK_SCREEN_MULTI_TARGETS_TAB,
  SINK_SCREEN_PLACEHOLDERS_TAB,
  SINK_SCREEN_RULES_TAB,
} from '../config/paths'
import { EntityType } from '../data/models'
import { swiftClientWithoutErrorHandling } from '../data/swiftClient'
import {
  FilterGroupsView,
  MultiTargetsView,
  PlaceholdersView,
  RulesView,
} from '../screen-components/sink-screen'
import {
  adaptSDKRead,
  buildEntityTypeTagsList,
  buildLabel,
  cacheKeys,
  normalizeFilterConfiguration,
  useGetOverseerOrganization,
} from '../utils-hooks'

export const sinkScreenTabs = [
  SINK_SCREEN_FILTER_GROUPS_TAB,
  SINK_SCREEN_RULES_TAB,
  SINK_SCREEN_MULTI_TARGETS_TAB,
  SINK_SCREEN_PLACEHOLDERS_TAB,
  SINK_SCREEN_ANCESTRY_TAB,
] as const

type SinkScreenTab = (typeof sinkScreenTabs)[number]

type SinkScreenProps = {
  tab?: SinkScreenTab
}

export const SinkScreen = ({ tab }: SinkScreenProps) => {
  const { organizationId } = useParams()

  return (
    <StyledDiv>
      <ReadScreen<SinkTypes>
        paramUuidProps={[
          {
            paramKey: 'organizationId',
            entityType: 'organization',
            redirectPath: `/${ORGANIZATIONS_SCREEN_PATH}`,
          },

          {
            paramKey: 'sinkId',
            entityType: 'sink',
            redirectPath: `/${ORGANIZATIONS_SCREEN_PATH}/${organizationId}`,
            redirectButtonLabel: 'Go to organization screen',
          },
        ]}
        readRequest={adaptSDKRead(swiftClientWithoutErrorHandling.sink)}
        cacheKey={cacheKeys.sink}
        entityType="sink"
        headerContentFactory={(sink) => <SinkHeader sink={sink} />}
        contentFactory={{
          tab,
          tabs: sinkScreenTabs,
          tabPanesFactory,
          tabBarExtraContent: (sink) => (
            <EntityTimestampsDisplay
              created_at={sink.created_at!}
              updated_at={sink.updated_at!}
            />
          ),
        }}
      />
    </StyledDiv>
  )
}

const SinkHeader = ({ sink }: { sink: SinkTypes }) => {
  const {
    sink_id,
    entity_name,
    entity_description,
    overseer_id,
    overseer_name,
    source_id,
    source_name: { name: source_name },
    sink_owner_id,
    sink_owner: { entity_name: sink_owner_name },
    sink_target_id,
    sink_target_entity: { entity_name: sink_target_entity_name },
    types_using_target_id,
    disabled,
    start_offset,
    allow_requester_response,
    replica_generator,
    extra_json,
  } = sink

  const { data: overseerOrganization, isPending } = useGetOverseerOrganization({
    entity: sink,
  })

  const overseerName = overseerOrganization?.entity_name

  const overseerId = overseerOrganization?.entity_id

  const headerContentProps: HeaderContentProps = {
    entityName: entity_name,
    entityId: sink_id,
    entityType: 'sink' as EntityType,
    entityDescription: entity_description,
    isBreadcrumbLoading: isPending,
    breadCrumbPath: [
      {
        label: 'Organizations',
        url: `/${ORGANIZATIONS_SCREEN_PATH}`,
      },
      {
        label: overseerName || '',
        url: `/${ORGANIZATIONS_SCREEN_PATH}/${overseerId}/${ORGANIZATION_SCREEN_SOURCES_TAB}`,
      },
    ],
    infoItemsLeft: [
      {
        title: 'Source',
        value: source_name,
        copyableText: source_id,
      },
      {
        title: 'Overseer',
        value: overseer_name,
        copyableText: overseer_id,
      },
      {
        title: 'Owner',
        value: sink_owner_name,
        copyableText: sink_owner_id,
      },
      {
        title: 'Sink target',
        value: sink_target_entity_name,
        copyableText: sink_target_id,
      },
      {
        title: 'Type Names',
        value: buildEntityTypeTagsList(types_using_target_id),
      },
    ],
    infoItemsRight: [
      {
        title: 'allow_requester_response',
        value: <EnabledDisplay enabled={allow_requester_response} />,
      },
      {
        title: 'disabled',
        value: <EnabledDisplay enabled={disabled} />,
      },
      {
        title: 'replica_generator',
        value: <EnabledDisplay enabled={replica_generator} />,
      },
      {
        title: 'start_offset',
        value: start_offset,
      },
    ],
    extraJson: extra_json,
  }

  return <HeaderContent {...headerContentProps} />
}

const tabPanesFactory = (sink: SinkTypes) => {
  const {
    filter_configuration,
    rules,
    multi_target_predicates,
    placeholders,
    sink_id,
  } = sink

  const filterConfiguration = normalizeFilterConfiguration(filter_configuration)

  const {
    route_filter,
    structure_filter,
    target_filter,
    mirror_target_filter,
    association_filter,
  } = filterConfiguration

  const filterGroupsCount = [
    route_filter,
    structure_filter,
    target_filter,
    mirror_target_filter,
    association_filter,
  ].reduce((count, filterGroup) => (filterGroup ? count + 1 : count), 0)

  const filterGroupsTabLabel = buildLabel('Filter groups', filterGroupsCount)

  const rulesTabLabel = buildLabel('Rules', rules.length)

  const multiTargetsTabLabel = buildLabel(
    'Multi Targets',
    multi_target_predicates?.length || 0,
  )

  const placeholdersTabLabel = buildLabel(
    'Placeholders',
    placeholders?.length || 0,
  )

  const tabs: TabsProps['items'] = [
    {
      key: SINK_SCREEN_FILTER_GROUPS_TAB,
      label: filterGroupsTabLabel,
      children: <FilterGroupsView filterConfiguration={filterConfiguration} />,
    },
    {
      key: SINK_SCREEN_RULES_TAB,
      label: rulesTabLabel,
      children: <RulesView rules={rules} />,
    },
    {
      key: SINK_SCREEN_MULTI_TARGETS_TAB,
      label: multiTargetsTabLabel,
      children: <MultiTargetsView multiTargets={multi_target_predicates} />,
    },
    {
      key: SINK_SCREEN_PLACEHOLDERS_TAB,
      label: placeholdersTabLabel,
      children: <PlaceholdersView placeholders={placeholders} rules={rules} />,
    },
    {
      key: SINK_SCREEN_ANCESTRY_TAB,
      label: 'Ancestry',
      children: <EntityAncestryView entity={{ ...sink, entity_id: sink_id }} />,
    },
  ]

  return tabs
}

const StyledDiv = styled.div`
  .ant-tabs-tabpane {
    background-color: transparent;
  }
`
