import { EntityType } from '../data/models'
import { providerFieldLabels } from './labels'

type EntityTypeConfig = {
  fields: FieldConfigMap
}

export type FieldConfigMap = { [key: string]: FieldConfig }

export type FieldConfig =
  | {
      /**
       * If omitted, displays the key as a code text
       */
      label?: string
      /**
       * If omitted, the code does a sensible default. Set a value to force a specific display
       */
      display?: 'hide' | 'number' | 'uuid' | 'json' | 'text' | 'boolean'
    }
  | string

type EntityDetailsConfig = {
  common: FieldConfigMap
  types: { [entityType in EntityType]?: EntityTypeConfig }
}

export const entityDetailsConfig: EntityDetailsConfig = {
  common: {
    'entity.created_at': { display: 'hide' },
    'entity.entity_description': { display: 'hide' },
    'entity.entity_id': { display: 'hide' },
    'entity.entity_name': { display: 'hide' },
    'entity.entity_status': { display: 'hide' },
    'entity.entity_type_id': { display: 'hide' },
    'entity.overseer_id': { display: 'hide' },
    'entity.overseer_name': { display: 'hide' },
    'entity.updated_at': { display: 'hide' },
    created_at: { display: 'hide' },
    entity_description: { display: 'hide' },
    entity_name: { display: 'hide' },
    entity_status: { display: 'hide' },
    entity_type_id: { display: 'hide' },
    overseer_id: { display: 'hide' },
    overseer_name: { display: 'hide' },
    updated_at: { display: 'hide' },
  },
  types: {
    building: {
      fields: {
        building_id: { display: 'hide' },
        'address.address_id': { display: 'hide' },
        'address.address_line_1': 'Address line 1',
        'address.address_line_2': 'Address line 2',
        'address.city': 'Address city',
        'address.country': 'Address country',
        'address.created_at': { display: 'hide' },
        'address.entity_description': { display: 'hide' },
        'address.entity_name': { display: 'hide' },
        'address.entity_status': { display: 'hide' },
        'address.entity_type_id': { display: 'hide' },
        'address.overseer_id': { display: 'hide' },
        'address.overseer_name': { display: 'hide' },
        'address.state': 'Address state',
        'address.timezone': 'Address timezone',
        'address.updated_at': { display: 'hide' },
        'address.zip': 'Address zip',
        addresses: { display: 'hide' },
        area: 'Area',
        capacity: 'Capacity',
        floor_count: 'Floor count',
        profile_id: 'Profile ID',
      },
    },
    controller: {
      fields: {
        controller_id: { display: 'hide' },
        type: 'Controller type',
      },
    },
    epi: {
      fields: {
        epi_id: { display: 'hide' },
        'type.epi_type_id': 'EPI type ID',
        'type.name': 'EPI type name',
        'type.type_description': 'EPI type description',
        'subtype.epi_subtype_id': 'Subtype ID',
        'subtype.product_name': 'Subtype product name',
        'subtype.sub_type_description': 'Subtype description',
        'subtype.vendor': 'Subtype vendor',
        core_api_version: 'Core API version',
        epi_version: 'EPI version',
        system_version: 'System version',
        on_premises: { label: 'On-premises', display: 'boolean' },
        'config.sendgrid.host': 'SendGrid host',
        'config.sendgrid.is_production_mode': 'SendGrid production mode',
        'config.sendgrid.sender_email': 'SendGrid sender email',
        'config.sendgrid.sender_name': 'SendGrid sender name',
        'config.templates.multi_factor_authentication.email.sendgrid_template_id':
          'SendGrid MFA email template ID',
        'config.templates.reset_password.email.sendgrid_template_id':
          'SendGrid reset password email template ID',
        'config.templates.single_use_token.email.sendgrid_template_id':
          'SendGrid single-use token email template ID',
        'config.twilio.is_production_mode': 'Twilio production mode',
        'config.epi_core_message.base_path': 'EPI core message base path',
        'config.reset_password_token_link': 'Reset password token link',
        'config.single_use_token_dynamic_link': 'Single-use token dynamic link',
        'config.pass_kit.description': 'Pass kit description',
        'config.pass_kit.pass_type_identifier': 'Pass kit type identifier',
        'config.pass_kit.pkcs12_key_store_file_path':
          'Pass kit PKCS12 keystore file path',
        'config.pass_kit.team_identifier': 'Pass kit team identifier',
        'config.pass_kit.wwdr_ca_file_path':
          'Pass kit WWDR certificate file path',
        'config.db.host': 'DB host',
        'config.db.name': 'DB name',
        'config.db.port': 'DB port',
        'config.db.ssl_root_cert': 'DB SSL root certificate path',
        'config.facility_code': 'Facility code',
        'config.format_name': 'Format name',
        'config.origo.application_id': 'Origo application ID',
        'config.origo.base_auth_url': 'Origo base auth URL',
        'config.origo.base_callback_url': 'Origo base callback URL',
        'config.origo.base_credential_url': 'Origo base credential URL',
        'config.origo.base_event_url': 'Origo base event URL',
        'config.origo.base_url': 'Origo base URL',
        'config.origo.base_user_url': 'Origo base user URL',
        'config.origo.filter_id': 'Origo filter ID',
        'config.origo.pass_template_id': 'Origo pass template ID',
        'config.web_server_addr': 'Web server address',
        'config.web_server_url': 'Web server URL',
        'config.api_timeout': 'API timeout',
        'config.api_uri': 'API URI',
        'config.db_host': 'DB host',
        'config.db_max_conn_idle_time': 'DB max connection idle time',
        'config.db_max_idle_connections': 'DB max idle connections',
        'config.db_max_open_connections': 'DB max open connections',
        'config.db_name': 'DB name',
        'config.db_port': 'DB port',
        'config.db_schema': 'DB schema',
        'config.db_ssl_root_certificate_file': 'DB SSL root certificate path',
        'config.mqtt_broker': 'MQTT broker',
        'config.db.db_host': 'DB host',
        'config.db.db_max_conn_idle_time': 'DB max connection idle time',
        'config.db.db_max_idle_connections': 'DB max idle connections',
        'config.db.db_max_open_connections': 'DB max open connections',
        'config.db.db_name': 'DB name',
        'config.db.db_port': 'DB port',
        'config.db.db_schema': 'DB schema',
        'config.db.db_ssl_root_certificate_file':
          'DB SSL root certificate path',
        'config.roles': 'Roles',
        'config.essensys.operateUrl': 'Essensys operate URL',
        'config.nexudus.authenticationUrl': 'Nexudus authentication URL',
        'config.nexudus.urlPostfix': 'Nexudus URL postfix',
        'config.nexudus.urlPrefix': 'Nexudus URL prefix',
        'config.officernd.apiUrl': 'OfficeRnD API URL',
        'config.officernd.authenticationUrl': 'OfficeRnD authentication URL',
        'config.SWIFTCTRL_KEEPALIVE_LOOP': 'SwiftCTRL keepalive loop',
        'config.SWIFTCTRL_ONGUARD_ACCESS_LOOP_INTERVAL':
          'SwiftCTRL OnGuard access loop interval',
        'config.SWIFTCTRL_ONGUARD_DB_MAX_CONN_IDLE_TIME_MIN':
          'SwiftCTRL OnGuard DB max connection idle time (minutes)',
        'config.SWIFTCTRL_ONGUARD_DB_MAX_IDLE_CONNS':
          'SwiftCTRL OnGuard DB max idle connections',
        'config.SWIFTCTRL_ONGUARD_DB_MAX_OPEN_CONNS':
          'SwiftCTRL OnGuard DB max open connections',
        'config.SWIFTCTRL_ONGUARD_EVENTS_LOOP_INTERVAL':
          'SwiftCTRL OnGuard events loop interval',
        'config.SWIFTCTRL_ONGUARD_LOOP_INTERVAL':
          'SwiftCTRL OnGuard loop interval',
        'config.SWIFTCTRL_PAGE_SIZE': 'SwiftCTRL page size',
      },
    },
    organization: {
      fields: {
        organization_id: { display: 'hide' },
        profile_id: 'Profile ID',
      },
    },
    profile: {
      fields: {
        profile_id: { display: 'hide' },
        'address.address_id': { display: 'hide' },
        'address.address_line_1': 'Address line 1',
        'address.address_line_2': 'Address line 2',
        'address.city': 'Address city',
        'address.country': 'Address country',
        'address.created_at': { display: 'hide' },
        'address.entity_description': { display: 'hide' },
        'address.entity_name': { display: 'hide' },
        'address.entity_status': { display: 'hide' },
        'address.entity_type_id': { display: 'hide' },
        'address.overseer_id': { display: 'hide' },
        'address.overseer_name': { display: 'hide' },
        'address.state': 'Address state',
        'address.timezone': 'Address timezone',
        'address.updated_at': { display: 'hide' },
        'address.zip': 'Address zip',
        addresses: { display: 'hide' },
        assistant_id: 'Assistant ID',
        avatar: 'Avatar',
        birth_date: 'Birth date',
        department_id: 'Department ID',
        display_name: 'Display name',
        division_id: 'Division ID',
        email: 'Email',
        emails: { display: 'hide' },
        employee_number: 'Employee number',
        first_name: 'First name',
        honorific_prefix: 'Honorific prefix',
        honorific_suffix: 'Honorific suffix',
        last_name: 'Last name',
        locale: 'Locale',
        manager_id: 'Manager ID',
        middle_name: 'Middle name',
        nick_name: 'Nickname',
        organization_id: 'Organization ID',
        person_id: 'Person ID',
        phone_primary: { label: 'Phone primary', display: 'text' },
        phone_secondary: { label: 'Phone primary', display: 'text' },
        phones: { display: 'hide' },
        preferred_language: 'Preferred language',
        timezone: 'Timezone',
        title: 'Title',
      },
    },
    provider: {
      fields: {
        provider_id: { display: 'hide' },
        'provider_type.provider_type_id': 'Provider type ID',
        'provider_type.name': providerFieldLabels.providerType,
        profile_session_ttl: providerFieldLabels.profileSessionTimeToLive,
        max_login_attempts: providerFieldLabels.maxLoginAttempts,
        auth_token_ttl: providerFieldLabels.authTokenTimeToLive,
        multi_factor_code_ttl: 'Multi-factor code time-to-live',
        'data.openid.issuer': providerFieldLabels.issuer,
        'data.openid.audience': providerFieldLabels.audience,
        'data.openid.claim_identifier': providerFieldLabels.claimIdentifier,
        'data.proxy_login.authorized_profile_id':
          providerFieldLabels.authorizedProfileId,
        'data.proxy_login.ip_restriction': providerFieldLabels.ipRestriction,
        'data.proxy_login.case_sensitive_identifier':
          providerFieldLabels.caseSensitiveIdentifier,
        multi_factor_enabled: 'Multi-factor enabled',
      },
    },
    provider_profile: {
      fields: {
        provider_profile_id: { display: 'hide' },
        login_attempts: 'Login attempts',
        multi_factor_code: 'Multi-factor code',
        multi_factor_code_expiry: 'Multi-factor code expiry',
        multi_factor_token: 'Multi-factor token',
        profile_id: 'Profile - ID',
        display_name: 'Profile - display name',
        first_name: 'Profile - first name',
        middle_name: 'Profile - middle name',
        last_name: 'Profile - last name',
        'login_data.email_password.email': 'Email/password - email',
        'login_data.email_password.password': 'Email/password - password',
        'login_data.email_password.verified': 'Email/password - verified',
        'login_data.openid.sub': 'OpenID - sub',
        'login_data.single_use_token.expiry': 'Single use token - expiry',
        'login_data.single_use_token.token': 'Single use token - token',
        'login_data.proxy_login.identifier': 'Proxy login - identifier',
        'login_data.proxy_login.token': 'Proxy login - token',
      },
    },
    reader: {
      fields: {
        reader_id: { display: 'hide' },
        type: 'Reader type',
        'controller.controller_id': { display: 'hide' },
        'controller.created_at': { display: 'hide' },
        'controller.entity_description': { display: 'hide' },
        'controller.entity_name': { display: 'hide' },
        'controller.entity_status': { display: 'hide' },
        'controller.entity_type_id': { display: 'hide' },
        'controller.overseer_id': { display: 'hide' },
        'controller.overseer_name': { display: 'hide' },
        'controller.type': { display: 'hide' },
        'controller.updated_at': { display: 'hide' },
        'epi.created_at': { display: 'hide' },
        'epi.entity_description': { display: 'hide' },
        'epi.entity_id': { display: 'hide' },
        'epi.entity_name': { display: 'hide' },
        'epi.entity_status': { display: 'hide' },
        'epi.entity_type_id': { display: 'hide' },
        'epi.overseer_id': { display: 'hide' },
        'epi.overseer_name': { display: 'hide' },
        'epi.updated_at': { display: 'hide' },
      },
    },
    role: {
      fields: {
        role_id: { display: 'hide' },
        profile_count: 'Profile count',
        permissions: 'Permissions',
        grants_on: 'Grants on',
      },
    },
  },
}
