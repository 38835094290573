import {
  OperationAction,
  OperationPrefix,
  RoleOperationTypes,
} from '@swiftctrl/api-client'
import { useRef } from 'react'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import { createRequestQueue, useBoolean } from '../../../../utils-hooks'
import { ActionError, AddRolePermissionsFormData } from './models'

export const useAddRoleOperations = ({
  roleId,
  onSuccess,
  onErrors,
}: {
  roleId: string
  onSuccess: (prefix: OperationPrefix, operationsCount: number) => void
  onErrors: (
    actionErrors: ActionError[],
    successfulRequestsCount: number,
  ) => void
}) => {
  const [isPending, setPendingTrue, setPendingFalse] = useBoolean()

  const addingOperationsToPrefix = useRef<OperationPrefix | undefined>()

  const successfulRequestsCount = useRef(0)

  const actionsWithFailedRequests = useRef(new Array<ActionError>())

  const { enqueueRequest } = createRequestQueue(() => {
    setPendingFalse()

    if (actionsWithFailedRequests.current.length === 0) {
      onSuccess(
        addingOperationsToPrefix.current!,
        successfulRequestsCount.current,
      )
    } else {
      onErrors(
        actionsWithFailedRequests.current,
        successfulRequestsCount.current,
      )
    }

    addingOperationsToPrefix.current = undefined

    successfulRequestsCount.current = 0

    actionsWithFailedRequests.current = []
  })

  const mutate = async (data: AddRolePermissionsFormData) => {
    const { prefix, actions } = data

    addingOperationsToPrefix.current = prefix

    setPendingTrue()

    actions!.forEach((action) =>
      enqueueRequest(async () => {
        const payload = buildPayload(data, action)

        try {
          await swiftClientWithoutErrorHandling.role
            .at(roleId)
            .operations.add(payload)

          successfulRequestsCount.current++
        } catch (error) {
          actionsWithFailedRequests.current.push({
            action,
            error,
          })
        }
      }),
    )
  }

  return {
    mutate,
    isPending,
  }
}

const buildPayload = (
  data: AddRolePermissionsFormData,
  action: OperationAction,
): RoleOperationTypes => {
  const {
    prefix,
    fieldNames,
    relationNames,
    scope,
    modifier,
    grantsOn,
    grantable,
  } = data

  return {
    prefix: prefix!,
    action,
    field_names: fieldNames || null,
    relation_names: relationNames || null,
    scope: scope!,
    modifier: modifier || null,
    grants_on: grantsOn
      ? {
          entity_id: grantsOn.entity_id!,
          entity_name: grantsOn.entity_name!,
          entity_type_id: grantsOn.entity_type_id!,
        }
      : null,
    grantable: grantable || false,
  }
}
