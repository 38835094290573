import { CredentialFieldIntegerRange } from '@swiftctrl/api-client'
import { getIntegerRangeStartEnd } from '../../../components'
import { RemoveConstraintButton } from '../RemoveConstraintButton'
import { TooltipIcon } from '../TooltipIcon'
import { BitfieldValue } from '../utils'
import { useBitfieldValueLogic } from './useBitfieldValueLogic'
import {
  BitfieldBitIndexInput,
  BitfieldPropertiesContainer,
  getEndBitMax,
  getStartBitMin,
} from './utils'

type FormatIntegerRangeTemplateBitfieldValueViewProps = {
  formatIntegerRange: CredentialFieldIntegerRange
  templateBitfieldValue: BitfieldValue
  onChange: (value: BitfieldValue | null) => void
}

export const FormatIntegerRangeTemplateBitfieldValueView = ({
  formatIntegerRange,
  templateBitfieldValue,
  onChange,
}: FormatIntegerRangeTemplateBitfieldValueViewProps) => {
  const { start_bit, end_bit } = templateBitfieldValue

  const differenceMax = getDifferenceMax(formatIntegerRange)

  const { startBit, endBit, handleStartChange, handleEndChange } =
    useBitfieldValueLogic({
      startBit: start_bit,
      endBit: end_bit,
      onChange,
    })

  const tooltip = `Due to the format field constraints, the bitfield value start and end bit must be at most ${differenceMax} apart`

  return (
    <BitfieldPropertiesContainer>
      bitfield_value
      <TooltipIcon message={tooltip} />
      <BitfieldBitIndexInput
        prefix="Start bit"
        min={getStartBitMin(endBit, differenceMax)}
        max={endBit}
        value={startBit}
        onChange={handleStartChange}
      />
      <BitfieldBitIndexInput
        prefix="End bit"
        min={startBit || 0}
        max={getEndBitMax(startBit, differenceMax)}
        value={endBit}
        onChange={handleEndChange}
      />
      <RemoveConstraintButton onClick={() => onChange(null)} />
    </BitfieldPropertiesContainer>
  )
}

const getDifferenceMax = (formatIntegerRange: CredentialFieldIntegerRange) => {
  const { start, end } = getIntegerRangeStartEnd(formatIntegerRange)

  const range = end - start

  const difference = Math.log2(range)

  const floored = Math.floor(difference)

  return floored - 1
}
