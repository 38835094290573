import {
  CredentialFieldBitfieldParity,
  CredentialFieldBitfieldValue,
  CredentialFieldConstraint,
  CredentialFieldDateRange,
  CredentialFieldFormat,
  CredentialFieldIntegerRange,
  CredentialFieldValidValues,
  CredentialFormatsTypes,
  CredentialTemplateAddTypes,
  CredentialTemplateFieldAddTypes,
  CredentialTemplateFieldBitfieldParity,
  CredentialTemplateFieldBitfieldValue,
  CredentialTemplateFieldConstraint,
} from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import { transformByteArrayToBase64String } from '../../../../utils-hooks'
import {
  AddCredentialTemplateFormData,
  getDateRangeStartEnd,
  getIntegerRangeStartEnd,
} from './utils'

export const useAddCredentialTemplate = ({
  onSuccess,
  onError,
}: {
  onSuccess: (credentialTemplateId: string) => void
  onError: (error: Error) => void
}) =>
  useMutation({
    mutationFn: ({
      values,
      fullCredentialFormat,
    }: {
      values: AddCredentialTemplateFormData
      fullCredentialFormat: CredentialFormatsTypes | undefined
    }) => {
      const { templateName, templateDescription } = values

      const { credential_format_id, credential_field_formats } =
        fullCredentialFormat!

      const payload: CredentialTemplateAddTypes = {
        credential_format_id,
        template_name: templateName!,
        template_description: templateDescription || '',
        credential_field_configs: buildTemplateFields(credential_field_formats),
      }

      return swiftClientWithoutErrorHandling.credentialTemplate.add(payload)
    },
    onSuccess: (response) =>
      onSuccess(response.data.credential_format_config_id),
    onError,
  })

const buildTemplateFields = (fields: CredentialFieldFormat[]) => {
  const sortedFields = fields.slice().sort(alphabetize)

  const templateFields: CredentialTemplateFieldAddTypes[] = sortedFields.map(
    (field, index) => {
      return {
        ...field,
        rank: index,
        display_name: field.field_name,
        placeholder: '' || null,
        tooltip: '' || null,
        is_hidden: field.field_type === 'BYTE_ARRAY',
        default_value: '' || null,
        bitfield_value: buildBitfieldValue(field.constraint),
        bitfield_parity: buildBitfieldParity(field.constraint),
        constraint: buildConstraint(field.constraint),
      }
    },
  )

  return templateFields
}

const alphabetize = (a: CredentialFieldFormat, b: CredentialFieldFormat) =>
  a.field_name.localeCompare(b.field_name)

const buildBitfieldValue = (
  constraint: CredentialFieldConstraint | undefined,
): CredentialTemplateFieldBitfieldValue | null => {
  if (!constraint || constraint.type !== 'bitfield_value') {
    return null
  }

  const { start_bit, end_bit } =
    constraint.value as CredentialFieldBitfieldValue

  return {
    start_bit,
    end_bit,
  }
}

const buildBitfieldParity = (
  constraint: CredentialFieldConstraint | undefined,
): CredentialTemplateFieldBitfieldParity | null => {
  if (!constraint || constraint.type !== 'bitfield_parity') {
    return null
  }

  const { parity_bit_location, parity_even, parity_mask } =
    constraint.value as CredentialFieldBitfieldParity

  return {
    parity_bit_location,
    parity_even,
    parity_mask: transformByteArrayToBase64String(parity_mask),
  }
}

const buildConstraint = (
  constraint: CredentialFieldConstraint | undefined,
): CredentialTemplateFieldConstraint | null => {
  if (!constraint) {
    return null
  }

  const { type, value } = constraint

  switch (type) {
    case 'date_range':
      return buildDateRangeConstraint(value as CredentialFieldDateRange)

    case 'integer_range':
      return buildIntegerRangeConstraint(value as CredentialFieldIntegerRange)

    case 'valid_values':
      return buildValidValuesConstraint(value as CredentialFieldValidValues)

    case 'bitfield_value':
    case 'bitfield_parity':
      // Handled as properties directly on the template field
      return null
  }
}

const buildDateRangeConstraint = (
  dateRange: CredentialFieldDateRange,
): CredentialTemplateFieldConstraint => {
  const date_range = getDateRangeStartEnd(dateRange)

  return {
    date_range,
    integer_range: null,
    valid_values: null,
  }
}

const buildIntegerRangeConstraint = (
  integerRange: CredentialFieldIntegerRange,
): CredentialTemplateFieldConstraint => {
  const integer_range = getIntegerRangeStartEnd(integerRange)

  return {
    integer_range,
    date_range: null,
    valid_values: null,
  }
}

const buildValidValuesConstraint = (
  validValues: CredentialFieldValidValues,
): CredentialTemplateFieldConstraint => {
  return {
    valid_values: validValues.valid_values,
    date_range: null,
    integer_range: null,
  }
}
