import { delay } from './delay'

const DELAY_MILLISECONDS = 50

/**
 * By default, this adds a small delay between requests.
 *
 * This is to avoid sending requests to the BE too frequently,
 * which results in a 429 "Too Many Requests" error.
 */
export const createRequestQueue = (onSettled?: () => void) => {
  const queue: (() => Promise<void>)[] = []

  let processingQueue = false

  const enqueueRequest = (request: () => Promise<void>) => {
    queue.push(request)

    if (!processingQueue) {
      processQueue()
    }
  }

  const processQueue = async () => {
    processingQueue = true

    while (queue.length > 0) {
      let request = queue.shift()!

      await request()

      await delay(DELAY_MILLISECONDS)
    }

    onSettled?.()

    processingQueue = false
  }

  /**
   * Important: be sure to pass in the same request instance!
   *
   * This function removes the request based on equality.
   */
  const removeRequest = (request: () => Promise<void>) => {
    const index = queue.indexOf(request)

    if (index > -1) {
      queue.splice(index, 1)
    }
  }

  return {
    enqueueRequest,
    removeRequest,
  }
}
