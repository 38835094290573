import { ProfilesTypes } from '@swiftctrl/api-client'

export interface RoleProfile {
  profile: Partial<ProfilesTypes>
  assigned: boolean
  selected: boolean
}

interface BuildRoleProfileProps {
  profiles?: Partial<ProfilesTypes>[]
  assigned: boolean
  selected: boolean
}

export const buildRoleProfile = ({
  profiles,
  assigned,
  selected,
}: BuildRoleProfileProps): RoleProfile[] => {
  return (
    profiles?.map((profile: Partial<ProfilesTypes>) => ({
      profile,
      assigned,
      selected,
    })) || []
  )
}
