import { ManagedSpacesTypes, PersonTypes } from '@swiftctrl/api-client'
import { Drawer, Form, Input } from '@swiftctrl/swift-component-library'
import { AddFormButtonGroup, BackendErrorAlert } from '../../../components'
import {
  INVALID_EMAIL_FORMAT_MESSAGE,
  VALID_EMAIL_FORMAT,
} from '../../../data/models'
import {
  buildCommonDrawerProps,
  buildCommonFormItemProps,
  buildCommonFormProps,
} from '../../../utils-hooks'
import { BuildingsSelect } from './BuildingsSelect'
import { OrganizationArchetypesSelect } from './OrganizationArchetypesSelect'
import { useAddArchetypeLogic } from './useAddArchetypeLogic'

type AddArchetypeViewProps = {
  person: PersonTypes
  buildings: Partial<ManagedSpacesTypes>[]
  open: boolean
  onClose: () => void
}

export const AddArchetypeView = ({
  person,
  buildings,
  open,
  onClose,
}: AddArchetypeViewProps) => {
  const {
    values,
    setValues,
    error,
    checkBeforeClose,
    handleFinish,
    isPending,
    isValid,
  } = useAddArchetypeLogic(person, buildings, onClose)

  const { archetype } = values

  const selectMultipleBuildings = archetype?.building_selection === 'multiple'

  const showBuildingsSelect =
    selectMultipleBuildings || archetype?.building_selection === 'one'

  const isBuildingRequired = archetype?.role_archetype !== 'TENANT_ADMIN'

  return (
    <Drawer
      {...buildCommonDrawerProps({
        title: 'Add archetype',
        open,
        onClose: checkBeforeClose,
        isPending,
      })}
    >
      <Form
        {...buildCommonFormProps({
          values,
          setValues,
          isPending,
          onFinish: handleFinish,
        })}
      >
        {!person.primary_profile.email && (
          <Form.Item
            {...buildCommonFormItemProps({
              name: 'email',
              label: 'Email',
              required: true,
              rules: [
                {
                  pattern: VALID_EMAIL_FORMAT,
                  message: INVALID_EMAIL_FORMAT_MESSAGE,
                },
              ],
            })}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          {...buildCommonFormItemProps({
            name: 'archetype',
            label: 'Archetype',
            required: true,
          })}
        >
          <OrganizationArchetypesSelect
            organizationId={person.primary_profile.organization_id}
          />
        </Form.Item>
        {showBuildingsSelect && (
          <Form.Item
            {...buildCommonFormItemProps({
              name: 'buildingIds',
              label: selectMultipleBuildings ? 'Building(s)' : 'Building',
              required: isBuildingRequired,
            })}
          >
            <BuildingsSelect
              buildings={buildings}
              multiple={selectMultipleBuildings}
              allowClear={!isBuildingRequired}
            />
          </Form.Item>
        )}
        {error && (
          <Form.Item>
            <BackendErrorAlert error={error} />
          </Form.Item>
        )}
        <AddFormButtonGroup
          onClose={checkBeforeClose}
          isValid={isValid}
          isPending={isPending}
        />
      </Form>
    </Drawer>
  )
}
