import { Alert } from '@swiftctrl/swift-component-library'
import { getErrorMessage } from '../utils-hooks'

type ErrorAlertProps = {
  error: Error | null
}

export const ErrorAlert = ({ error, ...props }: ErrorAlertProps) => {
  if (error === null) {
    return null
  }

  const errorMessage = getErrorMessage(error)

  return <Alert message={errorMessage} type="error" {...props} />
}
