import { hoursToMilliseconds } from 'date-fns'

const keys = [
  'access_level',
  'access_level_credential',
  'access_level_reader_schedule',
  'acs_hardware',
  'acs_transaction',
  'booking',
  'building',
  'buildings',
  'campus',
  'controller',
  'credential',
  'credential_format',
  'credential_template',
  'credential_templates',
  'device',
  'department',
  'division',
  'entity',
  'entity_mirror',
  'entity_mirrors',
  'entity_counter',
  'EntitySelect_default_entity',
  'entityTree',
  'entities',
  'EntityAncestryView_children',
  'EntityChildrenList_children',
  'epi',
  'epi_field_mappings_local_field_definitions',
  'epi_field_mappings_remote_field_definitions',
  'epis',
  'floor',
  'image',
  'landlord_tenant_building_access_levels',
  'meeting',
  'meeting_invitation',
  'NestedSelectEntities_nodes',
  'nova',
  'opening_hours',
  'operations',
  'organization',
  'organization_assigned_buildings',
  'organization_deletable_descendants',
  'organization_descendant_buildings',
  'organization_has_email_password_providers',
  'organization_is_landlord',
  'organization_managed_buildings',
  'organization_profiles',
  'organization_role_archetypes',
  'organization_roles',
  'organizations',
  'OverseerOrganization',
  'pass',
  'payment',
  'person',
  'person_emails',
  'person_profiles',
  'person_role_archetypes',
  'person_roles',
  'personCredentials',
  'personProviderProfiles',
  'persons',
  'portfolio',
  'profile',
  'profile_organization_buildings',
  'profileCredentials',
  'profiles',
  'provider',
  'providers',
  'provider_profile',
  'provider_profiles',
  'reader',
  'report',
  'room',
  'room_price',
  'role',
  'role_archetypes_candidates',
  'role_operations',
  'role_permissions',
  'role_profiles',
  'role_profiles_candidates',
  'role_profiles_infinite',
  'role_target_children',
  'role_targets',
  'role_targets_candidates',
  'roles',
  'schedule',
  'selfProfile',
  'session',
  'sink',
  'sink_template',
  'sinks',
  'source',
  'sources',
  'static_data',
  'system',
  'zone',
] as const

type KeysType = (typeof keys)[number]

type CacheKeys = {
  [key in KeysType]: key
}

export const cacheKeys = keys.reduce(
  (accumulator, current) => ({ ...accumulator, [current]: current }),
  {} as CacheKeys,
)

export const longCacheOptions = {
  gcTime: hoursToMilliseconds(24),
  staleTime: hoursToMilliseconds(24),
}
