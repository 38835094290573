import {
  LiveSearch,
  LiveSearchProps,
  SelectProps,
  ValueTypeProps,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

type BaseLiveSearchProps = {
  value: string
  onChange: LiveSearchProps<string>['onChange']
  disabled?: boolean
  fetchOptions: (search: string) => Promise<ValueTypeProps[]>
  fetchDependencies: any[]
  placeholder?: string
  size?: SelectProps['size']
}

export const BaseLiveSearch = ({
  value,
  onChange,
  disabled,
  fetchOptions,
  fetchDependencies,
  placeholder,
  size = 'middle',
}: BaseLiveSearchProps) => (
  <StyledLiveSearch
    value={value || null}
    onChange={onChange}
    disabled={disabled}
    fetchOptions={[fetchOptions, fetchDependencies]}
    label=""
    size={size}
    placeholder={placeholder}
  />
)

const StyledLiveSearch = styled(LiveSearch)`
  > label {
    // The !important is needed because in Ant, an element style is applied to the label
    display: none !important;
  }
`
