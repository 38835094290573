import { ProfileBrowseTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { useInfiniteQuery } from '@tanstack/react-query'
import { SWIFT_ROOT_ENTITY_ID } from '../../../../data/models'
import { swiftClient } from '../../../../data/swiftClient'
import { cacheKeys } from '../../../../utils-hooks'
import { RoleProfile } from './buildRoleProfile'

const ROLE_PROFILE_DATA_PER_FETCH = 30

interface UseBrowseProfileListParams {
  search: string
  roleId: string
  enabled: boolean
  organizationId: string
}

export const useBrowseProfileList = ({
  search,
  enabled,
  roleId,
  organizationId,
}: UseBrowseProfileListParams) => {
  return useInfiniteQuery({
    queryKey: [
      cacheKeys.role_profiles_candidates,
      roleId,
      search,
      organizationId,
    ],
    queryFn: ({ pageParam, signal }) =>
      buildProfileList({
        search,
        roleId,
        organizationId,
        offset: pageParam,
        signal,
      }),
    enabled,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      const morePagesExist =
        lastPage.listOfProfiles.length === ROLE_PROFILE_DATA_PER_FETCH

      return morePagesExist
        ? lastPage.offset + ROLE_PROFILE_DATA_PER_FETCH
        : undefined
    },
  })
}

interface BuildProfileListProps {
  search: string
  roleId: string
  organizationId: string
  offset: number
  signal?: AbortSignal
}

const buildProfileList = async ({
  search,
  roleId,
  organizationId,
  offset,
  signal,
}: BuildProfileListProps) => {
  const q = getQueryHandler<ProfileBrowseTypes>()

  const { data: profiles } = await swiftClient.profile.browse(
    {
      baseId: organizationId,
      search,
      select: [
        'display_name',
        'overseer_name',
        'email',
        'phone_primary',
        'profile_id',
      ],
      limit: ROLE_PROFILE_DATA_PER_FETCH,
      offset,
      totalCountInHeader: false,
    },
    {
      signal,
    },
  )

  if (profiles.length === 0) {
    return {
      listOfProfiles: [],
      offset,
    }
  }

  const profileIds = profiles.map((profile) => profile.profile_id)

  const { data: roleProfiles } = await swiftClient.role
    .at(roleId)
    .profiles.browse(
      {
        baseId: SWIFT_ROOT_ENTITY_ID,
        select: [
          'display_name',
          'overseer_name',
          'email',
          'phone_primary',
          'profile_id',
        ],
        search,
        filters: [q('WHERE', 'profile_id', 'ANY', profileIds)],
        totalCountInHeader: false,
      },
      {
        signal,
      },
    )

  const listOfProfiles = profiles.map((profile) => {
    const profileId = profile.profile_id

    const assigned = roleProfiles.some(
      (roleProfile) => roleProfile.profile_id === profileId,
    )

    let modifiedProfile: RoleProfile = {
      profile,
      assigned,
      selected: assigned,
    }

    return modifiedProfile
  })

  return { listOfProfiles, offset }
}
