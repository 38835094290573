import { RolesTypes } from '@swiftctrl/api-client'
import { Modal } from '@swiftctrl/swift-component-library'
import { List } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import {
  invalidateReadScreenQuery,
  OrganizationSelect,
} from '../../../../components'
import { entityLabels } from '../../../../config/labels'
import { SWIFT_ROOT_ENTITY_ID } from '../../../../data/models'
import { queryClient } from '../../../../data/queryClient'
import {
  buildRoleProfilesInfiniteCacheKey,
  cacheKeys,
  showSuccessNotification,
  useBoolean,
  useEditRoleProfile,
} from '../../../../utils-hooks'
import {
  AddTableAction,
  CurrentSubDataInfoBar,
  FiltersBar,
  RoleSubDataManagerModal,
} from '../../manage-subdata'
import { RemoveSubdataButton } from '../../RemoveSubdataButton'
import { buildRoleProfilesCacheKey } from '../utils'
import { RoleProfileView } from '../view'
import { buildRoleProfile } from './buildRoleProfile'
import { checkBeforeRemoval } from './checkBeforeRemoval'
import { checkWithExistingProfile } from './checkWithExistingProfile'
import { RoleProfilesInfiniteTable } from './RoleProfilesInfiniteTable'
import { useBatchAddDeleteProfiles } from './useBatchDeleteAddProfiles'
import { useInfiniteBrowseAssignedProfiles } from './useInfiniteBrowseAssignedProfiles'
import { useInfiniteBrowseCandidateProfiles } from './useInfiniteBrowseCandidateProfiles'
import { useManageProfilesAtRole } from './useManageProfilesAtRole'

interface ManageProfilesRoleModalProps {
  open: boolean
  onClose: () => void
  role: RolesTypes
  roleOrganizationId: string
}

export const ManageProfilesRoleModal = ({
  open,
  onClose,
  role,
  roleOrganizationId,
}: ManageProfilesRoleModalProps) => {
  const { role_id, profile_count } = role

  const [savingChanges, startSavingChanges, stopSavingChanges] = useBoolean()

  const [removeAllRequired, enableRemoveAll, disableRemoveAll] = useBoolean()

  const [search, setSearch] = useState<string>('')

  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<string>(roleOrganizationId)

  const {
    rawAssignedProfiles,
    fetchMoreRawAssignedProfiles,
    isFetchingAssignedProfiles,
    isFetchingAssignedProfilesFirstTime,
  } = useInfiniteBrowseAssignedProfiles({
    roleId: role_id,
    enabled: open,
  })

  const { mutate: removeAll } = useEditRoleProfile()

  const {
    rawCandidateProfiles,
    fetchMoreRawCandidateProfiles,
    isFetchingCandidateProfiles,
    isFetchingCandidateProfilesFirstTime,
  } = useInfiniteBrowseCandidateProfiles({
    roleId: role_id,
    search,
    organizationId: selectedOrganizationId || SWIFT_ROOT_ENTITY_ID,
    enabled: open,
  })

  const resetAndClose = () => {
    onClose()

    reset()

    setSearch('')

    setSelectedOrganizationId(roleOrganizationId)
  }

  const { batchAddDeleteProfiles } = useBatchAddDeleteProfiles({
    roleId: role_id,
    onSuccess: () => {
      showSuccessNotification('Profiles saved')

      invalidateReadScreenQuery(cacheKeys.role, role_id)

      resetAndClose()
    },
    onSettled: stopSavingChanges,
  })

  const processedCandidateProfiles = checkWithExistingProfile(
    rawCandidateProfiles,
    rawAssignedProfiles,
  )

  const processedAssignedProfiles = buildRoleProfile({
    profiles: rawAssignedProfiles,
    selected: true,
    assigned: true,
  })

  const {
    selectedProfiles,
    candidateProfiles,
    addedProfiles,
    removedProfiles,
    addProfile,
    removeProfile,
    removeAllProfiles,
    reset,
  } = useManageProfilesAtRole(
    processedAssignedProfiles,
    processedCandidateProfiles,
  )

  const dirty = addedProfiles.length > 0 || removedProfiles.length > 0

  const checkBeforeClose = () => {
    if (!dirty) {
      resetAndClose()

      return
    }

    Modal.confirm({
      title: 'Are you sure you want to discard your changes?',
      okText: 'Discard',
      onOk: resetAndClose,
      okButtonProps: {
        danger: true,
      },
      centered: true,
    })
  }

  const handleSave = async () => {
    startSavingChanges()

    if (removeAllRequired) {
      removeAll(
        {
          roleId: role_id,
          profileIds: [],
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: buildRoleProfilesInfiniteCacheKey(role_id),
            })

            queryClient.invalidateQueries({
              queryKey: buildRoleProfilesCacheKey(role_id),
            })

            invalidateReadScreenQuery(cacheKeys.role, role_id)

            disableRemoveAll()

            resetAndClose()
          },

          onSettled: () => {
            stopSavingChanges()
          },
        },
      )
    } else {
      batchAddDeleteProfiles(addedProfiles, removedProfiles)
    }
  }

  const handleRemoveAll = () =>
    Modal.confirm({
      title: "You can't remove all the profiles",
      content:
        'Profiles that are children of this role cannot be removed using this operation. Instead, you can delete those profiles later. Do you still want to remove the profiles that are not children of this role?',
      okText: 'Remove',
      onOk: () => {
        removeAllProfiles()

        enableRemoveAll()
      },
      okButtonProps: {
        danger: true,
      },
      centered: true,
    })

  return (
    <RoleSubDataManagerModal
      open={open}
      onClose={checkBeforeClose}
      title="Manage Profile"
      description="Select all Profiles you want to associate to this role"
      filtersBar={
        <FiltersBar
          onSearch={setSearch}
          extraFilter={
            <OrganizationSelectContainer>
              <OrganizationSelect
                placeholder="All Organizations"
                onChange={setSelectedOrganizationId}
                size="large"
                initialValue={roleOrganizationId}
              />
            </OrganizationSelectContainer>
          }
        />
      }
      candidatesSubDataTable={
        <RoleProfilesInfiniteTable
          header="Profile List"
          data={candidateProfiles}
          isFetching={isFetchingCandidateProfiles}
          isFetchingFirstTime={isFetchingCandidateProfilesFirstTime}
          fetchNextPage={fetchMoreRawCandidateProfiles}
          renderItem={(item) => (
            <List.Item key={item.profile.profile_id}>
              <RoleProfileView
                profile={item.profile}
                actionContainer={
                  <AddTableAction
                    showAddButton={!item.selected}
                    onAdd={() => addProfile(item)}
                  />
                }
              />
            </List.Item>
          )}
        />
      }
      infoBar={
        <CurrentSubDataInfoBar
          labels={entityLabels.profile}
          count={
            Number(profile_count) +
            addedProfiles.length -
            removedProfiles.length
          }
          onRemoveAll={handleRemoveAll}
        />
      }
      selectedSubDataTable={
        <RoleProfilesInfiniteTable
          header="Selected Profiles"
          data={selectedProfiles}
          isFetching={isFetchingAssignedProfiles}
          isFetchingFirstTime={isFetchingAssignedProfilesFirstTime}
          fetchNextPage={fetchMoreRawAssignedProfiles}
          renderItem={(item) => (
            <List.Item key={item.profile.profile_id}>
              <RoleProfileView
                roleId={role.role_id}
                showMoreInfo
                profile={item.profile}
                actionContainer={
                  <RemoveSubdataButton
                    data={item}
                    removeSubdata={checkBeforeRemoval(role_id, removeProfile)}
                  />
                }
              />
            </List.Item>
          )}
        />
      }
      onSave={handleSave}
      saveButtonState={{
        disabled: !dirty,
        loading: savingChanges,
      }}
    />
  )
}

const OrganizationSelectContainer = styled.div`
  width: 210px;
`
