import { useState } from 'react'
import { useEventContext } from '../../../../states'
import {
  hasSomeValue,
  showSuccessNotification,
  useCheckBeforeClose,
} from '../../../../utils-hooks'
import { getIsAccessCloudOperationPrefix } from '../models'
import { invalidateGetRoleOperationsQuery } from '../view/useGetRoleOperations'
import { isFormDataValid } from './isFormDataValid'
import { ActionError, AddRolePermissionsFormData } from './models'
import { useAddRoleOperations } from './useAddRoleOperations'

export const useAddRolePermissionsLogic = ({
  roleId,
  onClose,
}: {
  roleId: string
  onClose: () => void
}) => {
  const [values, innerSetValues] = useState<AddRolePermissionsFormData>({})

  const setValues = (values: AddRolePermissionsFormData) => {
    innerSetValues((previous) => {
      if (values.prefix === previous.prefix) {
        return values
      }

      return {
        prefix: values.prefix,
        actions: undefined,
        fieldNames: undefined,
        relationNames: undefined,
        scope: undefined,
        modifier: undefined,
        grantsOn: undefined,
      }
    })

    resetActionErrors()
  }

  const [actionErrors, setActionErrors] = useState<ActionError[]>([])

  const resetActionErrors = () => setActionErrors([])

  const reset = () => {
    innerSetValues({})

    resetActionErrors()
  }

  const isValid = isFormDataValid(values)

  const isDirty = hasSomeValue(values)

  const { prefix } = values

  const isAccessCloudOperationPrefix = prefix
    ? getIsAccessCloudOperationPrefix(prefix)
    : false

  const { emitEvent } = useEventContext()

  const { mutate, isPending } = useAddRoleOperations({
    roleId,
    onSuccess: (prefix, operationsCount) => {
      const message =
        operationsCount > 1 ? 'Permissions added' : 'Permission added'

      showSuccessNotification(message)

      invalidateGetRoleOperationsQuery(roleId)

      emitEvent('role.permission.added', prefix)

      reset()

      onClose()
    },
    onErrors: (actionErrors, successfulRequestsCount) => {
      setActionErrors(actionErrors)

      if (successfulRequestsCount > 0) {
        invalidateGetRoleOperationsQuery(roleId)
      }
    },
  })

  const submit = (data: AddRolePermissionsFormData) => {
    resetActionErrors()

    mutate(data)
  }

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending,
    isDirty,
    close: onClose,
    reset,
  })

  return {
    values,
    actionErrors,
    isValid,
    isAccessCloudOperationPrefix,
    isPending,
    setValues,
    submit,
    checkBeforeClose,
  }
}
