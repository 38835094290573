import { useQuery } from '@tanstack/react-query'
import { swiftClient } from '../../data/swiftClient'
import { cacheKeys } from '../cacheKeysUtils'

const {
  organization: { at },
} = swiftClient

export const useReadOrganization = ({
  id,
  enabled,
}: {
  id: string
  enabled?: boolean
}) =>
  useQuery({
    queryKey: [cacheKeys.organization, id],
    queryFn: () => at(id).read(),
    enabled,
  })
