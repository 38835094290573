import {
  EntityCounterBrowseTypes,
  EntityCounterTypes,
  FIELDS,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { useState } from 'react'
import {
  CopyableDisplay,
  CreatedUpdatedDateDisplay,
  EntitiesTableBrowser,
  HTMLTitle,
  StyledEntitiesBrowserScreen,
  TABLE_DATA_PER_FETCH,
} from '../components'
import { swiftClient } from '../data/swiftClient'
import { CounterReportFilterBar } from '../screen-components/counter-report-screen'
import { BrowseEntitiesConfig, BrowseEntitiesContextProvider } from '../states'
import {
  adaptSDKBrowseRequest,
  cacheKeys,
  useGetValidParam,
} from '../utils-hooks'

const DATE_REGEX = /\d{4}-\d{2}-\d{2}/

export const CounterReportsScreen = () => {
  const dateParam = useGetValidParam({
    paramKey: 'date',
    validator: validateDateParam,
  })

  const [date, setDate] = useState(dateParam)

  return (
    <StyledEntitiesBrowserScreen>
      <HTMLTitle value="Counter Reports" />
      <BrowseEntitiesContextProvider
        dataPerFetch={TABLE_DATA_PER_FETCH}
        browse={browse(date)}
        dataKey="entity_name"
        entityType="entity_counter"
        topBar={<CounterReportFilterBar onDateChange={setDate} date={date} />}
      >
        <EntitiesTableBrowser columns={columns} />
      </BrowseEntitiesContextProvider>
    </StyledEntitiesBrowserScreen>
  )
}

const browse = (date: string): BrowseEntitiesConfig<EntityCounterTypes> => {
  return {
    request: adaptSDKBrowseRequest({
      request: swiftClient.entityCounter.browse,
      select: FIELDS.EntityCounterBrowse,
      getFilters: () => {
        const q = getQueryHandler<EntityCounterBrowseTypes>()

        return [q('WHERE', 'date', 'EQ', date)]
      },
      basedIdRequire: false,
    }),
    cacheKey: [cacheKeys.entity_counter, date],
  }
}

const columns = [
  {
    title: 'Entity Name',
    render: ({ entity_name, entity_id }: EntityCounterTypes) => (
      <CopyableDisplay label={entity_name} copyableText={entity_id} />
    ),
  },
  {
    title: 'Parent',
    dataIndex: 'entity_parent_name',
  },
  {
    title: 'Profiles Added',
    dataIndex: 'profile_add',
  },
  {
    title: 'Profiles Modified',
    dataIndex: 'profile_modify',
  },
  {
    title: 'Credential Added',
    dataIndex: 'credential_add',
  },
  {
    title: 'Credentials Modified',
    dataIndex: 'credential_modify',
  },
  {
    title: 'Created / Updated Date',
    render: ({ created_at, updated_at }: EntityCounterTypes) =>
      created_at || updated_at ? (
        <CreatedUpdatedDateDisplay
          createdAt={created_at}
          updatedAt={updated_at}
        />
      ) : (
        '-'
      ),
  },
]

const validateDateParam = (dateParam: string) => {
  const isValid = DATE_REGEX.test(dateParam)

  if (!isValid) {
    return ''
  }

  return dateParam
}
