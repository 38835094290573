import { useEffect } from 'react'

export const usePreventTabUnload = (preventTabUnload: boolean) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (preventTabUnload) {
        event.preventDefault()

        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [preventTabUnload])
}
