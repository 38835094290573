import { Typography } from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'

type DetailItemProps = {
  label: string
  value: ReactNode
}

export const DetailItem = ({ label, value }: DetailItemProps) => (
  <Typography.Paragraph>
    <Typography.Text type="secondary">{label}:</Typography.Text> {value}
  </Typography.Paragraph>
)
