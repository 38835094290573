import { SourceTypes } from '@swiftctrl/api-client'
import { ColumnsType, Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  EntitiesBrowserProps,
} from '../../../../states'
import {
  adaptSDKBrowseRequest,
  buildEntityTypeTagsList,
  cacheKeys,
} from '../../../../utils-hooks'
import { CopyableDisplay } from '../../../data-display'
import {
  EntitiesTableBrowser,
  TABLE_DATA_PER_FETCH,
} from '../../EntitiesTableBrowser'
import { SinksBrowser } from '../sink'
import { DeleteSourceButton } from './DeleteSourceButton'

export const SourcesBrowser = (props: EntitiesBrowserProps) => {
  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={TABLE_DATA_PER_FETCH}
      browse={browse}
      dataKey="source_id"
      entityType="source"
      addDataTemplate={{ sourceEntitySelectBaseId: props.baseId }}
      {...props}
    >
      <EntitiesTableBrowser
        columns={columns}
        expandable={{
          rowExpandable: () => true,
          expandedRowRender: (source: SourceTypes) => (
            <StyledSinksContainer>
              <SinksBrowser
                nested
                sourceId={source.source_id}
                displayAddButton
                organizationId={props.baseId!}
              />
            </StyledSinksContainer>
          ),
        }}
      />
    </BrowseEntitiesContextProvider>
  )
}

const StyledSinksContainer = styled.div`
  padding: 0 4em;
  .ant-table-thead th {
    border: none;
  }
`

const browse: BrowseEntitiesConfig<SourceTypes> = {
  request: adaptSDKBrowseRequest({
    request: swiftClient.source.browse,
    select: [
      'source_id',
      'entity_name',
      'overseer_id',
      'overseer_name',
      'source_owner_id',
      'source_owner',
      'type_names',
      'source_target_id',
      'source_target_entity',
    ],
    sort: 'entity_name:asc',
  }),
  cacheKey: cacheKeys.sources,
}

const columns: ColumnsType<SourceTypes> = [
  {
    title: 'Source Name',
    render: (_, source) => (
      <CopyableDisplay
        label={source.entity_name}
        copyableText={source.source_id}
      />
    ),
    width: '15%',
  },
  {
    title: 'Target Name',
    render: (_, source) => (
      <CopyableDisplay
        label={source.source_target_entity.entity_name}
        copyableText={source.source_target_id}
      />
    ),
    width: '15%',
  },
  {
    title: 'Source Target Entity',
    render: (_, source) => (
      <CopyableDisplay
        label={source.source_target_entity.entity_name}
        copyableText={source.source_target_id}
      />
    ),
    width: '15%',
  },
  {
    title: 'Source Owner',
    render: (_, source) => (
      <CopyableDisplay
        label={source.source_owner.entity_name}
        copyableText={source.source_owner_id}
      />
    ),
    width: '15%',
  },
  {
    title: 'Type Names',
    render: (_, source) => (
      <Typography.Text>
        {buildEntityTypeTagsList(source.type_names)}
      </Typography.Text>
    ),
    width: '34%',
  },
  {
    render: (_, source) => <DeleteSourceButton source={source} />,

    width: '30px',
  },
]
