export const ENTITIES_SCREEN_PATH = 'entities'
export const ENTITIES_SCREEN_TREE_TAB = 'tree'

export const EPIS_SCREEN_PATH = 'epis'
export const EPI_SCREEN_FIELD_MAPPINGS_TAB = 'field-mappings'
export const EPI_SCREEN_CONFIG_TAB = 'config'
export const EPI_SCREEN_ANCESTRY_TAB = 'ancestry'

export const ORGANIZATIONS_SCREEN_PATH = 'organizations'
export const ORGANIZATION_SCREEN_BUILDINGS_TAB = 'buildings'
export const ORGANIZATION_SCREEN_EPIS_TAB = 'epis'
export const ORGANIZATION_SCREEN_PROFILES_TAB = 'profiles'
export const ORGANIZATION_SCREEN_PROVIDERS_TAB = 'providers'
export const ORGANIZATION_SCREEN_ROLES_TAB = 'roles'
export const ORGANIZATION_SCREEN_SINKS_PATH = 'sinks'
export const ORGANIZATION_SCREEN_SOURCES_TAB = 'sources'
export const ORGANIZATION_SCREEN_CONFIG_TAB = 'config'
export const ORGANIZATION_SCREEN_ANCESTRY_TAB = 'ancestry'

export const PEOPLE_SCREEN_PATH = 'people'
export const PERSON_SCREEN_CREDENTIALS_TAB = 'credentials'
export const PERSON_SCREEN_ROLES_TAB = 'roles'
export const PERSON_SCREEN_PROFILES_TAB = 'profiles'
export const PERSON_SCREEN_AUTHENTICATION_TAB = 'authentication'

export const BUILDINGS_SCREEN_PATH = 'buildings'

export const PROVIDERS_SCREEN_PATH = 'providers'

export const REMOTE_CONFIGS_SCREEN_PATH = 'remote-configs'

export const ROLES_SCREEN_PATH = 'roles'
export const ROLE_SCREEN_ARCHETYPES_TAB = 'archetypes'
export const ROLE_SCREEN_PROFILES_TAB = 'profiles'
export const ROLE_SCREEN_PERMISSIONS_TAB = 'permissions'
export const ROLE_SCREEN_TARGETS_TAB = 'targets'
export const ROLE_SCREEN_ANCESTRY_TAB = 'ancestry'

export const SINK_SCREEN_FILTER_GROUPS_TAB = 'filter-groups'
export const SINK_SCREEN_RULES_TAB = 'rules'
export const SINK_SCREEN_MULTI_TARGETS_TAB = 'multi-targets'
export const SINK_SCREEN_PLACEHOLDERS_TAB = 'placeholders'
export const SINK_SCREEN_ANCESTRY_TAB = 'ancestry'

export const PROFILE_SCREEN_MIRRORS_TAB = 'mirrors'
export const PROFILE_SCREEN_CREDENTIALS_TAB = 'credentials'
export const PROFILE_SCREEN_PROVIDER_PROFILES_TAB = 'provider-profiles'
export const PROFILE_SCREEN_ANCESTRY_TAB = 'ancestry'

export const COUNTER_REPORTS_SCREEN_PATH = 'counter-reports'

export const CREDENTIAL_TEMPLATES_SCREEN_PATH = 'credential-templates'

export const PROFILES_SCREEN_PATH = 'profiles'
