import { getUniqueId } from './getUniqueId'
import { generateHexadecimal } from './hexadecimalUtils'

const ID_LENGTH = 6

export const buildUniqueIdGenerator = () => {
  const existingIds = new Set<string>()

  const generateId = () =>
    getUniqueId(() => generateHexadecimal(ID_LENGTH), existingIds)

  return { generateId }
}
