import { CredentialFieldBitfieldValue } from '@swiftctrl/api-client'
import { TooltipIcon } from '../TooltipIcon'
import { BitfieldValue } from '../utils'
import { useBitfieldValueLogic } from './useBitfieldValueLogic'
import { BitfieldBitIndexInput, BitfieldPropertiesContainer } from './utils'

type FormatBitfieldValueViewProps = {
  bitfieldValue: CredentialFieldBitfieldValue
  onChange: (value: BitfieldValue | null) => void
}

export const FormatBitfieldValueView = ({
  bitfieldValue,
  onChange,
}: FormatBitfieldValueViewProps) => {
  const { start_bit, end_bit } = bitfieldValue

  const { startBit, endBit, handleStartChange, handleEndChange } =
    useBitfieldValueLogic({
      startBit: start_bit,
      endBit: end_bit,
      onChange,
    })

  const tooltip = `Due to the format field constraints, the bitfield value minimum start bit and maximum end bit are ${start_bit} and ${end_bit}`

  return (
    <BitfieldPropertiesContainer>
      bitfield_value
      <TooltipIcon message={tooltip} />
      <BitfieldBitIndexInput
        prefix="Start bit"
        min={start_bit}
        max={endBit}
        value={startBit}
        onChange={handleStartChange}
      />
      <BitfieldBitIndexInput
        prefix="End bit"
        min={startBit}
        max={end_bit}
        value={endBit}
        onChange={handleEndChange}
      />
    </BitfieldPropertiesContainer>
  )
}
