import { EntityType } from '../../data/models'
import {
  getEntityDescriptionKey,
  getEntityId,
  getEntityNameKey,
  useEditEntity,
} from '../../utils-hooks'
import { EditEntityDrawer } from '../EditEntityDrawer'

type GenericEditEntityDrawerProps<T> = {
  open: boolean
  entity: T
  entityType: EntityType
  onEdited: () => void
  onClose: () => void
}

/**
 * This component is only for editing the name and description of an entity
 */
export const GenericEditEntityDrawer = <T extends object>({
  open,
  entity,
  entityType,
  onEdited,
  onClose,
}: GenericEditEntityDrawerProps<T>) => {
  const { editEntity, processingEditRequest } = useEditEntity(
    entity,
    getEntityId(entity, entityType),
    entityType,
    onEdited,
  )

  const handleSave = (name: string, description: string) => {
    const nameKey = getEntityNameKey(entityType) as keyof T

    const descriptionKey = getEntityDescriptionKey(entityType) as keyof T

    const payload = {
      [nameKey]: name,
      [descriptionKey]: description,
    }

    editEntity(payload as Partial<T>)
  }

  return (
    <EditEntityDrawer
      open={open}
      entity={entity}
      entityType={entityType}
      requiredFields={['name']}
      processingEditRequest={processingEditRequest}
      onSave={handleSave}
      onClose={onClose}
    />
  )
}
