import { Layout } from '@swiftctrl/swift-component-library'
import React from 'react'
import styled from 'styled-components'

interface StatusProps {
  children?: React.ReactNode
  status: 'Completed' | 'Required' | 'Ongoing'
}

const statusMap = {
  Completed: '#52C41A',
  Required: '#F5222D',
  Ongoing: '#1890FF',
}

export const Status = ({ children, status }: StatusProps) => {
  return (
    <Layout horizontal alignItems="center">
      <StatusDot $color={statusMap[status]} />
      <NoWrapLayout paddingLeft="small">{children}</NoWrapLayout>
    </Layout>
  )
}

const StatusDot = styled.div<{ $color: string }>`
  border-radius: 50%;
  height: 6px;
  width: 6px;
  background-color: ${({ $color }) => $color};
`

const NoWrapLayout = styled(Layout)`
  white-space: nowrap;
`
