import {
  CredentialFieldValidValues,
  CredentialTemplateFieldValidValue,
} from '@swiftctrl/api-client'
import { Button, Layout } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { useBoolean } from '../../../utils-hooks'
import { PresetValidValuesEditorModal } from './PresetValidValuesEditorModal'
import { TooltipIcon } from '../TooltipIcon'
import { CredentialTemplateFieldConstraintViewProps } from '../utils'

type PresetValidValuesViewProps = {
  formatValidValues: CredentialFieldValidValues
  templateValidValues: CredentialTemplateFieldValidValue[]
  onChange: CredentialTemplateFieldConstraintViewProps['onChange']
}

export const PresetValidValuesView = ({
  formatValidValues,
  templateValidValues,
  onChange,
}: PresetValidValuesViewProps) => {
  const [isModalOpen, openModal, closeModal] = useBoolean()

  const handleSave = (values: CredentialTemplateFieldValidValue[]) => {
    onChange({ valid_values: values, date_range: null, integer_range: null })

    closeModal()
  }

  return (
    <StyledLayout horizontal alignItems="center">
      valid_values
      <TooltipIcon message="Due to the format field constraints, the valid values can't be modified. They can be included or excluded for the template field." />
      <StyledButton type="link" size="small" onClick={openModal}>
        {getValidValuesLabel(templateValidValues.length)}
      </StyledButton>
      <PresetValidValuesEditorModal
        open={isModalOpen}
        templateValidValues={templateValidValues}
        formatValidValues={formatValidValues.valid_values}
        onClose={closeModal}
        onSave={handleSave}
      />
    </StyledLayout>
  )
}

const StyledLayout = styled(Layout)`
  gap: ${({ theme }) => theme.spacing.medium};
`

const StyledButton = styled(Button)`
  padding: 0px;

  > span {
    text-decoration: underline;

    text-underline-offset: 3px;
  }
`

const getValidValuesLabel = (validValuesCount: number) => {
  const label = validValuesCount === 1 ? 'value' : 'values'

  const validValuesLabel = `${validValuesCount} ${label}`

  return validValuesLabel
}
