import {
  ProviderTypeId,
  providerTypeIdsToProviderTypes,
} from '@swiftctrl/api-client'
import { EntityType } from '../../data/models'

const legacyEmailPhoneAddressMappings: Map<string, string> = new Map([
  ['emails[0].email', 'email'],
  ['phones[0].number', 'phone_primary'],
  ['addresses[0].country', 'address.country'],
  ['addresses[0].address_line_1', 'address.address_line_1'],
  ['addresses[0].address_line_2', 'address.address_line_2'],
  ['addresses[0].city', 'address.city'],
  ['addresses[0].state', 'address.state'],
  ['addresses[0].zip', 'address.zip'],
  ['addresses[0].timezone', 'address.timezone'],
])

export const flattenEntityForFormData = (
  source: any,
  target: any,
  keyPrefix: string,
  entityType: EntityType | undefined,
) => {
  for (const [key, value] of Object.entries(source)) {
    const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key

    if (legacyEmailPhoneAddressMappings.has(fullKey)) {
      const mappedKey = legacyEmailPhoneAddressMappings.get(fullKey)!

      target[mappedKey] = value

      continue
    }

    if (typeof value === 'string') {
      target[fullKey] = value

      continue
    }

    if (typeof value === 'number') {
      target[fullKey] = parseNumber(fullKey, value, entityType)

      continue
    }

    if (typeof value !== 'object') {
      target[fullKey] = value

      continue
    }

    if (value === null) {
      target[fullKey] = undefined

      continue
    }

    if (!Array.isArray(value)) {
      flattenEntityForFormData(value, target, fullKey, entityType)

      continue
    }

    value.forEach((entry, index) => {
      flattenEntityForFormData(
        entry,
        target,
        `${fullKey}[${index}]`,
        entityType,
      )
    })

    target[fullKey] = value
  }

  return target
}

const parseNumber = (
  key: string,
  value: number,
  entityType: EntityType | undefined,
) => {
  if (entityType === 'provider' && key === 'provider_type.provider_type_id') {
    const providerType =
      providerTypeIdsToProviderTypes[Number(value) as ProviderTypeId]

    return providerType
  }

  return value
}
