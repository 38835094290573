import { EpiTypes } from '@swiftctrl/api-client'
import { Typography } from '@swiftctrl/swift-component-library'
import { CopyableDisplay } from '../../../data-display'

type EpiTypeDisplayProps = {
  epi: EpiTypes
}

export const EpiTypeDisplay = ({ epi }: EpiTypeDisplayProps) => {
  const {
    type: { name, epi_type_id, type_description },
  } = epi

  return (
    <>
      <Typography.Text strong>Type: </Typography.Text>
      <CopyableDisplay
        label={name}
        copyableText={epi_type_id}
        tooltip={
          type_description && (
            <>
              <p>{type_description}</p>
              ID: {epi_type_id}
            </>
          )
        }
      />
    </>
  )
}
