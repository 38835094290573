import {
  ManagedSpacesBrowseTypes,
  ManagedSpacesTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import {
  ColumnsType,
  Layout,
  Typography,
} from '@swiftctrl/swift-component-library'
import {
  CopyableDisplay,
  EntitiesTableBrowser,
  TABLE_DATA_PER_FETCH,
} from '../../../components'
import { swiftClient } from '../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
} from '../../../states'
import {
  adaptSDKBrowseRequest,
  cacheKeys,
  isValidUuid,
} from '../../../utils-hooks'

type ManagedBuildingsBrowserProps = {
  organizationId: string
}

export const ManagedBuildingsBrowser = ({
  organizationId,
}: ManagedBuildingsBrowserProps) => {
  const browse = buildBrowse(organizationId)

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={TABLE_DATA_PER_FETCH}
      browse={browse}
      dataKey="space_id"
      entityType="building"
      searchable
    >
      <EntitiesTableBrowser columns={columns} />
    </BrowseEntitiesContextProvider>
  )
}

const buildBrowse = (
  organizationId: string,
): BrowseEntitiesConfig<ManagedSpacesTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClient.organization.at(organizationId).spaces.managed.browse,
    select: ['space_id', 'name', 'location'],
    getFilters: (search) => {
      const q = getQueryHandler<ManagedSpacesBrowseTypes>()

      const filters = [q('WHERE', 'type', 'EQ', 'building')]

      if (search) {
        if (isValidUuid(search)) {
          filters.push(q('AND', 'space_id', 'EQ', search))
        } else {
          filters.push(q('AND', 'name', 'EQ_IC', search))
        }
      }

      return filters
    },
  }),
  cacheKey: [cacheKeys.organization_managed_buildings, organizationId],
})

const columns: ColumnsType<ManagedSpacesTypes> = [
  {
    title: 'Building',
    render: (_, space) => (
      <Layout>
        <CopyableDisplay label={space.name} copyableText={space.space_id} />
        {space.location && (
          <Typography.Text type="secondary">{space.location}</Typography.Text>
        )}
      </Layout>
    ),
  },
]
