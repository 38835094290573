import { createContext, useContext } from 'react'

const defaultValue = {
  values: {} as any,
  updateValue: (name: string, value: string) => {},
  addingEntity: false,
}

export const AddEntityFormContext = createContext(defaultValue)

export const useAddEntityFormContext = () => useContext(AddEntityFormContext)
