import { Layout, PreLoader } from '@swiftctrl/swift-component-library'
import { List } from 'antd'
import { InfiniteScrollTrigger } from '../../../../components'
import {
  RoleSubdataInfiniteTableProps,
  RoleSubDataTableContainer,
  RoleSubDataTableHeader,
  ScrollContainer,
} from '../../manage-subdata'

export const RoleProfilesInfiniteTable = <T,>({
  header,
  data,
  isFetching,
  isFetchingFirstTime,
  fetchNextPage,
  renderItem,
}: RoleSubdataInfiniteTableProps<T>) => {
  return (
    <RoleSubDataTableContainer>
      <RoleSubDataTableHeader label={header} />
      <ScrollContainer>
        {!isFetchingFirstTime && (
          <List dataSource={data} renderItem={renderItem} />
        )}
        {isFetching && (
          <Layout paddingTop="medium" alignItems="center">
            <PreLoader size="small" />
          </Layout>
        )}
        <InfiniteScrollTrigger callback={fetchNextPage} />
      </ScrollContainer>
    </RoleSubDataTableContainer>
  )
}
