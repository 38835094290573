import {
  Button,
  CloseOutlined,
  DrawerProps,
} from '@swiftctrl/swift-component-library'

type buildCommonDrawerPropsParams = Omit<
  DrawerProps,
  'closable' | 'maskClosable' | 'destroyOnClose' | 'onClose' | 'extra'
> & {
  isPending?: boolean
  onClose: () => void
}

/**
 * Sets up common behavior we want for drawers
 */
export const buildCommonDrawerProps = ({
  isPending,
  onClose,
  ...props
}: buildCommonDrawerPropsParams) => {
  return {
    closable: false,
    maskClosable: !isPending,
    destroyOnClose: true,
    onClose,
    extra: (
      <Button
        type="text"
        icon={<CloseOutlined />}
        size="small"
        onClick={onClose}
        disabled={isPending}
      />
    ),
    ...props,
  }
}
