import { BuildingTypes } from '@swiftctrl/api-client'
import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { EntityCard } from '../../../browse-entities'
import { LabelDisplay } from '../../../data-display'
import { formatAddress } from './utils'

type BuildingCardProps = {
  building: BuildingTypes
}

export const BuildingCard = ({ building }: BuildingCardProps) => {
  const { address } = building

  return (
    <EntityCard entity={building}>
      {address && (
        <Layout paddingTop="xSmall">
          <Typography.Paragraph>
            <LabelDisplay label="Address" />
            {formatAddress(address)}
          </Typography.Paragraph>
        </Layout>
      )}
    </EntityCard>
  )
}
