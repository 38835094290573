import { EntityTypes, OrganizationTypes } from '@swiftctrl/api-client'
import { arrayToTree } from 'performant-array-to-tree'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ORGANIZATIONS_SCREEN_PATH } from '../../../config/paths'
import { EntityType } from '../../../data/models'
import { queryClient } from '../../../data/queryClient'
import { cacheKeys, showSuccessNotification } from '../../../utils-hooks'
import { recursivelyDelete } from './recursivelyDelete'
import { useGetDeletableDescendants } from './useGetDeletableDescendants'
import {
  areAllEntityTypesConfigured,
  EntityTypeToEntitiesArray,
  TreeItem,
} from './utils'

type Params = {
  open: boolean
  onClose: () => void
  organization: Partial<OrganizationTypes>
}

const states = [
  'None',
  'FetchingDescendants',
  'CannotBeDeleted',
  'AwaitingConfirmation',
  'DeletingEntities',
  'ErrorOccurred',
] as const

export type State = (typeof states)[number]

export const useDeleteOrganizationLogic = ({
  open,
  onClose,
  organization,
}: Params) => {
  const { organization_id, entity_name, overseer_id } = organization

  const { data, isFetching } = useGetDeletableDescendants({
    organization,
    enabled: open,
  })

  const entityTypeToEntitiesArray = buildEntityTypeToEntitiesArray(
    organization,
    data,
  )

  const [isDeleting, setIsDeleting] = useState(false)

  const [deletedCount, setDeletedCount] = useState(0)

  const incrementDeletedCount = () => {
    setDeletedCount((previous) => previous + 1)
  }

  const [error, setError] = useState<Error | undefined>()

  const navigate = useNavigate()

  const startDeleting = async () => {
    setIsDeleting(true)

    const descendants = arrayToTree(data!, {
      id: 'entity_id',
      parentId: 'overseer_id',
      rootParentIds: { [organization_id!]: true },
    }) as TreeItem[]

    const subtree: TreeItem = {
      data: {
        entity_id: organization_id,
        entity_name,
        entity_type_id: 'organization',
        overseer_id,
      },
      children: descendants,
    }

    try {
      await recursivelyDelete(subtree, incrementDeletedCount)

      queryClient.invalidateQueries({
        queryKey: [cacheKeys.organizations],
      })

      queryClient.invalidateQueries({
        queryKey: [cacheKeys.organization],
      })

      showSuccessNotification(`"${entity_name}" and descendants deleted`)

      navigate(`/${ORGANIZATIONS_SCREEN_PATH}`)
    } catch (error) {
      setError(error as Error)

      setIsDeleting(false)

      setDeletedCount(0)
    }
  }

  const reset = () => {
    setError(undefined)

    setDeletedCount(0)
  }

  const restartFlow = () => {
    queryClient.resetQueries({
      queryKey: [cacheKeys.organization_deletable_descendants],
    })

    reset()
  }

  const state = getState({
    open,
    isFetching,
    entityTypeToEntitiesArray,
    isDeleting,
    error,
  })

  const checkBeforeClose = () => {
    switch (state) {
      case 'FetchingDescendants':
      case 'DeletingEntities':
        return
    }

    reset()

    onClose()
  }

  return {
    state,
    entityTypeToEntitiesArray,
    entitiesCount: data ? 1 + data.length : undefined,
    startDeleting,
    deletedCount,
    error,
    restartFlow,
    checkBeforeClose,
  }
}

const buildEntityTypeToEntitiesArray = (
  organization: Partial<OrganizationTypes>,
  descendants: Partial<EntityTypes>[] | undefined,
) => {
  if (!descendants) {
    return undefined
  }

  const entityTypeToEntitiesMap = new Map<EntityType, Partial<EntityTypes>[]>()

  entityTypeToEntitiesMap.set('organization', [
    {
      entity_name: organization.entity_name,
    },
  ])

  descendants.forEach((entity) => {
    const { entity_type_id } = entity

    const entitiesOfType = entityTypeToEntitiesMap.get(entity_type_id!)

    if (entitiesOfType) {
      entitiesOfType.push(entity)
    } else {
      entityTypeToEntitiesMap.set(entity_type_id!, [entity])
    }
  })

  const entityTypeToEntitiesArray: EntityTypeToEntitiesArray = []

  entityTypeToEntitiesMap.forEach((entities, entityType) => {
    entities.sort((a, b) => a.entity_name!.localeCompare(b.entity_name!))

    entityTypeToEntitiesArray.push({ entityType, entities })
  })

  entityTypeToEntitiesArray.sort((a, b) =>
    a.entityType.localeCompare(b.entityType),
  )

  return entityTypeToEntitiesArray
}

const getState = ({
  open,
  isFetching,
  entityTypeToEntitiesArray,
  isDeleting,
  error,
}: {
  open: boolean
  isFetching: boolean
  entityTypeToEntitiesArray: EntityTypeToEntitiesArray | undefined
  isDeleting: boolean
  error: Error | undefined
}): State => {
  if (!open) {
    return 'None'
  }

  if (isFetching) {
    return 'FetchingDescendants'
  }

  if (!areAllEntityTypesConfigured(entityTypeToEntitiesArray!)) {
    return 'CannotBeDeleted'
  }

  if (isDeleting) {
    return 'DeletingEntities'
  }

  if (error) {
    return 'ErrorOccurred'
  }

  return 'AwaitingConfirmation'
}
