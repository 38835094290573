import { Input } from '@swiftctrl/swift-component-library'
import { debounce } from 'lodash'
import { ChangeEvent, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { validateBrowseSearch, useHandleUrlParams } from '../../utils-hooks'

type EntitiesBrowserSearchInputProps = {
  initialSearch: string
  onDebouncedChange: (value: string) => void
}

export const EntitiesBrowserSearchInput = ({
  initialSearch,
  onDebouncedChange,
}: EntitiesBrowserSearchInputProps) => {
  const [search, setSearch] = useState(initialSearch)

  const { updateParams } = useHandleUrlParams()

  const debounceSearch = useRef(debounce(onDebouncedChange, 500))

  const updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setSearch(value)

    updateParams({ search: value })

    const { isValidSearch, validatedSearch } = validateBrowseSearch(value)

    if (!isValidSearch) {
      return
    }

    debounceSearch.current.cancel()

    debounceSearch.current(validatedSearch)
  }

  return (
    <StyledSearchInput
      value={search}
      onChange={updateSearch}
      placeholder="Search"
      size="middle"
      allowClear
    />
  )
}

const StyledSearchInput = styled(Input.Search)`
  width: 330px;
`
