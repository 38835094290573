import { RolesTypes } from '@swiftctrl/api-client'
import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { getEntityLabelByCount } from '../../../../config/labels'
import { ROLES_SCREEN_PATH } from '../../../../config/paths'
import {
  getPluralLabelByCount,
  targetPluralLabelConfig,
} from '../../../../utils-hooks'
import { EntityCard } from '../../../browse-entities'

interface RoleCardProps {
  role: RolesTypes
}

export const RoleCard = ({ role }: RoleCardProps) => {
  const { profile_count, granted_on, role_id } = role

  const profilesLabel = `${profile_count} ${getEntityLabelByCount({
    entityType: 'profile',
    count: profile_count,
    capitalize: false,
  })}`

  const targetsLabel = `${granted_on?.length || 0} ${getPluralLabelByCount({
    labels: targetPluralLabelConfig,
    count: granted_on?.length || 0,
    capitalize: false,
  })}`

  const subDataInfo = [profilesLabel, targetsLabel].join(', ')

  const path = `/${ROLES_SCREEN_PATH}/${role_id}`

  return (
    <EntityCard detailsNavigatePath={path} entity={role}>
      <Layout paddingTop="xSmall">
        <Typography.Paragraph>{subDataInfo}</Typography.Paragraph>
      </Layout>
    </EntityCard>
  )
}
