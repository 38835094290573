import { ProfilesTypes } from '@swiftctrl/api-client'
import { SWIFT_ROOT_ENTITY_ID } from '../../../../data/models'
import { useInfiniteBrowseProfilesAtRole } from '../../../../utils-hooks'

export const useInfiniteBrowseAssignedProfiles = ({
  roleId,
  enabled,
}: {
  roleId: string
  enabled: boolean
}) => {
  const { data, fetchNextPage, isFetching } = useInfiniteBrowseProfilesAtRole({
    roleId,
    baseId: SWIFT_ROOT_ENTITY_ID,
    select: ['display_name', 'overseer_name', 'email', 'phone_primary'],
    enabled,
    totalCountInHeader: false,
  })

  if (!data) {
    return {
      rawAssignedProfiles: [],
      fetchMoreRawAssignedProfiles: fetchNextPage,
      isFetchingAssignedProfiles: isFetching,
      isFetchingAssignedProfilesFirstTime: !data,
    }
  }

  const rawAssignedProfiles = data.pages.reduce(
    (profiles, page) => [...profiles, ...page.data],
    new Array<Partial<ProfilesTypes>>(),
  )

  return {
    rawAssignedProfiles,
    fetchMoreRawAssignedProfiles: fetchNextPage,
    isFetchingAssignedProfiles: isFetching,
    isFetchingAssignedProfilesFirstTime: !data,
  }
}
