import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { HTMLTitle, StyledScreen } from '../components'
import { ENTITIES_SCREEN_PATH, ENTITIES_SCREEN_TREE_TAB } from '../config/paths'
import { EntityTreeTab } from '../screen-components/entities-screen'
import { ReadEntityScreen } from './ReadEntityScreen'

const entitiesScreenTabs = [ENTITIES_SCREEN_TREE_TAB]

export const EntitiesScreen = () => {
  const { content } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    if (!content) {
      navigate(`/${ENTITIES_SCREEN_PATH}/${entitiesScreenTabs[0]}`, {
        replace: true,
      })
    }
  }, [content, navigate])

  if (!content) {
    return null
  }

  const isTabContent = entitiesScreenTabs.includes(content)

  if (!isTabContent) {
    return <ReadEntityScreen entityId={content} />
  }

  return (
    <StyledScreen>
      <HTMLTitle value="Entities" />
      <EntityTreeTab />
    </StyledScreen>
  )
}
