import { RolesTypes } from '@swiftctrl/api-client'
import { Button, Layout, Typography } from '@swiftctrl/swift-component-library'
import { List } from 'antd'
import { styled } from 'styled-components'
import { ManageAssignedDataList } from '../../../../components'

type SelectedRolesListProps = {
  data: Partial<RolesTypes>[]
  onRemoveRole: (role: Partial<RolesTypes>) => void
  isSaving: boolean
}

export const SelectedRolesList = ({
  data,
  onRemoveRole,
  isSaving,
}: SelectedRolesListProps) => {
  return (
    <ManageAssignedDataList
      header="Selected Roles"
      dataSource={data}
      renderItem={(role) => (
        <List.Item key={role.role_id}>
          <StyledLayout
            horizontal
            justifyContent="space-between"
            alignItems="center"
          >
            <Layout grow>
              {role.entity_name}
              <Typography.Text type="secondary">
                {role.entity_description}
              </Typography.Text>
            </Layout>
            <Button
              size="small"
              danger
              type="text"
              onClick={() => onRemoveRole(role)}
              disabled={isSaving}
            >
              Remove
            </Button>
          </StyledLayout>
        </List.Item>
      )}
    />
  )
}

const StyledLayout = styled(Layout)`
  flex: 1;
`
