import {
  OperationAction,
  OperationPrefix,
  RoleOperationTypes,
} from '@swiftctrl/api-client'

export type RoleOperationsGroup = {
  prefix: OperationPrefix
  actions: OperationAction[]
  roleOperations: RoleOperationTypes[]
}

export const getIsAccessCloudOperationPrefix = (prefix: OperationPrefix) => {
  const check = prefix.startsWith('AC_')

  return check
}
