import { hoursToSeconds, minutesToSeconds } from 'date-fns'

export const transformDurationPickerValueToDuration = (
  value: Date | number | undefined,
) => {
  if (!value) {
    return undefined
  }

  if (typeof value === 'number') {
    return value
  }

  const duration =
    hoursToSeconds(value.getHours()) +
    minutesToSeconds(value.getMinutes()) +
    value.getSeconds()

  return duration
}
