import { ProviderTypes } from '@swiftctrl/api-client'
import { useBrowseEntitiesContext } from '../../../states'
import { useReadProvider } from '../../../utils-hooks'
import { EditProviderView } from './EditProviderView'

type ReadEditProviderViewProps = {
  open: boolean
  provider: Partial<ProviderTypes>
  onEdited: () => void
  onClose: () => void
}

export const ReadEditProviderView = ({
  open,
  provider,
  onEdited,
  onClose,
}: ReadEditProviderViewProps) => {
  const { baseIdIsPreset } = useBrowseEntitiesContext()

  const { data } = useReadProvider(provider.provider_id!, open)

  if (!data) {
    return null
  }

  return (
    <EditProviderView
      open={open}
      provider={data.data}
      onEdited={onEdited}
      onClose={onClose}
      baseIdIsPreset={baseIdIsPreset}
    />
  )
}
