export const areFalsy = (a: any, b: any) => {
  if (a) {
    return false
  }

  if (b) {
    return false
  }

  return true
}
