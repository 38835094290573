import { notification } from 'antd'
import { MutableRefObject, ReactNode, useRef } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { createRequestQueue } from './createRequestQueue'

type Request<T> = (data: T) => Promise<any>

type BuildErrorMessage<T> = (
  data: T,
  statusCode: string,
  errorMessage: string,
) => ReactNode

export const useBatchAddDeleteRequests = <T>({
  addRequest,
  buildAddErrorMessage,
  deleteRequest,
  buildDeleteErrorMessage,
  invalidateQueryKeys,
  onSuccess,
  onError,
  onSettled,
}: {
  addRequest: Request<T>
  buildAddErrorMessage: BuildErrorMessage<T>
  deleteRequest: Request<T>
  buildDeleteErrorMessage: BuildErrorMessage<T>
  invalidateQueryKeys: string[]
  onSuccess?: () => void
  onError?: () => void
  onSettled?: () => void
}) => {
  const queryClient = useQueryClient()

  const failedRequests = useRef<number>(0)

  const { enqueueRequest } = createRequestQueue(() => {
    invalidateQueryKeys.forEach((key: string) =>
      queryClient.invalidateQueries({ queryKey: [key] }),
    )

    if (failedRequests.current === 0) {
      onSuccess?.()
    } else {
      onError?.()
    }

    onSettled?.()
  })

  const addWrapper = buildRequestWrapper(
    addRequest,
    buildAddErrorMessage,
    failedRequests,
  )

  const deleteWrapper = buildRequestWrapper(
    deleteRequest,
    buildDeleteErrorMessage,
    failedRequests,
  )

  const batchAddDeleteRequests = (
    entitiesToAdd: T[],
    entitiesToDelete: T[],
  ) => {
    entitiesToAdd.forEach((entity) => enqueueRequest(() => addWrapper(entity)))

    entitiesToDelete.forEach((entity) =>
      enqueueRequest(() => deleteWrapper(entity)),
    )
  }

  return { batchAddDeleteRequests }
}

const buildRequestWrapper = <T>(
  request: Request<T>,
  buildErrorMessage: BuildErrorMessage<T>,
  failedRequests: MutableRefObject<number>,
) => {
  const wrapper = async (data: T) => {
    try {
      await request(data)
    } catch (e: any) {
      failedRequests.current++

      let statusCode: string
      let errorMessage: string

      const {
        status,
        data: { message },
      } = e.response
      statusCode = status

      errorMessage = message

      notification.error({
        message: buildErrorMessage(data, statusCode, errorMessage),
        duration: 0,
      })
    }
  }

  return wrapper
}
