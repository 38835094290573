import { EntityTypes } from '@swiftctrl/api-client'
import { isTargetInArray } from './isTargetInArray'

export const getChangesToSave = (
  assignedTargets: Partial<EntityTypes>[],
  selectedTargets: Partial<EntityTypes>[],
) => {
  const targetsToAdd = new Array<Partial<EntityTypes>>()

  const targetsToRemove = new Array<Partial<EntityTypes>>()

  selectedTargets.forEach((target) => {
    const isAssigned = isTargetInArray(target, assignedTargets)

    if (!isAssigned) {
      targetsToAdd.push(target)
    }
  })

  assignedTargets.forEach((target) => {
    const isSelected = isTargetInArray(target, selectedTargets)

    if (!isSelected) {
      targetsToRemove.push(target)
    }
  })

  return {
    targetsToAdd,
    targetsToRemove,
  }
}
