import {
  CredentialFieldConstraint,
  CredentialFieldIntegerRange,
} from '@swiftctrl/api-client'
import { BitfieldValue, Constraint } from '../utils'
import { CustomValidValuesView } from './CustomValidValuesView'
import { getMinMaxFromFormatIntegerRangeWithTemplateBitfieldValue } from './utils'

type FormatIntegerRangeWithTemplateBitfieldValueAndValidValuesViewProps = {
  templateConstraint: Constraint
  templateBitfieldValue: BitfieldValue
  formatConstraint: CredentialFieldConstraint
  onChange: (constraint: Constraint | null) => void
}

export const FormatIntegerRangeWithTemplateBitfieldValueAndValidValuesView = ({
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: FormatIntegerRangeWithTemplateBitfieldValueAndValidValuesViewProps) => {
  const { min, max, constraintFromFormat, constraintFromTemplate } =
    getMinMaxFromFormatIntegerRangeWithTemplateBitfieldValue(
      formatConstraint.value as CredentialFieldIntegerRange,
      templateBitfieldValue,
    )

  return (
    <CustomValidValuesView
      templateConstraint={templateConstraint}
      templateBitfieldValue={templateBitfieldValue}
      formatConstraint={formatConstraint}
      min={min}
      max={max}
      constraintFromFormat={constraintFromFormat}
      constraintFromTemplate={constraintFromTemplate}
      onChange={onChange}
    />
  )
}
