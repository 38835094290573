import { EpiAddTypes, FieldMapping } from '@swiftctrl/api-client'
import { swiftClient } from '../../data/swiftClient'
import { AddEntityConfig } from '../../utils-hooks'

type FormData = {
  entity_name: string
  entity_description: string
  overseer_id: string
  'type.epi_type_id': string
  'subtype.epi_subtype_id': string
  on_premises: boolean
  entity_fields_map: Omit<FieldMapping, 'epi_id'>[] | ''
  config: Record<string, any> | ''
}

export const epi: AddEntityConfig<FormData, EpiAddTypes> = {
  fieldGroups: [
    {
      fields: [
        {
          key: 'entity_name',
          label: 'Name',
        },
        {
          key: 'entity_description',
          label: 'Description',
          optional: true,
        },
      ],
    },
    {
      condition: (_, overseerIdIsPreset) => !overseerIdIsPreset,
      fields: [
        {
          key: 'overseer_id',
          label: 'Organization',
          inputType: 'organizationSelect',
          entitySelectType: 'organization',
        },
      ],
    },
    {
      fields: [
        {
          key: 'type.epi_type_id',
          label: 'Type',
          inputType: 'epiTypeSelect',
        },
        {
          key: 'subtype.epi_subtype_id',
          label: 'Subtype',
          inputType: 'epiSubtypeSelect',
        },
        {
          key: 'on_premises',
          label: 'On-premises',
          inputType: 'boolean',
        },
        {
          key: 'entity_fields_map',
          inputType: 'fieldMappings',
          optional: true,
        },
        {
          key: 'config',
          inputType: 'epiConfig',
          optional: true,
        },
      ],
    },
  ],
  buildData: ({
    entity_name,
    entity_description,
    overseer_id,
    on_premises,
    entity_fields_map,
    config,
    ...values
  }) => ({
    entity_name,
    entity_description,
    overseer_id,
    type: {
      epi_type_id: values['type.epi_type_id'],
    },
    subtype: {
      epi_subtype_id: values['subtype.epi_subtype_id'],
    },
    on_premises,
    entity_fields_map: entity_fields_map !== '' ? entity_fields_map : [],
    config: config || null,
  }),
  addRequest: (data) => swiftClient.epi.add(data),
}
