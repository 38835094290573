import { Layout, Tag } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { colors } from '../theme'

export type TagButtonGroupProps = {
  items: { key: string; label: string }[]
  activeKey: string
  onChange: (key: string) => void
}

export const TagButtonGroup = ({
  items,
  activeKey,
  onChange,
  ...props
}: TagButtonGroupProps) => {
  return (
    <Layout horizontal {...props}>
      {items.map((item) => {
        const { key, label } = item

        const isActive = key === activeKey

        return (
          <StyledTag
            key={key}
            $active={isActive}
            onClick={isActive ? undefined : () => onChange(key)}
          >
            {label}
          </StyledTag>
        )
      })}
    </Layout>
  )
}

const StyledTag = styled(Tag)<{ $active: boolean }>`
  cursor: pointer;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  color: ${({ $active }) => ($active ? '#fff' : colors['primary-color'])};
  border-color: ${({ $active }) =>
    $active ? undefined : colors['primary-color']};
  background-color: ${({ $active }) =>
    $active ? colors['primary-color'] : undefined};
`
