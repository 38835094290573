import {
  Button,
  Col,
  Layout,
  Typography,
} from '@swiftctrl/swift-component-library'
import { PropsWithChildren, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useBrowseEntitiesContext } from '../../states'
import {
  getEntityDescription,
  getEntityName,
  getField,
} from '../../utils-hooks'
import {
  CopyableDisplay,
  EntityTimestampsDisplay,
  LabelDisplay,
} from '../data-display'
import { EntityCardActionsDropdown } from './EntityCardActionsDropdown'

type EntityCardInfoProps<T> = PropsWithChildren<{
  entity: T
  entityId: string
  onEditClick?: () => void
  onDuplicateClick?: () => void
  onDeleteClick?: () => void
  /**
   * If omitted the details button won't show
   */
  detailsNavigatePath?: string
  /**
   * Displayed below the timestamps, if any,
   * and to the right of the details button, if it exists.
   */
  extraButton?: ReactNode
  childrenAboveOverseer?: ReactNode
}>

export const EntityCardInfo = <T extends object>({
  entity,
  entityId,
  onEditClick,
  onDuplicateClick,
  onDeleteClick,
  detailsNavigatePath,
  children,
  extraButton,
  childrenAboveOverseer,
}: EntityCardInfoProps<T>) => {
  const { entityType } = useBrowseEntitiesContext()

  const name = getEntityName(entity, entityType)

  const description = getEntityDescription(entity, entityType)

  const overseerName = getField(entity, 'overseer_name')

  const overseerId = getField(entity, 'overseer_id')

  const createdAt = getField(entity, 'created_at')

  const updatedAt = getField(entity, 'updated_at')

  return (
    <CardContainer>
      <NameContainer horizontal justifyContent="space-between">
        <CopyableDisplay label={name} copyableText={entityId} title />
        <StyledActionsColumn>
          <EntityCardActionsDropdown
            onEditClick={onEditClick}
            onDuplicateClick={onDuplicateClick}
            onDeleteClick={onDeleteClick}
          />
        </StyledActionsColumn>
      </NameContainer>
      {description && (
        <Typography.Paragraph type="secondary">
          {description}
        </Typography.Paragraph>
      )}
      <Layout paddingTop="xMedium" paddingBottom="xMedium" grow>
        {childrenAboveOverseer}
        {(overseerName || overseerId) && (
          <Typography.Paragraph>
            <LabelDisplay label="Overseer" />
            <CopyableDisplay label={overseerName} copyableText={overseerId} />
          </Typography.Paragraph>
        )}
        {children}
      </Layout>
      <EntityTimestampsDisplay created_at={createdAt} updated_at={updatedAt} />
      {(detailsNavigatePath || extraButton) && (
        <Layout horizontal paddingTop="xMedium" spacing="small">
          {detailsNavigatePath && (
            <Link to={detailsNavigatePath}>
              <Button type="primary" ghost size="middle">
                Details
              </Button>
            </Link>
          )}
          {extraButton}
        </Layout>
      )}
    </CardContainer>
  )
}

const StyledActionsColumn = styled(Col)`
  svg {
    transform: translateX(12px);
  }
`

const CardContainer = styled(Layout)`
  width: 100%;
  height: 100%;

  .ant-typography {
    margin-bottom: 0px;
  }
`

const NameContainer = styled(Layout)`
  width: 100%;
`
