import { useMutation } from '@tanstack/react-query'
import { swiftClient } from '../../data/swiftClient'

const {
  providerProfile: { at },
} = swiftClient

export const useDeleteProviderProfile = () => {
  const deleteProviderProfile = ({
    providerProfileId,
  }: {
    providerProfileId: string
  }) => at(providerProfileId).delete()
  return useMutation({ mutationFn: deleteProviderProfile })
}
