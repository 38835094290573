import { CredentialTemplateFieldValidValue } from '@swiftctrl/api-client'
import {
  getBitfieldValueMax,
  getBitfieldValueMin,
} from '../integer-constraint/utils'
import {
  CredentialFieldValidator,
  getFormatBitfieldValue,
  getFormatFieldValidValues,
  getFormatIntegerRange,
  validateValidValues,
} from './utils'
import {
  getBitfieldValueMax as getFormatBitfieldValueMax,
  getBitfieldValueMin as getFormatBitfieldValueMin,
  getIntegerRangeStartEnd,
} from '../../../components'
import { parseBigInt } from '../../../utils-hooks'

export const integerValidator: CredentialFieldValidator = (
  templateField,
  formatField,
): boolean => {
  const { bitfield_value, constraint, default_value } = templateField

  const templateBitfieldValueMin = bitfield_value
    ? getBitfieldValueMin(bitfield_value)
    : undefined

  const templateBitfieldValueMax = bitfield_value
    ? getBitfieldValueMax(bitfield_value)
    : undefined

  const templateIntegerRangeStart = constraint?.integer_range?.start

  const templateIntegerRangeEnd = constraint?.integer_range?.end

  const templateValidValues = constraint?.valid_values

  const formatBitfieldValue = getFormatBitfieldValue(formatField)

  const formatBitfieldValueMin = formatBitfieldValue
    ? getFormatBitfieldValueMin(formatBitfieldValue)
    : undefined

  const formatBitfieldValueMax = formatBitfieldValue
    ? getFormatBitfieldValueMax(formatBitfieldValue)
    : undefined

  const formatIntegerRange = getFormatIntegerRange(formatField)

  const { start: formatIntegerRangeStart, end: formatIntegerRangeEnd } =
    formatIntegerRange
      ? getIntegerRangeStartEnd(formatIntegerRange)
      : { start: undefined, end: undefined }

  const formatValidValues = getFormatFieldValidValues(formatField)

  if (
    constraint?.integer_range &&
    (templateIntegerRangeStart === undefined ||
      templateIntegerRangeEnd === undefined)
  ) {
    return false
  }

  if (
    hasBitfieldValueMinMax(formatBitfieldValueMin, formatBitfieldValueMax) &&
    hasBitfieldValueMinMax(
      templateBitfieldValueMin,
      templateBitfieldValueMax,
    ) &&
    (templateBitfieldValueMin! < formatBitfieldValueMin! ||
      templateBitfieldValueMax! > formatBitfieldValueMax!)
  ) {
    return false
  }

  if (
    hasIntegerRange(formatIntegerRangeStart, formatIntegerRangeEnd) &&
    hasIntegerRange(templateIntegerRangeStart, templateIntegerRangeEnd) &&
    (templateIntegerRangeStart! < formatIntegerRangeStart! ||
      templateIntegerRangeEnd! > formatIntegerRangeEnd!)
  ) {
    return false
  }

  if (
    hasIntegerRange(templateIntegerRangeStart, templateIntegerRangeEnd) &&
    hasBitfieldValueMinMax(
      templateBitfieldValueMin,
      templateBitfieldValueMax,
    ) &&
    !isIntegerRangeWithinBitfieldValueMinMax(
      templateIntegerRangeStart!,
      templateIntegerRangeEnd!,
      templateBitfieldValueMin!,
      templateBitfieldValueMax!,
    )
  ) {
    return false
  }

  if (!validateValidValues(templateValidValues, formatValidValues)) {
    return false
  }

  if (
    templateValidValues &&
    hasIntegerRange(templateIntegerRangeStart, templateIntegerRangeEnd) &&
    !isValidValuesWithinIntegerRange(
      templateValidValues,
      templateIntegerRangeStart!,
      templateIntegerRangeEnd!,
    )
  ) {
    return false
  }

  if (
    templateValidValues &&
    hasBitfieldValueMinMax(
      templateBitfieldValueMin,
      templateBitfieldValueMax,
    ) &&
    !isValidValuesWithinBitfieldValueMinMax(
      templateValidValues,
      templateBitfieldValueMin!,
      templateBitfieldValueMax!,
    )
  ) {
    return false
  }

  if (!default_value) {
    return true
  }

  const defaultValue = Number(default_value)

  if (
    hasBitfieldValueMinMax(
      templateBitfieldValueMin,
      templateBitfieldValueMax,
    ) &&
    !isDefaultValueWithinBitfieldValueMinMax(
      defaultValue,
      templateBitfieldValueMin!,
      templateBitfieldValueMax!,
    )
  ) {
    return false
  }

  if (
    hasIntegerRange(templateIntegerRangeStart, templateIntegerRangeEnd) &&
    !isDefaultValueWithinIntegerRange(
      defaultValue,
      templateIntegerRangeStart!,
      templateIntegerRangeEnd!,
    )
  ) {
    return false
  }

  if (
    templateValidValues &&
    !isDefaultWithinValidValues(defaultValue, templateValidValues)
  ) {
    return false
  }

  return true
}

const hasIntegerRange = (
  integerRangeStart: number | bigint | undefined,
  integerRangeEnd: number | bigint | undefined,
) => {
  return integerRangeStart !== undefined && integerRangeEnd !== undefined
}

const hasBitfieldValueMinMax = (
  bitfieldValueMin: number | undefined,
  bitfieldValueMax: bigint | undefined,
) => {
  return bitfieldValueMin !== undefined && bitfieldValueMax !== undefined
}

const isIntegerRangeWithinBitfieldValueMinMax = (
  integerRangeStart: number | bigint,
  integerRangeEnd: number | bigint,
  bitfieldValueMin: number,
  bitfieldValueMax: bigint,
): boolean => {
  return (
    integerRangeStart >= bitfieldValueMin &&
    BigInt(integerRangeEnd) <= bitfieldValueMax
  )
}

const isValidValuesWithinIntegerRange = (
  validValues: CredentialTemplateFieldValidValue[],
  integerRangeStart: number | bigint,
  integerRangeEnd: number | bigint,
): boolean => {
  for (let i = 0; i < validValues.length; i++) {
    const value = Number(validValues[i].value)

    if (value < integerRangeStart || value > integerRangeEnd) {
      return false
    }
  }

  return true
}

const isValidValuesWithinBitfieldValueMinMax = (
  validValues: CredentialTemplateFieldValidValue[],
  bitfieldValueMin: number,
  bitfieldValueMax: bigint,
): boolean => {
  for (let i = 0; i < validValues.length; i++) {
    const { value } = validValues[i]

    if (Number(value) < bitfieldValueMin || BigInt(value) > bitfieldValueMax) {
      return false
    }
  }

  return true
}

const isDefaultValueWithinBitfieldValueMinMax = (
  defaultValue: number,
  bitfieldValueMin: number,
  bitfieldValueMax: bigint,
): boolean => {
  return (
    defaultValue >= bitfieldValueMin && BigInt(defaultValue) <= bitfieldValueMax
  )
}

const isDefaultValueWithinIntegerRange = (
  defaultValue: number,
  integerRangeStart: number | bigint,
  integerRangeEnd: number | bigint,
): boolean => {
  const parsedDefaultValue = BigInt(defaultValue)

  const parsedStart = parseBigInt(integerRangeStart)

  const parsedEnd = parseBigInt(integerRangeEnd)

  return parsedDefaultValue >= parsedStart && parsedDefaultValue <= parsedEnd
}

const isDefaultWithinValidValues = (
  defaultValue: number,
  validValues: CredentialTemplateFieldValidValue[],
): boolean => {
  const entry = validValues.find((entry) => entry.value === defaultValue)

  return Boolean(entry)
}
