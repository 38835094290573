import { useRead } from '../../components'
import {
  swiftClient,
  swiftClientWithoutErrorHandling,
} from '../../data/swiftClient'
import { adaptSDKRead } from '../axiosAdapters'
import { cacheKeys } from '../cacheKeysUtils'

export const useReadCredentialTemplate = ({
  id,
  enabled,
  showErrorNotification,
}: {
  id: string
  enabled?: boolean
  showErrorNotification?: boolean
}) => {
  const client = showErrorNotification
    ? swiftClient
    : swiftClientWithoutErrorHandling

  return useRead({
    readRequest: adaptSDKRead(client.credentialTemplate),
    cacheKey: cacheKeys.credential_template,
    id,
    enabled,
  })
}
