import { FieldMapping } from '@swiftctrl/api-client'
import { Button, ButtonProps, Modal } from '@swiftctrl/swift-component-library'
import { useBoolean } from '../../utils-hooks'
import { FieldMappingsTable } from '../editable-table'

type FieldMappingsButtonProps = {
  value: FieldMapping[] | undefined
  onChange: (value: Omit<FieldMapping, 'epi_id'>[]) => void
  disabled?: boolean
  size?: ButtonProps['size']
}

export const FieldMappingsButton = ({
  value = [],
  onChange,
  disabled,
  size,
}: FieldMappingsButtonProps) => {
  const [isEditorOpen, openEditor, closeEditor] = useBoolean()

  const handleConfirm = (fieldMappings: FieldMapping[]) => {
    closeEditor()

    const data: Omit<FieldMapping, 'epi_id'>[] = fieldMappings.map(
      (mapping) => {
        const { remote_field, local_field } = mapping

        return { remote_field, local_field }
      },
    )

    onChange(data)
  }

  return (
    <>
      <Button size={size} disabled={disabled} onClick={openEditor}>
        Edit field mappings ({value.length})
      </Button>
      <Modal open={isEditorOpen} footer={null} closable={false} width="80%">
        <FieldMappingsTable
          fieldMappings={value}
          epiId=""
          confirmButtonLabel="Close"
          onConfirm={handleConfirm}
        />
      </Modal>
    </>
  )
}
