export const buildISOTimestampFromLocalDate = (date: Date) => {
  const year = date.getFullYear()

  const month = pad(date.getMonth() + 1)

  const day = pad(date.getDate())

  const hours = pad(date.getHours())

  const minutes = pad(date.getMinutes())

  const seconds = pad(date.getSeconds())

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`

  return formattedDate
}

const pad = (value: number) => value.toString(10).padStart(2, '0')
