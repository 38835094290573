import { FlowAnalysisGraphConfig, IGraph, IGroup } from '@ant-design/graphs'
import { FlowAnalysisNodeData } from '@ant-design/graphs/es/components/flow-analysis-graph'
import {
  buildNodeExtraContent,
  buildNodeStyle,
  PARENT_CHILD_RELATIONSHIP_COLOR,
} from '../../../utils-hooks/add-entity-subtree-utils'
import { SOURCE_SINK_RELATIONSHIP_COLOR } from './models'
import { Edge, NodeExtraData } from './useGetChartData'

// The `nodeStateStyles` is left as is, because otherwise with the
// `customContent`, the `drag-node` behavior does not work.
// The user has to click and drag on the hover area, but outside
// the custom node itself.
export const buildChartConfig = (
  data: FlowAnalysisGraphConfig['data'],
  selectedEntityId: string | undefined,
  onEntityClick: (entityId: string) => void,
  onOutsideClick: () => void,
): FlowAnalysisGraphConfig => {
  return {
    data,
    nodeCfg: {
      size: [269, 96],
      style: buildNodeStyle(selectedEntityId),
      title: buildTitleStyle(),
      customContent: (
        item: NodeExtraData,
        group: IGroup | undefined,
        config: {
          startX: number
          startY: number
        },
      ) =>
        buildNodeExtraContent(
          {
            ...item,
            addChildEnabled: false,
            deleteEnabled: false,
            errorCount: 0,
            isLoading: false,
            isPlanReadOnly: true,
          },
          group,
          config,
        ),
    },
    edgeCfg: {
      style: (edge) => ({
        stroke: (edge as unknown as Edge)?.isSourceSinkRelationship
          ? SOURCE_SINK_RELATIONSHIP_COLOR
          : PARENT_CHILD_RELATIONSHIP_COLOR,
        lineWidth: 3,
      }),
      endArrow: (edge) => {
        const color = (edge as unknown as Edge)?.isSourceSinkRelationship
          ? SOURCE_SINK_RELATIONSHIP_COLOR
          : PARENT_CHILD_RELATIONSHIP_COLOR

        return {
          stroke: color,
          fill: color,
        }
      },
      edgeStateStyles: {}, // This clears the default hover style, which we do not want
    },
    behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
    onReady: (graph: IGraph) => {
      graph.on('node:click', (event) => {
        const { name, item } = event

        if (name !== 'click' || !item) {
          return
        }

        const { id } = item.get('model') as FlowAnalysisNodeData

        onEntityClick(id)
      })

      graph.on('canvas:click', (event) => {
        if (!event.item) {
          onOutsideClick()
        }
      })
    },
    fitCenter: false,
  }
}

const buildTitleStyle = () => ({
  containerStyle: {
    fill: 'transparent',
  },
})
