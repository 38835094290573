import { buildRedirectSearch } from '@swiftctrl/api-helpers'
import { config } from '../config/config'

type AccountsAppPage = 'signin' | 'signout'

export const goToAccountsApp = (page: AccountsAppPage) => {
  const url = buildAccountsAppUrl(page)

  window.location.href = url
}

export const buildAccountsAppUrl = (page: AccountsAppPage) => {
  const url = `${config.accountsUrl}/${page}`

  const accountsUrl = new URL(url)

  accountsUrl.search = buildRedirectSearch('config')

  return accountsUrl.href
}
