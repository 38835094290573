import { SortOrder } from '@swiftctrl/swift-component-library'

export type BrowseEntitySort = {
  columnTitle: string | undefined
  order: SortOrder
}

export type SortArg =
  | string
  | undefined
  | ((sort: BrowseEntitySort | undefined) => string | undefined)

export const getSort = (
  searchParam: string | undefined,
  sortArg: SortArg,
  sort: BrowseEntitySort | undefined,
) => {
  if (searchParam || !sortArg) {
    return undefined
  }

  if (typeof sortArg === 'string') {
    return sortArg
  }

  const sortParam = sortArg(sort)

  return sortParam
}
