import { generateHexadecimal } from '../../../../../utils-hooks'

/**
 * This is to make it more likely that we have a unique field name
 * that won't conflict with any names from the template itself.
 */
export const SINK_TEMPLATE_ID_FIELD_NAME = generateHexadecimal(10)

export const buildFormItemProps = (name: string, label: string) => ({
  name,
  label,
  required: false,
  rules: [
    {
      required: true,
      whitespace: true,
      message: `${label} is required`,
    },
  ],
})
