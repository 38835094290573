import { EntityTypes } from '@swiftctrl/api-client'
import { Swift, Text, Typography } from '@swiftctrl/swift-component-library'

type EntityNameDisplayProps = {
  entity: EntityTypes
  bold?: boolean
}

export const EntityNameDisplay: Swift.FC<EntityNameDisplayProps> = ({
  entity: { entity_name, entity_id },
  bold,
}) => {
  if (entity_name) {
    return <Text weight={bold ? 'semibold' : 'regular'}>{entity_name}</Text>
  }

  return <Typography.Text code>{entity_id}</Typography.Text>
}
