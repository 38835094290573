import { Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { formatEntityTimestamp } from '../../../utils-hooks'

type TimestampProps = {
  label: string
  value: string
}

export const Timestamp = ({ label, value }: TimestampProps) => (
  <StyledText type="secondary">
    {`${label}: ${formatEntityTimestamp(value)}`}
  </StyledText>
)

const StyledText = styled(Typography.Text)`
  font-size: 0.8em;
`
