import { sleep } from '../../../utils-hooks'
import { getCollectionWithDeletion, TreeItem } from './utils'

const DELAY_MS = 50

export const recursivelyDelete = async (
  subtree: TreeItem,
  onDeleted: () => void,
) => {
  const {
    children,
    data: { entity_type_id, entity_id },
  } = subtree

  for (let i = 0; i < children.length; i++) {
    await recursivelyDelete(children[i], onDeleted)

    await sleep(DELAY_MS)
  }

  const collection = getCollectionWithDeletion(entity_type_id!)

  if (!collection) {
    throw new Error(
      `Missing mapping for deleting entity of type: ${entity_type_id}`,
    )
  }

  await collection.at(entity_id!).delete()

  onDeleted()
}
