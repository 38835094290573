import { CredentialFieldType } from '@swiftctrl/api-client'
import { Input } from './Input'

type CredentialTemplateFieldTooltipInputProps = {
  fieldType: CredentialFieldType
  tooltip: string | null
  onChange: (value: string) => void
}

export const CredentialTemplateFieldTooltipInput = ({
  fieldType,
  tooltip,
  onChange,
}: CredentialTemplateFieldTooltipInputProps) => {
  if (fieldType === 'BYTE_ARRAY') {
    return null
  }

  return <Input value={tooltip || ''} onChange={onChange} />
}
