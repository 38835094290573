import { EntityBrowseTypes, EntityTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { SelectProps } from '@swiftctrl/swift-component-library'
import { swiftClient } from '../../data/swiftClient'
import { useAddEntityFormContext } from '../../states'
import {
  alphabetizeEntities,
  buildEntityTypeFilter,
  getCachedSwiftCore,
  mapDataToSelectOptions,
} from '../../utils-hooks'
import { BaseLiveSearch } from './BaseLiveSearch'

type SourceOwnerSelectProps = {
  disabled?: boolean
  onChange: (entity: Partial<EntityTypes>) => void
  size: SelectProps['size']
}

export const SourceOwnerSelect = ({
  disabled,
  onChange,
  size,
}: SourceOwnerSelectProps) => {
  const { values } = useAddEntityFormContext()

  const value = values.owner as EntityTypes | undefined

  const baseId = values.sourceEntitySelectBaseId as string | undefined

  const updateEntity = (_: string, option: any) => {
    const entity = option?.data

    onChange(entity)
  }

  const fetchEntities = async (search: string) => {
    const filters = [buildEntityTypeFilter('epi')]

    if (search) {
      const q = getQueryHandler<EntityBrowseTypes>()

      filters.push(q('AND', 'entity_name', 'EQ_IC', search))
    }

    const { data: epis } = await swiftClient.entity.browse({
      baseId,
      select: ['entity_id', 'entity_name'],
      filters,
    })

    const data = [...epis]

    const swiftCore = getCachedSwiftCore()

    if (
      swiftCore.entity_name
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase())
    ) {
      data.push(swiftCore)

      data.sort(alphabetizeEntities)
    }

    return mapDataToSelectOptions(data, 'entity_id', 'entity_name', true)
  }

  return (
    <BaseLiveSearch
      value={value?.entity_id || ''}
      onChange={updateEntity}
      disabled={disabled}
      fetchOptions={fetchEntities}
      fetchDependencies={[baseId]}
      size={size}
    />
  )
}
