import { RoleArchetype } from '@swiftctrl/api-client'

export const checkExistingArchetype = (
  archetypes: RoleArchetype[],
  archetype: RoleArchetype,
) => {
  const check = archetypes.some((item) => item === archetype)

  return check
}
