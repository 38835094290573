import { notification } from '@swiftctrl/swift-component-library'
import { CopyableDisplay } from '../../components'
import { BACKEND_ERROR_MESSAGE_SEPARATOR } from '../../data/models'
import { BackendError } from '../../data/swiftClient'
import { stringifyJson } from '../jsonUtils'

export const showErrorNotification = (title: string, error: any) => {
  const errorMessage = getErrorMessage(error)

  const messages = errorMessage.split(BACKEND_ERROR_MESSAGE_SEPARATOR)

  messages.forEach((message) => {
    notification.error({
      message: title,
      description: (
        <CopyableDisplay
          copyableText={stringifyJson(error.response?.data || message)}
          label={message}
          copyIconTooltip="Copy response"
          disableTooltipForText
        />
      ),
      placement: 'bottomLeft',
      duration: 5,
    })
  })
}

export const getErrorMessage = (error: any) => {
  if (error.isAxiosError) {
    const backendError = error as BackendError

    const backendErrorMessage =
      backendError.response?.data.message || backendError.message

    return backendErrorMessage
  }

  if (error instanceof Error) {
    return error.message
  }

  return String(error)
}
