import { RoleArchetype } from '@swiftctrl/api-client'
import { RoleSubdataTable, RoleSubDataTableItem } from '../../manage-subdata'
import { RemoveSubdataButton } from '../../RemoveSubdataButton'

type SelectedArchetypesTableProps = {
  data: RoleArchetype[]
  onRemoveArchetype: (archetype: RoleArchetype) => void
}

export const SelectedArchetypesTable = ({
  data,
  onRemoveArchetype,
}: SelectedArchetypesTableProps) => {
  return (
    <RoleSubdataTable
      header="Selected Archetypes"
      dataSource={data}
      renderItem={(archetype) => (
        <RoleSubDataTableItem>
          {archetype}
          <RemoveSubdataButton
            data={archetype}
            removeSubdata={onRemoveArchetype}
          />
        </RoleSubDataTableItem>
      )}
    />
  )
}
