import { OrganizationTypes } from '@swiftctrl/api-client'
import { Form, Select } from '@swiftctrl/swift-component-library'
import { buildCommonFormItemProps } from '../../../../utils-hooks'
import { AddFormButtonGroup } from '../../../AddFormButtonGroup'
import { BackendErrorAlert } from '../../../BackendErrorAlert'
import { AddProfileDrawer } from './AddProfileDrawer'
import { AddProfileForm } from './AddProfileForm'
import { AddProfileFormItemEmailInput } from './AddProfileFormItemEmailInput'
import { AddProfileFormItemFirstNameInput } from './AddProfileFormItemFirstNameInput'
import { AddProfileFormItemLastNameInput } from './AddProfileFormItemLastNameInput'
import { AddProfileFormItemPhoneInput } from './AddProfileFormItemPhoneInput'
import { useAddProfileLogic } from './useAddProfileLogic'

type AddProfileViewProps = {
  organization: OrganizationTypes
  open: boolean
  onClose: () => void
}

export const AddProfileView = ({
  organization,
  open,
  onClose,
}: AddProfileViewProps) => {
  const { organization_id, entity_name } = organization

  const {
    values,
    setValues,
    error,
    isValid,
    checkBeforeClose,
    handleFinish,
    isPending,
    emailOptions,
    phoneOptions,
    requireEmailOrPhone,
  } = useAddProfileLogic({
    organization,
    onClose,
  })

  return (
    <AddProfileDrawer
      open={open}
      onClose={checkBeforeClose}
      isPending={isPending}
    >
      <AddProfileForm
        values={values}
        setValues={setValues}
        isPending={isPending}
        onFinish={handleFinish}
      >
        <Form.Item
          {...buildCommonFormItemProps({
            label: 'Organization',
            required: true,
          })}
        >
          <Select
            options={[{ value: organization_id, label: entity_name }]}
            defaultValue={organization_id}
            disabled
          />
        </Form.Item>
        <AddProfileFormItemFirstNameInput />
        <AddProfileFormItemLastNameInput />
        <AddProfileFormItemEmailInput
          required={emailOptions.required}
          requireEmailOrPhone={requireEmailOrPhone}
          phone={values.phone}
        />
        {phoneOptions.visible && (
          <AddProfileFormItemPhoneInput
            required={phoneOptions.required}
            requireEmailOrPhone={requireEmailOrPhone}
            email={values.email}
            isPending={isPending}
          />
        )}
        {error && (
          <Form.Item>
            <BackendErrorAlert error={error} />
          </Form.Item>
        )}
        <AddFormButtonGroup
          onClose={checkBeforeClose}
          isValid={isValid}
          isPending={isPending}
        />
      </AddProfileForm>
    </AddProfileDrawer>
  )
}
