import { RoleOperationTypes } from '@swiftctrl/api-client'
import { Modal, Typography } from '@swiftctrl/swift-component-library'

export const confirmRemoveRoleOperation = (
  roleOperation: RoleOperationTypes,
  onRemoveOperation: (roleOperation: RoleOperationTypes) => void,
) => {
  const onClick = () => {
    Modal.confirm({
      title: 'Remove Permission',
      content: (
        <>
          Are you sure you want to remove
          <Typography.Text code>{roleOperation.action}</Typography.Text>?
        </>
      ),
      okText: 'Remove',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => onRemoveOperation(roleOperation),
      centered: true,
    })
  }

  return onClick
}
