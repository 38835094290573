import { ProviderType } from '@swiftctrl/api-client'
import { ProviderProfileLoginData } from '../config/add-entity-types'
import { providerFieldLabels } from '../config/labels'

export const buildProviderProfileName = (
  providerType: ProviderType,
  loginData: Partial<ProviderProfileLoginData>,
) => {
  const providerTypeLabel = providerFieldLabels.providerTypeLabels[providerType]

  const tokens = [providerTypeLabel]

  switch (providerType) {
    case 'email_password':
      const email = loginData['login_data.email_password.email']!

      tokens.push(email)

      break

    case 'openid':
    case 'openid_okta':
      const sub = loginData['login_data.openid.sub']!

      tokens.push(sub)

      break

    case 'single_use_token':
      const token = loginData['login_data.single_use_token.token']!

      tokens.push(token)

      break

    case 'proxy_login':
      const identifier = loginData['login_data.proxy_login.identifier']!

      tokens.push(identifier)

      break
  }

  if (tokens.length === 1) {
    return undefined
  }

  const providerProfileName = tokens.join(' ')

  return providerProfileName
}
