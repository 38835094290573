import { Modal, Typography } from '@swiftctrl/swift-component-library'
import { useMutation } from '@tanstack/react-query'
import { getDeleteRequest } from '../config/deleteEntityConfig'
import { getEntityLabel } from '../config/labels'
import { EntityType } from '../data/models'
import { showErrorNotification } from './notifications'

type Params = {
  entityType: EntityType
  entityId: string
  entityName: string
  onSuccess: () => void
}

export const useDeleteEntityFlow = ({
  entityType,
  entityId,
  entityName,
  onSuccess,
}: Params) => {
  const { mutate } = useMutation({
    mutationFn: () => {
      const deleteRequest = getDeleteRequest(entityType)

      return deleteRequest(entityId)
    },
    onSuccess,
    onError: (error) => {
      showErrorNotification(
        `Error while deleting ${getEntityLabel({
          entityType,
          capitalize: false,
        })}`,
        error,
      )
    },
  })

  const handleDeleteClick = () => {
    Modal.confirm({
      title: `Delete ${getEntityLabel({ entityType })}?`,
      content: (
        <>
          Are you sure you want to delete{' '}
          {entityName ? (
            <b>{entityName}</b>
          ) : (
            <Typography.Text code>{entityId}</Typography.Text>
          )}
          ?
        </>
      ),
      okText: 'Delete',
      okButtonProps: {
        danger: true,
      },
      onOk: () => mutate(),
      centered: true,
    })
  }

  return { handleDeleteClick }
}
