import { Form } from '@swiftctrl/swift-component-library'
import { PropsWithChildren } from 'react'
import { buildCommonFormProps } from '../../../../utils-hooks'
import { AddProfileFormData } from './utils'

type AddProfileFormProps = PropsWithChildren<{
  values: AddProfileFormData
  setValues: (values: AddProfileFormData) => void
  isPending: boolean
  onFinish: (values: AddProfileFormData) => void
}>

export const AddProfileForm = ({
  values,
  setValues,
  isPending,
  onFinish,
  children,
}: AddProfileFormProps) => {
  return (
    <Form
      {...buildCommonFormProps({
        values,
        setValues,
        isPending,
        onFinish,
      })}
    >
      {children}
    </Form>
  )
}
