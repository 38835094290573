export const baseStyle = `
--PhoneInput-color--focus: #03b2cb;
--PhoneInputInternationalIconPhone-opacity: 0.8;
--PhoneInputInternationalIconGlobe-opacity: 0.65;
--PhoneInputCountrySelect-marginRight: 0.6em;
--PhoneInputCountrySelectArrow-width: 0.45em;
--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
--PhoneInputCountrySelectArrow-borderWidth: 1px;
--PhoneInputCountrySelectArrow-opacity: 0.45;
--PhoneInputCountrySelectArrow-color: currentColor;
--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
--PhoneInputCountrySelectArrow-transform: rotate(45deg);
--PhoneInputCountryFlag-aspectRatio: 1.5;
--PhoneInputCountryFlag-height: 1em;
--PhoneInputCountryFlag-borderWidth: 1px;
--PhoneInputCountryFlag-borderColor: #d9d9d9;
--PhoneInputCountryFlag-borderRadius: 2px;
--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);

.PhoneInput {
	display: flex;
	align-items: center;
}

.PhoneInputInput {
	flex: 1;
	font-size: 16px;
	min-width: 0;
	border-color: var(--PhoneInputCountryFlag-borderColor);
	border-radius: 0 var(--PhoneInputCountryFlag-borderRadius) var(--PhoneInputCountryFlag-borderRadius) 0;
	border-style: solid;
	border-width: 1px;
	padding: 8px 11px;
	transition: all 0.2s;

	&:hover {
		border-color: #40a9ff;
	}

	&:focus {
		border-color: #40a9ff;
		box-shadow: 0 0 0 2px #1890ff33;
		border-right-width: 1px;
		outline: 0;
	}
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	display: block;
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

.PhoneInputCountry {
	align-items: center;
	align-self: stretch;
	background-color: rgba(0,0,0,.02);
	border-width: 1px 0 1px 1px;
	display: flex;
	padding: 0 8px 0 11px;
	position: relative;
    border-color: var(--PhoneInputCountryFlag-borderColor);
    border-radius: var(--PhoneInputCountryFlag-borderRadius) 0 0 var(--PhoneInputCountryFlag-borderRadius);
    border-style: solid;
}

.PhoneInputCountrySelect {
	border: 0;
	cursor: pointer;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-style: solid;
	border-top-width: 0;
	content: '';
	display: block;
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
	transform: var(--PhoneInputCountrySelectArrow-transform);
	width: var(--PhoneInputCountrySelectArrow-width);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}
`
