import {
  EntityTypes,
  EntityTypesIds,
  SourceAddTypes,
} from '@swiftctrl/api-client'
import { EntityType } from '../../data/models'
import { swiftClient } from '../../data/swiftClient'
import { AddEntityConfig, getCachedSwiftCore } from '../../utils-hooks'
import { getEntityLabel } from '../labels'

const supportedEntityTypes: EntityType[] = [
  'profile',
  'phone',
  'building',
  'organization',
  'system',
  'credential_format',
  'credential',
  'epi',
  'controller',
  'reader',
  'schedule',
  'access_level',
  'role',
  'acs_transaction',
  'acs_hardware',
  'address',
  'meeting',
  'meeting_invitation',
  'room',
  'room_price',
  'booking',
  'entity',
  'provider',
  'provider_profile',
  'session',
  'device',
  'access_level_credential',
  'division',
  'department',
  'opening_hours',
]

const typeNamesOptions = supportedEntityTypes.map((entityType) => ({
  key: entityType,
  label: getEntityLabel({ entityType: entityType as EntityType }),
}))

const alphabetize = (a: { label: string }, b: { label: string }) =>
  a.label.localeCompare(b.label)

typeNamesOptions.sort(alphabetize)

type FormData = {
  name: string
  description: string
  overseer: EntityTypes
  sourceEntitySelectBaseId: string
  sourceTarget: EntityTypes
  owner: EntityTypes
  typeNames: string[]
}

export const source: AddEntityConfig<FormData, SourceAddTypes> = {
  fieldGroups: [
    {
      fields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'description',
          label: 'Description',
          optional: true,
        },
        {
          key: 'sourceTarget',
          label: 'Source Target',
          inputType: 'entitySelect',
          entitySelectType: [
            'epi',
            'building',
            'entity',
            'role',
            'organization',
          ],
          entitySelectBaseIdKey: 'sourceEntitySelectBaseId',
          onChange: (value, values) => {
            values.sourceTarget = value

            if (!value) {
              return values
            }

            if (values.owner) {
              return values
            }

            const entity = value as EntityTypes

            values.owner =
              entity.entity_type_id === 'epi' ? entity : getCachedSwiftCore()

            return values
          },
        },
        {
          key: 'owner',
          label: 'Owner',
          inputType: 'sourceOwnerSelect',
        },
        {
          key: 'overseer',
          label: 'Overseer',
          inputType: 'entitySelect',
          entitySelectType: ['entity', 'epi', 'role'],
          entitySelectBaseIdKey: 'sourceEntitySelectBaseId',
        },
        {
          key: 'typeNames',
          label: 'Type Names',
          inputType: 'select',
          selectMultiple: true,
          selectOptions: typeNamesOptions,
        },
      ],
    },
  ],
  buildData: (values) => {
    const data: SourceAddTypes = {
      source_owner_id: values.owner.entity_id,
      overseer_id: values.overseer.entity_id,
      source_target_id: values.sourceTarget.entity_id,
      entity_name: values.name,
      entity_description: values.description,
      type_names: values.typeNames as EntityTypesIds[],
    }

    return data
  },
  addRequest: (source) => swiftClient.source.add(source),
}
