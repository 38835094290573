import React from 'react'
import DefaultPhoneInput from 'react-phone-number-input'
import styled from 'styled-components'
import { baseStyle } from './baseStyle'

type InputPhoneProps = React.ComponentProps<typeof DefaultPhoneInput>

/** React phone number with an Ant Design UI Layer, see documentation: https://catamphetamine.gitlab.io/react-phone-number-input/ */
export const PhoneInput: React.FC<InputPhoneProps> = styled(DefaultPhoneInput)`
  ${baseStyle}

  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  background-color: #fff;
  background-image: none;
  transition: all 0.2s;

  .PhoneInputInput {
    padding-top: 6.5px;
    padding-bottom: 6.5px;
  }
`

PhoneInput.defaultProps = {
  ...PhoneInput.defaultProps,
  defaultCountry: navigator.language.split(
    '-',
  )[1] as InputPhoneProps['defaultCountry'],
}
