import { useLocation } from 'react-router-dom'

export const useLocationStateRuleId = () => {
  const { state } = useLocation()

  if (!state) {
    return undefined
  }

  if (!state.ruleId) {
    return undefined
  }

  const ruleId = String(state.ruleId)

  return ruleId
}
