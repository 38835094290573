import { FieldMapping } from '@swiftctrl/api-client'
import { Empty, PreLoader } from '@swiftctrl/swift-component-library'
import {
  useGetLocalFieldDefinitions,
  useGetRemoteFieldDefinitions,
  validateLocalField,
} from '../../../utils-hooks'
import { EditableTable } from '../EditableTable'
import { LocalFieldInput } from './LocalFieldInput'
import { RemoteFieldInput } from './RemoteFieldInput'

type EpiFieldMappingsTableProps = {
  fieldMappings: FieldMapping[]
  epiId: string
  confirmButtonLabel: string
  confirmButtonLoading?: boolean
  disableConfirmButtonWhenDataUnchanged?: boolean
  onConfirm: (fieldMappings: FieldMapping[]) => void
}

export const FieldMappingsTable = ({
  fieldMappings,
  epiId,
  confirmButtonLabel,
  confirmButtonLoading,
  disableConfirmButtonWhenDataUnchanged,
  onConfirm,
}: EpiFieldMappingsTableProps) => {
  // For validating the local fields, the rule is built from the definitions.
  // We need the definitions before the component finishes setting up,
  // because the validation is set up by the component
  const { data: localFieldDefinitionsResponse } = useGetLocalFieldDefinitions()

  const { data: remoteFieldDefinitionsResponse } =
    useGetRemoteFieldDefinitions()

  if (!localFieldDefinitionsResponse || !remoteFieldDefinitionsResponse) {
    return <PreLoader />
  }

  return (
    <EditableTable
      columns={[
        {
          title: 'Remote field',
          dataIndex: 'remote_field',
          renderInput: (value, onChange) => (
            <RemoteFieldInput value={value} onChange={onChange} />
          ),
          sorter: (a, b) => a.remote_field.localeCompare(b.remote_field),
          validationRules: [
            {
              required: true,
              message: 'Remote field is required',
              validator: (value: string) => Boolean(value.trim()),
            },
          ],
        },
        {
          title: 'Local field',
          dataIndex: 'local_field',
          renderInput: (value, onChange) => (
            <LocalFieldInput value={value} onChange={onChange} />
          ),
          sorter: (a, b) => a.local_field.localeCompare(b.local_field),
          validationRules: [
            {
              validator: validateLocalField,
            },
          ],
        },
      ]}
      initialData={fieldMappings}
      dataFactory={() => ({
        epi_id: epiId,
        remote_field: '',
        local_field: '',
      })}
      confirmButtonLabel={confirmButtonLabel}
      confirmButtonLoading={confirmButtonLoading}
      disableConfirmButtonWhenDataUnchanged={
        disableConfirmButtonWhenDataUnchanged
      }
      onConfirm={onConfirm}
      locale={{ emptyText: <Empty description="No field mappings" /> }}
    />
  )
}
