import {
  HTMLTitle,
  ProvidersBrowser,
  StyledEntitiesBrowserScreen,
} from '../components'

export const ProvidersScreen = () => (
  <StyledEntitiesBrowserScreen>
    <HTMLTitle value="Providers" />
    <ProvidersBrowser
      filters={{ baseIdType: 'organization' }}
      displayAddButton
    />
  </StyledEntitiesBrowserScreen>
)
