import { AxiosResponse } from '@swiftctrl/api-client'
import { isEqual } from 'lodash'
import { useState } from 'react'
import * as AddEntityTypes from '../config/add-entity-types'
import { EntityType } from '../data/models'
import { areFalsy } from './areFalsy'
import { showErrorNotification, showWarningNotification } from './notifications'

export type AddEntityConfig<FormDataType = any, PayloadDataType = any> = {
  fieldGroups: FieldGroupConfig<FormDataType>[]
  /**
   * This info is displayed after the fields and before the "Add" button
   */
  infoLabel?: string
  /**
   * If this is omitted, the form data will be used directly as the payload data
   */
  buildData?: (values: FormDataType) => PayloadDataType
  addRequest: (
    data: PayloadDataType,
  ) => Promise<AxiosResponse<any, PayloadDataType>>
}

export type FieldGroupConfig<T> = {
  fields: FieldConfig<T>[]
  /**
   * `values` will usually consist of fields with string values.
   *
   * However, in some cases, a value will be an object.
   * In this case, the optional chaining operator (`?.`) will not work
   * all the way through.
   * You first have to check if the `value.key` exists, and then
   * navigate into it.
   *
   * For example, `provider_profile` is configured to use `ProviderSelect`,
   * which sets the provider object to the `provider` field.
   * Later on, in a condition, it's checked like this:
   * ```
   * values.provider &&
   * values.provider.provider_type.name === 'email_password'
   * ```
   */
  condition?: (values: T, overseerIdIsPreset: boolean) => boolean
}

export type FieldConfig<T> = {
  /**
   * For any given config, each field key should be unique.
   *
   * There is a special key value, `baseId`, which indicates that this field
   * should be used as the base ID for select inputs that fetch data
   * from the backend.
   */
  key: keyof T
  label?: string
  /**
   * Appended to the label inside parentheses
   */
  labelExtra?: string
  /**
   * If omitted, defaults to 'text'
   */
  inputType?: FieldInputType
  placeholder?: string
  /**
   * Should be used when `inputType` is `select`
   */
  selectOptions?: { key: string; label: string }[]
  /**
   * Should be used when `inputType` is `select`
   */
  selectMultiple?: boolean
  /**
   * Can be used when `inputType` is `entitySelect` to filter the entities by type
   */
  entitySelectType?: EntityType | EntityType[]
  /**
   * Can be used when `inputType` is `entitySelect`, `profileSelect` or `providerSelect` to use a value
   * from the `values` object as the base ID
   */
  entitySelectBaseIdKey?: keyof T
  optional?: boolean
  /**
   * Can be used for updating multiple values at once.
   *
   * `values` can be mutated directly.
   */
  onChange?: (value: any, values: T) => T
  rules?: ValidationRule[]
  /**
   * After the entity has been created, controls whether the field can be edited or not.
   *
   * If omitted, defaults to `true`.
   */
  editable?: boolean
  /**
   * Use to show a larger text above the field label.
   */
  title?: string
  /**
   * Can be used when `inputType` is `entitySelect` to show the entity type
   */
  entitySelectDisplayEntityType?: boolean
}

export type FieldInputType =
  | 'text'
  | 'textArea'
  | 'number'
  | 'select'
  | 'boolean'
  | 'datetime'
  | 'duration'
  | 'phone'
  | 'entitySelect'
  | 'organizationSelect'
  | 'profileSelect'
  | 'epiTypeSelect'
  | 'epiSubtypeSelect'
  | 'fieldMappings'
  | 'epiConfig'
  | 'sourceOwnerSelect'
  | 'providerProfileLoginDataInput'

export type ValidationRule = {
  /**
   * The message that will be displayed to the user if this rule isn't met
   */
  message: string
  /**
   * Used for `inputType` `"text"`
   */
  minLength?: number
  /**
   * Used for `inputType` `"text"`
   */
  maxLength?: number
  /**
   * Used for `inputType` `"text"`
   */
  pattern?: RegExp
  /**
   * Used for `inputType` `"number"`
   */
  min?: number
  /**
   * Used for `inputType` `"number"`
   */
  max?: number
  /**
   * The validator should return a boolean value depending on the input value being valid or not
   * E.g: if Validator returns true, then the input value is valid.
   * E.g: if validator returns false, then the input value is not valid.
   */
  validator?: (value: any) => boolean
}

export type ConfiguredEntityTypes = keyof typeof AddEntityTypes

export const isEntityTypeConfiguredForAdding = (entityType: EntityType) => {
  const entityTypeIsConfigured = entityType in AddEntityTypes

  return entityTypeIsConfigured
}

const getAddEntityConfig = (entityType: ConfiguredEntityTypes) => {
  const config = AddEntityTypes[entityType]

  return config
}

export const useAddEntity = <FormDataType, PayloadDataType>({
  entityType,
  addDataTemplate,
  overseerId,
  onDirty,
  onAddingEntityStarted,
  onAddingEntityStopped,
  onEntityAdded,
}: {
  entityType: ConfiguredEntityTypes
  addDataTemplate: object | undefined
  overseerId: string | undefined
  onDirty: () => void
  onAddingEntityStarted: () => void
  onAddingEntityStopped: () => void
  onEntityAdded: () => void
}) => {
  const { fieldGroups, infoLabel, buildData, addRequest } = getAddEntityConfig(
    entityType,
  ) as unknown as AddEntityConfig<FormDataType, PayloadDataType>

  const initialValues = buildInitialValues(
    fieldGroups,
    addDataTemplate,
    overseerId,
  )

  const [values, setValues] = useState(initialValues)

  const addEntity = async () => {
    if (!addRequest) {
      showWarningNotification(
        `The entity type ${entityType} is not configured for use by this form`,
        'If you think this is a mistake, ask your friendly ConfigCloud team 🧑‍💻',
      )

      return
    }

    onAddingEntityStarted()

    const data = buildData ? buildData(values) : values

    try {
      await addRequest(data)

      setValues(initialValues)

      onEntityAdded()
    } catch (error: any) {
      showErrorNotification(`Error while adding ${entityType}`, error)
    } finally {
      onAddingEntityStopped()
    }
  }

  const { currentFieldGroups } = getCurrentFieldConfigs(
    fieldGroups,
    values,
    Boolean(overseerId),
  )

  const currentFields = currentFieldGroups.flatMap((group) => group.fields)

  const updateValue = (name: string, value: string) =>
    setValues((values: any) => {
      const currentValue = values[name]

      if (areFalsy(currentValue, value) || isEqual(currentValue, value)) {
        return values
      }

      const fieldConfig = getFieldConfig(fieldGroups, name)

      const update = fieldConfig.onChange
        ? fieldConfig.onChange(value, { ...values })
        : { ...values, [name]: value }

      if (!isEqual(update, initialValues)) {
        onDirty()
      }

      return update
    })

  return {
    values,
    updateValue,
    addEntity,
    currentFields,
    infoLabel,
  }
}

const buildInitialValues = <T,>(
  fieldGroups: FieldGroupConfig<T>[],
  addDataTemplate: object | undefined,
  overseerId: string | undefined,
) => {
  const initialValues = fieldGroups.reduce((obj, fieldGroup) => {
    fieldGroup.fields.forEach((field) => {
      const { inputType, key } = field

      if (inputType === 'boolean') {
        obj[key] = false
      } else {
        obj[key] = ''
      }
    })

    return obj
  }, addDataTemplate || ({} as any))

  if (overseerId) {
    if (initialValues.hasOwnProperty('overseerId')) {
      initialValues.overseerId = overseerId
    }

    if (initialValues.hasOwnProperty('overseer_id')) {
      initialValues.overseer_id = overseerId
    }
  }

  return initialValues
}

export const getCurrentFieldConfigs = <T,>(
  fieldGroups: FieldGroupConfig<T>[],
  values: any,
  overseerIdIsPreset: boolean,
) => {
  const currentFieldGroups =
    fieldGroups.filter((fieldGroup) => {
      if (!fieldGroup.condition) {
        return true
      }

      if (!values) {
        return false
      }

      return fieldGroup.condition(values, overseerIdIsPreset)
    }) || []

  const currentFieldConfigs = currentFieldGroups.reduce(
    (fieldConfigs, fieldGroup) => {
      fieldConfigs.push(...fieldGroup.fields)

      return fieldConfigs
    },
    [] as FieldConfig<T>[],
  )

  return {
    currentFieldGroups,
    currentFieldConfigs,
  }
}

const getFieldConfig = <FormDataType,>(
  fieldGroups: FieldGroupConfig<FormDataType>[],
  key: string,
): FieldConfig<FormDataType> => {
  for (let i = 0; i < fieldGroups.length; i++) {
    const fieldGroup = fieldGroups[i]

    for (let j = 0; j < fieldGroup.fields.length; j++) {
      const fieldConfig = fieldGroup.fields[j]

      if (fieldConfig.key === key) {
        return fieldConfig
      }
    }
  }

  throw new Error(`Could not find field config with key ${key}`)
}
