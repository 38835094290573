import { EntityTypes } from '@swiftctrl/api-client'
import { CopyableCodeDisplay, CopyableDisplay } from '../data-display'
import { EntityTypeTag } from '../read-entity'

type EntityDisplayProps = {
  entity: Partial<EntityTypes>
}

export const EntityDisplay = ({ entity }: EntityDisplayProps) => {
  const { entity_name, entity_id, entity_type_id } = entity

  return (
    <>
      <EntityTypeTag entityType={entity_type_id!} />
      {entity_name ? (
        <CopyableDisplay label={entity_name} copyableText={entity_id} />
      ) : (
        <CopyableCodeDisplay label={entity_id!} />
      )}
    </>
  )
}
