import { AntLayout, Swift } from '@swiftctrl/swift-component-library'
import { BasicProps } from 'antd/lib/layout/layout'
import styled from 'styled-components'

export const Header: Swift.FC = ({
  children,

  ...props
}: BasicProps) => {
  return <StyledHeader {...props}>{children}</StyledHeader>
}

const StyledHeader = styled(AntLayout.Header)`
  background-color: var(--primary-color-darker-3);
`
