import { SinkTypes, SourceTypes } from '@swiftctrl/api-client'
import { Text, Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { EntityTypeTag } from '../../../components'
import { EntityType } from '../../../data/models'
import { DetailItem } from './DetailItem'
import { SinkDetails } from './SinkDetails'
import { SourceDetails } from './SourceDetails'
import { Entity } from './models'

type EntityDetailsViewProps = {
  entity: Entity
}

export const EntityDetailsView = ({ entity }: EntityDetailsViewProps) => {
  const { entity_name, entity_description, overseer_name, entity_type_id } =
    entity

  const entityType = entity_type_id as EntityType

  return (
    <Container>
      <Typography.Paragraph>
        <Text size="20px">{entity_name}</Text>
      </Typography.Paragraph>
      <DetailItem
        label="Entity type"
        value={<EntityTypeTag entityType={entityType} />}
      />
      <DetailItem
        label="Description"
        value={entity_description || '(no description)'}
      />
      {overseer_name && <DetailItem label="Overseer" value={overseer_name} />}
      {entityType === 'source' && (
        <SourceDetails source={entity as SourceTypes} />
      )}
      {entityType === 'sink' && <SinkDetails sink={entity as SinkTypes} />}
    </Container>
  )
}

const Container = styled.div`
  position: absolute;

  top: 0px;
  right: 0px;
  width: 350px;
  height: calc(100% + 1px);

  background-color: #fff;
  border-left: 1px solid var(--light-gray-background-color);

  overflow: scroll;

  padding: 0px 24px;
`
