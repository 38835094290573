import { EntityType } from '../../data/models'

export type NodeExtraData = {
  text: ''
  entityType: EntityType | undefined
  id: string | undefined
  name: string
  addChildEnabled: boolean
  deleteEnabled: boolean
  errorCount: number
  isLoading: boolean
  isPlanReadOnly: boolean
}

export const NEW_ENTITY_TITLE = 'New Entity'

export const PARENT_CHILD_RELATIONSHIP_COLOR = '#ADC6FF'
