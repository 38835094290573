import { CredentialFieldType } from '@swiftctrl/api-client'
import {
  Button,
  Divider,
  EditAltOffOutlined,
  EditAltOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  Layout,
  Switch,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'

type CredentialTemplateFieldOptionsViewProps = {
  fieldType: CredentialFieldType
  isOptional: boolean
  readOnly: boolean
  isHidden: boolean
  onOptionalChange: (value: boolean) => void
  onReadOnlyChange: (value: boolean) => void
  onHiddenChange: (value: boolean) => void
}

export const CredentialTemplateFieldOptionsView = ({
  fieldType,
  isOptional,
  readOnly,
  isHidden,
  onOptionalChange,
  onReadOnlyChange,
  onHiddenChange,
}: CredentialTemplateFieldOptionsViewProps) => {
  const hiddenTooltip = buildHiddenTooltip(fieldType, isHidden)

  return (
    <Layout horizontal alignItems="center">
      Required
      <StyledSwitch
        checked={!isOptional}
        onChange={() => onOptionalChange(!isOptional)}
      />
      <Divider type="vertical" />
      <Tooltip title={readOnly ? 'Read-only' : 'Editable'}>
        <Button
          type="link"
          icon={readOnly ? <EditAltOffOutlined /> : <EditAltOutlined />}
          onClick={() => onReadOnlyChange(!readOnly)}
        />
      </Tooltip>
      <Tooltip title={hiddenTooltip}>
        <Button
          type="link"
          icon={isHidden ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          onClick={() => onHiddenChange(!isHidden)}
          disabled={fieldType === 'BYTE_ARRAY'}
        />
      </Tooltip>
    </Layout>
  )
}

const buildHiddenTooltip = (
  fieldType: CredentialFieldType,
  isHidden: boolean,
) => {
  if (fieldType === 'BYTE_ARRAY') {
    return 'Byte array fields cannot be made visible'
  }

  if (isHidden) {
    return 'Hidden'
  }

  return 'Visible'
}

const StyledSwitch = styled(Switch)`
  margin-left: ${({ theme }) => theme.spacing.small};
  margin-right: ${({ theme }) => theme.spacing.medium};
`
