import {
  BuildingsBrowser,
  HTMLTitle,
  StyledEntitiesBrowserScreen,
} from '../components'

export const BuildingsScreen = () => (
  <StyledEntitiesBrowserScreen>
    <HTMLTitle value="Buildings" />
    <BuildingsBrowser
      filters={{ baseIdType: 'organization' }}
      displayAddButton
    />
  </StyledEntitiesBrowserScreen>
)
