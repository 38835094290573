import { OrganizationTypes, ProfilesTypes } from '@swiftctrl/api-client'
import { ColumnsType } from '@swiftctrl/swift-component-library'
import {
  PEOPLE_SCREEN_PATH,
  PERSON_SCREEN_PROFILES_TAB,
} from '../../../../config/paths'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  EntitiesBrowserProps,
} from '../../../../states'
import {
  adaptSDKBrowseRequest,
  cacheKeys,
  useBoolean,
} from '../../../../utils-hooks'
import {
  ContactInfo,
  CopyableDisplay,
  CopyableLink,
  CreatedUpdatedDateDisplay,
} from '../../../data-display'
import {
  EntitiesTableBrowser,
  TABLE_DATA_PER_FETCH,
} from '../../EntitiesTableBrowser'
import { AddProfileView } from './AddProfileView'

type ProfilesBrowserProps = Omit<EntitiesBrowserProps, 'baseId'> & {
  organization: OrganizationTypes
}

/**
 * This one is not generic. It is used in the organization details page's "Profiles" tab.
 *
 * The profile list screen uses a separate profiles browser.
 */
export const ProfilesBrowser = ({
  organization,
  ...props
}: ProfilesBrowserProps) => {
  const { organization_id } = organization

  const [isAddViewOpen, openAddView, closeAddView] = useBoolean()

  return (
    <BrowseEntitiesContextProvider
      baseId={organization_id}
      dataPerFetch={TABLE_DATA_PER_FETCH}
      browse={browse}
      dataKey="profile_id"
      searchable
      entityType="profile"
      displayAddButton
      onAddButtonClick={openAddView}
      {...props}
    >
      <EntitiesTableBrowser columns={columns} />
      <AddProfileView
        organization={organization}
        open={isAddViewOpen}
        onClose={closeAddView}
      />
    </BrowseEntitiesContextProvider>
  )
}

const browse: BrowseEntitiesConfig<ProfilesTypes> = {
  request: adaptSDKBrowseRequest({
    request: swiftClient.profile.browse,
    select: [
      'display_name',
      'first_name',
      'last_name',
      'profile_id',
      'email',
      'phone_primary',
      'overseer_name',
      'overseer_id',
      'created_at',
      'updated_at',
      'employee_number',
      'person_id',
    ],
    getSearch: (search: string) => search,
  }),
  cacheKey: cacheKeys.organization_profiles,
}

const columns: ColumnsType<ProfilesTypes> = [
  {
    title: 'Name',
    render: ({ person_id, profile_id, display_name }: ProfilesTypes) => (
      <CopyableLink
        to={`/${PEOPLE_SCREEN_PATH}/${person_id}/${PERSON_SCREEN_PROFILES_TAB}/${profile_id}`}
        label={display_name || profile_id}
        copyableText={profile_id}
      />
    ),
    width: '20%',
  },
  {
    title: 'Overseer',
    render: ({ overseer_id, overseer_name }: ProfilesTypes) => (
      <CopyableDisplay label={overseer_name} copyableText={overseer_id} />
    ),
    width: '20%',
  },
  {
    title: 'Employee number',
    render: ({ employee_number }: ProfilesTypes) =>
      employee_number ? <CopyableDisplay label={employee_number} /> : '-',
    width: '20%',
  },
  {
    title: 'Contact',
    render: ({ email, phone_primary }: ProfilesTypes) => (
      <ContactInfo email={email} phone={phone_primary} />
    ),
    width: '20%',
  },
  {
    title: 'Created / Updated Date',
    render: ({ created_at, updated_at }: ProfilesTypes) => (
      <CreatedUpdatedDateDisplay
        createdAt={created_at}
        updatedAt={updated_at}
      />
    ),
    width: '20%',
  },
]
