import { PatchField, SinkRuleField } from '@swiftctrl/api-client'
import { ColumnsType, Modal, Table } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { JsonDisplay } from '../../components'

interface PatchModalProps {
  isVisible: boolean
  onClose: () => void
  record: SinkRuleField
}

export const PatchModal = ({ isVisible, onClose, record }: PatchModalProps) => {
  if (!('patch' in record)) {
    return null
  }

  const columns = getColumns()

  const fieldsWithId = record.patch.fields.map((field, index) => ({
    ...field,
    key: index,
  }))

  return (
    <StyledModal
      title="PATCH"
      open={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <StyledTable
        bordered
        pagination={false}
        dataSource={fieldsWithId}
        columns={columns}
      />
    </StyledModal>
  )
}

const findKey = (record: PatchField) => {
  return Object.keys(record).find((key) =>
    ['message', 'integer', 'string', 'bool', 'uuid', 'time'].includes(key),
  )
}

const getColumns = (): ColumnsType<PatchField> => {
  const columns: ColumnsType<PatchField> = [
    {
      title: 'Field Name',
      dataIndex: 'field_name',
      key: 'field',
      width: '25%',
    },
    {
      title: 'Type',
      key: 'type',
      width: '10%',
      render: (_, record) => {
        const key = findKey(record)
        return key || ''
      },
    },
    {
      title: 'Patch value',
      key: 'patch value',
      width: '50%',
      render: (_, record) => {
        const key = findKey(record)
        return key ? (
          <JsonDisplay value={record[key as keyof PatchField]} />
        ) : (
          ''
        )
      },
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
      width: '15%',
    },
  ]

  return columns
}

const StyledModal = styled(Modal)`
  min-width: 60%;
  @media (max-width: 1350px) {
    min-width: 80%;
  }
`

const StyledTable: typeof Table = styled(Table)`
  .ant-table-cell > pre {
    margin: 0px;
  }
`
