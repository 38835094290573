import { Entry, ValidationRule } from './types'
import { buildErrorKey } from './utils'

const DEFAULT_MESSAGE = 'Value is not valid'

export const validate = <T>(
  value: string,
  rules: ValidationRule<T>[],
  errors: Map<string, string[]>,
  entryKey: string,
  dataIndex: string,
  entries: Entry<T>[],
) => {
  const errorsForEntry = new Array<string>()

  rules.forEach((rule) => {
    const { message } = rule

    const result = passesRule(value, rule, entries)

    switch (typeof result) {
      case 'boolean':
        if (!result) {
          errorsForEntry.push(message || DEFAULT_MESSAGE)
        }
        break

      case 'string':
        errorsForEntry.push(result)
    }
  })

  if (errorsForEntry.length > 0) {
    errors.set(buildErrorKey(entryKey, dataIndex), errorsForEntry)
  }
}

const passesRule = <T>(
  value: string,
  rule: ValidationRule<T>,
  entries: Entry<T>[],
) => {
  const { required, pattern, validator } = rule

  if (required && !value) {
    return false
  }

  if (pattern && !pattern.test(value)) {
    return false
  }

  if (validator) {
    const result = validator(value, entries)

    switch (typeof result) {
      case 'boolean':
        if (!result) {
          return false
        }
        break

      case 'string':
        if (result) {
          return result
        }
        break
    }
  }

  return true
}
