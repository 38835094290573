import { NovaError } from '@swiftctrl/api-client'
import { appendEntityValidationErrors, FieldConfig } from '../../utils-hooks'

export const areCurrentFieldsValid = <T>(
  fields: FieldConfig<T>[],
  values: T,
  operation: 'add' | 'edit',
) => {
  if (!values) {
    return false
  }

  const errors = new Array<NovaError>()

  appendEntityValidationErrors(values, fields, '', errors, operation)

  return errors.length === 0
}
