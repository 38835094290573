import { ProviderProfileTypes } from '@swiftctrl/api-client'
import { ColumnsType } from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
} from '../../../../states'
import {
  CopyableCodeDisplay,
  CreatedUpdatedDateDisplay,
} from '../../../data-display'
import {
  EntitiesTableBrowser,
  TABLE_DATA_PER_FETCH,
} from '../../EntitiesTableBrowser'
import { LoginDataDisplay } from './LoginDataDisplay'
import { ProviderProfileActionsDropdown } from './ProviderProfileActionsDropdown'
import { ProviderProfileProviderDisplay } from './ProviderProfileProviderDisplay'

type BaseProviderProfilesBrowserProps = {
  browse: BrowseEntitiesConfig<ProviderProfileTypes>
  onAddButtonClick: () => void
  addView: ReactNode
}

export const BaseProviderProfilesBrowser = ({
  browse,
  onAddButtonClick,
  addView,
}: BaseProviderProfilesBrowserProps) => {
  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={TABLE_DATA_PER_FETCH}
      browse={browse}
      dataKey="provider_profile_id"
      searchable
      entityType="provider_profile"
      displayAddButton
      onAddButtonClick={onAddButtonClick}
    >
      <EntitiesTableBrowser columns={columns} />
      {addView}
    </BrowseEntitiesContextProvider>
  )
}

const columns: ColumnsType<Partial<ProviderProfileTypes>> = [
  {
    title: 'ID',
    render: (_, providerProfile) => {
      const { provider_profile_id } = providerProfile

      return <CopyableCodeDisplay label={provider_profile_id!} />
    },
    width: '25%',
  },
  {
    title: 'Provider',
    render: (_, providerProfile) => (
      <ProviderProfileProviderDisplay providerProfile={providerProfile} />
    ),
    width: '25%',
  },
  {
    title: 'Login Data',
    render: (_, providerProfile) => (
      <LoginDataDisplay providerProfile={providerProfile} />
    ),
    width: '25%',
  },
  {
    title: 'Created / Updated Date',
    render: (_, providerProfile) => {
      const { created_at, updated_at } = providerProfile

      return (
        <CreatedUpdatedDateDisplay
          createdAt={created_at}
          updatedAt={updated_at}
        />
      )
    },
    width: '25%',
  },
  {
    key: 'action',
    render: (_, providerProfile) => (
      <ProviderProfileActionsDropdown providerProfile={providerProfile} />
    ),
  },
]
