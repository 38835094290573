import { RolesTypes } from '@swiftctrl/api-client'
import { Modal, Typography } from '@swiftctrl/swift-component-library'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import {
  GenericEditEntityDrawer,
  HeaderContent,
  HeaderContentProps,
  invalidateReadScreenQuery,
} from '../../components'
import { ROLES_SCREEN_PATH } from '../../config/paths'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import {
  cacheKeys,
  showErrorNotification,
  showSuccessNotification,
  useBoolean,
} from '../../utils-hooks'

type RoleScreenHeaderProps = {
  role: RolesTypes
}

export const RoleScreenHeader = ({ role }: RoleScreenHeaderProps) => {
  const {
    entity_name,
    role_id,
    entity_description,
    overseer_name,
    overseer_id,
    extra_json,
  } = role

  const [isEditViewOpen, openEditView, closeEditView] = useBoolean()

  const handleEdited = () => {
    invalidateReadScreenQuery(cacheKeys.role, role_id)

    closeEditView()
  }

  const { handleDeleteClick } = useHandleDeleteClick(role_id, entity_name)

  const headerContentProps: HeaderContentProps = {
    entityName: entity_name,
    entityId: role_id,
    breadCrumbPath: `/${ROLES_SCREEN_PATH}`,
    entityType: 'role',
    entityDescription: entity_description,
    infoItemsLeft: [
      {
        title: 'Overseer',
        value: overseer_name,
        copyableText: overseer_id,
      },
    ],
    actionDropDownsItems: [
      {
        key: 'edit',
        label: 'Edit',
        onClick: openEditView,
      },
      {
        key: 'delete',
        label: 'Delete',
        danger: true,
        onClick: handleDeleteClick,
      },
    ],
    extraJson: extra_json,
  }

  return (
    <>
      <HeaderContent {...headerContentProps} />
      <GenericEditEntityDrawer
        open={isEditViewOpen}
        entity={role}
        entityType="role"
        onEdited={handleEdited}
        onClose={closeEditView}
      />
    </>
  )
}

const useHandleDeleteClick = (roleId: string, roleName: string) => {
  const navigate = useNavigate()

  const { mutate } = useMutation({
    mutationFn: () => swiftClientWithoutErrorHandling.role.at(roleId).delete(),
    onSuccess: () => {
      showSuccessNotification('Role deleted')

      navigate(`/${ROLES_SCREEN_PATH}`)
    },
    onError: (error) => {
      showErrorNotification('Error while deleting role', error)
    },
  })

  const handleDeleteClick = () => {
    Modal.confirm({
      title: 'Delete Role?',
      content: (
        <>
          Are you sure you want to delete{' '}
          {roleName ? (
            <b>{roleName}</b>
          ) : (
            <Typography.Text code>{roleId}</Typography.Text>
          )}
          ?
        </>
      ),
      okText: 'Delete',
      okButtonProps: {
        danger: true,
      },
      onOk: () => mutate(),
      centered: true,
    })
  }

  return { handleDeleteClick }
}
