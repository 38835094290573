import { Drawer } from '@swiftctrl/swift-component-library'
import { useBoolean, useCheckBeforeClose } from '../../../../../utils-hooks'
import { AddSinkForm } from './AddSinkForm'
import { useAddSinkMutation } from './useAddSinkMutation'

type AddSinkViewProps = {
  open: boolean
  sourceId: string
  onClose: () => void
}

export const AddSinkView = ({ open, sourceId, onClose }: AddSinkViewProps) => {
  const { mutate, isPending } = useAddSinkMutation({
    sourceId,
    onSinkAdded: onClose,
  })

  const [isDirty, setDirtyTrue, setDirtyFalse] = useBoolean()

  const { checkBeforeClose } = useCheckBeforeClose({
    isPending,
    isDirty,
    reset: setDirtyFalse,
    close: onClose,
  })

  return (
    <Drawer
      title="Add sink from template"
      onClose={checkBeforeClose}
      open={open}
      destroyOnClose
      maskClosable={!isPending}
      width={500}
    >
      <AddSinkForm
        isPending={isPending}
        onDirty={setDirtyTrue}
        onSubmit={mutate}
        onClose={checkBeforeClose}
      />
    </Drawer>
  )
}
