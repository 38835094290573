import { Input } from '@swiftctrl/swift-component-library'
import {
  CredentialTemplateFieldComponentProps,
  CredentialTemplateFieldFormItem,
} from './utils'

export const CredentialTemplateFieldInput = ({
  field,
}: CredentialTemplateFieldComponentProps) => {
  const { placeholder, default_value, read_only } = field

  return (
    <CredentialTemplateFieldFormItem field={field}>
      <Input
        placeholder={placeholder || undefined}
        defaultValue={default_value || undefined}
        disabled={read_only}
      />
    </CredentialTemplateFieldFormItem>
  )
}
