import { useNavigate } from 'react-router-dom'

const DELAY = 100

export const useDelayedCacheInvalidationAfterNavigation = () => {
  const navigate = useNavigate()

  const delayedCacheInvalidationAfterNavigation = ({
    navigatePath,
    invalidateCache,
  }: {
    navigatePath: string
    invalidateCache: () => void
  }) => {
    navigate(navigatePath)

    setTimeout(invalidateCache, DELAY)
  }

  return {
    delayedCacheInvalidationAfterNavigation,
  }
}
