import {
  CredentialFieldValidator,
  getFormatFieldValidValues,
  validateValidValues,
} from './utils'

export const stringValidator: CredentialFieldValidator = (
  templateField,
  formatField,
): boolean => {
  const { default_value, constraint } = templateField

  const templateValidValues = constraint?.valid_values

  const formatValidValues = getFormatFieldValidValues(formatField)

  if (!validateValidValues(templateValidValues, formatValidValues)) {
    return false
  }

  if (!default_value || !templateValidValues) {
    return true
  }

  const validValue = templateValidValues.find(
    ({ value }) => value === default_value,
  )

  return Boolean(validValue)
}
