import {
  CredentialFieldBitfieldValue,
  CredentialFieldConstraint,
  CredentialFieldFormat,
  CredentialFieldIntegerRange,
  CredentialFieldValidValue,
  CredentialFieldValidValues,
  CredentialTemplateFieldValidValue,
} from '@swiftctrl/api-client'
import { CredentialTemplateField } from '../utils'

export type CredentialFieldValidator = (
  templateField: CredentialTemplateField,
  formatField: CredentialFieldFormat,
) => boolean

export const getFormatFieldValidValues = (
  formatField: CredentialFieldFormat,
) => {
  const validValues = getFormatFieldConstraint(formatField, 'valid_values') as
    | CredentialFieldValidValues
    | undefined

  if (!validValues) {
    return undefined
  }

  return validValues.valid_values
}

const getFormatFieldConstraint = (
  formatField: CredentialFieldFormat,
  neededType: CredentialFieldConstraint['type'],
) => {
  const { constraint } = formatField

  if (!constraint) {
    return undefined
  }

  const { type, value } = constraint

  if (type !== neededType) {
    return undefined
  }

  return value
}

export const getFormatBitfieldValue = (formatField: CredentialFieldFormat) => {
  const constraint = getFormatFieldConstraint(formatField, 'bitfield_value') as
    | CredentialFieldBitfieldValue
    | undefined

  return constraint
}

export const getFormatIntegerRange = (formatField: CredentialFieldFormat) => {
  const constraint = getFormatFieldConstraint(formatField, 'integer_range') as
    | CredentialFieldIntegerRange
    | undefined

  return constraint
}

export const validateValidValues = (
  templateValidValues: CredentialTemplateFieldValidValue[] | null | undefined,
  formatValidValues: CredentialFieldValidValue[] | undefined,
) => {
  if (!formatValidValues) {
    return true
  }

  if (!templateValidValues || templateValidValues.length === 0) {
    return false
  }

  for (let i = 0; i < templateValidValues.length; i++) {
    const { value } = templateValidValues[i]

    const entry = formatValidValues.find((entry) => entry.value === value)

    if (!entry) {
      return false
    }
  }

  return true
}
