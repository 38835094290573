import { PersonTypes, ProfilesTypes } from '@swiftctrl/api-client'
import { Alert, Modal } from '@swiftctrl/swift-component-library'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { ActionsDropdown } from '../../components'
import { PEOPLE_SCREEN_PATH } from '../../config/paths'
import { queryClient } from '../../data/queryClient'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { AppThemeProvider } from '../../theme'
import {
  cacheKeys,
  showErrorNotification,
  showSuccessNotification,
} from '../../utils-hooks'
import { isPrimaryProfile } from './utils'

type ProfileActionsDropdownProps = {
  profile: Partial<ProfilesTypes>
  person: PersonTypes
}

export const ProfileActionsDropdown = ({
  profile,
  person,
}: ProfileActionsDropdownProps) => {
  const handleDeleteProfileClick = () => {
    Modal.confirm({
      centered: true,
      title: 'Delete Profile',
      content: (
        <>
          Are you sure you want to delete <b>{profile.display_name}</b>?
          {isPrimaryProfile(profile, person) && (
            <AppThemeProvider>
              <StyledAlert
                type="warning"
                message="Deleting the primary Profile will also remove the Person as a whole."
              />
            </AppThemeProvider>
          )}
        </>
      ),
      okText: 'Delete',
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
      onOk: () => mutate(),
    })
  }

  const navigate = useNavigate()

  const { mutate } = useMutation({
    mutationFn: () =>
      swiftClientWithoutErrorHandling.profile.at(profile.profile_id!).delete(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [cacheKeys.profiles],
      })

      queryClient.invalidateQueries({
        queryKey: [cacheKeys.person_profiles],
      })

      queryClient.invalidateQueries({
        queryKey: [cacheKeys.organization_profiles],
      })

      showSuccessNotification('Profile deleted')

      if (isPrimaryProfile(profile, person)) {
        navigate(`/${PEOPLE_SCREEN_PATH}`)
      }
    },
    onError: (error) => {
      showErrorNotification('Error while deleting profile', error)
    },
  })

  return (
    <ActionsDropdown
      items={[
        {
          key: 'delete',
          label: 'Delete',
          danger: true,
          onClick: handleDeleteProfileClick,
        },
      ]}
    />
  )
}

const StyledAlert = styled(Alert)`
  margin-top: ${({ theme }) => theme.spacing.medium};
`
