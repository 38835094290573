import { Input, Modal, Swift } from '@swiftctrl/swift-component-library'
import { ChangeEvent, useState } from 'react'
import { getDeleteRequest } from '../config/deleteEntityConfig'
import { EntityType } from '../data/models'
import { showErrorNotification, useBoolean } from '../utils-hooks'

type DeleteEntityViewProps = {
  open: boolean
  entity: {
    entityType: EntityType
    entityId: string
    entityName: string | undefined
  }
  onEntityDeleted: () => void
  onClose: () => void
}

export const DeleteEntityView: Swift.FC<DeleteEntityViewProps> = ({
  open,
  entity: { entityType, entityId, entityName },
  onEntityDeleted,
  onClose,
}) => {
  const displayName = entityName || entityId

  const [value, setValue] = useState('')

  const [deletingEntity, setDeletingEntityTrue, setDeletingEntityFalse] =
    useBoolean()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value)

  const deleteEntity = async () => {
    try {
      setDeletingEntityTrue()

      const deleteRequest = getDeleteRequest(entityType)

      await deleteRequest(entityId)

      onEntityDeleted()
    } catch (error: any) {
      showErrorNotification('Error while deleting entity', error)
    } finally {
      setDeletingEntityFalse()
    }
  }

  const handleClose = () => {
    setValue('')

    onClose()
  }

  return (
    <Modal
      open={open}
      title={`Are you sure you want to delete "${displayName}"?`}
      okText="Delete"
      onOk={deleteEntity}
      onCancel={handleClose}
      okButtonProps={{
        disabled: value !== displayName,
        loading: deletingEntity,
        danger: true,
      }}
      cancelButtonProps={{
        disabled: deletingEntity,
      }}
      maskClosable={!deletingEntity}
    >
      <p>This cannot be undone!</p>
      <p>
        To confirm, please enter the entity's name below, then click 'Delete'
      </p>
      <Input value={value} onChange={handleInputChange} />
    </Modal>
  )
}
