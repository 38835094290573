import { JSX } from 'react'
import { Link } from 'react-router-dom'
import { NavigationMenuItem } from '../../config/routesConfig'

interface ItemFactory {
  key: string
  label: JSX.Element
  icon?: JSX.Element
  children?: ItemFactory[]
}

export const itemFactory = ({
  label,
  icon,
  path,
  children,
}: NavigationMenuItem): ItemFactory => {
  const item = {
    key: path,
    label: <Link to={path}>{label}</Link>,
    icon,
  }

  if (children?.length) {
    return {
      ...item,
      children: children.map(itemFactory),
    }
  }

  return item
}
