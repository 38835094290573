import { Layout } from '@swiftctrl/swift-component-library'
import { ReactNode, useState } from 'react'
import { TagButtonGroup } from './TagButtonGroup'

export type TagTabsProps = {
  items: { key: string; label: string; children: ReactNode }[]
  defaultActiveKey: string
  onChange: (activeKey: string) => void
}

export const TagTabs = ({
  items,
  defaultActiveKey,
  onChange,
  ...props
}: TagTabsProps) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey)

  const handleActiveKey = (activeKey: string) => {
    setActiveKey(activeKey)

    onChange(activeKey)
  }

  const activeItem = items.find((item) => item.key === activeKey)!

  return (
    <Layout grow {...props}>
      <TagButtonGroup
        items={items}
        activeKey={activeKey}
        onChange={handleActiveKey}
      />
      {activeItem.children}
    </Layout>
  )
}
