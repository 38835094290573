import { CredentialTemplateFieldConstraint } from '@swiftctrl/api-client'
import { getCredentialTemplateFieldDateTimePickerDisabledDate } from '../../../components'
import { buildFakeLocalDateFromISODate } from '../../../utils-hooks'
import { CredentialFieldValidator } from './utils'

export const timeValidator: CredentialFieldValidator = (
  templateField,
): boolean => {
  const { default_value, constraint } = templateField

  if (!default_value || !constraint?.date_range) {
    return true
  }

  const date = buildFakeLocalDateFromISODate(default_value)!

  const disabledDate = getCredentialTemplateFieldDateTimePickerDisabledDate(
    constraint as CredentialTemplateFieldConstraint,
  )

  const isDisabledDate = disabledDate(date)

  return !isDisabledDate
}
