import { AddRolePermissionsFormData } from './models'

export const isFormDataValid = (values: AddRolePermissionsFormData) => {
  const { prefix, actions, scope, modifier } = values

  if (!prefix || !scope || !modifier) {
    return false
  }

  if (!actions || actions.length === 0) {
    return false
  }

  return true
}
