import { ProfilesTypes } from '@swiftctrl/api-client'
import { RoleProfile } from './buildRoleProfile'

export const checkWithExistingProfile = (
  allProfile: RoleProfile[],
  existingProfiles: Partial<ProfilesTypes>[] | undefined,
) => {
  const currentProfileIds = new Set(
    existingProfiles?.map((profile) => profile.profile_id),
  )

  const listOfProfiles = allProfile.map((profile) => {
    const profileId = profile.profile.profile_id

    const selected = currentProfileIds.has(profileId)

    let modifiedProfile: RoleProfile = {
      ...profile,
      selected: selected,
      assigned: selected,
    }

    return modifiedProfile
  })

  return listOfProfiles
}
