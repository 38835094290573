import {
  Col,
  Divider,
  Row,
  Typography,
} from '@swiftctrl/swift-component-library'
import { JSX, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { EntityType } from '../../data/models'
import {
  displayEntityDescription,
  getEntityDescription,
  getEntityId,
  getEntityName,
  getField,
} from '../../utils-hooks'
import {
  CopyableDisplay,
  EntityTimestampsDisplay,
  LabelDisplay,
} from '../data-display'

type ReadScreenHeaderProps<T> = PropsWithChildren<{
  data: T
  entityType: EntityType
  headerContentFactory?: (data: T) => JSX.Element
}>

export const ReadScreenHeader = <T extends object>({
  data,
  entityType,
  headerContentFactory,
  children,
}: ReadScreenHeaderProps<T>) => {
  if (headerContentFactory) {
    return <StyledScreenHeader>{headerContentFactory(data)}</StyledScreenHeader>
  }
  const id = getEntityId(data, entityType)

  const name = getEntityName(data, entityType)

  const description = getEntityDescription(data, entityType)

  const overseerName = getField(data, 'overseer_name')

  const overseerId = getField(data, 'overseer_id')

  const createdAt = getField(data, 'created_at')

  const updatedAt = getField(data, 'updated_at')

  return (
    <StyledScreenHeader>
      <Row align="stretch">
        <Col flex="1">
          <Typography.Paragraph>
            <CopyableDisplay label={name} copyableText={id} title />
          </Typography.Paragraph>
          {overseerName && (
            <Typography.Paragraph>
              <LabelDisplay label="Overseer" />
              <CopyableDisplay label={overseerName} copyableText={overseerId} />
            </Typography.Paragraph>
          )}
        </Col>
        <Col flex="40px">
          <StyledDivider type="vertical" />
        </Col>
        <Col flex="1">
          <Typography.Paragraph type="secondary">
            {displayEntityDescription(description)}
          </Typography.Paragraph>
          <EntityTimestampsDisplay
            created_at={createdAt}
            updated_at={updatedAt}
          />
        </Col>
      </Row>
      {children}
    </StyledScreenHeader>
  )
}

const StyledScreenHeader = styled.div`
  background-color: #fff;

  padding: 1.5em 1.5em 0;
`

const StyledDivider = styled(Divider)`
  height: 100%;
`
