import { RoleOperationTypes } from '@swiftctrl/api-client'
import { ColumnsType, Table, Tag } from '@swiftctrl/swift-component-library'
import { ActionsDropdown, EnabledDisplay } from '../../../../components'
import { addRoleOperationKey } from './addRoleOperationKey'
import { confirmRemoveRoleOperation } from './confirmRemoveRoleOperation'

type AccessCloudRoleOperationsTableProps = {
  dataSource: RoleOperationTypes[]
  onRemoveRoleOperation: (roleOperation: RoleOperationTypes) => void
}

export const AccessCloudRoleOperationsTable = ({
  dataSource,
  onRemoveRoleOperation,
}: AccessCloudRoleOperationsTableProps) => {
  const keyedData = dataSource.map(addRoleOperationKey)

  const columns = buildColumns(onRemoveRoleOperation)

  return <Table columns={columns} dataSource={keyedData} pagination={false} />
}

const buildColumns = (
  onRemoveOperation: (roleOperation: RoleOperationTypes) => void,
): ColumnsType<RoleOperationTypes> => [
  {
    title: 'Operation',
    render: (_, operation) => <Tag>{operation.action}</Tag>,
    width: '25%',
  },
  {
    title: 'Relation Names',
    render: (_, operation) => (
      <>
        {operation.relation_names?.map((relationName) => (
          <Tag key={relationName}>{relationName}</Tag>
        )) || '-'}
      </>
    ),
    width: '25%',
  },
  {
    title: 'Scope',
    dataIndex: 'scope',
    width: '25%',
  },
  {
    title: 'Modifier',
    render: (_, operation) => operation.modifier || '-',
    width: '25%',
  },
  {
    title: 'Grantable',
    render: (_, operation) => <EnabledDisplay enabled={operation.grantable} />,
  },
  {
    render: (_, operation) => (
      <ActionsDropdown
        items={[
          {
            key: 'remove-permission',
            label: 'Remove Permission',
            danger: true,
            onClick: confirmRemoveRoleOperation(operation, onRemoveOperation),
          },
        ]}
      />
    ),
  },
]
