import { EntityTypes } from '@swiftctrl/api-client'
import {
  Card,
  Divider,
  Spin,
  Typography,
} from '@swiftctrl/swift-component-library'
import { PropsWithChildren } from 'react'
import {
  EntityDetailLine,
  EntityTimestampsDisplay,
  ExtraJsonButton,
} from '../../components'
import { AdditionalDetails } from './AdditionalDetails'
import { EntityNameDisplay } from './EntityNameDisplay'
import { EntityTypeTag } from './EntityTypeTag'

export type EntityDetailsProps = PropsWithChildren<{
  entity: EntityTypes
  performingRead: boolean
  readResponseData: any
  bordered?: boolean
}>

export const EntityDetails = ({
  entity,
  performingRead,
  readResponseData,
  children,
  ...props
}: EntityDetailsProps) => {
  const {
    entity_type_id,
    entity_id,
    entity_description,
    entity_status,
    created_at,
    updated_at,
  } = entity

  const extraJson = readResponseData?.extra_json

  return (
    <Card
      title={<EntityNameDisplay entity={entity} bold />}
      extra={<ExtraJsonButton extraJson={extraJson} />}
      {...props}
    >
      <EntityDetailLine>
        {'Type: '}
        <EntityTypeTag entityType={entity_type_id} />
      </EntityDetailLine>
      <EntityDetailLine>
        {'ID: '}
        <Typography.Text code copyable>
          {entity_id}
        </Typography.Text>
      </EntityDetailLine>
      <EntityDetailLine>
        {'Status: '}
        {entity_status ? (
          <Typography.Text code>{entity_status}</Typography.Text>
        ) : (
          'N/A'
        )}
      </EntityDetailLine>
      <EntityDetailLine>{`Description: ${
        entity_description || 'N/A'
      }`}</EntityDetailLine>
      <EntityTimestampsDisplay
        created_at={created_at}
        updated_at={updated_at}
      />
      {(performingRead || readResponseData) && <Divider />}
      {performingRead && <Spin />}
      {readResponseData && (
        <AdditionalDetails
          entityType={entity.entity_type_id}
          readResponseData={readResponseData}
        />
      )}
      {children}
    </Card>
  )
}
