import { Form } from '@swiftctrl/swift-component-library'
import { PropsWithChildren } from 'react'
import { styled } from 'styled-components'

type ValidatedFormItemProps = PropsWithChildren<{
  isValid: boolean
  validationMessage: string
}>

export const ValidatedFormItem = ({
  isValid,
  validationMessage,
  children,
}: ValidatedFormItemProps) => {
  return (
    <StyledFormItem
      help={isValid ? undefined : validationMessage}
      validateStatus={isValid ? undefined : 'error'}
      $isValid={isValid}
    >
      {children}
    </StyledFormItem>
  )
}

const StyledFormItem = styled(Form.Item)<{ $isValid: boolean }>`
  margin: 0px;

  .ant-input {
    padding: 0px;
  }

  input {
    color: ${({ $isValid }) => ($isValid ? '#000' : 'var(--ant-error-color)')};
  }
`
