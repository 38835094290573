import { PersonTypes, ProfilesTypes } from '@swiftctrl/api-client'
import { ColumnsType, Tag } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import {
  ContactInfo,
  CopyableLink,
  CreatedUpdatedDateDisplay,
  EntitiesTableBrowser,
  TABLE_DATA_PER_FETCH,
} from '../../components'
import {
  PEOPLE_SCREEN_PATH,
  PERSON_SCREEN_PROFILES_TAB,
} from '../../config/paths'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
} from '../../states'
import { adaptSDKBrowseRequest, cacheKeys } from '../../utils-hooks'
import { ProfileActionsDropdown } from './ProfileActionsDropdown'
import { isPrimaryProfile } from './utils'

type PersonProfilesBrowserProps = {
  person: PersonTypes
}

export const PersonProfilesBrowser = ({
  person,
}: PersonProfilesBrowserProps) => {
  const browse = buildBrowse(person)

  const columns = buildColumns(person)

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={TABLE_DATA_PER_FETCH}
      browse={browse}
      dataKey="profile_id"
      entityType="provider"
    >
      <EntitiesTableBrowser columns={columns} />
    </BrowseEntitiesContextProvider>
  )
}

const buildBrowse = (
  person: PersonTypes,
): BrowseEntitiesConfig<ProfilesTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClientWithoutErrorHandling.person.at(person.person_id).profile
      .browse,
    select: [
      'profile_id',
      'organization_id',
      'display_name',
      'email',
      'phone_primary',
      'created_at',
      'updated_at',
    ],
  }),
  cacheKey: [cacheKeys.person_profiles, person.person_id],
})

const buildColumns = (
  person: PersonTypes,
): ColumnsType<Partial<ProfilesTypes>> => [
  {
    title: 'Name',
    render: (_, profile) => (
      <>
        <CopyableLink
          to={`/${PEOPLE_SCREEN_PATH}/${person.person_id}/${PERSON_SCREEN_PROFILES_TAB}/${profile.profile_id}`}
          label={profile.display_name}
          copyableText={profile.profile_id}
        />
        {isPrimaryProfile(profile, person) && <StyledTag>Primary</StyledTag>}
      </>
    ),
    width: '33%',
  },
  {
    title: 'Contact',
    render: (_, profile) => (
      <ContactInfo email={profile.email!} phone={profile.phone_primary!} />
    ),
    width: '34%',
  },
  {
    title: 'Created / Updated Date',
    render: (_, profile) => (
      <CreatedUpdatedDateDisplay
        createdAt={profile.created_at!}
        updatedAt={profile.updated_at!}
      />
    ),
    width: '33%',
  },
  {
    key: 'action',
    render: (_, profile) => (
      <ProfileActionsDropdown profile={profile} person={person} />
    ),
  },
]

const StyledTag = styled(Tag)`
  margin-left: ${({ theme }) => theme.spacing.small};
`
