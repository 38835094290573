import { Avatar, Popover } from '@swiftctrl/swift-component-library'
import { Button } from 'antd'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../../assets/svg/header-logo.svg'
import { useUserContext } from '../../states'
import { Header } from '../../theme/'
import {
  APP_HEADER_HEIGHT,
  useBoolean,
  useHandleNavigationWithUnsavedChanges,
} from '../../utils-hooks'
import { EntitySearchInput } from './entity-search-input'
import { UserMenu } from './UserMenu'

const LOGO_HEIGHT = '32px'

export const AppHeader = () => {
  const { user } = useUserContext()

  const menuAnchor = useRef(null)

  const [isUserMenuVisible, showUserMenu, hideUserMenu] = useBoolean()

  const { handleClickCapture } = useHandleNavigationWithUnsavedChanges()

  return (
    <StyledAppHeader>
      <StyledLink to="/" onClickCapture={handleClickCapture}>
        <StyledImg src={Logo} />
      </StyledLink>
      <EntitySearchInput />
      <StyledUserInfoButton
        type="text"
        ref={menuAnchor}
        onClick={showUserMenu}
        icon={
          <Avatar
            userId={user.profile_id}
            name={user.display_name}
            size="small"
          />
        }
      >
        <span>{user?.display_name}</span>
      </StyledUserInfoButton>
      <StyledPopover
        anchorEl={menuAnchor?.current}
        open={isUserMenuVisible}
        onClose={hideUserMenu}
        disableScrollLock
      >
        <UserMenu />
      </StyledPopover>
    </StyledAppHeader>
  )
}

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 2px, rgb(0 0 0 / 10%) 0px 2px 10px;
  }
`

const StyledLink = styled(Link)`
  line-height: ${LOGO_HEIGHT};
`

const StyledImg = styled.img`
  height: ${LOGO_HEIGHT};
`

const StyledAppHeader = styled(Header)`
  padding: 0px 24px 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${APP_HEADER_HEIGHT};
`

const StyledUserInfoButton = styled(Button)`
  height: ${APP_HEADER_HEIGHT}; // To Align the Popover with the Menu
  font-size: 14px;
  align-items: center;
  display: flex;
  color: #fff !important;

  :hover {
    opacity: 0.8;
  }

  > span:first-of-type {
    margin-right: 8px;
  }
  .ant-avatar > span {
    position: relative;
  }
`
