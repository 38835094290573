import { DatePicker } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { StyledEntitiesBrowserTopBar } from '../../components'
import { useHandleUrlParams } from '../../utils-hooks'

interface CounterReportFilterBarProps {
  onDateChange: (date: string) => void
  date?: string
}

export const CounterReportFilterBar = ({
  date,
  onDateChange,
}: CounterReportFilterBarProps) => {
  const { updateParams } = useHandleUrlParams()

  const handleDateChange = (date: string) => {
    updateParams({ date })

    onDateChange(date)
  }

  return (
    <StyledEntitiesBrowserTopBar>
      <b>{`Report date: ${date || 'Latest'}`}</b>
      <StyledDatePicker
        onChange={(_: any, date: string) => {
          handleDateChange(date)
        }}
        size="middle"
        placeholder="Select date"
        allowClear
        disabledDate={(current: Date) =>
          current && current.valueOf() > Date.now()
        }
        defaultValue={
          date
            ? new Date(
                parseInt(date.split('-')[0]),
                parseInt(date.split('-')[1]) - 1,
                parseInt(date.split('-')[2]),
              )
            : undefined
        }
      />
    </StyledEntitiesBrowserTopBar>
  )
}

const StyledDatePicker = styled(DatePicker)`
  width: 300px;
`
