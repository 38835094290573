import { isValidUuid } from '../../../utils-hooks'
import {
  CredentialFieldValidator,
  getFormatFieldValidValues,
  validateValidValues,
} from './utils'

export const uuidValidator: CredentialFieldValidator = (
  templateField,
  formatField,
): boolean => {
  const { default_value, constraint } = templateField

  if (!default_value) {
    return true
  }

  const templateValidValues = constraint?.valid_values

  const formatValidValues = getFormatFieldValidValues(formatField)

  if (!validateValidValues(templateValidValues, formatValidValues)) {
    return false
  }

  if (!templateValidValues) {
    const isValid = isValidUuid(default_value)

    return isValid
  }

  const validValue = templateValidValues.find(
    ({ value }) => value === default_value,
  )

  return Boolean(validValue)
}
