import {
  ProviderProfileAddTypes,
  ProviderType,
  ProviderTypes,
} from '@swiftctrl/api-client'
import { formatRFC3339 } from 'date-fns'
import {
  INVALID_EMAIL_FORMAT_MESSAGE,
  INVALID_PASSWORD_MESSAGES,
  VALID_EMAIL_FORMAT,
  VALID_PASSWORD,
} from '../../data/models'
import { swiftClient } from '../../data/swiftClient'
import { AddEntityConfig, buildProviderProfileName } from '../../utils-hooks'
import { getEntityLabel } from '../labels'

export type ProviderProfileFormData = ProviderProfileLoginData & {
  preset_organization_id: string
  preset_provider: ProviderTypes | undefined
  profile_id: string
}

export type ProviderProfileLoginData = {
  'login_data.email_password.email': string
  'login_data.email_password.password'?: string
  'login_data.openid.sub': string
  'login_data.single_use_token.token': string
  'login_data.single_use_token.expiry': Date
  'login_data.proxy_login.identifier': string
}

const isProviderOfType = (...types: ProviderType[]) => {
  const check = (values: any, _: boolean) => {
    const providerType = getProviderType(values)

    if (!providerType) {
      return false
    }

    return types.includes(providerType)
  }

  return check
}

export const provider_profile: AddEntityConfig<
  ProviderProfileFormData,
  ProviderProfileAddTypes
> = {
  fieldGroups: [
    {
      condition: (values) => Boolean(values.preset_organization_id),
      fields: [
        {
          key: 'profile_id',
          label: getEntityLabel({ entityType: 'profile' }),
          inputType: 'profileSelect',
          entitySelectBaseIdKey: 'preset_organization_id',
        },
      ],
    },
    {
      condition: isProviderOfType('email_password'),
      fields: [
        {
          key: 'login_data.email_password.email',
          label: 'Email',
          inputType: 'providerProfileLoginDataInput',
          rules: [
            {
              pattern: VALID_EMAIL_FORMAT,
              message: INVALID_EMAIL_FORMAT_MESSAGE,
            },
          ],
        },
        {
          key: 'login_data.email_password.password',
          label: 'Password',
          editable: false,
          rules: [
            {
              minLength: VALID_PASSWORD.MIN_LENGTH,
              message: INVALID_PASSWORD_MESSAGES.MIN_LENGTH,
            },
            {
              maxLength: VALID_PASSWORD.MAX_LENGTH,
              message: INVALID_PASSWORD_MESSAGES.MAX_LENGTH,
            },
            {
              pattern: VALID_PASSWORD.UPPERCASE,
              message: INVALID_PASSWORD_MESSAGES.UPPERCASE,
            },
            {
              pattern: VALID_PASSWORD.LOWERCASE,
              message: INVALID_PASSWORD_MESSAGES.LOWERCASE,
            },
            {
              pattern: VALID_PASSWORD.NUMBER_OR_SPECIAL_CHARACTER,
              message: INVALID_PASSWORD_MESSAGES.NUMBER_OR_SPECIAL_CHARACTER,
            },
          ],
        },
      ],
    },
    {
      condition: isProviderOfType('openid', 'openid_okta'),
      fields: [
        {
          key: 'login_data.openid.sub',
          label: 'Sub',
          inputType: 'providerProfileLoginDataInput',
        },
      ],
    },
    {
      condition: isProviderOfType('single_use_token'),
      fields: [
        {
          key: 'login_data.single_use_token.token',
          label: 'Token',
        },
        {
          key: 'login_data.single_use_token.expiry',
          label: 'Expiry',
          inputType: 'datetime',
        },
      ],
    },
    {
      condition: isProviderOfType('proxy_login'),
      fields: [
        {
          key: 'login_data.proxy_login.identifier',
          label: 'Identifier',
          inputType: 'providerProfileLoginDataInput',
        },
      ],
    },
  ],
  buildData: (values) => {
    const { preset_provider, profile_id } = values

    const providerType = getProviderType(values)!

    const data = {
      entity_name: buildProviderProfileName(providerType, values),
      profile_id,
    } as ProviderProfileAddTypes

    if (preset_provider) {
      data.overseer_id = preset_provider.provider_id
    }

    switch (providerType) {
      case 'email_password':
        data.login_data = {
          email_password: {
            email: values['login_data.email_password.email'],
            password: values['login_data.email_password.password']!,
            verified: true,
          },
        }
        break

      case 'openid_okta':
      case 'openid':
        data.login_data = {
          openid: {
            sub: values['login_data.openid.sub'],
          },
        }
        break

      case 'single_use_token':
        const expiry = values['login_data.single_use_token.expiry']

        data.login_data = {
          single_use_token: {
            token: values['login_data.single_use_token.token'],
            expiry: expiry ? formatRFC3339(expiry) : '',
          },
        }
        break

      case 'proxy_login':
        data.login_data = {
          proxy_login: {
            identifier: values['login_data.proxy_login.identifier'],
          },
        }
    }

    return data as ProviderProfileAddTypes
  },
  addRequest: swiftClient.providerProfile.add,
}

const getProviderType = (values: any) => {
  const { preset_provider } = values

  if (!preset_provider) {
    return undefined
  }

  const providerType = preset_provider.provider_type.name

  return providerType as ProviderType
}
