import styled from 'styled-components'
import { StyledScreen } from '../StyledScreen'
import {
  ENTITIES_BROWSER_TOP_BAR_HEIGHT,
  StyledEntitiesBrowserTopBar,
} from './EntitiesBrowserTopBar'

const GAP = '1em'

export const StyledEntitiesBrowserScreen = styled(StyledScreen)`
  margin: 0 ${GAP};

  ${StyledEntitiesBrowserTopBar} {
    background-color: var(--light-gray-background-color);

    padding-top: ${GAP};
  }

  .ant-table-thead {
    top: calc(${ENTITIES_BROWSER_TOP_BAR_HEIGHT} + ${GAP});
  }
`
