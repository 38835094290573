import { useQuery } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../../data/swiftClient'
import {
  cacheKeys,
  getAllFromBrowseRequest,
  longCacheOptions,
} from '../../../utils-hooks'

type Params = {
  organizationId: string
  enabled: boolean
}

export const useGetBuildings = ({ organizationId, enabled }: Params) => {
  const { data: organizationIsLandlord } = useGetOrganizationIsLandlord(
    organizationId,
    enabled,
  )

  return useBrowseBuildings({
    organizationId,
    isLandlord: Boolean(organizationIsLandlord),
    enabled: organizationIsLandlord !== undefined,
  })
}

const useGetOrganizationIsLandlord = (
  organizationId: string,
  enabled: boolean,
) =>
  useQuery({
    queryKey: [cacheKeys.organization_is_landlord, organizationId],
    queryFn: async ({ signal }) => {
      const { data } = await swiftClientWithoutErrorHandling.organization
        .at(organizationId)
        .read({ params: { select: 'is_landlord' }, signal })

      return data.is_landlord
    },
    enabled,
    ...longCacheOptions,
  })

const useBrowseBuildings = ({
  organizationId,
  isLandlord,
  enabled,
}: {
  organizationId: string
  isLandlord: boolean
  enabled: boolean
}) =>
  useQuery({
    queryKey: [cacheKeys.profile_organization_buildings, organizationId],
    queryFn: ({ signal }) => {
      const organizationSpaces =
        swiftClientWithoutErrorHandling.organization.at(organizationId).spaces

      const endpoint = isLandlord
        ? organizationSpaces.managed
        : organizationSpaces.assigned

      return getAllFromBrowseRequest((offset, limit) =>
        endpoint.browse(
          {
            select: ['space_id', 'name'],
            offset,
            limit,
          },
          { signal },
        ),
      )
    },
    enabled,
  })
