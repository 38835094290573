export const transformByteArrayToBase64String = (bytes: number[]) => {
  const value = bytes.reduce((data, byte) => {
    data += String.fromCharCode(byte)

    return data
  }, '')

  const base64value = btoa(value)

  return base64value
}
