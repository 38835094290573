import { EntityType } from '../../data/models'
import {
  buildPluralLabelConfig,
  getPluralLabel,
  getPluralLabelByCount,
  PluralLabelConfig,
} from '../../utils-hooks/pluralLabel'
// The above import needs to be more specific to avoid a compiler error

export const entityLabels: Record<EntityType, PluralLabelConfig> = {
  access_level: buildPluralLabelConfig('Access level(s)'),
  access_level_credential: buildPluralLabelConfig('Access level credential(s)'),
  access_level_reader_schedule: buildPluralLabelConfig(
    'Access level reader schedule(s)',
  ),
  acs_hardware: buildPluralLabelConfig('ACS hardware(s)', true),
  acs_transaction: buildPluralLabelConfig('ACS transaction(s)', true),
  address: buildPluralLabelConfig('Address(es)'),
  archetype: buildPluralLabelConfig('Archetype(s)'),
  association: buildPluralLabelConfig('Association(s)'),
  booking: buildPluralLabelConfig('Booking(s)'),
  building: buildPluralLabelConfig('Building(s)'),
  campus: buildPluralLabelConfig('Campus(es)'),
  controller: buildPluralLabelConfig('Controller(s)'),
  credential: buildPluralLabelConfig('Credential(s)'),
  credential_format: buildPluralLabelConfig('Credential format(s)'),
  credential_template: buildPluralLabelConfig('Credential template(s)'),
  department: buildPluralLabelConfig('Department(s)'),
  device: buildPluralLabelConfig('Device(s)'),
  division: buildPluralLabelConfig('Division(s)'),
  email: buildPluralLabelConfig('Email(s)'),
  entity: {
    singular: 'Entity',
    plural: 'Entities',
  },
  entity_mirror: buildPluralLabelConfig('Entity mirror(s)'),
  entity_counter: buildPluralLabelConfig('Entity counter(s)'),
  epi: buildPluralLabelConfig('EPI(s)', true),
  floor: buildPluralLabelConfig('Floor(s)'),
  image: buildPluralLabelConfig('Image(s)'),
  pass: buildPluralLabelConfig('Pass(es)'),
  meeting: buildPluralLabelConfig('Meeting(s)'),
  meeting_invitation: buildPluralLabelConfig('Meeting invitation(s)'),
  opening_hours: {
    singular: 'Opening hours',
    plural: 'Opening hours',
  },
  organization: buildPluralLabelConfig('Organization(s)'),
  payment: buildPluralLabelConfig('Payment(s)'),
  permission: buildPluralLabelConfig('Permission(s)'),
  person: {
    singular: 'Person',
    plural: 'People',
  },
  phone: buildPluralLabelConfig('Phone(s)'),
  portfolio: buildPluralLabelConfig('Portfolio(s)'),
  profile: buildPluralLabelConfig('Profile(s)'),
  profile_role: buildPluralLabelConfig('Profile role(s)'),
  provider_profile: buildPluralLabelConfig('Provider profile(s)'),
  provider: buildPluralLabelConfig('Provider(s)'),
  reader: buildPluralLabelConfig('Reader(s)'),
  reference: buildPluralLabelConfig('Reference(s)'),
  report: buildPluralLabelConfig('Report(s)'),
  role: buildPluralLabelConfig('Role(s)'),
  room: buildPluralLabelConfig('Room(s)'),
  room_price: buildPluralLabelConfig('Room price(s)'),
  schedule: buildPluralLabelConfig('Schedule(s)'),
  session: buildPluralLabelConfig('Session(s)'),
  sink: buildPluralLabelConfig('Sink(s)'),
  source: buildPluralLabelConfig('Source(s)'),
  system: buildPluralLabelConfig('System(s)'),
  zone: buildPluralLabelConfig('Zone(s)'),
}

export const getEntityLabel = ({
  entityType,
  singular = true,
  capitalize = true,
}: {
  entityType: EntityType
  /**
   * Defaults to true
   */
  singular?: boolean
  /**
   * Defaults to true
   */
  capitalize?: boolean
}) =>
  getPluralLabel({
    labels: entityLabels[entityType],
    capitalize,
    singular,
  })

export const getEntityLabelByCount = ({
  entityType,
  count,
  capitalize = true,
}: {
  entityType: EntityType
  count: number
  /**
   * Defaults to true
   */
  capitalize?: boolean
}) =>
  getPluralLabelByCount({
    labels: entityLabels[entityType],
    count,
    capitalize,
  })
