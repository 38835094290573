import { CredentialTemplateTypes } from '@swiftctrl/api-client'
import { ReadScreen } from '../components'
import { CREDENTIAL_TEMPLATES_SCREEN_PATH } from '../config/paths'
import { swiftClientWithoutErrorHandling } from '../data/swiftClient'
import {
  CredentialTemplateScreenContent,
  CredentialTemplateScreenHeader,
} from '../screen-components/credential-template-screen'
import { adaptSDKRead, cacheKeys } from '../utils-hooks'

export const CredentialTemplateScreen = () => {
  return (
    <ReadScreen<CredentialTemplateTypes>
      paramUuidProps={{
        paramKey: 'credentialTemplateId',
        entityType: 'credential_template',
        redirectPath: `/${CREDENTIAL_TEMPLATES_SCREEN_PATH}`,
      }}
      readRequest={adaptSDKRead(
        swiftClientWithoutErrorHandling.credentialTemplate,
      )}
      cacheKey={cacheKeys.credential_template}
      headerContentFactory={(credentialTemplate) => (
        <CredentialTemplateScreenHeader
          credentialTemplate={credentialTemplate}
        />
      )}
      contentFactory={(credentialTemplate) => (
        <CredentialTemplateScreenContent
          credentialTemplate={credentialTemplate}
        />
      )}
      entityType="credential_template"
    />
  )
}
