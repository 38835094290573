import { AssignedSpacesTypes, OrganizationTypes } from '@swiftctrl/api-client'
import { Button } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { useBoolean } from '../../../utils-hooks'
import { ConfigureAccessLevelsModal } from './configure-access-levels'

type AssignedAccessLevelsButtonProps = {
  landlordId: string
  tenant: OrganizationTypes
  space: AssignedSpacesTypes
}

export const AssignedAccessLevelsButton = ({
  landlordId,
  tenant,
  space,
}: AssignedAccessLevelsButtonProps) => {
  const [isConfigureViewOpen, openConfigureView, closeConfigureView] =
    useBoolean()

  return (
    <>
      {space.assigned_access_level_count === 0 ? (
        <Button size="middle" onClick={openConfigureView}>
          Configure
        </Button>
      ) : (
        <StyledButton type="link" size="middle" onClick={openConfigureView}>
          {getLabel(space)}
        </StyledButton>
      )}
      <ConfigureAccessLevelsModal
        open={isConfigureViewOpen}
        onClose={closeConfigureView}
        landlordId={landlordId}
        tenant={tenant}
        space={space}
      />
    </>
  )
}

const getLabel = (space: AssignedSpacesTypes) => {
  const { assigned_access_level_count, assigned_default_access_level_count } =
    space

  const label = `${getTotalAccessLevelLabel(
    assigned_access_level_count,
  )} (${getDefaultAccessLevelLabel(assigned_default_access_level_count)})`

  return label
}

const getTotalAccessLevelLabel = (totalCount: number) => {
  if (totalCount === 1) {
    return '1 Access Level'
  }

  return `${totalCount} Access Levels`
}

const getDefaultAccessLevelLabel = (defaultCount: number) => {
  switch (defaultCount) {
    case 0:
      return 'no defaults'

    case 1:
      return '1 default'

    default:
      return `${defaultCount} defaults`
  }
}

const StyledButton = styled(Button)`
  padding-left: 0px;
`
