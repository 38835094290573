import { EntityTypes } from '@swiftctrl/api-client'
import { RoleSubdataTable, RoleSubDataTableItem } from '../../manage-subdata'
import { RemoveSubdataButton } from '../../RemoveSubdataButton'
import { RoleTargetView } from '../RoleTargetView'

type SelectedTargetsTableProps = {
  selectedTargets: Partial<EntityTypes>[]
  removeTarget: (target: Partial<EntityTypes>) => void
}

export const SelectedTargetsTable = ({
  selectedTargets,
  removeTarget,
}: SelectedTargetsTableProps) => {
  return (
    <RoleSubdataTable
      header="Selected Target"
      dataSource={selectedTargets}
      renderItem={(target) => (
        <RoleSubDataTableItem>
          <RoleTargetView target={target} />
          <RemoveSubdataButton data={target} removeSubdata={removeTarget} />
        </RoleSubDataTableItem>
      )}
    />
  )
}
