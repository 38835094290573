import { EntityTypes } from '@swiftctrl/api-client'
import { ColumnsType, Layout } from '@swiftctrl/swift-component-library'
import { RoleTargetView } from '../RoleTargetView'
import { RoleTargetActionsDropdown } from './RoleTargetActionsDropdown'

export const buildColumns = (
  roleId: string,
): ColumnsType<Partial<EntityTypes>> => [
  {
    title: 'Target',
    render: (_, target) => (
      <Layout horizontal justifyContent="space-between" alignItems="center">
        <RoleTargetView target={target} />
        <RoleTargetActionsDropdown roleId={roleId} target={target} />
      </Layout>
    ),
  },
]
