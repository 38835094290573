import {
  ApiOutlined,
  AuditOutlined,
  BankOutlined,
  BuildingOutlined,
  ControlOutlined,
  IdcardOutlined,
  KeyOutlined,
  PartitionOutlined,
  SettingOutlined,
  SolutionOutlined,
  UserOutlined,
} from '@swiftctrl/swift-component-library'
import { JSX } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppLayout } from '../components'
import {
  BuildingsScreen,
  CounterReportsScreen,
  CredentialTemplateScreen,
  CredentialTemplatesScreen,
  EntitiesScreen,
  EpiScreen,
  epiScreenTabs,
  EpisScreen,
  NotFoundScreen,
  OrganizationScreen,
  organizationScreenTabs,
  OrganizationsScreen,
  PeopleScreen,
  PersonScreen,
  personScreenTabs,
  ProfileScreen,
  profileScreenTabs,
  ProfilesScreen,
  ProviderScreen,
  ProvidersScreen,
  RemoteConfigsScreen,
  RoleScreen,
  roleScreenTabs,
  RolesScreen,
  SinkScreen,
  sinkScreenTabs,
} from '../screens'
import {
  BUILDINGS_SCREEN_PATH,
  COUNTER_REPORTS_SCREEN_PATH,
  CREDENTIAL_TEMPLATES_SCREEN_PATH,
  ENTITIES_SCREEN_PATH,
  EPIS_SCREEN_PATH,
  ORGANIZATIONS_SCREEN_PATH,
  ORGANIZATION_SCREEN_SINKS_PATH,
  PEOPLE_SCREEN_PATH,
  PERSON_SCREEN_PROFILES_TAB,
  PROFILES_SCREEN_PATH,
  PROVIDERS_SCREEN_PATH,
  REMOTE_CONFIGS_SCREEN_PATH,
  ROLES_SCREEN_PATH,
} from './paths'

export type NavigationMenuItem = {
  label: string
  icon?: JSX.Element
  path: string
  children?: NavigationMenuItem[]
}

export const AppRoutes = () => (
  <Routes>
    <Route element={<AppLayout />}>
      <Route path="/" element={<Navigate to={ENTITIES_SCREEN_PATH} />} />

      <Route path={ENTITIES_SCREEN_PATH}>
        <Route index element={<EntitiesScreen />} />
        <Route path={':content'} element={<EntitiesScreen />} />
      </Route>

      <Route
        path={REMOTE_CONFIGS_SCREEN_PATH}
        element={<RemoteConfigsScreen />}
      />

      <Route path={PROVIDERS_SCREEN_PATH}>
        <Route index element={<ProvidersScreen />} />
        <Route path=":providerId">
          <Route index element={<ProviderScreen />} />
        </Route>
      </Route>

      <Route
        path={COUNTER_REPORTS_SCREEN_PATH}
        element={<CounterReportsScreen />}
      />

      <Route path={ROLES_SCREEN_PATH}>
        <Route index element={<RolesScreen />} />

        <Route path=":roleId">
          <Route index element={<RoleScreen />} />

          {roleScreenTabs.map((tab) => (
            <Route key={tab} path={tab} element={<RoleScreen tab={tab} />} />
          ))}
        </Route>
      </Route>

      <Route path={ORGANIZATIONS_SCREEN_PATH}>
        <Route index element={<OrganizationsScreen />} />

        <Route path=":organizationId">
          <Route index element={<OrganizationScreen />} />

          {organizationScreenTabs.map((tab) => (
            <Route
              key={tab}
              path={tab}
              element={<OrganizationScreen tab={tab} />}
            />
          ))}

          <Route path={`${ORGANIZATION_SCREEN_SINKS_PATH}/:sinkId`}>
            <Route index element={<SinkScreen />} />

            {sinkScreenTabs.map((tab) => (
              <Route key={tab} path={tab} element={<SinkScreen tab={tab} />} />
            ))}
          </Route>
        </Route>
      </Route>

      <Route path={PEOPLE_SCREEN_PATH}>
        <Route index element={<PeopleScreen />} />

        <Route path=":personId">
          <Route index element={<PersonScreen />} />

          {personScreenTabs.map((tab) => (
            <Route key={tab} path={tab} element={<PersonScreen tab={tab} />} />
          ))}

          <Route path={PERSON_SCREEN_PROFILES_TAB}>
            <Route path=":profileId">
              <Route index element={<ProfileScreen />} />

              {profileScreenTabs.map((tab) => (
                <Route
                  key={tab}
                  path={tab}
                  element={<ProfileScreen tab={tab} />}
                />
              ))}
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path={BUILDINGS_SCREEN_PATH} element={<BuildingsScreen />} />

      <Route path={EPIS_SCREEN_PATH}>
        <Route index element={<EpisScreen />} />

        <Route path=":epiId">
          <Route index element={<EpiScreen />} />

          {epiScreenTabs.map((tab) => (
            <Route key={tab} path={tab} element={<EpiScreen tab={tab} />} />
          ))}
        </Route>
      </Route>

      <Route path={CREDENTIAL_TEMPLATES_SCREEN_PATH}>
        <Route index element={<CredentialTemplatesScreen />} />

        <Route
          path=":credentialTemplateId"
          element={<CredentialTemplateScreen />}
        />
      </Route>

      <Route path={PROFILES_SCREEN_PATH} element={<ProfilesScreen />} />

      <Route path="*" element={<NotFoundScreen />} />
    </Route>
  </Routes>
)

export const navigationMenuItems: NavigationMenuItem[] = [
  {
    label: 'Entities',
    icon: <PartitionOutlined />,
    path: ENTITIES_SCREEN_PATH,
  },
  {
    label: 'Organizations',
    icon: <BankOutlined />,
    path: ORGANIZATIONS_SCREEN_PATH,
  },
  {
    label: 'Buildings',
    icon: <BuildingOutlined />,
    path: BUILDINGS_SCREEN_PATH,
  },
  {
    label: 'EPIs',
    icon: <ApiOutlined />,
    path: EPIS_SCREEN_PATH,
  },
  {
    label: 'Remote Configs',
    icon: <ControlOutlined />,
    path: REMOTE_CONFIGS_SCREEN_PATH,
  },
  {
    label: 'Roles',
    icon: <KeyOutlined />,
    path: ROLES_SCREEN_PATH,
  },
  {
    label: 'Providers',
    icon: <IdcardOutlined />,
    path: PROVIDERS_SCREEN_PATH,
  },
  {
    label: 'People',
    icon: <UserOutlined />,
    path: PEOPLE_SCREEN_PATH,
  },
  {
    label: 'Profiles',
    icon: <SolutionOutlined />,
    path: PROFILES_SCREEN_PATH,
  },
  {
    label: 'Counter Reports',
    icon: <AuditOutlined />,
    path: COUNTER_REPORTS_SCREEN_PATH,
  },
  {
    label: 'Credential Templates',
    icon: <SettingOutlined />,
    path: CREDENTIAL_TEMPLATES_SCREEN_PATH,
  },
]
