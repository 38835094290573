import { RemoveConstraintButton } from '../RemoveConstraintButton'
import { BitfieldValue } from '../utils'
import { useBitfieldValueLogic } from './useBitfieldValueLogic'
import {
  BitfieldBitIndexInput,
  BitfieldPropertiesContainer,
  getEndBitMax,
  getStartBitMin,
} from './utils'

type TemplateBitfieldValueViewProps = {
  bitfieldValue: BitfieldValue
  onChange: (value: BitfieldValue | null) => void
}

export const TemplateBitfieldValueView = ({
  bitfieldValue,
  onChange,
}: TemplateBitfieldValueViewProps) => {
  const { start_bit, end_bit } = bitfieldValue

  const { startBit, endBit, handleStartChange, handleEndChange } =
    useBitfieldValueLogic({
      startBit: start_bit,
      endBit: end_bit,
      onChange,
    })

  return (
    <BitfieldPropertiesContainer>
      bitfield_value
      <BitfieldBitIndexInput
        prefix="Start bit"
        min={getStartBitMin(endBit)}
        max={endBit}
        value={startBit}
        onChange={handleStartChange}
      />
      <BitfieldBitIndexInput
        prefix="End bit"
        min={startBit || 0}
        max={getEndBitMax(startBit)}
        value={endBit}
        onChange={handleEndChange}
      />
      <RemoveConstraintButton onClick={() => onChange(null)} />
    </BitfieldPropertiesContainer>
  )
}
