import { OrganizationTypes } from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { styled } from 'styled-components'
import { getReadScreenQueryKey, JsonEditor } from '../../components'
import { queryClient } from '../../data/queryClient'
import { swiftClient } from '../../data/swiftClient'
import { useEventEmitterEffect } from '../../states'
import {
  cacheKeys,
  showSuccessNotification,
  usePreventTabUnload,
} from '../../utils-hooks'

type OrganizationConfigViewProps = {
  organization: OrganizationTypes
}

export const OrganizationConfigView = ({
  organization,
}: OrganizationConfigViewProps) => {
  const { organization_id, extra_json } = organization

  const { mutate, isPending } = useMutation({
    mutationFn: swiftClient.organization.at(organization_id).edit,
    onSuccess: (response) => {
      showSuccessNotification('Config saved')

      queryClient.setQueryData(
        getReadScreenQueryKey(cacheKeys.organization, organization_id),
        response.data,
      )
    },
  })

  const save = (json: Record<string, any>) => {
    const payload = { ...organization, extra_json: json }

    mutate(payload)
  }

  const [isDirty, setIsDirty] = useState(false)

  usePreventTabUnload(isDirty)

  useEventEmitterEffect('hasUnsavedChanges', isDirty)

  return (
    <StyledJsonEditor
      json={extra_json || {}}
      confirmButtonLabel="Save"
      confirmButtonLoading={isPending}
      disableConfirmButtonWhenDataUnchanged
      onConfirm={save}
      onDirty={setIsDirty}
    />
  )
}

const StyledJsonEditor = styled(JsonEditor)`
  padding-top: 1em;
  padding-bottom: 1em;
`
