import { Modal } from '@swiftctrl/swift-component-library'

export const useCheckBeforeClose = ({
  isPending,
  isDirty,
  reset,
  close,
}: {
  isPending: boolean
  isDirty: boolean
  reset: () => void
  close: () => void
}) => {
  const checkBeforeClose = () => {
    if (isPending) {
      return
    }

    if (isDirty) {
      Modal.confirm({
        title: 'Are you sure you want to discard your changes?',
        okText: 'Discard',
        onOk: resetAndClose,
        centered: true,
      })

      return
    }

    resetAndClose()
  }

  const resetAndClose = () => {
    reset()

    close()
  }

  return { checkBeforeClose }
}
