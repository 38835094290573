import { AntLayout } from '@swiftctrl/swift-component-library'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { APP_HEADER_HEIGHT } from '../../utils-hooks'
import { ErrorBoundary } from '../ErrorBoundary'
import { AppHeader } from './AppHeader'
import { AppSider } from './AppSider'

const { Content } = AntLayout

export const AppLayout = () => (
  <>
    <AppHeader />
    <AntLayout>
      <AppSider />
      <StyledContent>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </StyledContent>
    </AntLayout>
  </>
)

const StyledContent = styled(Content)`
  height: calc(100vh - ${APP_HEADER_HEIGHT});
  overflow: auto;
`

export const FullScreenContainer = styled(AntLayout)`
  overflow-x: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh;
`
