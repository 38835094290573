import { Button, Drawer } from '@swiftctrl/swift-component-library'
import {
  buildCommonDrawerProps,
  stringifyJson,
  useBoolean,
} from '../utils-hooks'

type ExtraJsonButtonProps = {
  extraJson: any
}

export const ExtraJsonButton = ({ extraJson }: ExtraJsonButtonProps) => {
  const [isDrawerOpen, openDrawer, closeDrawer] = useBoolean()

  if (!extraJson) {
    return null
  }

  return (
    <>
      <Button size="small" onClick={openDrawer}>
        Extra JSON
      </Button>
      <Drawer
        {...buildCommonDrawerProps({
          title: 'Extra JSON',
          open: isDrawerOpen,
          onClose: closeDrawer,
          size: 'large',
        })}
      >
        <pre>
          <code>{stringifyJson(extraJson)}</code>
        </pre>
      </Drawer>
    </>
  )
}
