import { EntityType } from '../data/models'
import { getEntityDescriptionKey } from './getEntityDescriptionKey'

export const getEntityDescription = <T extends object>(
  entity: T,
  entityType: EntityType,
) => {
  const descriptionKey = getEntityDescriptionKey(entityType) as keyof T

  const description = entity[descriptionKey]

  if (description === null || description === undefined) {
    return ''
  }

  return String(description)
}
