import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { CopyableDisplay } from '../../components'

interface ProviderProfileNameViewProps {
  name?: string
  description?: string
  id?: string
}

export const ProviderProfileNameView = ({
  name,
  description,
  id,
}: ProviderProfileNameViewProps) => (
  <Layout>
    <CopyableDisplay label={name} copyableText={id} />
    {description && (
      <Typography.Text type="secondary">{description}</Typography.Text>
    )}
  </Layout>
)
