import { OrganizationTypes } from '@swiftctrl/api-client'
import { Form } from '@swiftctrl/swift-component-library'
import {
  AddFormButtonGroup,
  AddProfileDrawer,
  AddProfileForm,
  AddProfileFormItemEmailInput,
  AddProfileFormItemFirstNameInput,
  AddProfileFormItemLastNameInput,
  AddProfileFormItemPhoneInput,
  BackendErrorAlert,
  useAddProfileLogic,
} from '../../components'
import { SWIFT_ROOT_ENTITY_ID } from '../../data/models'
import { useBrowseEntitiesContext } from '../../states'
import {
  buildCommonFormItemProps,
  useReadOrganization,
} from '../../utils-hooks'
import { OrganizationSelect } from './OrganizationSelect'

type AddProfileViewProps = {
  open: boolean
  onClose: () => void
}

export const AddProfileView = ({ open, onClose }: AddProfileViewProps) => {
  let { baseId } = useBrowseEntitiesContext()

  if (baseId === SWIFT_ROOT_ENTITY_ID) {
    baseId = ''
  }

  const { data } = useReadOrganization({ id: baseId, enabled: Boolean(baseId) })

  if (baseId && !data) {
    return null
  }

  return (
    <Content
      key={baseId}
      organization={data?.data}
      open={open}
      onClose={onClose}
    />
  )
}

const Content = ({
  organization,
  open,
  onClose,
}: AddProfileViewProps & { organization: OrganizationTypes | undefined }) => {
  const {
    values,
    setValues,
    error,
    isValid,
    checkBeforeClose,
    handleFinish,
    isPending,
    emailOptions,
    phoneOptions,
    requireEmailOrPhone,
  } = useAddProfileLogic({
    organization,
    onClose,
  })

  return (
    <AddProfileDrawer
      open={open}
      onClose={checkBeforeClose}
      isPending={isPending}
    >
      <AddProfileForm
        values={values}
        setValues={setValues}
        isPending={isPending}
        onFinish={handleFinish}
      >
        <Form.Item
          {...buildCommonFormItemProps({
            name: 'organization',
            label: 'Organization',
            required: true,
          })}
        >
          <OrganizationSelect initialValue={organization} />
        </Form.Item>
        <AddProfileFormItemFirstNameInput />
        <AddProfileFormItemLastNameInput />
        <AddProfileFormItemEmailInput
          required={emailOptions.required}
          requireEmailOrPhone={requireEmailOrPhone}
          phone={values.phone}
        />
        {phoneOptions.visible && (
          <AddProfileFormItemPhoneInput
            required={phoneOptions.required}
            requireEmailOrPhone={requireEmailOrPhone}
            email={values.email}
            isPending={isPending}
          />
        )}
        {error && (
          <Form.Item>
            <BackendErrorAlert error={error} />
          </Form.Item>
        )}
        <AddFormButtonGroup
          onClose={checkBeforeClose}
          isValid={isValid}
          isPending={isPending}
        />
      </AddProfileForm>
    </AddProfileDrawer>
  )
}
