import { AccessLevelTypes } from '@swiftctrl/api-client'
import { AssignAccessLevelToBuildingProps } from '@swiftctrl/api-client/lib/organization/getBuildingsAtProtoType'
import { triggerPromisesSequentially } from '@swiftctrl/api-helpers'
import { useMutation } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'

export const useBatchUpdateAccessLevels = () =>
  useMutation({
    mutationFn: batchUpdateAccessLevels,
  })

const batchUpdateAccessLevels = ({
  landlordId,
  tenantId,
  buildingId,
  accessLevelsToAdd,
  accessLevelIdsToRemove,
  defaultsToAdd,
  defaultsToRemove,
}: {
  landlordId: string
  tenantId: string
  buildingId: string
  accessLevelsToAdd: Partial<AccessLevelTypes>[]
  accessLevelIdsToRemove: string[]
  defaultsToAdd: string[]
  defaultsToRemove: string[]
}) => {
  const accessLevels = swiftClientWithoutErrorHandling.organization
    .at(landlordId)
    .tenant.at(tenantId)
    .buildings.at(buildingId).accessLevel

  const assignAccessLevels = accessLevels.assign(
    buildAssignPayload(accessLevelsToAdd),
  )

  const unassignAccessLevels = accessLevels.unassign(accessLevelIdsToRemove)

  const updateAccessLevels = accessLevels.assignUpdate(
    buildUpdatePayload(defaultsToAdd, defaultsToRemove),
  )

  const response = triggerPromisesSequentially(
    [assignAccessLevels, unassignAccessLevels, updateAccessLevels],
    {
      delay: 100,
    },
  )

  return response
}

const buildAssignPayload = (
  accessLevelsToAdd: Partial<AccessLevelTypes>[],
): AssignAccessLevelToBuildingProps[] => {
  const payload: AssignAccessLevelToBuildingProps[] = accessLevelsToAdd.map(
    (accessLevel) => ({
      accessLevelId: accessLevel.access_level_id!,
      isDefault: accessLevel.is_default!,
    }),
  )

  return payload
}

const buildUpdatePayload = (
  defaultsToAdd: string[],
  defaultsToRemove: string[],
): Required<AssignAccessLevelToBuildingProps>[] => {
  const payload = new Array<Required<AssignAccessLevelToBuildingProps>>()

  defaultsToAdd.forEach((id) =>
    payload.push({ accessLevelId: id, isDefault: true }),
  )

  defaultsToRemove.forEach((id) =>
    payload.push({ accessLevelId: id, isDefault: false }),
  )

  return payload
}
