import {
  AxiosResponse,
  EntityBrowseTypes,
  EntityTypes,
  FIELDS,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { useQuery } from '@tanstack/react-query'
import { SWIFT_CORE_ID, SWIFT_ROOT_ENTITY_ID } from '../data/models'
import { queryClient } from '../data/queryClient'
import { swiftClientWithoutErrorHandling } from '../data/swiftClient'
import { cacheKeys, longCacheOptions } from './cacheKeysUtils'

const STATIC_DATA_CACHE_KEY = [cacheKeys.static_data]

export const useGetStaticData = () =>
  useQuery({
    queryKey: STATIC_DATA_CACHE_KEY,
    queryFn: ({ signal }) => {
      const q = getQueryHandler<EntityBrowseTypes>()

      return swiftClientWithoutErrorHandling.entity.browse(
        {
          baseId: SWIFT_ROOT_ENTITY_ID,
          filters: [
            q('WHERE', 'entity_id', 'EQ', SWIFT_CORE_ID),
            q('OR', [
              q('WHERE', 'overseer_id', 'EQ', SWIFT_ROOT_ENTITY_ID),
              q('AND', 'entity_type_id', 'EQ', 'entity'),
              q('AND', 'entity_name', 'EQ', 'Accounts'),
            ]),
          ],
          select: FIELDS.EntityBrowse,
          totalCountInHeader: false,
        },
        {
          signal,
        },
      )
    },
    ...longCacheOptions,
  })

export const getCachedSwiftCore = () =>
  getCachedEntity((entity) => entity.entity_id === SWIFT_CORE_ID)

const getCachedEntity = (predicate: (value: EntityTypes) => boolean) =>
  queryClient
    .getQueryData<AxiosResponse<EntityTypes[], any>>(STATIC_DATA_CACHE_KEY)!
    .data.find(predicate)!

export const getCachedAccountsEntity = () =>
  getCachedEntity((entity) => entity.entity_name === 'Accounts')
