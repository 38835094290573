import { Button } from '@swiftctrl/swift-component-library'

type RemoveSubdataButtonProps<T> = {
  data: T
  removeSubdata: (data: T) => void
}

export const RemoveSubdataButton = <T,>({
  data,
  removeSubdata,
}: RemoveSubdataButtonProps<T>) => (
  <Button type="link" size="small" danger onClick={() => removeSubdata(data)}>
    Remove
  </Button>
)
