import { ShapeCfg } from '@ant-design/graphs'

export const buildNodeStyle = (selectedEntityId: string | undefined) => {
  const func = (config: ShapeCfg) => {
    const isSelected = selectedEntityId === config.id

    if (!isSelected) {
      return {
        stroke: 'transparent',
        fill: 'transparent',
      }
    }

    return {
      stroke: '#13C2C2',
      lineDash: [4, 4],
      lineWidth: 2,
      radius: 4,
      fill: 'transparent',
    }
  }

  return func
}
