export const getUniqueId = (
  idFactory: () => string,
  existingIds: Set<string>,
) => {
  let id = ''

  do {
    id = idFactory()
  } while (existingIds.has(id))

  existingIds.add(id)

  return id
}
