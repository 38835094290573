import { useQuery } from '@tanstack/react-query'
import { swiftClient } from '../../data/swiftClient'
import { adaptSDKReadRequest } from '../adaptSDKReadRequest'
import { cacheKeys } from '../cacheKeysUtils'

export const useReadProfile = (id: string, enabled = true) =>
  useQuery({
    queryKey: [cacheKeys.profile, id],
    queryFn: adaptSDKReadRequest(swiftClient.profile, id),
    enabled,
  })
