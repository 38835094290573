import { Layout } from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'
import { InvertStatus } from './InvertStatus'

type FilterGroupStatusProps = {
  invert: boolean
  extraStatus?: ReactNode
}

export const FilterGroupStatus = ({
  invert,
  extraStatus,
}: FilterGroupStatusProps) => {
  if (!extraStatus) {
    return <InvertStatus isInverted={invert} />
  }

  return (
    <Layout horizontal spacing="large">
      <InvertStatus isInverted={invert} />
      {extraStatus}
    </Layout>
  )
}
