import {
  Dropdown,
  MenuProps,
  MoreOutlined,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

export type ActionsDropdownItem = {
  tooltipText?: string
} & NonNullable<MenuProps['items']>[number]

type ActionsDropdownProps = {
  items: ActionsDropdownItem[]
  spaceRequired?: boolean
}

export const ActionsDropdown = ({
  items,
  spaceRequired,
}: ActionsDropdownProps) => {
  return (
    <Dropdown
      dropdownRender={(content) => (
        <StyledDropDownContainer $spaceRequired={spaceRequired}>
          {content}
        </StyledDropDownContainer>
      )}
      menu={{
        items: items.map((item) => {
          const { tooltipText, ...itemProps } = item

          if ('label' in item) {
            return {
              ...itemProps,
              label: tooltipText ? (
                <Tooltip title={tooltipText}>{item.label}</Tooltip>
              ) : (
                item.label
              ),
            }
          } else {
            return itemProps
          }
        }),
      }}
      trigger={['click']}
    >
      <StyledIcon />
    </Dropdown>
  )
}

const StyledIcon = styled(MoreOutlined)`
  font-size: 16px;
  padding: 8px;
`

const StyledDropDownContainer = styled.div<{ $spaceRequired?: boolean }>`
  margin-top: ${({ theme, $spaceRequired }) =>
    $spaceRequired ? theme.spacing.medium : theme.spacing.none};
`
