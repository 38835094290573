import { AccessLevelTypes } from '@swiftctrl/api-client'
import { useState } from 'react'
import { queryClient } from '../../../../data/queryClient'
import { cacheKeys } from '../../../../utils-hooks'
import { useBatchUpdateAccessLevels } from './useBatchUpdateAccessLevels'
import { useInfiniteBrowseAccessLevels } from './useInfiniteBrowseAccessLevels'

type Params = {
  open: boolean
  landlordId: string
  tenantId: string
  buildingId: string
  close: () => void
}

export const useConfigureAccessLevelsLogic = ({
  open,
  landlordId,
  tenantId,
  buildingId,
  close,
}: Params) => {
  const [search, setSearch] = useState('')

  const {
    data: rawCandidateAccessLevels,
    fetchNextPage: fetchMoreCandidateAccessLevels,
    isFetching: isFetchingCandidateAccessLevels,
    isFetchingFirstTime: isFetchingCandidateAccessLevelsFirstTime,
  } = useInfiniteBrowseAccessLevels({
    tenantId,
    landlordId,
    buildingId,
    enabled: open,
    assigned: false,
    search,
  })

  const {
    data: rawAssignedAccessLevels,
    fetchNextPage: fetchMoreAssignedAccessLevels,
    isFetching: isFetchingAssignedAccessLevels,
    isFetchingFirstTime: isFetchingAssignedAccessLevelsFirstTime,
  } = useInfiniteBrowseAccessLevels({
    tenantId,
    landlordId,
    buildingId,
    enabled: open,
    assigned: true,
  })

  const [addedAccessLevels, setAddedAccessLevels] = useState(
    new Array<Partial<AccessLevelTypes>>(),
  )

  const [removedAccessLevels, setRemovedAccessLevels] = useState(
    new Array<Partial<AccessLevelTypes>>(),
  )

  const [addedDefaults, setAddedDefaults] = useState(new Set<string>())

  const [removedDefaults, setRemovedDefaults] = useState(new Set<string>())

  const addAccessLevel = (accessLevel: Partial<AccessLevelTypes>) => {
    setAddedAccessLevels((previous) => {
      const existing = previous.find(
        (entry) => entry.access_level_id === accessLevel.access_level_id,
      )

      if (existing) {
        return previous
      }

      const rawAssignedAccessLevel = rawAssignedAccessLevels.find(
        (entry) => entry.access_level_id === accessLevel.access_level_id,
      )

      if (rawAssignedAccessLevel) {
        return previous
      }

      const update = [accessLevel, ...previous]

      return update
    })

    setRemovedAccessLevels((previous) => {
      const update = previous.filter(
        (entry) => entry.access_level_id !== accessLevel.access_level_id,
      )

      if (update.length !== previous.length) {
        return update
      }

      return previous
    })
  }

  const removeAccessLevel = (accessLevel: Partial<AccessLevelTypes>) => {
    setAddedAccessLevels((previous) => {
      const update = previous.filter(
        (entry) => entry.access_level_id !== accessLevel.access_level_id,
      )

      if (update.length !== previous.length) {
        return update
      }

      return previous
    })

    setRemovedAccessLevels((previous) => {
      const existing = previous.find(
        (entry) => entry.access_level_id === accessLevel.access_level_id,
      )

      if (existing) {
        return previous
      }

      const rawCandidateAccessLevel = rawCandidateAccessLevels.find(
        (entry) => entry.access_level_id === accessLevel.access_level_id,
      )

      if (rawCandidateAccessLevel) {
        return previous
      }

      const update = [accessLevel, ...previous]

      return update
    })

    setAddedDefaults((previous) => {
      const update = new Set(previous)

      update.delete(accessLevel.access_level_id!)

      return update
    })

    setRemovedDefaults((previous) => {
      const update = new Set(previous)

      update.delete(accessLevel.access_level_id!)

      return update
    })
  }

  const updateDefault = (accessLevelId: string, defaultValue: boolean) => {
    if (defaultValue) {
      setRemovedDefaults((previous) => {
        const update = new Set(previous)

        const deleted = update.delete(accessLevelId)

        if (deleted) {
          return update
        }

        if (!addedAccessLevelIds.includes(accessLevelId)) {
          setAddedDefaults((previous) => {
            const update = new Set(previous)

            update.add(accessLevelId)

            return update
          })
        }

        return previous
      })
    } else {
      setAddedDefaults((previous) => {
        const update = new Set(previous)

        const deleted = update.delete(accessLevelId)

        if (deleted) {
          return update
        }

        if (!addedAccessLevelIds.includes(accessLevelId)) {
          setRemovedDefaults((previous) => {
            const update = new Set(previous)

            update.add(accessLevelId)

            return update
          })
        }

        return previous
      })
    }

    setAddedAccessLevels((previous) => {
      const existingIndex = previous.findIndex(
        (entry) => entry.access_level_id === accessLevelId,
      )

      if (existingIndex === -1) {
        return previous
      }

      const update = [...previous]

      update[existingIndex].is_default = defaultValue

      return update
    })
  }

  const addedAccessLevelIds = addedAccessLevels.map(
    (accessLevel) => accessLevel.access_level_id!,
  )

  const removedAccessLevelIds = removedAccessLevels.map(
    (accessLevel) => accessLevel.access_level_id!,
  )

  const candidateAccessLevels = buildCandidateAccessLevels(
    rawCandidateAccessLevels,
    removedAccessLevels,
    addedAccessLevelIds,
  )

  const assignedAccessLevels = buildAssignedAccessLevels(
    rawAssignedAccessLevels,
    addedAccessLevels,
    removedAccessLevelIds,
  )

  const isDirty =
    addedAccessLevels.length > 0 ||
    removedAccessLevels.length > 0 ||
    addedDefaults.size > 0 ||
    removedDefaults.size > 0

  const reset = () => {
    setAddedAccessLevels([])

    setRemovedAccessLevels([])

    setAddedDefaults(new Set())

    setRemovedDefaults(new Set())
  }

  const { mutate, isPending } = useBatchUpdateAccessLevels()

  const save = () => {
    mutate(
      {
        landlordId,
        tenantId,
        buildingId,
        accessLevelsToAdd: addedAccessLevels,
        accessLevelIdsToRemove: removedAccessLevelIds,
        defaultsToAdd: Array.from(addedDefaults),
        defaultsToRemove: Array.from(removedDefaults),
      },
      {
        onSuccess: () => {
          reset()

          close()

          queryClient.removeQueries({
            queryKey: [
              cacheKeys.landlord_tenant_building_access_levels,
              landlordId,
              tenantId,
            ],
          })

          queryClient.resetQueries({
            queryKey: [cacheKeys.organization_assigned_buildings, tenantId],
          })
        },
      },
    )
  }

  return {
    candidateAccessLevels,
    fetchMoreCandidateAccessLevels,
    isFetchingCandidateAccessLevels,
    isFetchingCandidateAccessLevelsFirstTime,
    assignedAccessLevels,
    fetchMoreAssignedAccessLevels,
    isFetchingAssignedAccessLevels,
    isFetchingAssignedAccessLevelsFirstTime,
    setSearch,
    addAccessLevel,
    addedAccessLevelIds,
    removeAccessLevel,
    removedAccessLevelIds,
    updateDefault,
    addedDefaults,
    removedDefaults,
    isDirty,
    reset,
    save,
    isSaving: isPending,
  }
}

const buildCandidateAccessLevels = (
  rawCandidateAccessLevels: Partial<AccessLevelTypes>[],
  removedAccessLevels: Partial<AccessLevelTypes>[],
  addedAccessLevelIds: string[],
) => {
  const candidateAccessLevels = [...removedAccessLevels]

  rawCandidateAccessLevels.forEach((accessLevel) => {
    if (!addedAccessLevelIds.includes(accessLevel.access_level_id!)) {
      candidateAccessLevels.push(accessLevel)
    }
  })

  return candidateAccessLevels
}

const buildAssignedAccessLevels = (
  rawAssignedAccessLevels: Partial<AccessLevelTypes>[],
  addedAccessLevels: Partial<AccessLevelTypes>[],
  removedAccessLevelIds: string[],
) => {
  const assignedAccessLevels = [...addedAccessLevels]

  rawAssignedAccessLevels.forEach((accessLevel) => {
    if (!removedAccessLevelIds.includes(accessLevel.access_level_id!)) {
      assignedAccessLevels.push(accessLevel)
    }
  })

  return assignedAccessLevels
}
