import { OperationPrefix, OperationTypes } from '@swiftctrl/api-client'
import { SelectProps } from '@swiftctrl/swift-component-library'
import { RadioGroupProps } from 'antd'

type ReturnType = {
  prefixOptions: SelectProps['options']
  actionOptions: SelectProps['options']
  fieldNameOptions: SelectProps['options']
  relationNameOptions: SelectProps['options']
  scopeOptions: RadioGroupProps['options']
  modifierOptions: RadioGroupProps['options']
}

export const buildFormOptions = (
  operations: OperationTypes[],
  selectedPrefix: OperationPrefix | undefined,
): ReturnType => {
  const prefixOptions: SelectProps['options'] = operations.map((operation) => ({
    label: operation.prefix,
    value: operation.prefix,
  }))

  if (!selectedPrefix) {
    return {
      prefixOptions,
      actionOptions: [],
      fieldNameOptions: [],
      relationNameOptions: [],
      scopeOptions: [],
      modifierOptions: [],
    }
  }

  const { actions, field_names, relation_names, scopes, modifiers } =
    operations.find((operation) => operation.prefix === selectedPrefix)!

  return {
    prefixOptions,
    actionOptions: actions.map(buildOption),
    fieldNameOptions: field_names?.map(buildOption) || [],
    relationNameOptions: relation_names?.map(buildOption) || [],
    scopeOptions: scopes.map(buildOption),
    modifierOptions: modifiers.map(buildOption),
  }
}

const buildOption = <T,>(data: T) => ({
  label: data,
  value: data,
})
