import { Drawer } from '@swiftctrl/swift-component-library'
import { PropsWithChildren } from 'react'
import { buildCommonDrawerProps } from '../../../../utils-hooks'

type AddProfileDrawerProps = PropsWithChildren<{
  open: boolean
  onClose: () => void
  isPending: boolean
}>

export const AddProfileDrawer = ({
  open,
  onClose,
  isPending,
  children,
}: AddProfileDrawerProps) => {
  return (
    <Drawer
      {...buildCommonDrawerProps({
        title: 'Add profile',
        open,
        onClose,
        isPending,
      })}
    >
      {children}
    </Drawer>
  )
}
