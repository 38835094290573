import JsonEditor from '@hiimdjango/json-editor-react'
import { useState } from 'react'
import styled from 'styled-components'
import { useEventContext } from '../../states'

type UIEditorProps = {
  data: Record<string, any>
}

export const UIEditor = ({ data: dataProp }: UIEditorProps) => {
  const [data, setData] = useState(dataProp)

  const { emitEvent, useEvent } = useEventContext()

  const handleChange = (data: Record<string, any>) => {
    emitEvent('JsonEditor.UIEditor.change', data)
  }

  useEvent('JsonEditor.TextEditor.change', setData)

  return (
    <Style>
      <JsonEditor
        key={JSON.stringify(data)}
        data={data}
        onChange={handleChange}
        width="100%"
      />
    </Style>
  )
}

/**
 * The style needs to be added like this because the `JsonEditor` does not
 * have a `className` prop to forward to its DOM element.
 *
 * When we move code to the package, it would be good to add a `className`
 * which is merged with its internal `className`. Then we can remove
 * this empty `div`
 */
const Style = styled.div`
  .jsonEditorContainer {
    // Temporary Styles, will be moved to package
    line-height: 1;
    font-size: 12px;

    .objectContent,
    .arrayContent {
      position: relative;

      .jsonKey,
      .jsonValue {
        margin-right: 10px;
      }
      .arrayContent {
        margin-left: 20px;
      }
    }

    // Bigger input fields for Json Values
    .jsonValue > .ant-select {
      min-width: 250px;
    }

    .indexLine {
      position: relative;
      margin-bottom: 5px;
    }

    .tools {
      position: absolute;
      right: 0;
      top: 0;

      .iconSubtraction {
        margin-left: 10px;
      }
    }

    .addItem {
      display: flex;
      margin-top: 10px;

      // Primary Add Icons
      .anticon-plus-square {
        color: var(--ant-primary-color) !important;
      }
    }

    .mt15 {
      display: inline-block;
      margin-top: 7px;
    }
    .collapse {
      position: relative;

      &.down {
        transition: transform 0.2s ease;
        transform: rotate(270deg);
      }

      &.up {
        transition: transform 0.2s ease;
        transform: rotate(360deg);
      }
    }
  }
`
