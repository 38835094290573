import { BrowseFunction } from '@swiftctrl/api-client'
import { FilterParams } from '@swiftctrl/api-helpers'
import { BrowseEntitiesConfig } from '../states'
import { getSort, SortArg } from './browseEntityUtils'

type AdaptSDKBrowseRequestParams<DataType, DataSelectTypes, DataFilterTypes> = {
  request: BrowseFunction<DataSelectTypes, DataType, unknown, DataFilterTypes>
  select: DataSelectTypes[]
  getFilters?: (
    search: string,
    relatedEntityId?: string,
  ) => FilterParams<DataFilterTypes>[] | undefined
  getSearch?: (search: string) => string | undefined
  sort?: SortArg
  basedIdRequire?: boolean
}

export const adaptSDKBrowseRequest = <
  DataType,
  DataSelectTypes = keyof DataType,
  DataFilterTypes = DataSelectTypes,
>({
  request,
  select,
  getFilters: filtersArg,
  getSearch: searchArg,
  sort: sortArg,
  basedIdRequire = true,
}: AdaptSDKBrowseRequestParams<DataType, DataSelectTypes, DataFilterTypes>) => {
  const adapter: BrowseEntitiesConfig<DataType>['request'] = ({
    baseId,
    pageParam,
    dataPerFetch,
    search,
    relatedEntityId,
    sort,
    signal,
  }) =>
    request(
      {
        baseId: basedIdRequire ? baseId : undefined,
        offset: pageParam,
        limit: dataPerFetch,
        select,
        filters: filtersArg?.(search, relatedEntityId),
        search: searchArg?.(search),
        sort: getSort(search, sortArg, sort),
      },
      {
        signal,
      },
    )

  return adapter
}
