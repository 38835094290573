import { AxiosResponse } from '@swiftctrl/api-client'

type Provider<T> = {
  at: (id: string) => {
    read: () => Promise<AxiosResponse<T, any>>
  }
}

export const adaptSDKRead = <T>(provider: Provider<T>) => {
  const adapter = async (id: string) => {
    const { data } = await provider.at(id).read()

    return data
  }

  return adapter
}
