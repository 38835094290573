import { styled } from 'styled-components'
import {
  TagButtonGroup,
  TagButtonGroupProps,
} from '../../components/TagButtonGroup'

interface StreamIdFiltersProps {
  streamIds: string[]
  streamIdToCountMap: Map<string, number>
  onChange: (streamId: string) => void
  selectedStreamId: string
}

export const StreamIdFilters = ({
  streamIds,
  streamIdToCountMap,
  onChange,
  selectedStreamId,
}: StreamIdFiltersProps) => {
  const items: TagButtonGroupProps['items'] = streamIds.map((streamId) => {
    const count = streamIdToCountMap.get(streamId)!

    return {
      key: streamId,
      label: `${streamId} (${count})`,
    }
  })

  return (
    <StyledTagButtonGroup
      items={items}
      activeKey={selectedStreamId}
      onChange={onChange}
    />
  )
}

const StyledTagButtonGroup = styled(TagButtonGroup)`
  width: 100%;

  overflow: scroll;

  padding-bottom: ${({ theme }) => theme.spacing.medium};

  margin-bottom: ${({ theme }) => theme.spacing.small};

  span:last-of-type {
    margin-right: 0px;
  }
`
