import { ProviderProfileTypes } from '@swiftctrl/api-client'
import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { Link } from 'react-router-dom'
import { providerFieldLabels } from '../../../../config/labels'
import { PROVIDERS_SCREEN_PATH } from '../../../../config/paths'
import { getProviderTypeFromProviderProfile } from '../../../../utils-hooks'
import { CopyableDisplay } from '../../../data-display'

type ProviderProfileProviderDisplayProps = {
  providerProfile: Partial<ProviderProfileTypes>
}

export const ProviderProfileProviderDisplay = ({
  providerProfile,
}: ProviderProfileProviderDisplayProps) => {
  const { overseer_name, overseer_id } = providerProfile

  const providerType = getProviderTypeFromProviderProfile(providerProfile)

  return (
    <Layout>
      <CopyableDisplay
        label={
          <Link to={`/${PROVIDERS_SCREEN_PATH}/${overseer_id}`}>
            {overseer_name || overseer_id}
          </Link>
        }
        copyableText={overseer_id}
      />
      <Typography.Text type="secondary">
        Provider Type: {providerFieldLabels.providerTypeLabels[providerType]}
      </Typography.Text>
    </Layout>
  )
}
