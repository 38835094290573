import { FlowGraphEdgeData } from '@ant-design/graphs'
import { FlowAnalysisNodeData } from '@ant-design/graphs/es/components/flow-analysis-graph'
import {
  OrganizationTypes,
  SinkTypes,
  SourceTypes,
} from '@swiftctrl/api-client'
import { useQuery } from '@tanstack/react-query'
import { EntityType } from '../../../data/models'
import { swiftClient } from '../../../data/swiftClient'
import { cacheKeys, getAllFromBrowseRequest } from '../../../utils-hooks'
import { Entity, EntityNode } from './models'

export type NodeExtraData = {
  text: ''
  id: string
  name: string
  entityType: EntityType
}

export type Edge = FlowGraphEdgeData & {
  isSourceSinkRelationship: boolean
}

export type ChartData = {
  idToEntityMap: Map<string, Entity>
  chartData: {
    nodes: FlowAnalysisNodeData[]
    edges: Edge[]
  }
}

export const useGetChartData = (organization: OrganizationTypes) => {
  const { organization_id } = organization

  const { sources } = useGetSources(organization_id)

  const { sinks } = useGetSinks(organization_id)

  if (!sources || !sinks) {
    return
  }

  const { allEntities, nodes, edges } = buildChartData(
    organization,
    sources as SourceTypes[],
    sinks as SinkTypes[],
  )

  const idToEntityMap = buildIdToEntityMap(
    organization,
    allEntities,
    sources as SourceTypes[],
    sinks as SinkTypes[],
  )

  const chartData: ChartData = {
    idToEntityMap,
    chartData: {
      nodes,
      edges,
    },
  }

  return chartData
}

const useGetSources = (organizationId: string) => {
  const { data } = useQuery({
    queryKey: [cacheKeys.sources, organizationId],
    queryFn: () =>
      getAllFromBrowseRequest((offset, limit) =>
        swiftClient.source.browse({
          baseId: organizationId,
          select: [
            'entity_type_id',
            'source_id',
            'source_target_id',
            'source_target_entity',
            'overseer_name',
            'entity_name',
            'source_owner',
            'type_names',
          ],
          offset,
          limit,
        }),
      ),
  })

  return { sources: data }
}

const useGetSinks = (organizationId: string) => {
  const { data } = useQuery({
    queryKey: [cacheKeys.sinks, organizationId],
    queryFn: () =>
      getAllFromBrowseRequest((offset, limit) =>
        swiftClient.sink.browse({
          baseId: organizationId,
          select: [
            'entity_type_id',
            'source_id',
            'sink_target_id',
            'sink_target_entity',
            'overseer_name',
            'entity_name',
            'sink_owner',
            'types_using_target_id',
          ],
          offset,
          limit,
        }),
      ),
  })

  return { sinks: data }
}

const buildChartData = (
  organization: OrganizationTypes,
  sources: SourceTypes[],
  sinks: SinkTypes[],
) => {
  const { organization_id } = organization

  const edges = new Array<Edge>()

  const sinksAndSourcesEntityNodes = new Array<EntityNode>()

  const targets = new Array<EntityNode>()

  sources.forEach((source) => {
    edges.push(buildSourceTargetToSourceEdge(source))

    targets.push(buildSourceTarget(source))

    sinksAndSourcesEntityNodes.push(buildSourceEntityNode(source))
  })

  sinks.forEach((sink) => {
    edges.push(buildSourceToSinkEdge(sink))

    if (sink.sink_target_id !== sink.source_id) {
      edges.push(buildSinkToTargetEdge(sink))
    }

    targets.push(buildSinkTarget(sink))

    sinksAndSourcesEntityNodes.push(buildSinkEntityNode(sink))
  })

  const allEntities = new Map(
    [...targets, ...sinksAndSourcesEntityNodes].map((target) => [
      target.entity_id,
      target,
    ]),
  )

  const nodes = new Array<FlowAnalysisNodeData>(
    buildNode(organization_id, organization.entity_name, 'organization'),
  )

  allEntities.forEach((node) => {
    const { entity_id, entity_name, entity_type_id } = node

    nodes.push(buildNode(entity_id, entity_name || entity_id, entity_type_id))

    if (entity_type_id !== 'source' && entity_type_id !== 'sink') {
      edges.push(buildOrganizationToChildEdge(organization_id, entity_id))
    }
  })

  return {
    allEntities,
    nodes,
    edges,
  }
}

const buildNode = (id: string, value: string, entityType: string) => {
  const node: NodeExtraData = {
    text: '',
    id,
    name: value,
    entityType: entityType as EntityType,
  }

  return {
    id,
    value: {
      title: '',
      items: [node],
    },
  }
}

const buildSourceTargetToSourceEdge = (source: SourceTypes): Edge => ({
  source: source.source_target_id,
  target: source.source_id,
  isSourceSinkRelationship: true,
})

const buildSourceTarget = (source: SourceTypes): EntityNode => {
  const { entity_name, entity_description, entity_type_id } =
    source.source_target_entity

  return {
    entity_id: source.source_target_id,
    entity_name,
    entity_description,
    entity_type_id,
  }
}

const buildSourceEntityNode = (source: SourceTypes): EntityNode => ({
  entity_id: source.source_id,
  entity_name: source.entity_name,
  entity_description: source.entity_description || '',
  entity_type_id: 'source',
  overseer_name: source.overseer_name,
})

const buildSourceToSinkEdge = (sink: SinkTypes): Edge => ({
  source: sink.source_id,
  target: sink.sink_id,
  isSourceSinkRelationship: true,
})

const buildSinkToTargetEdge = (sink: SinkTypes): Edge => ({
  source: sink.sink_id,
  target: sink.sink_target_id,
  isSourceSinkRelationship: true,
})

const buildSinkTarget = (sink: SinkTypes): EntityNode => {
  const { entity_name, entity_description, entity_type_id } =
    sink.sink_target_entity

  return {
    entity_name,
    entity_description,
    entity_id: sink.sink_target_id,
    entity_type_id,
  }
}

const buildSinkEntityNode = (sink: SinkTypes): EntityNode => ({
  entity_id: sink.sink_id,
  entity_name: sink.entity_name,
  entity_description: sink.entity_description || '',
  entity_type_id: 'sink',
  overseer_name: sink.overseer_name,
})

const buildOrganizationToChildEdge = (
  organizationId: string,
  childId: string,
): Edge => ({
  source: organizationId,
  target: childId,
  isSourceSinkRelationship: false,
})

const buildIdToEntityMap = (
  organization: OrganizationTypes,
  allEntities: Map<string, EntityNode>,
  sources: SourceTypes[],
  sinks: SinkTypes[],
) => {
  const map = new Map<string, Entity>(allEntities)

  map.set(organization.organization_id, organization)

  sources.forEach((source) => map.set(source.source_id, source))

  sinks.forEach((sink) => map.set(sink.sink_id, sink))

  return map
}
