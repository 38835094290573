import { SinkFilterConfiguration } from '@swiftctrl/api-client'
import { Col, Empty, Row } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { Status } from '../../components'
import { AssociationFiltersView } from './AssociationFiltersView'
import { FilterGroupView } from './FilterGroupView'

type FilterGroupsViewProps = {
  filterConfiguration: SinkFilterConfiguration
}

export const FilterGroupsView = ({
  filterConfiguration,
}: FilterGroupsViewProps) => {
  if (!hasAnyFilter(filterConfiguration)) {
    return <Empty description="No filter groups" />
  }

  const {
    route_filter,
    invert_route_filter,
    structure_filter,
    invert_structure_filter,
    target_filter,
    invert_target_filter,
    target_filter_switch_mirror,
    mirror_target_filter,
    invert_mirror_target_filter,
    association_filter,
    invert_association_filter,
  } = filterConfiguration

  return (
    <>
      <Row gutter={[16, 24]}>
        <StyledCol span={12}>
          <FilterGroupView
            label="Route filter group"
            filterGroup={route_filter}
            invert={invert_route_filter}
          />
        </StyledCol>
        <StyledCol span={12}>
          <FilterGroupView
            label="Structure filter group"
            filterGroup={structure_filter}
            invert={invert_structure_filter}
          />
        </StyledCol>
        <StyledCol span={12}>
          <FilterGroupView
            label="Target filter group"
            filterGroup={target_filter}
            invert={invert_target_filter}
            extraStatus={
              <Status
                status={target_filter_switch_mirror ? 'Completed' : 'Required'}
              >
                switch_mirror
              </Status>
            }
          />
        </StyledCol>
        <StyledCol span={12}>
          <FilterGroupView
            label="Mirror target filter group"
            filterGroup={mirror_target_filter}
            invert={invert_mirror_target_filter}
          />
        </StyledCol>
        <StyledCol span={12}>
          <AssociationFiltersView
            filters={association_filter?.association_filters}
            invert={invert_association_filter}
          />
        </StyledCol>
      </Row>
    </>
  )
}

const StyledCol = styled(Col)`
  display: flex;
`

const hasAnyFilter = (filterConfiguration: SinkFilterConfiguration) => {
  const {
    route_filter,
    structure_filter,
    target_filter,
    mirror_target_filter,
    association_filter,
  } = filterConfiguration

  if (
    route_filter ||
    structure_filter ||
    target_filter ||
    mirror_target_filter
  ) {
    return true
  }

  if (!association_filter) {
    return false
  }

  const { association_filters } = association_filter

  if (!association_filters) {
    return false
  }

  return association_filters.length > 0
}
