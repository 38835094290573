import { SinkTemplateVariable } from '@swiftctrl/api-client'
import { Form, Input } from '@swiftctrl/swift-component-library'
import { ArrayInput } from './ArrayInput'
import { buildFormItemProps } from './addSinkUtils'

type SinkTemplateVariableFieldProps = {
  variable: SinkTemplateVariable
}

export const SinkTemplateVariableField = ({
  variable,
}: SinkTemplateVariableFieldProps) => {
  const { name, type } = variable

  const formItemProps = buildFormItemProps(name, name)

  switch (type) {
    case 'Array':
      return <ArrayInput {...formItemProps} />

    case 'String':
    case 'Uuid':
      return (
        <Form.Item {...formItemProps}>
          <Input />
        </Form.Item>
      )

    case 'Integer':
      return (
        <Form.Item {...formItemProps}>
          <Input type="number" />
        </Form.Item>
      )

    default:
      return (
        <>
          <p>Variable type {type} isn't supported</p>
          <p>
            This is a developer error - please let your friendly ConfigCloud
            team know about this 🧑‍💻
          </p>
        </>
      )
  }
}
