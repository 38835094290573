import { useMutation } from '@tanstack/react-query'
import { getEditEntityConfig } from '../../config/editEntityConfig'
import { getReadRequest, hasReadRequest } from '../../config/readEntityConfig'
import { EntityType } from '../../data/models'
import { showWarningNotification } from '../notifications'

export const useEditEntity = <T>(
  entity: T,
  entityId: string,
  entityType: EntityType,
  onEdited: () => void,
) => {
  const { editRequest, readBeforeEdit } = getEditEntityConfig(entityType)

  const editEntityMutation = useMutation({
    mutationFn: async (payload: Partial<T>) => {
      const previousData = await getPreviousData()

      const data = {
        ...previousData,
        ...payload,
      }

      return editRequest(data)
    },
    onSuccess: onEdited,
  })

  const getPreviousData = async () => {
    if (!readBeforeEdit) {
      return entity
    }

    if (!hasReadRequest(entityType)) {
      showWarningNotification(
        `${entityType} hasn't been configured for reading!`,
        'Ask your friendly ConfigCloud team to configure it 🧑‍💻',
      )

      return entity
    }

    const readRequest = getReadRequest(entityType)

    const { data } = await readRequest.at(entityId).read()

    return data as T
  }

  return {
    editEntity: editEntityMutation.mutate,
    processingEditRequest: editEntityMutation.isPending,
  }
}
