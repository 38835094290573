import { ProviderProfileUpdateTypes } from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { swiftClient } from '../../data/swiftClient'

type Params = {
  id: string
  data: ProviderProfileUpdateTypes
}

export const useEditProviderProfile = (onSuccess: () => void) =>
  useMutation({
    mutationFn: ({ id, data }: Params) =>
      swiftClient.providerProfile.at(id).edit(data),
    onSuccess,
  })
