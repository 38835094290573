import { DefaultValueSelect } from './DefaultValueSelect'

type DefaultBoolSelectProps = {
  defaultValue: string | null
  onChange: (value: string) => void
}

export const DefaultBoolSelect = ({
  defaultValue,
  onChange,
}: DefaultBoolSelectProps) => {
  return (
    <DefaultValueSelect
      options={['true', 'false']}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  )
}
