import { PersonTypes, RolesTypes } from '@swiftctrl/api-client'
import { ActionsDropdown } from '../../../components'
import {
  BackendError,
  swiftClientWithoutErrorHandling,
} from '../../../data/swiftClient'
import { useHandleDeleteClick } from '../../../utils-hooks'
import { personRoleQueryKeys } from './invalidatePersonRoleQueries'

type RoleActionsDropdownProps = {
  role: Partial<RolesTypes>
  person: PersonTypes
}

const INCORRECT_PROFILE_ERROR_MESSAGES = [
  'failed to delete profile role: the role is not assigned to the profile',
  'Not Found',
]

export const RoleActionsDropdown = ({
  role,
  person,
}: RoleActionsDropdownProps) => {
  const { handleDeleteClick } = useHandleDeleteClick({
    entity: role,
    entityType: 'role',
    action: 'remove',
    mutationFn: () => removeProfileRoleRelationship(role, person),
    invalidateQueryKeys: personRoleQueryKeys,
  })

  return (
    <ActionsDropdown
      items={[
        {
          key: 'remove',
          label: 'Remove',
          danger: true,
          onClick: handleDeleteClick,
        },
      ]}
    />
  )
}

const removeProfileRoleRelationship = async (
  role: Partial<RolesTypes>,
  person: PersonTypes,
) => {
  const { role_id } = role

  const { profile_ids } = person

  for (let i = 0; i < profile_ids.length; i++) {
    const profileId = profile_ids[i]

    try {
      const response = await swiftClientWithoutErrorHandling.profile
        .at(profileId)
        .role.at(role_id!)
        .delete()

      if (response.status === 204) {
        return response
      }
    } catch (error: any) {
      if (error.isAxiosError) {
        const backendError = error as BackendError

        const errorMessage = backendError.response?.data.message

        if (
          errorMessage &&
          INCORRECT_PROFILE_ERROR_MESSAGES.includes(errorMessage)
        ) {
          continue
        }
      }

      throw error
    }
  }

  throw new Error('Could not remove role from person')
}
