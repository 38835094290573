import { Result } from '@swiftctrl/swift-component-library'
import React from 'react'
import { Link } from 'react-router-dom'

export const NotFoundScreen: React.FC = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Link to="/">Back Home</Link>}
    />
  )
}
