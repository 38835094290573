import { List } from 'antd'
import { ManageAssignedDataHeader } from './ManageAssignedDataHeader'
import { ManageAssignedDataListContainer, ScrollContainer } from './utils'

type ManageAssignedDataListProps<T> = {
  header: string
  dataSource: T[]
  renderItem: (item: T) => React.ReactNode
}

export const ManageAssignedDataList = <T,>({
  header,
  dataSource,
  renderItem,
}: ManageAssignedDataListProps<T>) => {
  return (
    <ManageAssignedDataListContainer>
      <ManageAssignedDataHeader>{header}</ManageAssignedDataHeader>
      <ScrollContainer>
        <List dataSource={dataSource} renderItem={renderItem} />
      </ScrollContainer>
    </ManageAssignedDataListContainer>
  )
}
