import { Divider, Typography } from '@swiftctrl/swift-component-library'
import { Radio, RadioChangeEvent } from 'antd'
import { RemoteConfig, fetchAndActivate, getAll } from 'firebase/remote-config'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HTMLTitle } from '../components'
import { Env } from '../config/firebaseConfig'
import { getRemoteConfig } from '../data/firebase'
import { stringifyJson } from '../utils-hooks'

type DomainConfig = {
  domain: string
  name: string
  card_asset_url: string
  baseURL: string
  origoUUID: string
  applicationID: string
  auth: {
    strategy: string
    config: { loginUrl: string }
  }
}

const configsToExclude = ['sc_versions']

const parseDomainConfigs = (remoteConfig: RemoteConfig) => {
  const values = getAll(remoteConfig)

  const configs = Object.keys(values)
    .filter((key) => !configsToExclude.includes(key))
    .map((domain) => {
      const value = values[domain].asString()

      const config = { ...JSON.parse(value), domain } as DomainConfig

      return config
    })

  return configs.sort((a, b) => a.name.localeCompare(b.name))
}

// ---

export const RemoteConfigsScreen: React.FC = () => {
  const [env, setEnv] = useState<Env>('dev')

  const [domainConfigs, setDomainConfigs] = useState<DomainConfig[]>([])

  const updateEnvironment = (e: RadioChangeEvent) => {
    setEnv(e.target.value)
  }

  useEffect(() => {
    setDomainConfigs([])

    const remoteConfig = getRemoteConfig(env)

    fetchAndActivate(remoteConfig)
      .then(() => parseDomainConfigs(remoteConfig))
      .then(setDomainConfigs)
  }, [env])

  return (
    <StyledRemoteConfigsScreen>
      <HTMLTitle value="Remote Configs" />
      <Radio.Group value={env} onChange={updateEnvironment}>
        <Radio value="dev">Dev</Radio>
        <Radio value="qa">QA</Radio>
        <Radio value="prod">Prod</Radio>
      </Radio.Group>

      <p>{domainConfigs.length} configs</p>

      <StyledGrid>
        {domainConfigs.map((domain) => (
          <section key={domain.domain}>
            <Typography.Title level={3}>{domain.name}</Typography.Title>
            <h4>
              <i>{domain.domain}</i>
            </h4>
            <h4>{domain.baseURL}</h4>
            <a href={domain.card_asset_url} target="_blank" rel="noreferrer">
              <img src={domain.card_asset_url} alt="asset" />
            </a>
            <Divider />
            <pre>{stringifyJson(domain)}</pre>
          </section>
        ))}
      </StyledGrid>
    </StyledRemoteConfigsScreen>
  )
}

const StyledRemoteConfigsScreen = styled.div`
  padding: 16px;
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  section {
    padding: 16px;
    border: 1px solid #666;
    border-radius: 8px;
    min-width: 0;
    img {
      max-width: 100%;
      margin-top: 12px;
      border-radius: 16px;
    }
  }
`
