import { Editor } from '@monaco-editor/react'
import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import styled from 'styled-components'
import { useEventContext } from '../../states'
import { stringifyJson } from '../../utils-hooks'

type TextEditorProps = {
  data: Record<string, any>
}

const BACKGROUND_COLOR = '#f0f0f0'

const PADDING = '8px'

const COPY_ICON_SIZE = '24px'

export const TextEditor = ({ data: dataProp }: TextEditorProps) => {
  const [data, setData] = useState(dataProp)

  const { emitEvent, useEvent } = useEventContext()

  const handleChange = (value: string | undefined) => {
    if (!value) {
      emitEvent('JsonEditor.TextEditor.change', {})

      emitEvent('JsonEditor.TextEditor.valid', true)

      return
    }

    let json = undefined

    try {
      json = JSON.parse(value)
    } catch (error) {
      emitEvent('JsonEditor.TextEditor.valid', false)
    }

    if (json !== undefined) {
      emitEvent('JsonEditor.TextEditor.change', json)

      emitEvent('JsonEditor.TextEditor.valid', true)
    }
  }

  useEvent('JsonEditor.UIEditor.change', setData)

  const value = stringifyJson(data)

  return (
    <Container grow>
      <Editor
        defaultLanguage="json"
        value={value}
        onChange={handleChange}
        options={{
          minimap: {
            enabled: false,
          },
          scrollBeyondLastLine: false,
          fontSize: 13,
        }}
      />
      <StyledText copyable={{ text: value }} />
    </Container>
  )
}

const Container = styled(Layout)`
  position: relative;

  > section {
    padding-top: ${PADDING};

    background-color: ${BACKGROUND_COLOR};

    min-height: calc(${COPY_ICON_SIZE} + 2 * ${PADDING});
  }

  .margin-view-overlays,
  .monaco-editor-background {
    background-color: ${BACKGROUND_COLOR};
  }
`

const StyledText = styled(Typography.Text)`
  position: absolute;

  top: 0.5em;
  right: 1.5em;

  background: ${BACKGROUND_COLOR};

  svg {
    font-size: ${COPY_ICON_SIZE};
  }
`
