import {
  Button,
  Dropdown,
  MenuProps,
  PlusOutlined,
} from '@swiftctrl/swift-component-library'
import { getEnvironmentLocalStorageSwitch } from '../../../utils-hooks'

type AddButtonProps = {
  onAddArchetypeClick: () => void
  onAddRoleClick: () => void
  disabled: boolean
}

export const AddButton = ({
  onAddArchetypeClick,
  onAddRoleClick,
  disabled,
}: AddButtonProps) => {
  const options: MenuProps['items'] = []

  const addArchetypeEnabled = getEnvironmentLocalStorageSwitch({
    environments: ['local', 'dev'],
    localStorageKey: 'addArchetypeEnabled',
  })

  if (addArchetypeEnabled) {
    options.push({
      key: 'archetype',
      label: 'Archetype',
      onClick: onAddArchetypeClick,
    })
  }

  options.push({
    key: 'role',
    label: 'Role',
    onClick: onAddRoleClick,
  })

  return (
    <Dropdown
      menu={{
        items: options,
      }}
      disabled={disabled}
    >
      <Button type="primary" icon={<PlusOutlined />} size="middle">
        Assign New
      </Button>
    </Dropdown>
  )
}
