import { ProviderProfileTypes } from '@swiftctrl/api-client'
import {
  EmailDisplay,
  LabelDisplay,
  TextDisplay,
  Timestamp,
  UuidDisplay,
} from '../../../data-display'

type LoginDataDisplayProps = {
  providerProfile: Partial<ProviderProfileTypes>
}

export const LoginDataDisplay = ({
  providerProfile,
}: LoginDataDisplayProps) => {
  const { login_data, login_attempts, multi_factor_code_expiry } =
    providerProfile

  if (!login_data) {
    return null
  }

  const { email_password, single_use_token, proxy_login, openid } = login_data

  return (
    <>
      {email_password && <EmailDisplay email={email_password.email} />}
      {single_use_token && (
        <>
          <div>
            <LabelDisplay label="Token" />
            {single_use_token.token ? (
              <UuidDisplay value={single_use_token.token} />
            ) : (
              '-'
            )}
          </div>
          <div>
            <Timestamp label="Expiry" value={single_use_token.expiry} />
          </div>
        </>
      )}
      {proxy_login && (
        <>
          <div>
            <LabelDisplay label="Identifier" />
            {proxy_login.identifier ? (
              <TextDisplay value={proxy_login.identifier} copyable />
            ) : (
              '-'
            )}
          </div>
        </>
      )}
      {openid && (
        <>
          <div>
            <LabelDisplay label="Sub" />
            {openid.sub ? <TextDisplay value={openid.sub} copyable /> : '-'}
          </div>
        </>
      )}
      {Boolean(login_attempts) && (
        <div>
          <LabelDisplay label="Login attempts" />
          <TextDisplay value={login_attempts} />
        </div>
      )}
      {multi_factor_code_expiry && (
        <div>
          <Timestamp label="MFA expiry" value={multi_factor_code_expiry} />
        </div>
      )}
    </>
  )
}
