import { SinkRuleField } from '@swiftctrl/api-client'
import { Modal } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { renderFilterGroup } from './FilterGroupView'

interface ListFilterModalProps {
  isVisible: boolean
  onClose: () => void
  record: SinkRuleField
}

export const ListFilterModal = ({
  isVisible,
  onClose,
  record,
}: ListFilterModalProps) => {
  if (!('predicate' in record)) {
    return null
  }
  const filterGroup = record.predicate
  return (
    <StyledModal
      title="LIST_FILTER"
      open={isVisible}
      onCancel={onClose}
      footer={false}
    >
      {renderFilterGroup(filterGroup)}
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  min-width: fit-content;
`
