import { SinkTypes } from '@swiftctrl/api-client'
import {
  Button,
  DeleteOutlined,
  Modal,
} from '@swiftctrl/swift-component-library'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../../data/queryClient'
import { swiftClient } from '../../../../data/swiftClient'
import {
  cacheKeys,
  showErrorNotification,
  showSuccessNotification,
} from '../../../../utils-hooks'

type DeleteSinkButtonProps = {
  sink: SinkTypes
}

export const DeleteSinkButton = ({ sink }: DeleteSinkButtonProps) => {
  const confirmDeletion = () => {
    Modal.confirm({
      title: `Delete "${sink.entity_name}"`,
      content:
        'Are you sure you want to delete this sink? This cannot be undone!',
      okText: 'Delete',
      okButtonProps: {
        danger: true,
      },
      onOk: () => deleteSinkMutation.mutate({}),
    })
  }

  const deleteSinkMutation = useMutation({
    mutationFn: swiftClient.sink.at(sink.sink_id).delete,
    onSuccess: () => {
      showSuccessNotification('Sink deleted')

      queryClient.invalidateQueries({
        queryKey: [cacheKeys.sinks, sink.source_id],
      })
    },
    onError: (error) =>
      showErrorNotification('Error while deleting sink', error),
  })

  return (
    <Button
      type="text"
      danger
      icon={<DeleteOutlined />}
      onClick={confirmDeletion}
      loading={deleteSinkMutation.isPending}
    />
  )
}
