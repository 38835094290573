import { SinkRuleField } from '@swiftctrl/api-client'
import {
  Layout,
  Typography,
  UserOutlined,
} from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'

interface RulesTableFieldNameProps {
  record: SinkRuleField
}

export const RulesTableFieldName = ({ record }: RulesTableFieldNameProps) => {
  if ('user_defined_field' in record) {
    return (
      <Layout horizontal alignItems="center" spacing="xSmall">
        <Typography.Text>{record.user_defined_field || '-'}</Typography.Text>
        <StyledUserOutlined />
      </Layout>
    )
  }
  if ('predefined_field' in record) {
    return <Typography.Text>{record.predefined_field || '-'}</Typography.Text>
  }
  return null
}

const StyledUserOutlined = styled(UserOutlined)`
  color: #1890ff;
`
