import {
  CredentialFieldConstraint,
  CredentialFieldIntegerRange,
} from '@swiftctrl/api-client'
import { getIntegerRangeStartEnd } from '../../../components'
import { TooltipIcon } from '../TooltipIcon'
import { BitfieldValue, Constraint } from '../utils'
import { IntegerConstraintTypeView } from './IntegerConstraintTypeView'
import { IntegerRangeInput } from './IntegerRangeInput'
import { IntegerConstraintContainer } from './utils'

type FormatIntegerRangeViewProps = {
  templateConstraint: Constraint
  templateBitfieldValue: BitfieldValue | null | undefined
  formatConstraint: CredentialFieldConstraint
  onChange: (constraint: Constraint | null) => void
}

export const FormatIntegerRangeView = ({
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: FormatIntegerRangeViewProps) => {
  const { start: min, end: max } = getIntegerRangeStartEnd(
    formatConstraint.value as CredentialFieldIntegerRange,
  )

  const templateIntegerRange = templateConstraint.integer_range!

  const startDefaultValue = templateIntegerRange.start || min

  const endDefaultValue = templateIntegerRange.end || max

  const minLabel = min.toLocaleString()

  const maxLabel = max.toLocaleString()

  const tooltip = `Due to the format field constraints, the integer range min and max are ${minLabel} and ${maxLabel}`

  return (
    <IntegerConstraintContainer>
      <IntegerConstraintTypeView
        templateConstraint={templateConstraint}
        templateBitfieldValue={templateBitfieldValue}
        formatConstraint={formatConstraint}
        value="integer_range"
        onChange={onChange}
      />
      <TooltipIcon message={tooltip} />
      <IntegerRangeInput
        startDefaultValue={startDefaultValue}
        endDefaultValue={endDefaultValue}
        min={min}
        max={max}
        onChange={(start, end) => {
          onChange({
            integer_range: { start, end },
            date_range: null,
            valid_values: null,
          })
        }}
      />
    </IntegerConstraintContainer>
  )
}
