import { RoleOperationTypes } from '@swiftctrl/api-client'
import {
  ColumnsType,
  Table,
  Tag,
  Typography,
} from '@swiftctrl/swift-component-library'
import {
  ActionsDropdown,
  EnabledDisplay,
  EntityTypeTag,
} from '../../../../components'
import { addRoleOperationKey } from './addRoleOperationKey'
import { confirmRemoveRoleOperation } from './confirmRemoveRoleOperation'

type RoleOperationsTableProps = {
  dataSource: RoleOperationTypes[]
  onRemoveOperation: (roleOperation: RoleOperationTypes) => void
}

export const RoleOperationsTable = ({
  dataSource,
  onRemoveOperation,
}: RoleOperationsTableProps) => {
  const keyedData = dataSource.map(addRoleOperationKey)

  const columns = buildColumns(onRemoveOperation)

  return <Table columns={columns} dataSource={keyedData} pagination={false} />
}

const buildColumns = (
  onRemoveOperation: (roleOperation: RoleOperationTypes) => void,
): ColumnsType<RoleOperationTypes> => [
  {
    title: 'Operation',
    render: (_, operation) => <Tag>{operation.action}</Tag>,
    width: '10%',
  },
  {
    title: 'Field Permissions',
    render: (_, operation) => (
      <>
        {operation.field_names?.map((fieldName) => (
          <Tag key={fieldName}>{fieldName}</Tag>
        )) || '-'}
      </>
    ),
    width: '25%',
  },
  {
    title: 'Relation Names',
    render: (_, operation) => (
      <>
        {operation.relation_names?.map((relationName) => (
          <Tag key={relationName}>{relationName}</Tag>
        )) || '-'}
      </>
    ),
    width: '25%',
  },
  {
    title: 'Scope',
    dataIndex: 'scope',
    width: '10%',
  },
  {
    title: 'Modifier',
    render: (_, operation) => operation.modifier || '-',
    width: '10%',
  },
  {
    title: 'Grants On',
    render: (_, operation) => {
      if (!operation.grants_on) {
        return '-'
      }

      const { entity_type_id, entity_name, entity_id } = operation.grants_on

      return (
        <>
          <EntityTypeTag entityType={entity_type_id} />
          {entity_name || <Typography.Text code>{entity_id}</Typography.Text>}
        </>
      )
    },
    width: '20%',
  },
  {
    title: 'Grantable',
    render: (_, operation) => {
      const { grantable } = operation

      return <EnabledDisplay enabled={grantable} />
    },
  },
  {
    render: (_, operation) => (
      <ActionsDropdown
        items={[
          {
            key: 'remove-permission',
            label: 'Remove Permission',
            danger: true,
            onClick: confirmRemoveRoleOperation(operation, onRemoveOperation),
          },
        ]}
      />
    ),
  },
]
