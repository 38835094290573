import { RolesBrowseTypes, RolesTypes } from '@swiftctrl/api-client'
import { FilterParams, getQueryHandler } from '@swiftctrl/api-helpers'
import { useInfiniteQuery } from '@tanstack/react-query'
import { TABLE_DATA_PER_FETCH } from '../../../../components'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import { useBrowseEntitiesContext } from '../../../../states'
import {
  buildEntityBrowserNextPageParam,
  cacheKeys,
  flattenInfiniteQueryData,
  isValidUuid,
} from '../../../../utils-hooks'

type Params = {
  organizationId: string
  search: string
  enabled: boolean
}

export const useInfiniteBrowseCandidateRoles = ({
  organizationId,
  search,
  enabled,
}: Params) => {
  const { dataSource: assignedRoles } = useBrowseEntitiesContext<RolesTypes>()

  const { data, fetchNextPage, isFetching } = useInfiniteQuery({
    queryKey: [
      cacheKeys.organization_roles,
      organizationId,
      search,
      assignedRoles,
    ],
    queryFn: ({ pageParam, signal }) => {
      const q = getQueryHandler<RolesBrowseTypes>()

      const filters = new Array<FilterParams<RolesBrowseTypes>>()

      const assignedRoleIds = assignedRoles?.map((role) => role.role_id!)

      const filterForAssignedRoles =
        assignedRoleIds && assignedRoleIds.length > 0

      if (filterForAssignedRoles) {
        filters.push(q('WHERE', 'role_id', 'N_ANY', assignedRoleIds))
      }

      if (search) {
        const joiner = filterForAssignedRoles ? 'AND' : 'WHERE'

        const filter = isValidUuid(search)
          ? q(joiner, 'role_id', 'EQ', search)
          : q(joiner, 'entity_name', 'EQ_IC', search)

        filters.push(filter)
      }

      return swiftClientWithoutErrorHandling.role.browse(
        {
          baseId: organizationId,
          limit: TABLE_DATA_PER_FETCH,
          offset: pageParam,
          filters,
          select: ['role_id', 'entity_name', 'entity_description'],
          sort: 'entity_name:asc',
        },
        {
          signal,
        },
      )
    },
    enabled,
    initialPageParam: 0,
    getNextPageParam: buildEntityBrowserNextPageParam,
  })

  const flattenedData = flattenInfiniteQueryData(data) || []

  return {
    candidateRoles: flattenedData,
    fetchMoreCandidateRoles: fetchNextPage,
    isFetchingCandidateRoles: isFetching,
    isFetchingCandidateRolesFirstTime: !data,
  }
}
