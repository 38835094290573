import { IGroup } from '@ant-design/graphs'
import { getEntityLabel } from '../../config/labels'
import { EntityType } from '../../data/models'
import { truncateCanvasText } from '../truncateCanvasText'
import { isValidUuid } from '../uuidUtils'
import { getCachedEntityTypeLabelWidth } from './getCachedEntityTypeLabelWidth'
import { NEW_ENTITY_TITLE, NodeExtraData } from './models'

type Entity = {
  id: string
}

const entityTypeToBorderColor: { [type in EntityType]?: string } = {
  building: '#D3ADF7',
  controller: '#B7EB8F',
  entity: '#8C8C8C',
  epi: '#87E8DE',
  organization: '#91D5FF',
  profile: '#FFD591',
  provider: '#FFADD2',
  provider_profile: '#FFA39E',
  reader: '#AD8B00',
  role: '#FFE58F',
  room: '#ADC6FF',
  sink: '#FF4D4F',
  source: '#52C41A',
  system: '#FFBB96',
}

const entityTypeToLabelColor: { [type in EntityType]?: string } = {
  building: '#9254DE',
  controller: '#73D13D',
  entity: '#BFBFBF',
  epi: '#36CFC9',
  organization: '#40A9FF',
  profile: '#FFA940',
  provider: '#F759AB',
  provider_profile: '#FF4D4F',
  reader: '#614700',
  role: '#FFC53D',
  room: '#597EF7',
  sink: '#CF1322',
  source: '#237804',
  system: '#FF7A45',
}

const BACKGROUND_WIDTH = 237

const BACKGROUND_HEIGHT = 60

const BUTTON_SIZE = 24

const ENTITY_TYPE_FONT_SIZE = 12

const ENTITY_TYPE_MARGIN = 4

const buttonColors = {
  addChild: '#1890FF',
  delete: '#FF4D4F',
}

type ButtonConfig = {
  id: string
}

export const buildNodeExtraContent = (
  item: NodeExtraData,
  group: IGroup | undefined,
  config: {
    startX: number
    startY: number
  },
  selectedEntity?: Entity,
) => {
  if (!group) {
    return 0
  }

  const { startX, startY } = config

  const nodeX = startX - 5

  const nodeY = startY - 11

  const {
    entityType,
    id,
    name,
    addChildEnabled,
    deleteEnabled,
    errorCount,
    isLoading,
    isPlanReadOnly,
  } = item

  const { backgroundX, backgroundY } = addBackground(
    group,
    nodeX,
    nodeY,
    entityType,
  )

  addEntityType(group, backgroundX, backgroundY, entityType)

  const shouldDisplayId = isValidUuid(id)

  const { nameX, nameY } = addName(
    group,
    backgroundX,
    backgroundY,
    name,
    shouldDisplayId,
  )

  if (shouldDisplayId) {
    addId(group, nameX, nameY, id!)
  }

  if (!isPlanReadOnly && selectedEntity?.id === item.id) {
    addButtons(
      group,
      backgroundX,
      backgroundY,
      addChildEnabled,
      deleteEnabled,
      isLoading,
    )
  }

  if (errorCount > 0) {
    addErrorCount(group, backgroundX, backgroundY, errorCount)
  }

  // Translated from original comment: 行高 -> line height
  // But this does NOT control the vertical space occupied by this custom content!
  return 0
}

const addBackground = (
  group: IGroup,
  nodeX: number,
  nodeY: number,
  entityType: EntityType | undefined,
) => {
  const backgroundX = nodeX + 16

  const backgroundY = nodeY + 24

  const stroke = entityType ? entityTypeToBorderColor[entityType] : '#000'

  group.addShape('rect', {
    attrs: {
      x: backgroundX,
      y: backgroundY,
      width: BACKGROUND_WIDTH,
      height: BACKGROUND_HEIGHT,
      fill: `#fff`,
      stroke,
      lineWidth: 4,
      radius: 4,
    },
    name: `background-${Math.random()}`,
  })

  return { backgroundX, backgroundY }
}

const addEntityType = (
  group: IGroup,
  backgroundX: number,
  backgroundY: number,
  entityType: EntityType | undefined,
) => {
  const entityTypeX = backgroundX + 14

  const entityTypeY = backgroundY - 6

  const entityTypeLabel = entityType
    ? getEntityLabel({ entityType })
    : 'Select an entity type'

  const width = getCachedEntityTypeLabelWidth(
    entityTypeLabel,
    ENTITY_TYPE_FONT_SIZE,
  )

  group.addShape('rect', {
    attrs: {
      x: entityTypeX,
      y: entityTypeY,
      width: width + 2 * ENTITY_TYPE_MARGIN,
      height: ENTITY_TYPE_FONT_SIZE,
      fill: `#fff`,
    },
    name: `entityTypeBackground-${Math.random()}`,
  })

  group.addShape('text', {
    attrs: {
      textBaseline: 'top',
      x: entityTypeX + ENTITY_TYPE_MARGIN,
      y: entityTypeY,
      text: entityTypeLabel,
      fill: entityType ? entityTypeToLabelColor[entityType] : '#000',
      fontSize: ENTITY_TYPE_FONT_SIZE,
    },
    name: `entityType-${Math.random()}`,
  })
}

const addName = (
  group: IGroup,
  backgroundX: number,
  backgroundY: number,
  name: string,
  shouldDisplayId: boolean,
) => {
  const nameX = backgroundX + 10

  const nameY = backgroundY + (shouldDisplayId ? 13 : 19)

  const displayName = getDisplayName(name)

  group.addShape('text', {
    attrs: {
      textBaseline: 'top',
      x: nameX,
      y: nameY,
      text: displayName,
      fill: '#000',
      fontSize: 20,
    },
    name: `name-${Math.random()}`,
  })

  return { nameX, nameY }
}

const getDisplayName = (name: string) => {
  if (!name) {
    return NEW_ENTITY_TITLE
  }

  return truncateCanvasText(name, 130)
}

const addId = (group: IGroup, nameX: number, nameY: number, id: string) => {
  const truncatedId = id.substring(0, 19) + '…'

  group.addShape('text', {
    attrs: {
      textBaseline: 'top',
      x: nameX,
      y: nameY + 22,
      text: truncatedId,
      fill: '#8C8C8C',
      fontSize: 14,
    },
    name: `id-${Math.random()}`,
  })
}

const addButtons = (
  group: IGroup,
  backgroundX: number,
  backgroundY: number,
  addChildEnabled: boolean,
  deleteEnabled: boolean,
  isLoading: boolean,
) => {
  const buttons: ButtonConfig[] = []
  let buttonX = backgroundX + BACKGROUND_WIDTH - 26
  const buttonY = backgroundY + BACKGROUND_HEIGHT + 4

  if (addChildEnabled) {
    buttons.push({
      id: isLoading ? 'isLoadingForAdd' : 'addChild',
    })
  }

  if (deleteEnabled) {
    buttons.push({
      id: isLoading ? 'isLoadingForDelete' : 'delete',
    })
  }

  for (let i = buttons.length - 1; i >= 0; i--) {
    const { id } = buttons[i]

    const isIdDelete: boolean = id === 'delete' || id === 'isLoadingForDelete'

    const color = isIdDelete ? buttonColors.delete : buttonColors.addChild

    group.addShape('rect', {
      attrs: {
        x: buttonX,
        y: buttonY,
        width: BUTTON_SIZE,
        height: BUTTON_SIZE,
        stroke: color,
        fill: '#fff',
        cursor: 'pointer',
        radius: 1.5,
      },
      name: `${id}-${Math.random()}`,
    })

    if (isIdDelete) {
      addDeleteButtonContent(group, buttonX, buttonY)
    } else {
      addAddChildButtonContent(group, buttonX, buttonY, id)
    }
    buttonX -= BUTTON_SIZE + 8
  }
}

const addDeleteButtonContent = (
  group: IGroup,
  buttonX: number,
  buttonY: number,
) => {
  group.addShape('path', {
    attrs: {
      startArrow: {
        path: 'M 5 1.4 C 4.04 1.4 3.99 2.06 4 3 L 3.99 4.22 L 1.32 4.22 C 1.0033 4.22 1.0067 4.44 1.01 4.66 L 2.96 4.66 L 12.03 4.66 L 2.96 4.66 C 3 13.3242 3.6758 14 4 14 L 11 14 C 11.3242 14 12 13.3242 12.05 4.71 L 12.07 4.66 L 13.99 4.66 C 13.9833 4.44 13.9767 4.22 13.69 4.27 L 11.01 4.22 L 11 3 C 11.01 2.06 11.06 1.4 10 1.37 Z M 4.66 2.01 L 10.42 1.97 L 10.4 4.22 L 4.66 4.22 z',
      },
      stroke: buttonColors.delete,
      lineWidth: 1.1,
      path: [
        ['M', buttonX + 4.5, buttonY + 4],
        ['L', buttonX + 4.5, buttonY + 4],
      ],
    },
  })
}

const addAddChildButtonContent = (
  group: IGroup,
  buttonX: number,
  buttonY: number,
  id: string,
) => {
  group.addShape('text', {
    attrs: {
      text: '+',
      x: buttonX + 6,
      y: buttonY + 21,
      width: BUTTON_SIZE,
      height: BUTTON_SIZE,
      fill: buttonColors.addChild,
      fontSize: 20,
      cursor: 'pointer',
      lineWidth: 0.5,
    },
    name: `${id}-${Math.random()}`,
  })
}

const addErrorCount = (
  group: IGroup,
  backgroundX: number,
  backgroundY: number,
  errorCount: number,
) => {
  const errorTextX = backgroundX + BACKGROUND_WIDTH - 7

  const errorTextY = backgroundY

  group.addShape('circle', {
    attrs: {
      x: errorTextX,
      y: errorTextY,
      fill: '#F5222D',
      r: 11,
      stroke: '#FFF',
      lineWidth: 1,
    },
    name: `errorBackground-${Math.random()}`,
  })

  group.addShape('text', {
    attrs: {
      textAlign: 'center',
      x: errorTextX,
      y: errorTextY + 7,
      text: errorCount,
      fill: '#FFF',
      fontSize: 14,
    },
    name: `errors-${Math.random()}`,
  })
}
