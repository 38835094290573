import {
  HTMLTitle,
  RolesBrowser,
  StyledEntitiesBrowserScreen,
} from '../components'

export const RolesScreen = () => (
  <StyledEntitiesBrowserScreen>
    <HTMLTitle value="Roles" />
    <RolesBrowser filters={{ baseIdType: 'organization' }} displayAddButton />
  </StyledEntitiesBrowserScreen>
)
