import { Button, Layout } from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'

type ManageAssignedDataFooterProps = {
  hasUnsavedChanges: boolean
  onSave: () => void
  isSaving: boolean
  onClose: () => void
  leftContent: ReactNode
}

export const ManageAssignedDataFooter = ({
  hasUnsavedChanges,
  onSave,
  isSaving,
  onClose,
  leftContent,
}: ManageAssignedDataFooterProps) => {
  return (
    <Layout horizontal justifyContent="space-between" alignItems="center">
      {leftContent || <div />}
      <Layout horizontal>
        <Button size="middle" onClick={onClose} disabled={isSaving}>
          Cancel
        </Button>
        <Button
          size="middle"
          type="primary"
          onClick={onSave}
          disabled={!hasUnsavedChanges}
          loading={isSaving}
        >
          Save
        </Button>
      </Layout>
    </Layout>
  )
}
