import {
  CredentialFilterTypes,
  CredentialTypes,
  ProfilesTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { ColumnsType, Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  EntitiesBrowserProps,
} from '../../../../states'
import { adaptSDKBrowseRequest, cacheKeys } from '../../../../utils-hooks'
import {
  CopyableDisplay,
  CreatedUpdatedDateDisplay,
  DateDisplay,
} from '../../../data-display'
import {
  EntitiesTableBrowser,
  TABLE_DATA_PER_FETCH,
} from '../../EntitiesTableBrowser'
import { EntityMirrorsBrowser } from '../entity-mirror'

type CredentialsBrowserProps = Pick<EntitiesBrowserProps, 'baseId'> & {
  profile: ProfilesTypes
  organizationId: string
}

export const CredentialsBrowser = (props: CredentialsBrowserProps) => {
  const { profile, organizationId } = props

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={TABLE_DATA_PER_FETCH}
      browse={buildBrowse(profile.profile_id)}
      dataKey="credential_id"
      entityType="credential"
      {...props}
    >
      <EntitiesTableBrowser
        columns={columns}
        expandable={{
          expandedRowRender: (credential: CredentialTypes) => {
            const { credential_id, display_name, overseer_id, overseer_name } =
              credential

            return (
              <StyledMirrorsContainer>
                <EntityMirrorsBrowser
                  nested
                  entity={{
                    entity_id: credential_id,
                    overseer_id,
                    entity_type_id: 'credential',
                  }}
                  organizationId={organizationId}
                  idToNameMap={
                    new Map([
                      [credential_id, display_name],
                      [overseer_id, overseer_name],
                      [profile.profile_id, profile.display_name],
                      [profile.overseer_id, profile.overseer_name],
                    ])
                  }
                />
              </StyledMirrorsContainer>
            )
          },
        }}
      />
    </BrowseEntitiesContextProvider>
  )
}

const buildBrowse = (
  profileId: string,
): BrowseEntitiesConfig<CredentialTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClient.credential.browse,
    select: [
      'entity_name',
      'credential_id',
      'credential_format_name',
      'credential_format_id',
      'activate',
      'deactivate',
      'created_at',
      'updated_at',
      'display_name',
      'overseer_id',
      'overseer_name',
    ],
    sort: 'entity_name:asc',
    getFilters: () => {
      const q = getQueryHandler<CredentialFilterTypes>()

      return [q('WHERE', 'profile_id', 'EQ', profileId)]
    },
  }),
  cacheKey: [cacheKeys.profileCredentials, profileId],
})

const columns: ColumnsType<CredentialTypes> = [
  {
    title: 'Credential',
    render: (_, credential) => (
      <CopyableDisplay
        label={
          credential.display_name || (
            <Typography.Text code>{credential.credential_id}</Typography.Text>
          )
        }
        copyableText={credential.credential_id}
      />
    ),
  },
  {
    title: 'Credential Format',
    render: (_, credential) => (
      <CopyableDisplay
        label={credential.credential_format_name}
        copyableText={credential.credential_format_id}
      />
    ),
  },
  {
    title: 'Activated / Deactivated Date',
    render: (_, credential) => (
      <>
        <DateDisplay label="Activated" date={credential.activate} />
        <DateDisplay label="Deactivated" date={credential.deactivate} />
      </>
    ),
  },
  {
    title: 'Created / Updated Date',
    render: (_, credential) => (
      <CreatedUpdatedDateDisplay
        createdAt={credential.created_at}
        updatedAt={credential.updated_at}
      />
    ),
  },
]

const StyledMirrorsContainer = styled.div`
  padding: 40px 4em;

  .ant-table-thead th {
    border: none;
  }
`
