import {
  OrganizationBrowseTypes,
  OrganizationTypes,
} from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { LiveSearchProps } from '@swiftctrl/swift-component-library'
import { BaseLiveSearch } from '../../components'
import { SWIFT_ROOT_ENTITY_ID } from '../../data/models'
import { swiftClient } from '../../data/swiftClient'
import { mapDataToSelectOptions } from '../../utils-hooks'

type OrganizationSelectProps = {
  initialValue: OrganizationTypes | undefined
  value?: OrganizationTypes | undefined
  onChange?: (value: OrganizationTypes | undefined) => void
}

export const OrganizationSelect = ({
  initialValue,
  value,
  onChange,
}: OrganizationSelectProps) => {
  const fetchOptions = async (search: string) => {
    const q = getQueryHandler<OrganizationBrowseTypes>()

    const filters = search
      ? [q('WHERE', 'entity_name', 'EQ_IC', search)]
      : undefined

    const { data } = await swiftClient.organization.browse({
      baseId: SWIFT_ROOT_ENTITY_ID,
      select: ['organization_id', 'entity_name', 'extra_json'],
      filters,
      sort: 'entity_name:asc',
    })

    if (shouldAddInitialOrganization(initialValue, data)) {
      data.push(initialValue!)
    }

    const options = mapDataToSelectOptions(
      data,
      'organization_id',
      'entity_name',
      true,
    )

    return options
  }

  const handleChange: LiveSearchProps<string>['onChange'] = (_, option) => {
    if (Array.isArray(option)) {
      // no-op because not a multi-select
      return
    }

    const organization = option.data as OrganizationTypes | undefined

    onChange?.(organization)
  }

  return (
    <BaseLiveSearch
      fetchOptions={fetchOptions}
      fetchDependencies={[]}
      value={value?.organization_id || initialValue?.organization_id || ''}
      onChange={handleChange}
      size="large"
    />
  )
}

const shouldAddInitialOrganization = (
  initialValue: OrganizationTypes | undefined,
  data: Partial<OrganizationTypes>[],
) => {
  if (!initialValue) {
    return false
  }

  const included = data.some(
    (organization) =>
      organization.organization_id === initialValue.organization_id,
  )

  return !included
}
