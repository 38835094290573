import { CredentialFieldBitfieldParity } from '@swiftctrl/api-client'
import { styled } from 'styled-components'
import { transformByteArrayToBase64String } from '../../../utils-hooks'
import { TooltipIcon } from '../TooltipIcon'
import { BitfieldPropertiesContainer } from './utils'

type BitfieldParityViewProps = {
  bitfieldParity: CredentialFieldBitfieldParity
}

export const BitfieldParityView = ({
  bitfieldParity,
}: BitfieldParityViewProps) => {
  const { parity_even, parity_bit_location, parity_mask } = bitfieldParity

  return (
    <BitfieldPropertiesContainer>
      bitfield_parity
      <TooltipIcon message="Due to the format field constraints, the bitfield parity can't be modified" />
      <StyledSpan>
        parity_even: {parity_even ? 'true' : 'false'} - {parity_bit_location} -{' '}
        {transformByteArrayToBase64String(parity_mask)}
      </StyledSpan>
    </BitfieldPropertiesContainer>
  )
}

const StyledSpan = styled.span`
  margin-left: calc(
    ${({ theme }) => theme.spacing.xxLarge} -
      ${({ theme }) => theme.spacing.medium}
  );
`
