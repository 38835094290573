import { Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { TABLE_CSS } from '../../../data/models'

type RoleSubDataTableHeaderProps = {
  label: string
}

export const RoleSubDataTableHeader = ({
  label,
}: RoleSubDataTableHeaderProps) => {
  return (
    <HeaderContainer>
      <Typography.Text>{label}</Typography.Text>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  background-color: #fafafa;
  height: ${TABLE_CSS.HEADER_HEIGHT};
  border-bottom: ${TABLE_CSS.BORDER};
  padding: 16px;
  font-weight: 500;
`
