import {
  Button,
  InfoCircleFilled,
  Layout,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'

type FieldNameTooltipProps = {
  fieldName: string
  onResetClick: () => void
}

export const FieldNameTooltip = ({
  fieldName,
  onResetClick,
}: FieldNameTooltipProps) => {
  return (
    <Tooltip
      title={
        <StyledLayout>
          Field Name has been changed from its original value in the Core
          Credential Format.
          <StyledResetButton size="small" onClick={onResetClick}>
            Reset to "{fieldName}"
          </StyledResetButton>
        </StyledLayout>
      }
    >
      <StyledInfoIcon />
    </Tooltip>
  )
}

const StyledLayout = styled(Layout)`
  gap: ${({ theme }) => theme.spacing.xSmall};
`

const StyledResetButton = styled(Button)`
  align-self: center;
`

const StyledInfoIcon = styled(InfoCircleFilled)`
  color: var(--ant-info-color);
  cursor: pointer;
`
