import { CredentialFieldConstraint } from '@swiftctrl/api-client'
import { RemoveConstraintButton } from '../RemoveConstraintButton'
import { BitfieldValue, Constraint } from '../utils'
import { IntegerConstraintTypeView } from './IntegerConstraintTypeView'
import { IntegerRangeInput } from './IntegerRangeInput'
import { IntegerConstraintContainer } from './utils'

type TemplateIntegerRangeViewProps = {
  templateConstraint: Constraint
  templateBitfieldValue: BitfieldValue | null | undefined
  formatConstraint: CredentialFieldConstraint | undefined

  onChange: (constraint: Constraint | null) => void
}

export const TemplateIntegerRangeView = ({
  templateConstraint,
  templateBitfieldValue,
  formatConstraint,
  onChange,
}: TemplateIntegerRangeViewProps) => {
  const { start, end } = templateConstraint.integer_range!

  return (
    <IntegerConstraintContainer>
      <IntegerConstraintTypeView
        value="integer_range"
        templateConstraint={templateConstraint}
        templateBitfieldValue={templateBitfieldValue}
        formatConstraint={formatConstraint}
        onChange={onChange}
      />
      <IntegerRangeInput
        startDefaultValue={start}
        endDefaultValue={end}
        onChange={(start, end) => {
          onChange({
            integer_range: { start, end },
            date_range: null,
            valid_values: null,
          })
        }}
      />
      <RemoveConstraintButton onClick={() => onChange(null)} />
    </IntegerConstraintContainer>
  )
}
