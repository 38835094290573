import { EntityType } from '../data/models'

export const getEntityDescriptionKey = (entityType: EntityType) => {
  switch (entityType) {
    case 'credential_template':
      return 'template_description'

    default:
      return 'entity_description'
  }
}
