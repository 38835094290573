import { PersonTypes, RolesTypes } from '@swiftctrl/api-client'
import { ColumnsType } from '@swiftctrl/swift-component-library'
import { PropsWithChildren } from 'react'
import { CopyableLink, EntitiesTableBrowser } from '../../../components'
import { ROLES_SCREEN_PATH } from '../../../config/paths'
import { swiftClientWithoutErrorHandling } from '../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  FETCH_ALL_DATA,
} from '../../../states'
import {
  buildAxiosResponse,
  cacheKeys,
  getAllFromBrowseRequest,
} from '../../../utils-hooks'
import { RoleActionsDropdown } from './RoleActionsDropdown'

type RolesBrowserProps = PropsWithChildren<{
  person: PersonTypes
}>

export const RolesBrowser = ({ person, children }: RolesBrowserProps) => {
  const browse = buildBrowse(person)

  const columns = buildColumns(person)

  return (
    <BrowseEntitiesContextProvider
      dataPerFetch={FETCH_ALL_DATA}
      browse={browse}
      dataKey="role_id"
      entityType="role"
    >
      <EntitiesTableBrowser columns={columns} />
      {children}
    </BrowseEntitiesContextProvider>
  )
}

const buildBrowse = (
  person: PersonTypes,
): BrowseEntitiesConfig<RolesTypes> => ({
  request: async ({ signal }) => {
    const { profile_ids } = person

    const roles = new Array<Partial<RolesTypes>>()

    for (let i = 0; i < profile_ids.length; i++) {
      const profileId = profile_ids[i]

      const profileRoles = await getAllFromBrowseRequest((offset, limit) =>
        swiftClientWithoutErrorHandling.profile.at(profileId).role.browse(
          {
            offset,
            limit,
            select: ['role_id', 'entity_name'],
            sort: 'entity_name:asc',
          },
          { signal },
        ),
      )

      roles.push(...profileRoles)
    }

    const response = buildAxiosResponse(roles)

    return response
  },
  cacheKey: [cacheKeys.person_roles, person.person_id],
})

const buildColumns = (
  person: PersonTypes,
): ColumnsType<Partial<RolesTypes>> => [
  {
    title: 'Role',
    render: (_, role) => (
      <CopyableLink
        label={role.entity_name}
        copyableText={role.role_id}
        to={`/${ROLES_SCREEN_PATH}/${role.role_id}`}
      />
    ),
    width: '100%',
  },
  {
    key: 'action',
    render: (_, role) => <RoleActionsDropdown role={role} person={person} />,
  },
]
