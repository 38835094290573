import { lowerFirst } from 'lodash'

export type PluralLabelConfig = {
  singular: string
  plural: string
  isFirstLetterAlwaysCapitalized?: boolean
}

/**
 * Builds a `PluralLabelConfig` from the `formattedLabel`.
 *
 * E.g. for `formattedLabel` `'Organization(s)'`, the result is a config object
 * with fields `singular: 'Organization'`, `plural: 'Organizations'`,
 * and `isFirstLetterAlwaysCapitalized` according to the corresponding argument.
 */
export const buildPluralLabelConfig = (
  formattedLabel: string,
  isFirstLetterAlwaysCapitalized: boolean = false,
): PluralLabelConfig => {
  const pluralStartTokenIndex = formattedLabel.indexOf('(')

  const pluralEndTokenIndex = formattedLabel.indexOf(')')

  const singular = formattedLabel.substring(0, pluralStartTokenIndex)

  const plural =
    singular +
    formattedLabel.substring(pluralStartTokenIndex + 1, pluralEndTokenIndex)

  return {
    singular,
    plural,
    isFirstLetterAlwaysCapitalized,
  }
}

export const getPluralLabel = ({
  labels,
  singular = true,
  capitalize = true,
}: {
  labels: PluralLabelConfig
  /**
   * Defaults to true
   */
  singular?: boolean
  /**
   * Defaults to true
   */
  capitalize?: boolean
}) => {
  const label = singular ? labels.singular : labels.plural

  if (capitalize) {
    return label
  }

  if (labels.isFirstLetterAlwaysCapitalized) {
    return label
  }

  return lowerFirst(label)
}

export const getPluralLabelByCount = ({
  labels,
  count,
  capitalize = true,
}: {
  labels: PluralLabelConfig
  count: number
  /**
   * Defaults to true
   */
  capitalize?: boolean
}) =>
  getPluralLabel({
    labels,
    singular: count === 1,
    capitalize,
  })

export const permissionPluralLabelConfig =
  buildPluralLabelConfig('Permission(s)')

export const archetypePluralLabelConfig = buildPluralLabelConfig('Archetype(s)')

export const targetPluralLabelConfig = buildPluralLabelConfig('Target(s)')
