import { EpiTypes } from '@swiftctrl/api-client'
import { Typography } from '@swiftctrl/swift-component-library'
import { BooleanDisplay } from '../../../data-display'

type OnPremisesDisplayProps = {
  epi: EpiTypes
}

export const OnPremisesDisplay = ({ epi }: OnPremisesDisplayProps) => (
  <>
    <Typography.Text strong>On-premises: </Typography.Text>
    <BooleanDisplay value={epi.on_premises} />
  </>
)
