import {
  CredentialFieldValidValue,
  CredentialTemplateFieldValidValue,
} from '@swiftctrl/api-client'
import {
  Checkbox,
  CheckboxChangeEvent,
  Layout,
  Modal,
  Typography,
} from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { styled } from 'styled-components'

type PresetValidValuesEditorModalProps = {
  open: boolean
  templateValidValues: CredentialTemplateFieldValidValue[]
  formatValidValues: CredentialFieldValidValue[]
  onClose: () => void
  onSave: (values: CredentialTemplateFieldValidValue[]) => void
}

export const PresetValidValuesEditorModal = ({
  open,
  templateValidValues,
  formatValidValues,
  onClose,
  onSave,
}: PresetValidValuesEditorModalProps) => {
  const buildInitialIncludedValues = () => {
    const initialIncludedValues = new Set(
      templateValidValues.map((entry) => entry.value),
    )

    return initialIncludedValues
  }

  const [includedValues, setIncludedValues] = useState(
    buildInitialIncludedValues(),
  )

  const updateIncludedValues = (value: string | number) => {
    const onChange = (event: CheckboxChangeEvent) => {
      if (event.target.checked) {
        setIncludedValues((previous) => {
          if (previous.has(value)) {
            return previous
          }

          const update = new Set(previous)

          update.add(value)

          return update
        })
      } else {
        setIncludedValues((previous) => {
          if (!previous.has(value)) {
            return previous
          }

          const update = new Set(previous)

          update.delete(value)

          return update
        })
      }
    }

    return onChange
  }

  const handleSave = () => {
    const validValues = formatValidValues.reduce((validValues, entry) => {
      const { value } = entry

      if (includedValues.has(value)) {
        validValues.push(entry)
      }

      return validValues
    }, new Array<CredentialTemplateFieldValidValue>())

    onSave(validValues)
  }

  const resetAndClose = () => {
    setIncludedValues(buildInitialIncludedValues())

    onClose()
  }

  const isValid = includedValues.size > 0

  return (
    <Modal
      title="Values"
      open={open}
      onCancel={resetAndClose}
      okText="Save"
      okButtonProps={{
        disabled: !isValid,
      }}
      onOk={handleSave}
      centered
      destroyOnClose
    >
      <Layout spacing="small">
        {formatValidValues.map((entry) => {
          const { value } = entry

          return (
            <ValidValueContainer key={value}>
              <Checkbox
                checked={includedValues.has(value)}
                onChange={updateIncludedValues(value)}
              >
                {value}
              </Checkbox>
            </ValidValueContainer>
          )
        })}
        {!isValid && (
          <Typography.Text type="danger">
            There must be at least one valid value
          </Typography.Text>
        )}
      </Layout>
    </Modal>
  )
}

const ValidValueContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.small};
`
