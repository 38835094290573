import { Button, Form } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import styled from 'styled-components'
import { buildFormItemProps, SINK_TEMPLATE_ID_FIELD_NAME } from './addSinkUtils'
import { SinkTemplateSelect } from './SinkTemplateSelect'
import { SinkTemplateVariableFields } from './SinkTemplateVariableFields'

type AddSinkFormProps = {
  isPending: boolean
  onDirty: () => void
  onSubmit: (values: any) => void
  onClose: () => void
}

export const AddSinkForm = ({
  isPending,
  onDirty,
  onSubmit,
  onClose,
}: AddSinkFormProps) => {
  const [sinkTemplateId, setSinkTemplateId] = useState('')

  return (
    <Form
      layout="vertical"
      preserve={false}
      onFieldsChange={onDirty}
      onFinish={onSubmit}
      disabled={isPending}
    >
      <Form.Item
        {...buildFormItemProps(SINK_TEMPLATE_ID_FIELD_NAME, 'Sink template')}
      >
        <SinkTemplateSelect onChange={setSinkTemplateId} />
      </Form.Item>
      {sinkTemplateId && (
        <SinkTemplateVariableFields sinkTemplateId={sinkTemplateId} />
      )}
      <ButtonGroup>
        <Button type="text" onClick={onClose}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={isPending}>
          Add
        </Button>
      </ButtonGroup>
    </Form>
  )
}

const ButtonGroup = styled.div`
  display: flex;
  justify-content: end;
  gap: 1em;
`
