import { RolesTypes } from '@swiftctrl/api-client'
import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { List } from 'antd'
import { styled } from 'styled-components'
import {
  AddButtonOrCheckmarkDisplay,
  ManageAssignedDataInfiniteList,
} from '../../../../components'

type CandidateRolesListProps = {
  data: Partial<RolesTypes>[]
  isFetching: boolean
  isFetchingFirstTime: boolean
  fetchNextPage: () => void
  selectedRoleIds: Set<string>
  onAddRole: (role: Partial<RolesTypes>) => void
  isSaving: boolean
}

export const CandidateRolesList = ({
  data,
  isFetching,
  isFetchingFirstTime,
  fetchNextPage,
  selectedRoleIds,
  onAddRole,
  isSaving,
}: CandidateRolesListProps) => {
  return (
    <ManageAssignedDataInfiniteList
      header="Role list"
      data={data}
      isFetching={isFetching}
      isFetchingFirstTime={isFetchingFirstTime}
      fetchNextPage={fetchNextPage}
      renderItem={(role) => (
        <List.Item key={role.role_id}>
          <StyledLayout
            horizontal
            justifyContent="space-between"
            alignItems="center"
          >
            <Layout grow>
              {role.entity_name}
              <Typography.Text type="secondary">
                {role.entity_description}
              </Typography.Text>
            </Layout>
            <AddButtonOrCheckmarkDisplay
              showAddButton={!selectedRoleIds.has(role.role_id!)}
              addButtonDisabled={isSaving}
              onAdd={() => onAddRole(role)}
            />
          </StyledLayout>
        </List.Item>
      )}
    />
  )
}

const StyledLayout = styled(Layout)`
  flex: 1;
`
