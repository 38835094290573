import { Button, DeleteOutlined } from '@swiftctrl/swift-component-library'

type RemoveConstraintButtonProps = {
  onClick: () => void
}

export const RemoveConstraintButton = ({
  onClick,
}: RemoveConstraintButtonProps) => {
  return (
    <Button icon={<DeleteOutlined />} type="link" onClick={onClick} danger />
  )
}
