import { ManagedSpacesTypes } from '@swiftctrl/api-client'
import { Select } from '@swiftctrl/swift-component-library'
import { mapDataToSelectOptions } from '../../../utils-hooks'

type BuildingsSelectProps = {
  buildings: Partial<ManagedSpacesTypes>[]
  multiple: boolean
  allowClear: boolean
  value?: any
  onChange?: (value: any) => void
}

export const BuildingsSelect = ({
  buildings,
  multiple,
  allowClear,
  value,
  onChange,
}: BuildingsSelectProps) => {
  const options = mapDataToSelectOptions(buildings, 'space_id', 'name')

  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      mode={multiple ? 'multiple' : undefined}
      allowClear={allowClear}
    />
  )
}
