import { EntityTypes } from '@swiftctrl/api-client'
import { Button, PlusOutlined } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { getEntityLabel } from '../../config/labels'
import { SWIFT_ROOT_ENTITY_ID } from '../../data/models'
import {
  errorHandler,
  isCanceledError,
  isNotFoundError,
} from '../../data/swiftClient'
import { useBrowseEntitiesContext } from '../../states'
import {
  isEntityTypeConfiguredForAdding,
  showNoEntityParamErrorNotification,
  useBoolean,
} from '../../utils-hooks'
import { AddEntityView, EntitySelect } from '../add-entities'
import { EntitiesBrowserStyles } from './EntitiesBrowser'
import { EntitiesBrowserFilterLabel } from './EntitiesBrowserFilterLabel'
import { EntitiesBrowserSearchInput } from './EntitiesBrowserSearchInput'
import { BROWSER_COMPONENTS_Z_INDEXES } from './EntitiesTableBrowser'

export const ENTITIES_BROWSER_TOP_BAR_HEIGHT = '46px'

const EXPANDED_AREA_BACKGROUND_COLOUR = '#fafafa'

export const EntitiesBrowserTopBar = ({
  background,
  nested,
}: EntitiesBrowserStyles) => {
  const {
    setSearch: setContextSearch,
    searchable,
    initialSearch,
    entityType,
    displayAddButton: displayAddButtonProp,
    addDataTemplate,
    onAddButtonClick,
    invalidateData,
    baseId,
    relatedEntityId,
    baseIdFilterType,
    relatedEntityIdFilterType,
    handleBaseIdFilterChange: contextHandleBaseIdFilterChange,
    handleRelatedEntityIdFilterChange: contextHandleRelatedEntityIdFilterChange,
    baseIdParamKey,
    relatedEntityIdFilterParamKey,
    relatedEntityLabel,
  } = useBrowseEntitiesContext()

  const [addEntityViewOpen, openAddEntityView, closeAddEntityView] =
    useBoolean()

  const handleBaseIdFilterChange = (entity: EntityTypes | undefined) => {
    contextHandleBaseIdFilterChange(entity?.entity_id || '')
  }

  const handleRelatedEntityIdFilterChange = (
    entity: EntityTypes | undefined,
  ) => {
    contextHandleRelatedEntityIdFilterChange(entity?.entity_id || '')
  }

  const hasBaseIdFilter =
    baseIdFilterType && Boolean(contextHandleBaseIdFilterChange)

  const hasRelatedEntityIdFilter =
    relatedEntityIdFilterType &&
    Boolean(contextHandleRelatedEntityIdFilterChange)

  const canAddEntity = isEntityTypeConfiguredForAdding(entityType)

  const displayAddButton =
    Boolean(displayAddButtonProp) && (canAddEntity || Boolean(onAddButtonClick))

  const handleEntityAdded = () => {
    closeAddEntityView()

    invalidateData()
  }

  return (
    <StyledEntitiesBrowserTopBar
      $nested={nested}
      $background={background}
      $displayAddButton={displayAddButton}
    >
      <EntitiesBrowserTopBarSearchAndFilterGroup>
        {searchable && (
          <EntitiesBrowserSearchInput
            initialSearch={initialSearch}
            onDebouncedChange={setContextSearch}
          />
        )}
        {(hasBaseIdFilter || hasRelatedEntityIdFilter) && (
          <EntitiesBrowserFilterLabel />
        )}
        {hasBaseIdFilter && (
          <EntitiesBrowserTopBarFilterContainer>
            <EntitySelect
              key={baseId}
              onChange={handleBaseIdFilterChange}
              entityType={baseIdFilterType}
              excludeSwiftRoot={true}
              placeholder={getEntityLabel({ entityType: baseIdFilterType })}
              defaultEntityId={baseId !== SWIFT_ROOT_ENTITY_ID ? baseId : ''}
              onDefaultEntityError={(error) => {
                if (isCanceledError(error)) {
                  return
                }

                if (!isNotFoundError(error)) {
                  errorHandler(error)

                  return
                }

                showNoEntityParamErrorNotification(baseIdParamKey)

                contextHandleBaseIdFilterChange('')
              }}
            />
          </EntitiesBrowserTopBarFilterContainer>
        )}
        {hasRelatedEntityIdFilter && (
          <EntitiesBrowserTopBarFilterContainer>
            <EntitySelect
              key={relatedEntityId}
              onChange={handleRelatedEntityIdFilterChange}
              baseId={baseId}
              entityType={relatedEntityIdFilterType}
              placeholder={
                relatedEntityLabel ||
                getEntityLabel({
                  entityType: relatedEntityIdFilterType,
                })
              }
              defaultEntityId={relatedEntityId || ''}
              onDefaultEntityError={(error) => {
                if (isCanceledError(error)) {
                  return
                }

                if (!isNotFoundError(error)) {
                  errorHandler(error)

                  return
                }

                showNoEntityParamErrorNotification(
                  relatedEntityIdFilterParamKey,
                )

                contextHandleRelatedEntityIdFilterChange('')
              }}
            />
          </EntitiesBrowserTopBarFilterContainer>
        )}
      </EntitiesBrowserTopBarSearchAndFilterGroup>
      {displayAddButton && (
        <Button
          onClick={onAddButtonClick || openAddEntityView}
          type="primary"
          icon={<PlusOutlined />}
          size="middle"
        >
          {`Add ${getEntityLabel({
            entityType,
            capitalize: false,
          })}`}
        </Button>
      )}

      {displayAddButton && canAddEntity && (
        <AddEntityView
          open={addEntityViewOpen}
          entityType={entityType}
          addDataTemplate={addDataTemplate}
          onEntityAdded={handleEntityAdded}
          onClose={closeAddEntityView}
          overseerId={getAddEntityViewOverseerId(baseId)}
        />
      )}
    </StyledEntitiesBrowserTopBar>
  )
}

export const EntitiesBrowserTopBarFilterContainer = styled.div`
  width: 200px;
`

export const StyledEntitiesBrowserTopBar = styled.div<{
  $nested?: boolean
  $background?: boolean
  $displayAddButton?: boolean
}>`
  position: sticky;
  top: ${({ $nested }) =>
    $nested ? `calc(${ENTITIES_BROWSER_TOP_BAR_HEIGHT} + 52px)` : '0px'};
  z-index: ${({ $nested }) => {
    const {
      browserTopBar: { defaultValue, nested },
    } = BROWSER_COMPONENTS_Z_INDEXES
    return $nested ? nested : defaultValue
  }};
  background-color: ${({ $background, $nested }) =>
    $background
      ? '#fff'
      : $nested
      ? EXPANDED_AREA_BACKGROUND_COLOUR
      : 'inherit'};
  padding: ${({ $nested, $displayAddButton }) => {
    if ($nested && !$displayAddButton) {
      return '0px'
    }

    return '0 0 1em'
  }};

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const EntitiesBrowserTopBarSearchAndFilterGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`

/**
 * If the `overseerId` is omitted, then the `AddEntityView` displays a
 * select so that the user can find and set an overseer.
 *
 * Within the `BrowseEntitiesContext`, `baseId` gets a default value of
 * `SWIFT_ROOT_ENTITY_ID`, so we need to check it against that in
 * order to determine whether to omit the `overseerId`.
 */
const getAddEntityViewOverseerId = (baseId: string) =>
  baseId !== SWIFT_ROOT_ENTITY_ID ? baseId : undefined
