import {
  ExportOutlined,
  Layout,
  Typography,
} from '@swiftctrl/swift-component-library'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  ENTITIES_SCREEN_PATH,
  ENTITIES_SCREEN_TREE_TAB,
} from '../../config/paths'
import { isIncludedInEntityTree } from '../../data/EntityTreeData'
import { EntityType } from '../../data/models'
import {
  CopyableDisplay,
  EntityTimestampsDisplay,
  LabelDisplay,
} from '../data-display'
import { ExtraJsonButton } from '../ExtraJsonButton'
import { ActionsDropdown, ActionsDropdownItem } from '../menu'
import { BreadCrumbItem, HeaderBreadCrumbs } from './HeaderBreadCrumbs'

interface InfoItem {
  title: string
  value?: ReactNode
  copyableText?: string | number
}

export interface HeaderContentProps {
  entityId: string
  entityType: EntityType
  infoItemsLeft: InfoItem[]
  entityName?: string
  entityDescription?: string
  infoItemsRight?: InfoItem[]
  createdAt?: string
  updatedAt?: string
  actionDropDownsItems?: ActionsDropdownItem[]
  breadCrumbPath?: string | BreadCrumbItem[]
  isBreadcrumbLoading?: boolean
  leftContent?: ReactNode
  extraJson?: any
  extraButton?: ReactNode
}

export const HeaderContent = ({
  entityName,
  entityId,
  entityDescription,
  leftContent,
  infoItemsLeft,
  infoItemsRight,
  createdAt,
  updatedAt,
  actionDropDownsItems: items,
  breadCrumbPath,
  isBreadcrumbLoading,
  entityType,
  extraJson,
  extraButton,
}: HeaderContentProps) => {
  const nameDisplay = (
    <CopyableDisplay label={entityName} copyableText={entityId} title />
  )

  return (
    <Layout spacing="medium" paddingBottom="medium">
      <HeaderBreadCrumbs
        entityType={entityType}
        isBreadcrumbLoading={isBreadcrumbLoading}
        breadCrumbPath={breadCrumbPath}
      />
      <StyledLayout
        horizontal
        grow
        alignItems="center"
        justifyContent="space-between"
      >
        <Layout grow>
          {isIncludedInEntityTree(entityType) ? (
            <Layout horizontal alignItems="center" spacing="xMedium">
              {nameDisplay}
              <StyledLink
                to={`/${ENTITIES_SCREEN_PATH}/${ENTITIES_SCREEN_TREE_TAB}?entity-id=${entityId}`}
                target="_blank"
              >
                <ExportOutlined />
                Open in tree
              </StyledLink>
            </Layout>
          ) : (
            nameDisplay
          )}
          {entityDescription && (
            <Typography.Text type="secondary">
              {entityDescription}
            </Typography.Text>
          )}
        </Layout>
        <Layout horizontal spacing="small" alignItems="center">
          <ExtraJsonButton extraJson={extraJson} />
          {extraButton}
          {items && <ActionsDropdown items={items} />}
        </Layout>
      </StyledLayout>
      <StyledLayoutParent
        horizontal
        justifyContent="space-between"
        alignItems="end"
      >
        <Layout grow horizontal spacing="medium">
          {leftContent}
          <Layout grow spacing="small" horizontal>
            <StyledInfoLayoutLeft spacing="xSmall" paddingRight="xxLarge">
              {infoItemsLeft.map((item) => (
                <InfoContainer key={item.title} {...item} />
              ))}
            </StyledInfoLayoutLeft>
            {infoItemsRight && (
              <StyledInfoLayoutRight>
                <Layout spacing="xSmall" paddingLeft="xxLarge">
                  {infoItemsRight.map((item) => (
                    <InfoContainer key={item.title} {...item} />
                  ))}
                </Layout>
              </StyledInfoLayoutRight>
            )}
          </Layout>
        </Layout>
        {(createdAt || updatedAt) && (
          <div>
            <EntityTimestampsDisplay
              created_at={createdAt!}
              updated_at={updatedAt!}
            />
          </div>
        )}
      </StyledLayoutParent>
    </Layout>
  )
}

const StyledInfoLayoutLeft = styled(Layout)`
  flex: initial;
`

const StyledInfoLayoutRight = styled(Layout)`
  flex: initial;
  border-left: solid 1px #f0f0f0;
`

const StyledLayoutParent = styled(Layout)`
  width: 100%;
`

const StyledLayout = styled(Layout)`
  h4 {
    font-weight: 400;
    margin-bottom: 0px;
  }
`

const InfoContainer = ({ title, value, copyableText }: InfoItem) => {
  if (value === undefined || value === null || value === '') {
    return null
  }

  let displayValue
  if (
    (typeof value === 'string' || typeof value === 'number') &&
    copyableText !== undefined
  ) {
    displayValue = (
      <CopyableDisplay
        label={String(value)}
        copyableText={String(copyableText)}
      />
    )
  } else if (typeof value === 'boolean') {
    displayValue = <Typography.Text>{String(value)}</Typography.Text>
  } else {
    displayValue = value
  }

  return (
    <Typography.Text>
      <LabelDisplay label={title} />
      {displayValue}
    </Typography.Text>
  )
}

const StyledLink = styled(Link)`
  display: flex;
  gap: 8px;
`
