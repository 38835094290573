import { FIELDS, RoleOperationTypes } from '@swiftctrl/api-client'
import { useQuery } from '@tanstack/react-query'
import { queryClient } from '../../../../data/queryClient'
import { swiftClient } from '../../../../data/swiftClient'
import { cacheKeys, getAllFromBrowseRequest } from '../../../../utils-hooks'

export const useGetRoleOperations = (roleId: string) =>
  useQuery({
    queryKey: getQueryKey(roleId),
    queryFn: ({ signal }) =>
      getAllFromBrowseRequest((offset, limit) =>
        swiftClient.role.at(roleId).operations.browse(
          {
            select: FIELDS.RoleOperationBrowse,
            offset,
            limit,
          },
          {
            signal,
          },
        ),
      ) as Promise<RoleOperationTypes[]>,
  })

const getQueryKey = (roleId: string) => [cacheKeys.role_operations, roleId]

export const invalidateGetRoleOperationsQuery = (roleId: string) => {
  queryClient.invalidateQueries({ queryKey: getQueryKey(roleId) })
}
