import { AxiosResponse } from '@swiftctrl/api-client'
import { EntityType } from '../data/models'
import { swiftClientWithoutErrorHandling } from '../data/swiftClient'

type DeleteEntityConfig = {
  [entityType in EntityType]?: (entityId: string) => Promise<AxiosResponse>
}

const deleteEntityConfig: DeleteEntityConfig = {
  credential_template: (id) =>
    swiftClientWithoutErrorHandling.credentialTemplate.at(id).delete(),
  provider: (providerId) =>
    swiftClientWithoutErrorHandling.provider.at(providerId).delete(),
}

export const hasDeleteRequest = (entityType: EntityType) =>
  entityType in deleteEntityConfig

export const getDeleteRequest = (entityType: EntityType) => {
  const deleteRequest = deleteEntityConfig[entityType]

  if (!deleteRequest) {
    throw new Error(
      `delete request for entity type ${entityType} is being obtained without first checking if it exists. This is a developer error - please let your friendly ConfigCloud team know about this 🧑‍💻`,
    )
  }

  return deleteRequest
}
