import {
  EpisBrowser,
  HTMLTitle,
  StyledEntitiesBrowserScreen,
} from '../components'

export const EpisScreen = () => (
  <StyledEntitiesBrowserScreen>
    <HTMLTitle value="EPIs" />
    <EpisBrowser />
  </StyledEntitiesBrowserScreen>
)
