import { DataNode } from 'antd/lib/tree'

export const getEntitiesWithChildren = (entities: DataNode[]): string[] => {
  const ids: string[] = []

  for (const entity of entities) {
    if (entity.children && entity.children.length > 0) {
      ids.push(entity.key as string)

      ids.push(...getEntitiesWithChildren(entity.children))
    }
  }
  return ids
}
