import { styled } from 'styled-components'
import { TagButtonGroup, TagButtonGroupProps } from '../../../components'

interface RuleIdFiltersProps {
  options: RuleFilterOption[]
  onChange: (rule: RuleFilterOption) => void
  selectedId: string
}

export type RuleFilterOption = {
  ruleId: string
  ruleName: string
  count: number
}

export const RuleIdFilters = ({
  options,
  onChange,
  selectedId,
}: RuleIdFiltersProps) => {
  const handleChange = (activeKey: string) => {
    const option = options.find((option) => option.ruleId === activeKey)!

    onChange(option)
  }

  const items: TagButtonGroupProps['items'] = options.map((option) => ({
    key: option.ruleId,
    label: `${option.ruleName} (${option.count})`,
  }))

  return (
    <StyledTagButtonGroup
      items={items}
      activeKey={selectedId}
      onChange={handleChange}
    />
  )
}

const StyledTagButtonGroup = styled(TagButtonGroup)`
  margin-bottom: ${({ theme }) => theme.spacing.xMedium};
`
