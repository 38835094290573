import { Select, SelectProps } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'

type DefaultValueSelectProps = {
  options: string[]
  isValid?: boolean
  defaultValue: string | null
  onChange: (value: string) => void
}

export const DefaultValueSelect = ({
  options: optionsProp,
  defaultValue,
  isValid = true,
  onChange,
}: DefaultValueSelectProps) => {
  const options: SelectProps['options'] = optionsProp.map((option) => ({
    label: option,
    value: option,
  }))

  const handleChange = (value: any) => {
    onChange(value || '')
  }

  return (
    <StyledSelect
      options={options}
      defaultValue={defaultValue}
      $isValid={isValid}
      onChange={handleChange}
      size="small"
      bordered={false}
      showArrow={false}
      showLabel={false}
      allowClear
      dropdownMatchSelectWidth={false}
      showSearch
    />
  )
}

const StyledSelect = styled(Select)<{ $isValid: boolean }>`
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0px;
  }

  .ant-select-clear {
    right: 0px;
  }

  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selection-search {
    left: 0px;
    right: 0px;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
    .ant-select-selector {
    border-color: transparent !important;
  }

  .ant-select-selection-item {
    color: ${({ $isValid }) => ($isValid ? '#000' : 'var(--ant-error-color)')};
  }
`
