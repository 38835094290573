import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import { RestApiParams, RestApiParamsKeys } from '../types'

interface UpdateParamsConfig {
  replace: boolean
}
interface UseHandlerUrlTypes {
  updateParams: (params: RestApiParams, config?: UpdateParamsConfig) => void
  cleanParams: (paramsToDelete: Array<RestApiParamsKeys>) => void
  currentParams: RestApiParams
}

export const useHandleUrlParams = (parseNumbers = true): UseHandlerUrlTypes => {
  const navigate = useNavigate()

  const location = useLocation()

  const { stringify, parse } = queryString

  const parseCriteria = (criteria: string) =>
    parse(criteria, { parseNumbers, sort: false })

  const criteria = parseCriteria(location.search)

  const updateParams = (
    newParams: RestApiParams,
    config: UpdateParamsConfig = { replace: true },
  ): void => {
    if (isEmpty(newParams)) {
      return
    }

    const newCriteria = { ...criteria, ...newParams }
    if (isEqual(newCriteria, criteria)) {
      return
    }
    const search = stringify(newCriteria, { skipEmptyString: true })
    if (config?.replace) {
      navigate({ search }, { replace: true })
    } else {
      navigate({ search })
    }
  }

  const cleanParams = (paramsToDelete?: Array<RestApiParamsKeys>): void => {
    let newCriteria = {}
    if (paramsToDelete && paramsToDelete.length > 0) {
      newCriteria = { ...omit(criteria, paramsToDelete) }

      navigate({ search: stringify(newCriteria) })
    } else {
      navigate({ search: stringify(newCriteria) })
    }
  }

  return {
    updateParams,
    cleanParams,
    currentParams: criteria,
  }
}
