import {
  FileSearchOutlined,
  PreviewOutlined,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import { Link } from 'react-router-dom'
import { ENTITIES_SCREEN_PATH } from '../../config/paths'
import {
  getReadEntityScreenPath,
  hasReadEntityScreenPath,
} from '../../config/readEntityScreenConfig'
import { EntityType } from '../../data/models'

type ReadScreenLinkProps = {
  entityType: EntityType
  entityId: string
}

export const ReadScreenLink = ({
  entityType,
  entityId,
  ...props
}: ReadScreenLinkProps) => {
  const hasDetailsScreen = hasReadEntityScreenPath(entityType)

  const basePath = hasDetailsScreen
    ? getReadEntityScreenPath(entityType)
    : ENTITIES_SCREEN_PATH

  const path = `/${basePath}/${entityId}`

  return (
    <Tooltip title="View details" {...props}>
      <Link to={path} target="_blank">
        {hasDetailsScreen ? <FileSearchOutlined /> : <PreviewOutlined />}
      </Link>
    </Tooltip>
  )
}
