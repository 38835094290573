import { InputNumber } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { parseToStringOrUndefined } from '../../utils-hooks'
import {
  CredentialTemplateFieldComponentProps,
  CredentialTemplateFieldFormItem,
  getCredentialTemplateFieldNumberInputMax,
  getCredentialTemplateFieldNumberInputMin,
} from './utils'

export const CredentialTemplateFieldNumberInput = ({
  field,
}: CredentialTemplateFieldComponentProps) => {
  const { placeholder, default_value, constraint, bitfield_value, read_only } =
    field

  const defaultValue = getDefaultValue(default_value)

  const min = getCredentialTemplateFieldNumberInputMin(
    constraint,
    bitfield_value,
  )

  const max = getCredentialTemplateFieldNumberInputMax(
    constraint,
    bitfield_value,
  )

  return (
    <CredentialTemplateFieldFormItem field={field}>
      <StyledInputNumber
        type="number"
        placeholder={placeholder || undefined}
        defaultValue={defaultValue}
        min={parseToStringOrUndefined(min)}
        max={parseToStringOrUndefined(max)}
        controls={false}
        disabled={read_only}
        stringMode
      />
    </CredentialTemplateFieldFormItem>
  )
}

const getDefaultValue = (default_value: string | null) => {
  if (!default_value) {
    return undefined
  }

  const parsedValue = Number(default_value)

  if (isNaN(parsedValue)) {
    return undefined
  }

  return parsedValue
}

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`
