import { ProviderProfileTypes, ProviderTypes } from '@swiftctrl/api-client'
import { useReadProviderProfile } from '../../../utils-hooks'
import { EditEntityDrawer } from '../EditEntityDrawer'
import { useEditProviderProfileLogic } from './useEditProviderProfileLogic'

type EditProviderProfileViewProps = {
  open: boolean
  providerProfile: Partial<ProviderProfileTypes>
  provider: ProviderTypes
  onEdited: () => void
  onClose: () => void
}

export const EditProviderProfileView = ({
  open,
  providerProfile,
  provider,
  onEdited,
  onClose,
}: EditProviderProfileViewProps) => {
  const { data } = useReadProviderProfile(
    providerProfile.provider_profile_id!,
    open,
  )

  if (!data) {
    return null
  }

  return (
    <Content
      open={open}
      providerProfile={data.data}
      provider={provider}
      onEdited={onEdited}
      onClose={onClose}
    />
  )
}

const Content = ({
  open,
  providerProfile,
  provider,
  onEdited,
  onClose,
}: {
  open: boolean
  providerProfile: ProviderProfileTypes
  provider: ProviderTypes
  onEdited: () => void
  onClose: () => void
}) => {
  const props = useEditProviderProfileLogic({
    providerProfile,
    provider,
    onEdited,
    onClose,
  })

  return (
    <EditEntityDrawer open={open} entityType="provider_profile" {...props} />
  )
}
