import { FormItemProps } from 'antd'
import styled from 'styled-components'

export const buildFieldInputErrorProps = (
  errorMessages: string[] | undefined,
): Partial<FormItemProps> => {
  if (!errorMessages || errorMessages.length === 0) {
    return {}
  }

  return {
    validateStatus: 'error',
    hasFeedback: true,
    help: (
      <>
        {errorMessages.map((error) => (
          <StyledErrorMessage key={error}>{error}</StyledErrorMessage>
        ))}
      </>
    ),
  }
}

const StyledErrorMessage = styled.p`
  margin-bottom: 0;
`
