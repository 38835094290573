import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter } from 'react-router-dom'
import { FullScreenContainer, UserAuthorizationGuard } from './components'
import { AppRoutes } from './config/routesConfig'
import { initMockServer, isAnyMockFeatureEnabled } from './data/mock-server'
import { queryClient } from './data/queryClient'
import { EventContextProvider, UserContextProvider } from './states'
import './styles.css'
import { AppThemeProvider } from './theme'

export const App = () => {
  if (isAnyMockFeatureEnabled()) {
    initMockServer()
  }

  return (
    <EventContextProvider>
      <AppThemeProvider>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <UserAuthorizationGuard>
              <BrowserRouter>
                <FullScreenContainer>
                  <AppRoutes />
                </FullScreenContainer>
              </BrowserRouter>
            </UserAuthorizationGuard>
          </UserContextProvider>
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition="bottom-left"
          />
        </QueryClientProvider>
      </AppThemeProvider>
    </EventContextProvider>
  )
}
