import { CredentialTemplateFieldValidValue } from '@swiftctrl/api-client'
import {
  Button,
  ColumnsType,
  DeleteOutlined,
  Empty,
  Form,
  Input,
  Layout,
  Modal,
  PlusOutlined,
  Table,
  Typography,
} from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { styled } from 'styled-components'
import { buildUniqueIdGenerator } from '../../../utils-hooks'

type CustomValidValuesEditorModalProps = {
  open: boolean
  validValues: CredentialTemplateFieldValidValue[]
  validator?: CustomValidValuesValidator
  type: CustomValidValuesEntryType
  onClose: () => void
  onSave: (values: CredentialTemplateFieldValidValue[]) => void
}

export type CustomValidValuesEntryType = 'string' | 'uuid'

/**
 * If the value is valid, this should return `undefined`.
 *
 * If the value is invalid, it should return an error message.
 */
export type CustomValidValuesValidator = (
  value: string | number,
) => string | undefined

type Entry = CredentialTemplateFieldValidValue & {
  id: string
}

export const CustomValidValuesEditorModal = ({
  open,
  validValues,
  validator,
  type,
  onClose,
  onSave,
}: CustomValidValuesEditorModalProps) => {
  const { generateId } = buildUniqueIdGenerator()

  const initialEntries = validValues.map((value) => ({
    ...value,
    id: generateId(),
  }))

  const [entries, setEntries] = useState<Entry[]>(initialEntries)

  const handleSave = () => {
    const validValues = entries.map(
      (entry): CredentialTemplateFieldValidValue => ({
        type: entry.type,
        value: entry.value,
      }),
    )

    onSave(validValues)
  }

  const addEntry = () => {
    setEntries((previous) => {
      const update = structuredClone(previous) as Entry[]

      update.push({
        id: generateId(),
        type,
        value: '',
      })

      return update
    })
  }

  const editEntry = (id: string, value: string) => {
    setEntries((previous) => {
      const update = structuredClone(previous) as Entry[]

      const entry = update.find((entry) => entry.id === id)!

      entry.value = value

      return update
    })
  }

  const deleteEntry = (id: string) => {
    setEntries((previous) => {
      const update = previous.filter((entry) => entry.id !== id)

      return update
    })
  }

  const columns = buildColumns(editEntry, deleteEntry, entries, validator)

  const resetAndClose = () => {
    setEntries(initialEntries)

    onClose()
  }

  const { isValid, validationMessage } = validate(entries, validator)

  return (
    <Modal
      title="Values"
      open={open}
      onCancel={resetAndClose}
      okText="Save"
      onOk={handleSave}
      okButtonProps={{ disabled: !isValid }}
      centered
      destroyOnClose
    >
      <Layout spacing="medium">
        <StyledTable
          showHeader={false}
          dataSource={entries}
          columns={columns}
          bordered
          pagination={false}
          locale={{
            emptyText: <Empty description="No valid values" />,
          }}
          rowKey="id"
        />
        <Button
          icon={<PlusOutlined />}
          size="middle"
          type="primary"
          onClick={addEntry}
        >
          Add new value
        </Button>
        {validationMessage && (
          <Typography.Text type="danger">{validationMessage}</Typography.Text>
        )}
      </Layout>
    </Modal>
  )
}

const buildColumns = (
  editEntry: (id: string, value: string) => void,
  deleteEntry: (id: string) => void,
  entries: Entry[],
  validator?: CustomValidValuesValidator,
): ColumnsType<Entry> => [
  {
    render: (_, entry, index) => {
      const { id, value } = entry

      const validationErrorMessage = validateEntry(entry, entries, validator)

      return (
        <Layout horizontal spacing="small">
          <StyledFormItem
            help={validationErrorMessage}
            validateStatus={validationErrorMessage ? 'error' : undefined}
          >
            <Input
              key={id}
              size="middle"
              value={value}
              onChange={(event) => editEntry(id, event.target.value)}
              autoFocus={index === entries.length - 1 && value === ''}
            />
          </StyledFormItem>
          <Button
            icon={<DeleteOutlined />}
            size="middle"
            danger
            type="text"
            onClick={() => deleteEntry(id)}
          />
        </Layout>
      )
    },
  },
]

const validate = (
  entries: Entry[],
  validator: CustomValidValuesValidator | undefined,
): { isValid: boolean; validationMessage: string } => {
  for (let i = 0; i < entries.length; i++) {
    const { value } = entries[i]

    if (!value) {
      return {
        isValid: false,
        validationMessage: '',
      }
    }

    const validationErrorMessage = validator?.(value)

    if (validationErrorMessage) {
      return {
        isValid: false,
        validationMessage: '',
      }
    }
  }

  const uniqueValues = new Set(entries.map((entry) => entry.value))

  if (uniqueValues.size !== entries.length) {
    return { isValid: false, validationMessage: '' }
  }

  return { isValid: true, validationMessage: '' }
}

const StyledTable: typeof Table = styled(Table)`
  width: 100%;
`

const validateEntry = (
  entry: Entry,
  entries: Entry[],
  validator: CustomValidValuesValidator | undefined,
) => {
  const { value } = entry

  if (!value) {
    return 'Value must not be empty'
  }

  const matchingEntries = entries.filter((entry) => entry.value === value)

  if (matchingEntries.length > 1) {
    return 'Value must not be duplicated'
  }

  if (!validator) {
    return
  }

  const validationMessage = validator(value)

  return validationMessage
}

const StyledFormItem = styled(Form.Item)`
  width: 100%;

  margin: 0px;
`
