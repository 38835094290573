import { PreLoader } from '@swiftctrl/swift-component-library'
import { useQuery } from '@tanstack/react-query'
import { swiftClient } from '../../../../../data/swiftClient'
import { cacheKeys } from '../../../../../utils-hooks'
import { SinkTemplateVariableField } from './SinkTemplateVariableField'

type SinkTemplateVariableFieldsProps = {
  sinkTemplateId: string
}

export const SinkTemplateVariableFields = ({
  sinkTemplateId,
}: SinkTemplateVariableFieldsProps) => {
  const { data, isLoading } = useQuery({
    queryKey: [cacheKeys.sink_template, sinkTemplateId],
    queryFn: () => swiftClient.sinkTemplate.at(sinkTemplateId).read(),
  })

  if (isLoading) {
    return <PreLoader size="small" />
  }

  return (
    <>
      {data!.data.variables.map((variable) => (
        <SinkTemplateVariableField key={variable.name} variable={variable} />
      ))}
    </>
  )
}
