import { AxiosResponse, BrowseConfig } from '@swiftctrl/api-client'
import { QueryFunction } from '@tanstack/react-query'

export type SDKEntityProvider<T> = {
  at: (id: string) => {
    read: (config?: BrowseConfig) => Promise<AxiosResponse<T, any>>
  }
}

export const adaptSDKReadRequest = <T>(
  provider: SDKEntityProvider<T>,
  id: string,
) => {
  const adapter: QueryFunction<T> = async ({ signal }) => {
    const { data } = await provider.at(id).read({ signal })

    return data
  }

  return adapter
}
