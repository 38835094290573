import { Skeleton, Breadcrumb } from '@swiftctrl/swift-component-library'
import { Link } from 'react-router-dom'
import { getEntityLabel } from '../../config/labels'
import { EntityType } from '../../data/models'
import { useHandleNavigationWithUnsavedChanges } from '../../utils-hooks'

type HeaderBreadCrumbsProps = {
  entityType: EntityType
  isBreadcrumbLoading?: boolean
  breadCrumbPath?: string | BreadCrumbItem[]
}

export interface BreadCrumbItem {
  label: string
  url?: string
}

export const HeaderBreadCrumbs = ({
  entityType,
  isBreadcrumbLoading,
  breadCrumbPath,
}: HeaderBreadCrumbsProps) => {
  const { handleClickCapture } = useHandleNavigationWithUnsavedChanges()

  if (!breadCrumbPath) {
    return null
  }

  if (isBreadcrumbLoading) {
    return <Skeleton active paragraph={false} title={{ width: 200 }} />
  }

  const entityLabel = getEntityLabel({ entityType })

  const entitiesLabel = getEntityLabel({
    entityType,
    singular: false,
  })

  const normalizedItems: BreadCrumbItem[] = Array.isArray(breadCrumbPath)
    ? breadCrumbPath
    : [{ url: breadCrumbPath, label: entitiesLabel }]

  return (
    <Breadcrumb>
      {normalizedItems.map((item) => {
        const { label, url } = item

        return (
          <Breadcrumb.Item key={label}>
            {url ? (
              <Link to={url} onClickCapture={handleClickCapture}>
                {label}
              </Link>
            ) : (
              label
            )}
          </Breadcrumb.Item>
        )
      })}
      <Breadcrumb.Item>{`${entityLabel} details`}</Breadcrumb.Item>
    </Breadcrumb>
  )
}
