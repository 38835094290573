import {
  ExclamationCircleOutlined,
  Modal,
} from '@swiftctrl/swift-component-library'
import { MouseEvent, MouseEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBooleanEventState, useEventContext } from '../states'

const MODAL_CLOSE_TIME = 100

export const useHandleNavigationWithUnsavedChanges = () => {
  const hasUnsavedChanges = useBooleanEventState('hasUnsavedChanges')

  const navigate = useNavigate()

  const { showUnsavedChangesModal } = useShowUnsavedChangesModal()

  const handleClickCapture: MouseEventHandler<HTMLElement> = (event) => {
    if (!hasUnsavedChanges) {
      return
    }

    const href = getLinkHref(event)

    if (!href) {
      return
    }

    event.preventDefault()

    showUnsavedChangesModal({
      onExit: () => {
        const { pathname } = new URL(href)

        navigate(pathname)
      },
    })
  }

  return {
    /**
     * Should be assigned to `onClickCapture` in order to prevent the
     * default navigation behavior
     */
    handleClickCapture,
  }
}

const getLinkHref = (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
  const { currentTarget, target } = event

  if ('href' in currentTarget) {
    return String(currentTarget.href)
  }

  if ('href' in target) {
    return String(target.href)
  }
}

export const useShowUnsavedChangesModal = () => {
  const { emitEvent } = useEventContext()

  const showUnsavedChangesModal = ({ onExit }: { onExit: () => void }) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Unsaved Changes',
      content: 'There are unsaved changes. Do you wish to exit anyways?',
      okText: 'Keep Editing',
      cancelText: 'Exit without Saving',
      onCancel: () => {
        setTimeout(() => {
          emitEvent('hasUnsavedChanges', false)

          onExit()
        }, MODAL_CLOSE_TIME)
      },
    })
  }

  return { showUnsavedChangesModal }
}
