import { useEffect } from 'react'

const APP_NAME = 'ConfigCloud'

export const useSetAppTitle = (pageName: string | undefined) => {
  useEffect(() => {
    document.title = buildTitle(pageName)

    return () => {
      document.title = APP_NAME
    }
  }, [pageName])
}

const buildTitle = (pageName: string | undefined) => {
  if (!pageName) {
    return APP_NAME
  }

  const title = `${pageName} | ${APP_NAME}`

  return title
}
