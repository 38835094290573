import { Form, Input } from '@swiftctrl/swift-component-library'
import { FormItemProps } from 'antd'
import { TextAreaProps } from 'antd/lib/input'

type EntityDescriptionInputProps = Pick<
  TextAreaProps,
  'defaultValue' | 'onChange'
> &
  Pick<FormItemProps, 'noStyle'>

export const EntityDescriptionInput = (props: EntityDescriptionInputProps) => {
  const { noStyle, ...textAreaProps } = props

  return (
    <Form.Item name="description" noStyle={noStyle}>
      <Input.TextArea
        rows={4}
        placeholder="Enter a description (optional)"
        size="large"
        {...textAreaProps}
      />
    </Form.Item>
  )
}
