import { SourceTypes } from '@swiftctrl/api-client'
import { buildEntityTypeTagsList } from '../../../utils-hooks'
import { DetailItem } from './DetailItem'

type SourceDetailsProps = {
  source: SourceTypes
}

export const SourceDetails = ({ source }: SourceDetailsProps) => {
  const { source_owner, type_names, source_target_entity } = source

  return (
    <>
      <DetailItem label="Owner" value={source_owner.entity_name} />
      <DetailItem
        label="Type names"
        value={buildEntityTypeTagsList(type_names)}
      />
      <DetailItem label="Target" value={source_target_entity.entity_name} />
    </>
  )
}
