import { notification } from '@swiftctrl/swift-component-library'
import { RestApiParamsKeys } from '../../types'

export const showNoEntityParamErrorNotification = (
  paramKey: RestApiParamsKeys,
) => {
  notification.error({
    message: `No entity for param "${paramKey}"`,
    description: 'The param has been removed from the URL',
    placement: 'bottomLeft',
    duration: 5,
  })
}
