import { PersonTypes } from '@swiftctrl/api-client'
import { Layout } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { useEventContext } from '../../../states'
import { useBoolean } from '../../../utils-hooks'
import { AddArchetypeView } from './AddArchetypeView'
import { AddButton } from './AddButton'
import { ArchetypesBrowser } from './ArchetypesBrowser'
import { ManagePersonRolesModal } from './assign-person-roles'
import { RolesBrowser } from './RolesBrowser'
import { useGetBuildings } from './useGetBuildings'

type PersonRolesBrowserProps = {
  person: PersonTypes
}

export const PersonRolesBrowser = ({ person }: PersonRolesBrowserProps) => {
  const [isAddArchetypeViewOpen, openAddArchetypeView, closeAddArchetypeView] =
    useBoolean()

  const [isAddRoleViewOpen, openAddRoleView, closeAddRoleView] = useBoolean()

  const { addButtonDisabled } = useAddButtonDisabled()

  const { data } = useGetBuildings({
    organizationId: person.primary_profile.organization_id,
    enabled: isAddArchetypeViewOpen,
  })

  return (
    <>
      <Layout horizontal justifyContent="end">
        <AddButton
          onAddArchetypeClick={openAddArchetypeView}
          onAddRoleClick={openAddRoleView}
          disabled={addButtonDisabled}
        />
      </Layout>
      <ArchetypesBrowser person={person}>
        {data && (
          <AddArchetypeView
            person={person}
            buildings={data}
            open={isAddArchetypeViewOpen}
            onClose={closeAddArchetypeView}
          />
        )}
      </ArchetypesBrowser>
      <RolesBrowser person={person}>
        <ManagePersonRolesModal
          person={person}
          open={isAddRoleViewOpen}
          onClose={closeAddRoleView}
        />
      </RolesBrowser>
    </>
  )
}

const useAddButtonDisabled = () => {
  const { useEvent } = useEventContext()

  useEvent('BrowseEntitiesContext.isFetching', (payload) => {
    const { entityType, isFetching } = payload

    switch (entityType) {
      case 'archetype':
        setIsFetchingArchetypes(isFetching)

        break

      case 'role':
        setIsFetchingRoles(isFetching)

        break
    }
  })

  const [isFetchingArchetypes, setIsFetchingArchetypes] = useState(true)

  const [isFetchingRoles, setIsFetchingRoles] = useState(true)

  const addButtonDisabled = isFetchingArchetypes || isFetchingRoles

  return { addButtonDisabled }
}
