import { Result } from '@swiftctrl/swift-component-library'
import { getEntityLabel } from '../../config/labels'
import { EntityType } from '../../data/models'
import { RedirectButton, RedirectButtonProps } from '../navigation'

interface InvalidIdResultProps extends RedirectButtonProps {
  entityType: EntityType
}

export const InvalidIdResult = ({
  entityType,
  ...buttonProps
}: InvalidIdResultProps) => (
  <Result
    status="warning"
    title={`${getEntityLabel({
      entityType,
      singular: true,
    })} ID in URL is invalid`}
    extra={<RedirectButton {...buttonProps} />}
  />
)
