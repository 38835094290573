import { swiftClient } from '../../../../data/swiftClient'
import {
  buildRoleProfilesInfiniteCacheKey,
  useBatchAddDeleteRequests,
} from '../../../../utils-hooks'
import { RoleProfile } from './buildRoleProfile'

type Params = {
  roleId: string
  onSuccess: () => void
  onSettled: () => void
}

export const useBatchAddDeleteProfiles = ({
  roleId,
  onSuccess,
  onSettled,
}: Params) => {
  const { batchAddDeleteRequests } = useBatchAddDeleteRequests<
    Partial<RoleProfile>
  >({
    addRequest: (profile: Partial<RoleProfile>) =>
      swiftClient.profile
        .at(profile.profile?.profile_id!)
        .role.at(roleId)
        .add(),
    buildAddErrorMessage: (profile, statusCode: string, errorMessage: string) =>
      `Status code ${statusCode} while adding profile "${profile.profile?.display_name}": "${errorMessage}"`,
    deleteRequest: (profile) => {
      const { overseer_id, profile_id } = profile.profile!

      const profileEndpoint = swiftClient.profile.at(profile_id!)

      if (overseer_id !== roleId) {
        return profileEndpoint.role.at(roleId).delete()
      } else {
        return profileEndpoint.delete()
      }
    },
    buildDeleteErrorMessage: (
      profile,
      statusCode: string,
      errorMessage: string,
    ) =>
      `Status code ${statusCode} while removing profile "${profile.profile?.display_name}": "${errorMessage}"`,
    invalidateQueryKeys: buildRoleProfilesInfiniteCacheKey(roleId),
    onSuccess,
    onSettled,
  })

  return { batchAddDeleteProfiles: batchAddDeleteRequests }
}
