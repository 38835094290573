import { useEffect, useRef } from 'react'

export const useInfiniteScroll = ({ callback }: { callback: () => void }) => {
  const scrollRef = useRef(null)

  useEffect(() => {
    const currentScrollRef = scrollRef.current

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          callback()
        }
      },
      { threshold: 0 },
    )

    if (currentScrollRef) {
      observer.observe(currentScrollRef)
    }

    return () => {
      if (currentScrollRef) {
        observer.unobserve(currentScrollRef)
      }
    }
  }, [scrollRef, callback])

  return { scrollRef }
}
