import { CredentialTemplateFieldConstraint } from '@swiftctrl/api-client'
import { DatePicker } from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { getCredentialTemplateFieldDateTimePickerDisabledDate } from '../../../components'
import {
  buildFakeLocalDateFromISODate,
  buildISOTimestampFromLocalDate,
} from '../../../utils-hooks'
import { Constraint } from '../utils'
import { ValidatedFormItem } from '../ValidatedFormItem'

type DefaultTimePickerProps = {
  defaultValue: string | null
  constraint: Constraint | null
  onChange: (value: string) => void
}

export const DefaultTimePicker = ({
  defaultValue,
  constraint,
  onChange,
}: DefaultTimePickerProps) => {
  const timeDefaultValue = defaultValue
    ? buildFakeLocalDateFromISODate(defaultValue)
    : undefined

  const disabledDate = getCredentialTemplateFieldDateTimePickerDisabledDate(
    constraint as CredentialTemplateFieldConstraint | null,
  )

  const handleChange = (value: Date | null) => {
    if (value) {
      const timestamp = buildISOTimestampFromLocalDate(value)

      onChange(timestamp)
    } else {
      onChange('')
    }
  }

  const isValid = validate(timeDefaultValue, disabledDate)

  return (
    <ValidatedFormItem
      isValid={isValid}
      validationMessage="Default value must be within the date_range"
    >
      <StyledDatePicker
        showTime
        showNow={false}
        size="small"
        bordered={false}
        suffixIcon={null}
        onChange={handleChange}
        placeholder=""
        disabledDate={disabledDate}
        defaultValue={timeDefaultValue}
        $isValid={isValid}
        allowClear
      />
    </ValidatedFormItem>
  )
}

const StyledDatePicker = styled(DatePicker)<{ $isValid: boolean }>`
  padding: 0px;

  input {
    color: ${({ $isValid }) => ($isValid ? '#000' : 'var(--ant-error-color)')};
  }
`

const validate = (
  timeDefaultValue: Date | undefined,
  disabledDate: (date: Date) => boolean,
) => {
  if (!timeDefaultValue) {
    return true
  }

  const isDisabledDate = disabledDate(timeDefaultValue)

  return !isDisabledDate
}
