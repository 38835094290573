export const buildSetFromArrayField = <ArrayType, SetType>(
  array: ArrayType[],
  key: keyof ArrayType,
) => {
  const set = new Set<SetType>()

  array.forEach((entry) => {
    const field = entry[key] as SetType

    set.add(field)
  })

  return set
}
