import { Menu } from '@swiftctrl/swift-component-library'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { navigationMenuItems } from '../../config/routesConfig'
import { Sider } from '../../theme'
import {
  useHandleNavigationWithUnsavedChanges,
  useSideMenuState,
} from '../../utils-hooks'
import { itemFactory } from '../../utils-hooks/router/ItemFactory'

export const AppSider = () => {
  const location = useLocation()

  const { sideMenuCollapse, handleSideMenuCollapse } = useSideMenuState()

  const items = navigationMenuItems.map(itemFactory)

  const NESTED_SIDER_PATHS = ['mobile-id']

  const currentLocation = location.pathname.split('/')[1]

  const currentItemKey = NESTED_SIDER_PATHS.includes(currentLocation)
    ? `${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}`
    : currentLocation

  const { handleClickCapture } = useHandleNavigationWithUnsavedChanges()

  return (
    <StyledAppSider
      collapsible
      collapsed={sideMenuCollapse}
      onCollapse={handleSideMenuCollapse}
    >
      <Menu
        selectedKeys={[currentItemKey]}
        mode="inline"
        items={items}
        onClickCapture={handleClickCapture}
      />
    </StyledAppSider>
  )
}

const StyledAppSider = styled(Sider)`
  padding-top: 20px;
  background-color: #fff;

  div > ul {
    border: none;
  }
`
