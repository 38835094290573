import {
  CredentialFieldConstraint,
  CredentialFieldValidValues,
} from '@swiftctrl/api-client'
import { Constraint } from '../utils'
import {
  CustomValidValuesEntryType,
  CustomValidValuesValidator,
} from './CustomValidValuesEditorModal'
import { CustomValidValuesView } from './CustomValidValuesView'
import { PresetValidValuesView } from './PresetValidValuesView'
import { hasValidValues } from './utils'

type ValidValuesViewProps = {
  templateConstraint: Constraint | null
  formatConstraint: CredentialFieldConstraint | undefined
  /**
   * If the value is valid, this should return `undefined`.
   *
   * If the value is invalid, it should return an error message.
   */

  customValidValuesValidator?: CustomValidValuesValidator
  customValidValuesEntryType: CustomValidValuesEntryType
  onChange: (constraint: Constraint | null) => void
}

export const ValidValuesView = ({
  templateConstraint,
  formatConstraint,
  customValidValuesValidator,
  customValidValuesEntryType,
  onChange,
}: ValidValuesViewProps) => {
  const templateValidValues = templateConstraint?.valid_values || []

  if (hasValidValues(formatConstraint)) {
    return (
      <PresetValidValuesView
        formatValidValues={
          formatConstraint!.value as CredentialFieldValidValues
        }
        templateValidValues={templateValidValues}
        onChange={onChange}
      />
    )
  }

  return (
    <CustomValidValuesView
      templateValidValues={templateValidValues}
      customValidValuesValidator={customValidValuesValidator}
      customValidValuesEntryType={customValidValuesEntryType}
      onChange={onChange}
    />
  )
}
