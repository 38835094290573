import { AxiosError, AxiosResponse, ErrorProps } from '@swiftctrl/api-client'

const LIMIT = 1000

const OFFSET_EXCEEDS_TOTAL_ERROR_MESSAGE = 'offset is greater than the total'

export const getAllFromBrowseRequest = async <T>(
  browseRequest: (
    offset: number,
    limit: number,
  ) => Promise<AxiosResponse<T[], any>>,
) => {
  const all: T[] = []

  let responseLength = 0

  do {
    try {
      let { data } = await browseRequest(all.length, LIMIT)

      all.push(...data)

      responseLength = data.length
    } catch (e: any) {
      if (totalIsMultipleOfLimit(e)) {
        break
      } else {
        throw e
      }
    }
  } while (responseLength === LIMIT)

  return all
}

export const totalIsMultipleOfLimit = (e: AxiosError<ErrorProps>) =>
  e.response?.data.message === OFFSET_EXCEEDS_TOTAL_ERROR_MESSAGE
