import { AntLayout } from '@swiftctrl/swift-component-library'
import { SiderProps } from 'antd'
import styled from 'styled-components'

export const Sider = ({ children, ...props }: SiderProps) => {
  return <StyledSider {...props}>{children}</StyledSider>
}

const StyledSider = styled(AntLayout.Sider)`
  .ant-layout-sider-trigger {
    background-color: var(--primary-color-darker-3);
  }
`
