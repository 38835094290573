import { ProfilesTypes } from '@swiftctrl/api-client'
import { EMPTY_ID } from '../data/models'
import { swiftClient } from '../data/swiftClient'
import { getLargeFilteredBrowseRequest } from './getLargeFilteredBrowseRequest'
import { FieldKey } from './typeUtils'

export type EntityWithProfile<T> = {
  key: string
  entity: T
  profile: Partial<ProfilesTypes>
}

export const combineEntitiesWithProfile = async <
  T extends { profile_id: string },
>(
  data: T[],
  key: FieldKey<T>,
  baseId: string,
  signal?: AbortSignal,
): Promise<EntityWithProfile<T>[]> => {
  const profileIds = data
    .map((entity) => entity.profile_id)
    .filter((profileId) => profileId !== EMPTY_ID)

  const profiles = await getLargeFilteredBrowseRequest(
    profileIds,
    (ids) => `profile_id ANY ${ids.join(',')}`,
    async (filters) => {
      const response = await swiftClient.profile.browse(
        {
          baseId,
          select: ['profile_id', 'display_name', 'person_id'],
          filters,
        },
        {
          signal,
        },
      )

      return response.data
    },
  )

  const mapIdToProfile = profiles.reduce(
    (map, profile) => map.set(profile.profile_id!, profile),
    new Map<string, Partial<ProfilesTypes>>(),
  )

  const combinedData = data.map((entity) => {
    const { profile_id } = entity

    const profile: Partial<ProfilesTypes> = mapIdToProfile.get(profile_id) || {
      profile_id,
    }

    return {
      key: String(entity[key]),
      entity,
      profile,
    }
  })

  return combinedData
}
