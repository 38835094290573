import { EpiTypes, FieldMapping } from '@swiftctrl/api-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FieldMappingsTable, getReadScreenQueryKey } from '../../components'
import { swiftClient } from '../../data/swiftClient'
import {
  cacheKeys,
  showErrorNotification,
  showSuccessNotification,
} from '../../utils-hooks'

type FieldMappingsViewProps = {
  epi: EpiTypes
}

export const FieldMappingsView = ({ epi }: FieldMappingsViewProps) => {
  const { epi_id, entity_fields_map } = epi

  const queryClient = useQueryClient()

  const editEpiMutation = useMutation({
    mutationFn: swiftClient.epi.at(epi_id).edit,
    onSuccess: (data) => {
      showSuccessNotification('Field mappings saved')

      queryClient.setQueryData(
        getReadScreenQueryKey(cacheKeys.epi, epi_id),
        data.data,
      )
    },
    onError: (error) =>
      showErrorNotification('Error while saving field mappings', error),
  })

  const save = (fieldMappings: FieldMapping[]) => {
    editEpiMutation.mutate({
      ...epi,
      entity_fields_map: fieldMappings.map((fieldMapping) => ({
        ...fieldMapping,
        epi_id,
      })),
    } as Partial<EpiTypes>)
  }

  return (
    <FieldMappingsTable
      fieldMappings={entity_fields_map}
      epiId={epi_id}
      confirmButtonLabel="Save"
      confirmButtonLoading={editEpiMutation.isPending}
      disableConfirmButtonWhenDataUnchanged
      onConfirm={save}
    />
  )
}
