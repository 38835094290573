type Environment = 'local' | 'dev' | 'qa'

const environmentToHostMap: Record<Environment, string> = {
  local: 'config.localhost.swiftconnect.io',
  dev: 'config.dev.swiftconnect.io',
  qa: 'config.qa.swiftconnect.io',
}

type Params = {
  environments: Environment[]
  localStorageKey: string
}

export const getEnvironmentLocalStorageSwitch = ({
  environments,
  localStorageKey,
}: Params) => {
  const allowedHosts = environments.map(
    (environment) => environmentToHostMap[environment],
  )

  const isOnAllowedHost = allowedHosts.includes(window.location.hostname)

  if (!isOnAllowedHost) {
    return false
  }

  const value = localStorage.getItem(localStorageKey)

  if (!value) {
    return false
  }

  const parsedValue = Number(value)

  if (isNaN(parsedValue)) {
    return false
  }

  const enabled = parsedValue !== 0

  return enabled
}
