import { AssociationFilter } from '@swiftctrl/api-client'
import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { Card } from 'antd'
import styled from 'styled-components'
import { LabelDisplay, Status } from '../../components'
import { renderFilterGroup } from './FilterGroupView'
import { InvertStatus } from './InvertStatus'

type AssociationFilterViewProps = {
  filter: AssociationFilter
}

export const AssociationFilterView = ({
  filter,
}: AssociationFilterViewProps) => {
  const {
    all_targets_match_filter,
    invert_association_filter_group,
    association_filter_group,
    association_strategy,
    filter_field,
    target,
  } = filter

  return (
    <StyledCard type="inner">
      <Layout spacing="small">
        <Layout horizontal spacing="large" paddingBottom="medium">
          <Status status={all_targets_match_filter ? 'Completed' : 'Required'}>
            all_targets_match_filter
          </Status>
          <InvertStatus isInverted={invert_association_filter_group} />
        </Layout>
        {renderFilterGroup(association_filter_group)}
        <Layout horizontal>
          <LabelDisplay label="association_strategy" />
          <Typography.Text code>{association_strategy}</Typography.Text>
        </Layout>
        <Layout horizontal>
          <LabelDisplay label="filter_field" />
          <Typography.Text code>{filter_field}</Typography.Text>
        </Layout>
        <Layout horizontal>
          <LabelDisplay label="target" />
          <Typography.Text code>{target}</Typography.Text>
        </Layout>
      </Layout>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .ant-card-body {
    padding: 24px;
  }
`
