import {
  AxiosResponse,
  LandlordAddTypes,
  OrganizationTypes,
} from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../data/swiftClient'
import { AddOrganizationFormData } from './models'

export const useAddOrganization = ({
  onSuccess,
}: {
  onSuccess: (response: AxiosResponse<OrganizationTypes>) => void
}) =>
  useMutation({
    mutationFn: (values: AddOrganizationFormData) => {
      const payload: LandlordAddTypes = {
        organization_name: values.name!,
        organization_short_name: values.short_name!.toLocaleUpperCase(),
        organization_tag: values.tag!,
      }

      return swiftClientWithoutErrorHandling.landlord.add(payload)
    },
    onSuccess,
  })
