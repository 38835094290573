import { EntityBrowseTypes, OrganizationTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { useQuery } from '@tanstack/react-query'
import { EntityType } from '../../../data/models'
import { swiftClientWithoutErrorHandling } from '../../../data/swiftClient'
import { cacheKeys, getAllFromBrowseRequest } from '../../../utils-hooks'

const nonDeletableEntityTypes: EntityType[] = [
  'address',
  'email',
  'person',
  'phone',
]

type Params = {
  organization: Partial<OrganizationTypes>
  enabled: boolean
}

export const useGetDeletableDescendants = ({ organization, enabled }: Params) =>
  useQuery({
    queryKey: [
      cacheKeys.organization_deletable_descendants,
      organization.organization_id,
    ],
    queryFn: async () => {
      const { organization_id } = organization

      const q = getQueryHandler<EntityBrowseTypes>()

      const filters = [
        q('WHERE', 'entity_id', 'N_EQ', organization_id),
        q('AND', 'entity_type_id', 'N_ANY', nonDeletableEntityTypes),
      ]

      return getAllFromBrowseRequest((offset, limit) =>
        swiftClientWithoutErrorHandling.entity.browse({
          baseId: organization_id,
          select: ['entity_id', 'entity_type_id', 'entity_name'],
          filters,
          offset,
          limit,
        }),
      )
    },
    enabled,
  })
