import { EntityType } from '../data/models'
import { swiftClient } from '../data/swiftClient'
import { SDKEntityProvider } from '../utils-hooks'

type ReadEntityConfig = {
  [entityType in EntityType]?: SDKEntityProvider<any>
}

const readEntityConfig: ReadEntityConfig = {
  building: swiftClient.building,
  controller: swiftClient.controller,
  credential_template: swiftClient.credentialTemplate,
  epi: swiftClient.epi,
  organization: swiftClient.organization,
  profile: swiftClient.profile,
  provider: swiftClient.provider,
  provider_profile: swiftClient.providerProfile,
  reader: swiftClient.reader,
  role: swiftClient.role,
}

export const hasReadRequest = (entityType: EntityType) =>
  entityType in readEntityConfig

export const getReadRequest = (entityType: EntityType) => {
  const readRequest = readEntityConfig[entityType]

  if (readRequest) {
    return readRequest
  }

  throw new Error(
    `readRequest for entity type ${entityType} is being obtained without first checking if it exists. This is a developer error - please let your friendly ConfigCloud team know about this 🧑‍💻`,
  )
}
