import {
  Button,
  DeleteOutlined,
  Form,
  Input,
  PlusOutlined,
  Table,
  TableProps,
} from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import styled from 'styled-components'
import { buildUniqueIdGenerator } from '../../utils-hooks'
import { handleConfirm } from './handleConfirm'
import { Column, Entry } from './types'
import {
  buildDataIndexToValidationRules,
  buildErrorKey,
  calculateColumnWidth,
  isEquivalent,
} from './utils'

type EditableTableProps<T> = Pick<TableProps<T>, 'locale'> & {
  columns: Column<T>[]
  initialData?: T[]
  confirmButtonLabel: string
  confirmButtonLoading?: boolean
  disableConfirmButtonWhenDataUnchanged?: boolean
  dataFactory: () => T
  onConfirm: (data: T[]) => void
}

/**
 * ## Overview
 * Table component with an input in each cell. The user can edit the
 * value of each cell, add rows, and delete rows.
 *
 * The confirm button is disabled while the data is the same as
 * `initialData`.
 *
 * When the user clicks confirm, the data is validated. If the data is not
 * valid, the validation error messages are shown in the invalid cells,
 * and `onConfirm` is not called.
 *
 * ## Input
 *
 * By default, the input is an `Input` component. It can be replaced
 * using the `columns['render']` prop.
 *
 * ## Validation
 *
 * The data is validated according to the `columns['validationRules']`
 * prop.
 *
 * Multiple validation error messages can be shown per cell.
 *
 * ## Limitations
 *
 * The `columns['dataIndex']` prop should not be `"key"`, as that
 * is reserved for a unique key per row. The `"key"` entry is removed
 * from the data before being passed to the `onConfirm` function.
 */
export const EditableTable = <T extends object>({
  columns: columnsProp,
  initialData = [],
  confirmButtonLabel,
  confirmButtonLoading,
  disableConfirmButtonWhenDataUnchanged = false,
  dataFactory,
  onConfirm,
  ...props
}: EditableTableProps<T>) => {
  const { generateId } = buildUniqueIdGenerator()

  const [entries, setEntries] = useState<Entry<T>[]>(
    initialData.map((item) => ({ ...item, key: generateId() })),
  )

  const { dataIndexToValidationRules } =
    buildDataIndexToValidationRules(columnsProp)

  const [errors, setErrors] = useState(new Map<string, string[]>())

  const editEntry = (entryKey: string, dataIndex: string, value: string) =>
    setEntries((dataSource) => {
      const update = [...dataSource]

      const entry = update.find((entry) => entry.key === entryKey)!

      // @ts-ignore dataIndex is not recognized as a key of entry, despite attempts to type it
      entry[dataIndex] = value

      return update
    })

  const addEntry = () =>
    setEntries((dataSource) => {
      const newData = {
        ...dataFactory(),
        key: generateId(),
      }

      return [...dataSource, newData]
    })

  const deleteEntry = (key: string) =>
    setEntries((dataSource) => dataSource.filter((item) => item.key !== key))

  return (
    <EditableTableContainer>
      <ConfirmButton
        size="middle"
        type="primary"
        disabled={
          disableConfirmButtonWhenDataUnchanged
            ? isEquivalent(initialData, entries)
            : false
        }
        loading={confirmButtonLoading}
        onClick={() =>
          handleConfirm(
            entries,
            dataIndexToValidationRules,
            setErrors,
            onConfirm,
          )
        }
      >
        {confirmButtonLabel}
      </ConfirmButton>
      <Table
        dataSource={entries}
        columns={buildColumns(columnsProp, errors, editEntry, deleteEntry)}
        pagination={false}
        size="small"
        bordered
        {...props}
      />
      <Button
        onClick={addEntry}
        size="middle"
        type="dashed"
        block
        icon={<PlusOutlined />}
      >
        Add a row
      </Button>
    </EditableTableContainer>
  )
}

const EditableTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`

const ConfirmButton = styled(Button)`
  align-self: flex-end;
`

const FormItem = styled(Form.Item)`
  margin: 0;
`

const ErrorMessage = styled.p`
  margin-bottom: 0;
`

const buildColumns = <T,>(
  columnsProp: Column<T>[],
  errors: Map<string, string[]>,
  editEntry: (entryKey: string, dataIndex: string, value: string) => void,
  deleteEntry: (entryKey: string) => void,
) => {
  const columnWidth = calculateColumnWidth(columnsProp)

  const columns = []

  columnsProp.forEach((col) => {
    columns.push(buildColumn(col, columnWidth, errors, editEntry))
  })

  columns.push({
    render: (entry: Entry<T>) => (
      <Button
        type="text"
        danger
        icon={<DeleteOutlined />}
        onClick={() => deleteEntry(entry.key)}
      />
    ),
    width: '20px',
  })

  return columns
}

const buildColumn = <T,>(
  columnProp: Column<T>,
  columnWidth: string,
  errors: Map<string, string[]>,
  editEntry: (entryKey: string, dataIndex: string, value: string) => void,
) => {
  const render = (value: string, entry: Entry<T>) => {
    const errorsForEntry = errors.get(
      buildErrorKey(entry.key, columnProp.dataIndex),
    )

    const handleChange = (value: string) =>
      editEntry(entry.key, columnProp.dataIndex, value)

    const renderInput = columnProp.renderInput || defaultRenderInput

    return (
      <FormItem
        validateStatus={errorsForEntry ? 'error' : undefined}
        help={errorsForEntry?.map((error) => (
          <ErrorMessage key={error}>{error}</ErrorMessage>
        ))}
      >
        {renderInput(value, handleChange)}
      </FormItem>
    )
  }

  return {
    ...columnProp,
    render,
    width: columnWidth,
  }
}

const defaultRenderInput = (
  value: string,
  onChange: (value: string) => void,
) => {
  return (
    <Input
      value={value}
      size="middle"
      onChange={(event) => onChange(event.target.value)}
    />
  )
}
