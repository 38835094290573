import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  SortableContextProps,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  ColumnsType,
  ColumnType,
  Layout,
  MenuOutlined,
  Table,
  TableProps,
} from '@swiftctrl/swift-component-library'
import {
  Children,
  cloneElement,
  CSSProperties,
  Dispatch,
  HTMLAttributes,
  ReactElement,
  SetStateAction,
} from 'react'
import { styled } from 'styled-components'

type DraggableRowTableProps<T> = Omit<
  TableProps<T>,
  'components' | 'rowKey'
> & {
  rowKey: keyof T
  setDataSource: Dispatch<SetStateAction<T[]>>
}

/**
 * Table with draggable rows for reordering the data.
 *
 * Prepends the `columns` with a column consisting of buttons for dragging the rows.
 */
export const DraggableRowTable = <T extends object>(
  props: DraggableRowTableProps<T>,
) => {
  const { rowKey, columns: columnsProp, setDataSource, ...tableProps } = props

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over.id) {
      return
    }

    setDataSource((previous) => {
      const fromIndex = previous.findIndex(
        (field) => field[rowKey] === active.id,
      )

      const toIndex = previous.findIndex((field) => field[rowKey] === over.id)

      const update = arrayMove(previous, fromIndex, toIndex)

      return update
    })
  }

  const columns = prependMoveRowColumn(columnsProp)

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
      <SortableContext
        items={getItems(tableProps.dataSource, rowKey)}
        strategy={verticalListSortingStrategy}
      >
        <Table<T>
          {...tableProps}
          rowKey={String(rowKey)}
          columns={columns}
          components={{ body: { row: Row } }}
        />
      </SortableContext>
    </DndContext>
  )
}

interface RowProps extends HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  })

  const style: CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {Children.map(children, (child) => {
        if ((child as ReactElement).key === 'sort') {
          return cloneElement(child as ReactElement, {
            children: (
              <Layout alignItems="center">
                <StyledMenuOutlined ref={setActivatorNodeRef} {...listeners} />
              </Layout>
            ),
          })
        }

        return child
      })}
    </tr>
  )
}

const StyledMenuOutlined = styled(MenuOutlined)`
  cursor: move;
`

const prependMoveRowColumn = <T,>(columns: ColumnsType<T> | undefined) => {
  const moveRowColumn: ColumnType<T> = {
    title: 'Sort',
    key: 'sort',
    width: '62px',
    shouldCellUpdate: () => false,
  }

  if (!columns) {
    return [moveRowColumn]
  }

  return [moveRowColumn, ...columns]
}

const getItems = <T,>(
  dataSource: readonly T[] | undefined,
  rowKey: keyof T,
): SortableContextProps['items'] => {
  if (!dataSource || !rowKey) {
    return []
  }

  const items = dataSource.map((entry) => String(entry[rowKey]))

  return items
}
