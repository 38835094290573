import { SinkFilter, SinkFilterGroup } from '@swiftctrl/api-client'
import {
  Layout,
  Tag,
  Tooltip,
  Typography,
} from '@swiftctrl/swift-component-library'
import { Card } from 'antd'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { Status } from '../../components'
import { FilterGroupStatus } from './FilterGroupStatus'

type FilterGroupViewProps = {
  label: string
  filterGroup: SinkFilterGroup | SinkFilterGroup[] | null
  invert: boolean
  extraStatus?: ReactNode
}

export const FilterGroupView = ({
  label,
  filterGroup,
  invert,
  extraStatus,
}: FilterGroupViewProps) => (
  <StyledParentCard
    title={label}
    extra={<FilterGroupStatus invert={invert} extraStatus={extraStatus} />}
  >
    {renderFilterGroup(filterGroup)}
  </StyledParentCard>
)

const RenderFilter = ({ filter }: { filter: SinkFilter }) => {
  const fieldRef = useRef<HTMLSpanElement>(null)

  const valueRef = useRef<HTMLSpanElement>(null)

  const [isFieldOverflowing, setIsFieldOverflowing] = useState(false)

  const [isValueOverflowing, setIsValueOverflowing] = useState(false)

  const isOverflowing = (ref: React.RefObject<HTMLSpanElement>) => {
    if (ref.current) {
      return ref.current.offsetWidth < ref.current.scrollWidth
    }
    return false
  }

  useEffect(() => {
    setIsFieldOverflowing(isOverflowing(fieldRef))

    setIsValueOverflowing(isOverflowing(valueRef))
  }, [filter])

  return (
    <Container>
      <FieldValueTag>
        <Tooltip title={isFieldOverflowing ? filter.field : ''}>
          <Typography.Text ellipsis ref={fieldRef}>
            {filter.field || '-'}
          </Typography.Text>
        </Tooltip>
      </FieldValueTag>
      <OperatorTag>{filter.operator || '-'}</OperatorTag>
      <FieldValueTag>
        <Tooltip title={isValueOverflowing ? filter.value : ''}>
          <Typography.Text ellipsis ref={valueRef}>
            {filter.value || '-'}
          </Typography.Text>
        </Tooltip>
      </FieldValueTag>
    </Container>
  )
}

export const renderFilterGroup = (
  filterGroup: SinkFilterGroup | SinkFilterGroup[] | null | undefined,
) => {
  if (!filterGroup) {
    return '-'
  }

  const filterGroupsArray = Array.isArray(filterGroup)
    ? filterGroup
    : [filterGroup]

  return filterGroupsArray.map((group, index) => (
    <StyledCard
      key={index}
      title={
        <Layout horizontal grow justifyContent="space-between">
          <Tag color="gold">{group.operator}</Tag>
          <Status status={group.not ? 'Completed' : 'Required'}>not</Status>
        </Layout>
      }
      type="inner"
    >
      {group.filters &&
        group.filters.filters.map((filter, index) => (
          <RenderFilter key={index} filter={filter} />
        ))}
      {group.filter_groups &&
        group.filter_groups.filter_groups.map(renderFilterGroup)}
    </StyledCard>
  ))
}

const StyledCard = styled(Card)`
  width: 100%;

  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .ant-card-head-title {
    font-weight: normal;
  }

  .ant-card-body {
    padding: 24px;
  }
`

const BaseTag = styled(Tag)`
  margin-right: 0;
`

const FieldValueTag = styled(BaseTag)`
  min-width: 0;
`

const OperatorTag = styled(BaseTag)``

const Container = styled.div`
  width: 100%;

  padding-bottom: 12px;

  &:last-of-type {
    padding-bottom: 0;
  }

  display: flex;

  gap: 8px;
`

const StyledParentCard = styled(Card)`
  width: 100%;
`
