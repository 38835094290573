import { AxiosResponse } from '@swiftctrl/api-client'
import { EntityType } from '../data/models'
import { swiftClient } from '../data/swiftClient'

interface Config {
  editRequest: (data: any) => Promise<AxiosResponse<any, any>>
  readBeforeEdit: boolean
}

type EditEntityConfig = {
  [entityType in EntityType]?: Config
}

const editEntityConfig: EditEntityConfig = {
  building: {
    editRequest: (data) => swiftClient.building.at(data.building_id).edit(data),
    readBeforeEdit: true,
  },
  credential_template: {
    editRequest: (data) =>
      swiftClient.credentialTemplate
        .at(data.credential_format_config_id)
        .edit(data),
    readBeforeEdit: true,
  },
  epi: {
    editRequest: (data) => swiftClient.epi.at(data.epi_id).edit(data),
    readBeforeEdit: true,
  },
  organization: {
    editRequest: (data) =>
      swiftClient.organization.at(data.organization_id).edit(data),
    readBeforeEdit: true,
  },
  provider: {
    editRequest: (data) => swiftClient.provider.at(data.provider_id).edit(data),
    readBeforeEdit: true,
  },
  provider_profile: {
    editRequest: (data) =>
      swiftClient.providerProfile.at(data.provider_profile_id).edit(data),
    readBeforeEdit: true,
  },
  role: {
    editRequest: (data) => swiftClient.role.at(data.role_id).edit(data),
    readBeforeEdit: true,
  },
}

export const hasEditEntityConfig = (entityType: EntityType) =>
  entityType in editEntityConfig

export const getEditEntityConfig = (entityType: EntityType) => {
  const editRequest = editEntityConfig[entityType]

  if (editRequest) {
    return editRequest
  }

  throw new Error(
    `editRequest for entity type ${entityType} is being obtained without first checking if it exists. This is a developer error - please let your friendly ConfigCloud team know about this 🧑‍💻`,
  )
}
