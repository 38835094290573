import { DataNode } from 'antd/lib/tree'

export const findParentKeysPath = (
  data: DataNode[],
  targetKey?: string | number,
): (string | number)[] | null => {
  if (!targetKey) {
    return null
  }

  const search = (
    node: DataNode,
    path: (string | number)[],
  ): (string | number)[] | null => {
    if (node.key === targetKey) {
      return path
    }

    if (!node.children) {
      return null
    }

    for (const child of node.children) {
      const result = search(child, [...path, node.key as string | number])
      if (result) {
        return result
      }
    }

    return null
  }

  for (const node of data) {
    const result = search(node, [])
    if (result) {
      return result
    }
  }

  return null
}
