import { EpiFilterTypes, EpiTypes } from '@swiftctrl/api-client'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { useState } from 'react'
import { SWIFT_ROOT_ENTITY_ID } from '../../../../data/models'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
} from '../../../../states'
import {
  adaptSDKBrowseRequest,
  cacheKeys,
  isValidUuid,
  useGetValidUuidParams,
  useHandleUrlParams,
} from '../../../../utils-hooks'
import {
  CARDS_DATA_PER_FETCH,
  EntitiesCardsBrowser,
} from '../../EntitiesCardsBrowser'
import { EpiCard } from './EpiCard'
import { EpisBrowserTopBar } from './EpisBrowserTopBar'

type EpisBrowserProps = {
  /**
   * If omitted, an entity select will be displayed so the user can filter the EPIs
   * by organization
   */
  organizationId?: string
}

export const EpisBrowser = ({
  organizationId: organizationIdProp,
}: EpisBrowserProps) => {
  const { currentParams } = useHandleUrlParams()

  const [search, setSearch] = useState(currentParams.search || '')

  const params = useGetValidUuidParams(['organization-id', 'epi-type-id'])

  const [organizationId, setOrganizationId] = useState(
    organizationIdProp || params['organization-id'] || '',
  )

  const [epiType, setEpiType] = useState(params['epi-type-id'] || '')

  return (
    <>
      <BrowseEntitiesContextProvider
        dataPerFetch={CARDS_DATA_PER_FETCH}
        browse={buildBrowse(search, organizationId, epiType)}
        dataKey="epi_id"
        entityType="epi"
        baseId={organizationId || SWIFT_ROOT_ENTITY_ID}
        key={organizationId}
        topBar={
          <EpisBrowserTopBar
            displayOrganizationFilter={!organizationIdProp}
            search={search}
            organizationId={organizationId}
            epiType={epiType}
            onSearch={setSearch}
            onOrganizationFilter={setOrganizationId}
            onEpiTypeFilter={setEpiType}
          />
        }
      >
        <EntitiesCardsBrowser render={render} />
      </BrowseEntitiesContextProvider>
    </>
  )
}

const buildBrowse = (
  search: string,
  organizationId: string,
  epiTypeId: string,
): BrowseEntitiesConfig<EpiTypes> => ({
  request: adaptSDKBrowseRequest({
    request: swiftClient.epi.browse,
    select: [
      'epi_id',
      'overseer_name',
      'entity_name',
      'entity_description',
      'created_at',
      'updated_at',
      'type',
      'subtype',
      'on_premises',
    ],
    getFilters: () => {
      if (!search && !epiTypeId) {
        return undefined
      }

      const q = getQueryHandler<EpiFilterTypes>()

      if (!search) {
        return [q('WHERE', 'epi_type_id', 'EQ', epiTypeId)]
      }

      const searchFilters = isValidUuid(search)
        ? [
            q('WHERE', 'epi_id', 'EQ', search),
            q('OR', 'overseer_id', 'EQ', search),
          ]
        : [
            q('WHERE', 'entity_name', 'EQ_IC', search),
            q('OR', 'entity_description', 'EQ_IC', search),
          ]

      if (!epiTypeId) {
        return searchFilters
      }

      const filters = [
        q('WHERE', 'epi_type_id', 'EQ', epiTypeId),
        q('AND', searchFilters),
      ]

      return filters
    },
    sort: 'entity_name:asc',
  }),
  cacheKey: [cacheKeys.epis, search, organizationId, epiTypeId],
})

const render = (epi: EpiTypes) => <EpiCard epi={epi} />
