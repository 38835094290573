import { AxiosResponse, EntityTypes } from '@swiftctrl/api-client'
import { EntityType } from '../../../data/models'
import { swiftClientWithoutErrorHandling } from '../../../data/swiftClient'

export type TreeItem = {
  data: Partial<EntityTypes>
  children: TreeItem[]
}

export type EntityTypeToEntitiesArray = {
  entityType: EntityType
  entities: Partial<EntityTypes>[]
}[]

export type DeleteOrganizationModalContentProps = {
  entityTypeToEntitiesArray: EntityTypeToEntitiesArray | undefined
  entitiesCount: number | undefined
  deletedCount: number
  error: Error | undefined
}

type CollectionWithDeletion = {
  at: (id: string) => { delete: () => Promise<AxiosResponse<any, any>> }
}

const entityTypeToCollectionWithDeletionMap: {
  [type in EntityType]?: CollectionWithDeletion
} = {
  association: swiftClientWithoutErrorHandling.association,
  entity: swiftClientWithoutErrorHandling.entity,
  organization: swiftClientWithoutErrorHandling.organization,
  portfolio: swiftClientWithoutErrorHandling.portfolio,
  provider: swiftClientWithoutErrorHandling.provider,
  role: swiftClientWithoutErrorHandling.role,
}

export const getCollectionWithDeletion = (entityType: EntityType) => {
  const collection = entityTypeToCollectionWithDeletionMap[entityType]

  if (!collection) {
    throw new Error(
      'getCollectionWithDeletion called before checking areAllEntityTypesConfigured',
    )
  }

  return collection
}

export const areAllEntityTypesConfigured = (
  entityTypeToEntitiesArray: EntityTypeToEntitiesArray,
) => {
  for (let i = 0; i < entityTypeToEntitiesArray.length; i++) {
    const { entityType } = entityTypeToEntitiesArray[i]

    if (!isEntityTypeConfigured(entityType)) {
      return false
    }
  }

  return true
}

export const isEntityTypeConfigured = (entityType: EntityType) =>
  entityType in entityTypeToCollectionWithDeletionMap
