import { SinkPlaceholder, SinkRule } from '@swiftctrl/api-client'
import { RuleFilterOption } from './RuleIdFilters'

export const getRuleFilterOptions = (
  placeholders: SinkPlaceholder[],
  rules: SinkRule[],
) => {
  const ruleIdToRuleName = new Map<string, string>()

  rules.forEach((rule) => {
    const { rule_id, rule_name } = rule

    ruleIdToRuleName.set(rule_id, rule_name)
  })

  const ruleIdToCount = new Map<string, number>()

  placeholders.forEach((placeholder) => {
    const { sink_rule_id } = placeholder

    const count = ruleIdToCount.get(sink_rule_id) || 0

    ruleIdToCount.set(sink_rule_id, count + 1)
  })

  const ruleFilterOptions = new Array<RuleFilterOption>()

  ruleIdToCount.forEach((count, ruleId) => {
    const ruleName = ruleIdToRuleName.get(ruleId) || ruleId

    ruleFilterOptions.push({
      ruleId,
      ruleName,
      count,
    })
  })

  return ruleFilterOptions
}
