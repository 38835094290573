import { SourceTypes } from '@swiftctrl/api-client'
import {
  Button,
  DeleteOutlined,
  Modal,
  Typography,
} from '@swiftctrl/swift-component-library'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../../data/queryClient'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import {
  cacheKeys,
  showErrorNotification,
  showSuccessNotification,
} from '../../../../utils-hooks'

type DeleteSourceButtonProps = {
  source: SourceTypes
}

export const DeleteSourceButton = ({ source }: DeleteSourceButtonProps) => {
  const confirmationModal = () => {
    Modal.confirm({
      title: `Delete ${source.entity_name}?`,
      content: (
        <Typography.Text>
          Are you sure you want to delete this source?
        </Typography.Text>
      ),
      okText: 'Delete',
      okButtonProps: {
        danger: true,
      },
      onOk: () => deleteSourceMutate.mutate({}),
    })
  }

  const deleteSourceMutate = useMutation({
    mutationFn: swiftClientWithoutErrorHandling.source.at(source.source_id)
      .delete,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [cacheKeys.sources] })

      showSuccessNotification('Source was successfully deleted')
    },
    onError: (error) =>
      showErrorNotification('Could not delete the source', error),
  })

  return (
    <Button
      type="text"
      danger
      icon={<DeleteOutlined />}
      onClick={confirmationModal}
      loading={deleteSourceMutate.isPending}
    />
  )
}
