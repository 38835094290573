import { EntityTypes } from '@swiftctrl/api-client'
import { Layout, Typography } from '@swiftctrl/swift-component-library'
import { CopyableDisplay, EntityTypeTag } from '../../../components'

type RoleTargetViewProps = {
  target: Partial<EntityTypes>
}

export const RoleTargetView = ({ target }: RoleTargetViewProps) => {
  const { entity_type_id, entity_name, overseer_name, entity_id, overseer_id } =
    target

  return (
    <Layout grow>
      <Layout horizontal spacing="small" alignItems="center">
        <EntityTypeTag entityType={entity_type_id!} />
        <CopyableDisplay label={entity_name} copyableText={entity_id} />
      </Layout>
      <Typography.Text type="secondary">
        Overseer:{' '}
        <CopyableDisplay
          secondary
          label={overseer_name}
          copyableText={overseer_id}
        />
      </Typography.Text>
    </Layout>
  )
}
