import { SinkRuleField } from '@swiftctrl/api-client'
import {
  Card,
  ColumnsType,
  Layout,
  Table,
  Typography,
  UserOutlined,
} from '@swiftctrl/swift-component-library'
import { styled } from 'styled-components'
import { RulesTableFieldName } from './RulesTableFieldName'
import { RulesTableValue } from './RulesTableValue'

interface RuleFieldsProps {
  ruleFields: SinkRuleField[]
}

export const RuleFields = ({ ruleFields }: RuleFieldsProps) => {
  const columns = getColumns()

  const ruleFieldsWithId = ruleFields.map((ruleField, index) => ({
    ...ruleField,
    key: index,
  }))
  return (
    <Layout grow paddingBottom="large">
      <Card title="Rule fields">
        <Layout grow>
          <Table
            pagination={false}
            dataSource={ruleFieldsWithId}
            bordered
            columns={columns}
          />
        </Layout>
        <Layout
          justifyContent="flex-end"
          horizontal
          alignItems="center"
          spacing="xSmall"
          paddingTop="small"
        >
          <StyledUserOutlined />
          <Typography.Text type="secondary">
            User Predefined Field
          </Typography.Text>
        </Layout>
      </Card>
    </Layout>
  )
}

const getColumns = (): ColumnsType<SinkRuleField> => {
  const columns = [
    {
      title: 'Field Name',
      key: 'field',
      width: '50%',
      render: (_: string, record: SinkRuleField) => (
        <RulesTableFieldName record={record} />
      ),
    },
    {
      title: 'Value',
      key: 'value',
      width: '50%',
      render: (_: string, record: SinkRuleField) => (
        <RulesTableValue record={record} />
      ),
    },
  ]

  return columns
}

const StyledUserOutlined = styled(UserOutlined)`
  color: #1890ff;
`
