import {
  Card,
  Col,
  LoadingOutlined,
  Result,
  Row,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import {
  EntityAncestryView,
  EntityDetails,
  ErrorResult,
  HTMLTitle,
  InvalidIdResult,
} from '../components'
import { ENTITIES_SCREEN_PATH } from '../config/paths'
import {
  getErrorMessage,
  isValidUuid,
  useReadEntity,
  useReadEntityDetails,
} from '../utils-hooks'

const PADDING = '1em'

type ReadEntityScreenProps = {
  entityId: string
}

export const ReadEntityScreen = ({ entityId }: ReadEntityScreenProps) => {
  const { data, isError, error } = useReadEntity(
    entityId,
    isValidUuid(entityId),
  )

  const { entityDetails, isReadingEntityDetails } = useReadEntityDetails(data)

  const errorButtonLabel = 'Return to entities search'

  const errorButtonClickNavigatePath = `/${ENTITIES_SCREEN_PATH}/search`

  const is404Error = String(error).includes('404')

  if (isError) {
    return (
      <ErrorResult
        label={errorButtonLabel}
        redirectPath={errorButtonClickNavigatePath}
        error={is404Error ? '404' : getErrorMessage(error)}
      />
    )
  }

  if (!entityId || !isValidUuid(entityId)) {
    return (
      <InvalidIdResult
        entityType="entity"
        label={errorButtonLabel}
        redirectPath={errorButtonClickNavigatePath}
      />
    )
  }

  if (!data) {
    return <Result icon={<LoadingOutlined />} title="Reading entity" />
  }

  return (
    <StyledContainer>
      <HTMLTitle value={entityDetails?.display_name || data.entity_name} />
      <Row gutter={24}>
        <Col span={12}>
          <EntityDetails
            entity={data}
            performingRead={isReadingEntityDetails}
            readResponseData={entityDetails}
          />
        </Col>
        <Col span={12}>
          <Card title="Ancestry">
            <StyledEntityAncestryView entity={data} />
          </Card>
        </Col>
      </Row>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  padding: ${PADDING};
`

const StyledEntityAncestryView = styled(EntityAncestryView)`
  padding: 7px 0 0 0;
`
