import { Form, Input } from '@swiftctrl/swift-component-library'
import { buildCommonFormItemProps } from '../../../../utils-hooks'

export const AddProfileFormItemLastNameInput = () => {
  return (
    <Form.Item
      {...buildCommonFormItemProps({
        name: 'lastName',
        label: 'Last name',
        required: true,
      })}
    >
      <Input />
    </Form.Item>
  )
}
