import { Button } from '@swiftctrl/swift-component-library'
import { Divider } from 'antd'
import { EntityDetails, EntityDetailsProps } from '../../../components'
import { hasDeleteRequest } from '../../../config/deleteEntityConfig'
import { SWIFT_ROOT_ENTITY_ID } from '../../../data/models'

type ExtendedEntityDetailsProps = EntityDetailsProps & {
  onDeleteClick?: () => void
}

export const ExtendedEntityDetails = ({
  onDeleteClick,
  ...entityDetailsProps
}: ExtendedEntityDetailsProps) => {
  const { entity } = entityDetailsProps

  const { entity_type_id, entity_id } = entity

  const canBeDeleted =
    hasDeleteRequest(entity_type_id) && entity_id !== SWIFT_ROOT_ENTITY_ID

  return (
    <EntityDetails {...entityDetailsProps}>
      {canBeDeleted && (
        <>
          <Divider />
          <Button danger size="small" onClick={onDeleteClick}>
            Delete
          </Button>
        </>
      )}
    </EntityDetails>
  )
}
