import { EntityType } from '../data/models'
import { getEntityNameKey } from './getEntityNameKey'

export const getEntityName = <T extends object>(
  entity: T,
  entityType: EntityType,
) => {
  const nameKey = getEntityNameKey(entityType) as keyof T

  const name = entity[nameKey]

  if (name === null || name === undefined) {
    return ''
  }

  return String(name)
}
