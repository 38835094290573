import { EntityTypes } from '@swiftctrl/api-client'

export const checkSelectedTargetsDirtiness = (
  assignedTargets: Partial<EntityTypes>[],
  selectedTargets: Partial<EntityTypes>[],
) => {
  if (assignedTargets.length !== selectedTargets.length) {
    return true
  }

  const assignedTargetIds = assignedTargets.map((target) => target.entity_id!)

  const selectedTargetIds = selectedTargets.map((target) => target.entity_id!)

  const areEqual = assignedTargetIds.every((targetId) =>
    selectedTargetIds.includes(targetId),
  )

  return !areEqual
}
