import { BuildingTypes } from '@swiftctrl/api-client'
import { swiftClient } from '../../../../data/swiftClient'
import {
  BrowseEntitiesConfig,
  BrowseEntitiesContextProvider,
  EntitiesBrowserProps,
} from '../../../../states'
import { adaptSDKBrowseRequest, cacheKeys } from '../../../../utils-hooks'
import {
  CARDS_DATA_PER_FETCH,
  EntitiesCardsBrowser,
} from '../../EntitiesCardsBrowser'
import { BuildingCard } from './BuildingCard'
import { buildBrowseBuildingsFiltersParam } from './utils'

export const BuildingsBrowser = (props: EntitiesBrowserProps) => (
  <BrowseEntitiesContextProvider
    dataPerFetch={CARDS_DATA_PER_FETCH}
    browse={browse}
    dataKey="building_id"
    entityType="building"
    searchable
    {...props}
  >
    <EntitiesCardsBrowser render={render} />
  </BrowseEntitiesContextProvider>
)

const browse: BrowseEntitiesConfig<BuildingTypes> = {
  request: adaptSDKBrowseRequest({
    request: swiftClient.building.browse,
    select: [
      'building_id',
      'created_at',
      'entity_description',
      'entity_name',
      'overseer_id',
      'overseer_name',
      'updated_at',
      'address',
    ],
    getFilters: buildBrowseBuildingsFiltersParam,
    sort: 'entity_name:asc',
  }),
  cacheKey: cacheKeys.buildings,
}

const render = (building: BuildingTypes) => <BuildingCard building={building} />
