import {
  AxiosError,
  ConfigProps,
  ErrorProps,
  initSwiftClient,
} from '@swiftctrl/api-client'
import { config } from '../config/config'
import {
  goToAccountsApp,
  showErrorNotification,
  totalIsMultipleOfLimit,
} from '../utils-hooks'

export type BackendError = AxiosError<ErrorProps>

const { baseUrl, version } = config.swiftConnectApi

export const errorHandler = (error: BackendError) => {
  const statusCode = getStatusCode(error)

  if (statusCode === 401) {
    goToAccountsApp('signin')
  } else if (!isCanceledError(error)) {
    if (!totalIsMultipleOfLimit(error as AxiosError<ErrorProps>)) {
      showErrorNotification(`Status code ${statusCode}`, error)
    }
  }

  return Promise.reject(error)
}

export const getStatusCode = (error: BackendError) => {
  return error.response?.status
}

export const isCanceledError = (error: BackendError) => {
  return error.code === 'ERR_CANCELED'
}

export const isNotFoundError = (error: BackendError) => {
  const statusCode = getStatusCode(error)

  return statusCode === 404
}

const sdkConfig: ConfigProps['config'] = {
  environment: {
    baseUrl: baseUrl,
    apiVersion: parseInt(version || '2', 10),
  },
}

export const swiftClient = initSwiftClient({
  ...sdkConfig,
  interceptors: {
    response: [{ onRejected: errorHandler }],
  },
})

export const swiftClientWithoutErrorHandling = initSwiftClient(sdkConfig)
