import { styled } from 'styled-components'
import { useInfiniteScroll } from '../utils-hooks'

export const InfiniteScrollTrigger = ({
  callback,
}: {
  callback: () => void
}) => {
  const { scrollRef } = useInfiniteScroll({
    callback,
  })

  return <StyledScrollDiv ref={scrollRef} />
}

const StyledScrollDiv = styled.div`
  height: 1px;
`
