import { Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { PARENT_CHILD_RELATIONSHIP_COLOR } from '../../../utils-hooks/add-entity-subtree-utils'
import { SOURCE_SINK_RELATIONSHIP_COLOR } from './models'

export const ChartKey = () => (
  <Container>
    <Typography.Text strong>Key: </Typography.Text>
    <span style={{ color: PARENT_CHILD_RELATIONSHIP_COLOR }}>
      Parent-child relationship
    </span>
    {' | '}
    <span style={{ color: SOURCE_SINK_RELATIONSHIP_COLOR }}>
      Source-sink relationship
    </span>
  </Container>
)

const Container = styled.div`
  position: absolute;

  bottom: -1px;
  left: 0px;

  background-color: #fff;
`
