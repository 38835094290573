import { useMutation } from '@tanstack/react-query'
import { swiftClient } from '../../data/swiftClient'

interface UseEditRoleProfileParams {
  profileIds: string[]
  roleId: string
}

export const useEditRoleProfile = () => {
  const editRoleProfile = ({ roleId, profileIds }: UseEditRoleProfileParams) =>
    swiftClient.role.at(roleId).profiles.edit(profileIds)

  return useMutation({ mutationFn: editRoleProfile })
}
